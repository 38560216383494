import React from 'react';
import {
    SmsEditorContainer,
    EmailEditorContainer,
    //MessengerEditorContainer
} from './../../../../redux/containers/V2/CampaignCreateContainer';
import Constants from './../../../../Constants';

const CampaignDesign = ({ transports }) => {
    return (
        <>
            {transports.includes(Constants.TRANSPORT_SMS) && <SmsEditorContainer />}

            {transports.includes(Constants.TRANSPORT_EMAIL) && <EmailEditorContainer />}

        </>

    )
};

export default CampaignDesign;
