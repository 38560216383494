import React, { forwardRef, useCallback, useState, useEffect, useImperativeHandle } from 'react';
import { BillingService } from './../../services/Services';
import LoadingComponent from './../utils/LoadingComponent';
import AddPaymentMethod from './AddPaymentMethod';
import { Card, Form } from '@shoutout-labs/shoutout-themes';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import './CardPayment.css';
// import { notify } from 'react-notify-toast';


const CardPayment = forwardRef(({ billingInfo, selectedPlan, hideAddCard = false }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [cardHolderName, setCardHolderName] = useState("");


    const elements = useElements();

    const stripe = useStripe();


    const onChange = useCallback((e) => {
        setCardHolderName(e.target.value);
    }, [setCardHolderName])

    const loadPaymentMethods = useCallback(async () => {
        try {
            setIsLoading(true);
            const paymentMethodsResponse = await BillingService.getPaymentMethods();
            setPaymentMethods(paymentMethodsResponse?.paymentMethods || []);
            if (paymentMethodsResponse?.paymentMethods?.length > 0) {
                setSelectedPaymentMethod(paymentMethodsResponse?.paymentMethods[0]?.id);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, [setPaymentMethods, setIsLoading])


    const onChangePaymentMethod = useCallback((e) => {
        console.debug(e.target);
        setSelectedPaymentMethod(e.target.value)
    }, [setSelectedPaymentMethod])
    useEffect(() => {
        loadPaymentMethods();
    }, [])


    const handleCardError = useCallback(async (error, clientSecret, resolveCallback, rejectCallback) => {

        if (error.code === "resource_missing") {
            const { error: newError } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: error.payment_intent.last_payment_error.payment_method.id,
                setup_future_usage: 'off_session'
            });
            if (newError) {
                setErrorMessage(newError.message);
                rejectCallback(newError);
                return;
            } else {
                resolveCallback();
            }

        } else {
            setErrorMessage(error.message);
            rejectCallback(error);
            return;
        }


    }, [setErrorMessage])

    useImperativeHandle(ref, () => ({

        submit: (callback) => {
            return new Promise(async (resolve, reject) => {
                try {

                    // let paymentMethodData;
                    // //if new card > save
                    // if (!selectedPaymentMethod) {
                    //     const { error, paymentMethod } = await addCardRef.current.submit();
                    //     if (error) {
                    //         setErrorMessage(error.message);
                    //         reject(error);
                    //         return;
                    //     }
                    //     paymentMethodData = paymentMethod;
                    // } else {
                    //     paymentMethodData = paymentMethods.find((paymentMethod) => paymentMethod.id === selectedPaymentMethod);
                    // }
                    // console.debug("method:", paymentMethodData)
                    const { name,
                        streetAddress,
                        streetAddress2,
                        city,
                        state,
                        zip,
                        country,
                        email,
                        phone } = billingInfo;


                    const cardDetails = {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: cardHolderName || name,
                            email,
                            phone,
                            address: {
                                city,
                                state,
                                country,
                                line1: streetAddress,
                                line2: streetAddress2,
                                postal_code: zip
                            }
                        }
                    };

                    if (!selectedPaymentMethod && !cardHolderName) {
                        notify.show("Card holder name is required", "error");
                        reject();
                        return;

                    }


                    //TODO: Added for number purchase. Need to modify later when number purchase has option to create payment method
                    if (callback) {
                        try {
                            await callback(selectedPaymentMethod);
                            resolve();
                        } catch (e) {
                            console.error(e);
                            reject(e);
                        }
                        return;
                    }

                    //if subscription package> create a subscription
                    if (selectedPlan.metaData.type === "subscription") {
                        const paymentSubscriptionReq = { planId: selectedPlan.id };
                        if (selectedPaymentMethod) {
                            paymentSubscriptionReq.paymentMethodId = selectedPaymentMethod;
                        } else {
                            const { token, error } = await stripe.createToken(cardDetails.card, cardDetails.billing_details);
                            if (error) {
                                setErrorMessage(error.message);
                                reject(error);
                                return;
                            }
                            paymentSubscriptionReq.token = token;
                        }

                        await BillingService.createSubscription(paymentSubscriptionReq);
                        resolve();
                    }
                    else {

                        //create payment intent
                        const paymentIntentReq = { planId: selectedPlan.id, quantity: selectedPlan.quantity || 1 };
                        if (selectedPaymentMethod) {
                            paymentIntentReq.paymentMethodId = selectedPaymentMethod;
                        }
                        const { clientSecret, message, paymentMethodId, status } = await BillingService.createPaymentIntent(paymentIntentReq);
                        console.debug("intent:", clientSecret, message, paymentMethodId);

                        if (status === "success") {
                            resolve();
                        }
                        else {
                            if (clientSecret) {
                                //submit payment
                                let paymentMethodReq;

                                if (paymentMethodId) {
                                    paymentMethodReq = paymentMethodId;
                                } else {
                                    paymentMethodReq = cardDetails;

                                }
                                try {
                                    const { error } = await stripe.confirmCardPayment(clientSecret, {
                                        payment_method: paymentMethodReq,
                                        setup_future_usage: 'off_session'

                                    });

                                    if (error) {
                                        await handleCardError(error, clientSecret, resolve, reject);
                                        return;
                                    }

                                } catch (error) {

                                    await handleCardError(error, clientSecret, resolve, reject);
                                    return;

                                }



                            }

                            // notify.show("Successfully made the payment", "success");
                            resolve();
                        }
                    }
                    reject("unknown");
                } catch (e) {
                    reject(e);
                }
            })

        }
    })
    )
    const cardBrands = [
        {
            name: "Visa",
            url: "https://js.stripe.com/v3/fingerprinted/img/visa-365725566f9578a9589553aa9296d178.svg"
        },
        {
            name: "Mastercard",
            url: "https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg"
        },
        {
            name: "Amex",
            url: "https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg"
        }
    ]
    const findCardBrand = (name) => {
        const cardImage = cardBrands.find(element => element.name === name.split('*')[0])
        return cardImage?.url || '/assets/images/default-card.svg'
    }
    return (
        <div className="card-payment-container">
            {isLoading ? < LoadingComponent /> :
                <>
                    {
                        paymentMethods.map(({ id, name }) => {

                            return (
                                <Card className="my-3" key={id}>
                                    <Card.Body>
                                        <Form.Group className="d-flex mb-0">


                                            <Form.Label className="mb-0 d-flex justify-content-between w-100 align-items-center">
                                                {/* <input type="radio" name="test" value="small" checked /> */}
                                                <Form.Check
                                                    type="radio"
                                                    id={`default-${id}`}
                                                    name="paymentMethod"
                                                    className="card-payment"
                                                    checked={selectedPaymentMethod === id}
                                                    onChange={onChangePaymentMethod}
                                                    value={id}
                                                    inline
                                                    custom
                                                    label={name}
                                                />
                                                <img width="32" src={findCardBrand(name)} />
                                            </Form.Label>
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            )
                        })
                    }
                    {hideAddCard && paymentMethods.length === 0 &&
                        <div>
                            <p className="text-muted">
                                No payment methods found. Please purchase a package first
                            </p>
                            <Link to="/billing" className="btn btn-link btn-sm px-0">Go to billing</Link>
                        </div>}
                    {!hideAddCard && <Card className="mb-3">
                        <Card.Body>
                            <Form.Group className="mb-0 d-flex justify-content-between w-100 align-items-center">
                                <Form.Check
                                    type="radio"
                                    id="addNew"
                                    name="paymentMethod"
                                    checked={!selectedPaymentMethod}
                                    onChange={onChangePaymentMethod}
                                    className="card-payment"
                                    value=""
                                    inline
                                    custom
                                    label="New Card"
                                />

                            </Form.Group>

                            {
                                !selectedPaymentMethod &&
                                <>
                                    <Form.Group controlId="cardName" size="sm" className="text-left pt-3">
                                        <Form.Label>
                                            Card Holder Name:
                    </Form.Label>

                                        <Form.Control type='text' name='cardHolderName' placeholder="Sam Perera" onChange={onChange} value={cardHolderName} required />

                                    </Form.Group>
                                    <AddPaymentMethod />

                                </>}
                        </Card.Body>
                    </Card>}
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}


                </>
            }
        </div>

    )
}
)
export default CardPayment;