/**
 * Created by madura on 2/1/17.
 */
import React, { Component } from "react";
import {
    Button,
    Modal,
    Form,
} from '@shoutout-labs/shoutout-themes';
import { snakeCase } from 'lodash';
import PropTypes from 'prop-types';
let attributeName = '', attributeType = 'string';

class AddCustomAttributeDialog extends Component {

    constructor(props) {
        super(props);
        this.state = { isReservedAttr: false, attributeName, attributeType };
        this._setAttributeName = this._setAttributeName.bind(this);
        this._setAttributeType = this._setAttributeType.bind(this);
        this._setCustomAttr = this._setCustomAttr.bind(this);
    }

    _setAttributeName(e) {
        const attributeName = e.target.value;
        if (this.props.reservedAttributes.indexOf(snakeCase(attributeName)) < 0) {
            this.setState({ attributeName, isReservedAttr: false });
        } else {
            this.setState({ attributeName: '', isReservedAttr: true });
        }

    }

    _setAttributeType(e) {
        this.setState({ attributeType: e.target.value });
    }

    _setCustomAttr() {
        this.props.onSubmit(this.state.attributeName, this.state.attributeType);
    }

    render() {
        const { showAddCustomAttrDialog, closeAddCustomAttrDialog } = this.props;

        return (
            <Modal show={showAddCustomAttrDialog} onHide={closeAddCustomAttrDialog}>
                <Modal.Header closeButton>
                    <h6 className="mb-0 font-weight-bold">Add Attributes</h6>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="attr-name">
                        <Form.Label>Attribute name:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Eg: country" onChange={this._setAttributeName}

                        />
                        {this.state.isReservedAttr &&
                            <p className="text-danger">This is a reserved attribute. Please choose something else.</p>}
                    </Form.Group>
                    <Form.Group controlId="attr-type">
                        <Form.Label>Attribute type:</Form.Label>
                        <Form.Control as="select" required onChange={this._setAttributeType}>
                            <option value="string">String</option>
                            <option value="number">Number</option>
                            <option value="array">Array</option>
                        </Form.Control>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mr-2" variant="dark" size="sm" onClick={closeAddCustomAttrDialog}>Cancel</Button>
                    <Button variant="primary" size="sm" onClick={this._setCustomAttr}
                        disabled={this.state.isReservedAttr || !this.state.attributeName}>Add</Button>
                </Modal.Footer>

            </Modal>
        );
    };
}

AddCustomAttributeDialog.propTypes = {
    showAddCustomAttrDialog: PropTypes.bool.isRequired,
    closeAddCustomAttrDialog: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};
export default AddCustomAttributeDialog;