/**
 * Created by asankanissanka on 12/20/16.
 */
import React, { useState, useCallback } from 'react';
import { ShoutOUTService } from '../../services/Services';
import {
    Form,
    Modal,
    Button
} from '@shoutout-labs/shoutout-themes';
import Multistep from '../../lib/react-multistep/multistep';
import { notify } from 'react-notify-toast';
import SenderRequestLegal from './SenderRequestLegal';
import SenderRequestDetails from './SenderRequestDetails';
import './SenderRequest.css'

const SenderRequest = ({ onCloseCallback, senderIdType, showModal }) => {

    const [isSending, setIsSending] = useState(false)
    const [requestSent, setRequestSent] = useState(false)

    const [details, setDetails] = useState({ senderId: '', isValid: false, isSending: false, requestSent: false, sample: '', usage: false, purpose: 'transactional', countries: ['Sri Lanka'], company: '', businessNature: '', userName: '', designation: '', individualName: '', idNumber: '', userType: 'BUSINESS', agreementUrl: '', countryISOCodes: ["LK"] })

    const close = useCallback((result) => {
        onCloseCallback(result);
        setRequestSent(false);
        setDetails({ senderId: '', isValid: false, isSending: false, requestSent: false, sample: '', usage: false, purpose: 'transactional', countries: ['Sri Lanka'], company: '', businessNature: '', userName: '', designation: '', individualName: '', idNumber: '', userType: 'BUSINESS', agreementUrl: '', countryISOCodes: ["LK"] })

    }, [setRequestSent, setDetails]);

    const submitSenderID = useCallback((e) => {
        const { senderId, company, businessNature, designation, userName, usage, sample, purpose, individualName, idNumber, userType, agreementUrl, countryISOCodes } = details;

        const senderIdTrimmed = senderId.trim();

        setIsSending(true);

        const countries = details.countries.map(country => {
            return country.toLowerCase();
        })
        ShoutOUTService.requestSenderId(senderIdTrimmed, senderIdType, company, businessNature, designation, userName, countries, usage, sample, purpose, individualName, idNumber, userType, agreementUrl, countryISOCodes).then(res => {
            notify.show("Sender ID request sent", "success");
            //ctx.close(res);
            setIsSending(false)
            setRequestSent(true)
        }, err => {
            notify.show("Sender ID request couldn't send ", "error");
            setIsSending(false)
        })
    }, [details, setIsSending, setRequestSent])

    const steps = [
        {
            name: 'Details',
            nextBtnName: 'Next',
            showPreviousBtn: false,
            isDisableNextBtn: !details.isValid || details.senderId === '' || details.purpose === '' || details.sample === '',
            component: <SenderRequestDetails details={details} setDetails={setDetails} />
        }

    ];
    steps.push({
        name: 'Request',
        nextBtnName: 'Request',
        isDisableNextBtn: isSending || (details.company === '' || details.businessNature === '' || details.userName === '' || details.designation === '' || details.userType !== 'BUSINESS') && (details.individualName === '' || details.idNumber === '' || details.userType !== 'INDIVIDUAL'),
        component: <SenderRequestLegal details={details} setDetails={setDetails} isSending={isSending} />,
        onClickNext: submitSenderID,
    });

    return (

        <Modal className="border-0" size="lg" show={showModal} onHide={close} >
            {/* onEntered={checkProps} */}
            <Modal.Header closeButton>
                        <h6 className="mb-0 font-weight-bold">Request SMS Sender ID</h6>
            </Modal.Header>
            <>

                {requestSent ? <Modal.Body><div>
                    <p>Your request has been sent. Please download the agreement and upload the signed agreement with relevant documents to proceed.</p>
                    <hr className="mx-n3" />
                    <Button type="button" onClick={close} variant="dark" size="sm" className="float-right">Close</Button>
                </div> </Modal.Body>
                    :

                    <Form className="px-4" id="sender-request-form">
                        <Modal.Body className="bg-white text-center">
                            <Multistep className="text-left" steps={steps} fixedStepBar>
                            </Multistep>
                        </Modal.Body>
                        {/* <Modal.Footer>
                                    <Form.Group className="text-center">
                                        <Button className="btn btn-primary btn-sm" disabled={!this.state.isValid || this.state.isSending} type="submit">{this.state.isSending ? 'Sending Request...' : 'Send Request'}</Button>
                                    </Form.Group>
                                </Modal.Footer> */}
                    </Form>
                }
            </>

        </Modal>
    );
}

export default SenderRequest;
