 import React, { useContext } from 'react';
 import { Button, OverlayTrigger,FaIcon,ButtonGroup,Dropdown } from '@shoutout-labs/shoutout-themes';
 import DropdownButton from 'react-bootstrap/DropdownButton';

 import {DropdownItem} from 'react-bootstrap';
 import { faInfoCircle} from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
 import UserContext from './../../../../contexts/UserContext';
 import Constants from './../../../../Constants';
 import './CampaignCreate.css';
 
 
 const renderTooltip = props => (
     <div
         {...props}
         style={{
             backgroundColor: 'rgba(0, 0, 0, 0.85)',
             padding: '2px 10px',
             color: 'white',
             borderRadius: 3,
             zIndex: 2000,
             ...props.style,
 
         }}
     >
         You can not create a campaign till you verify the phone number.
     </div>
 );
 
 const CampaignCreateButton = ({ toggleTransport, btnStyle, btnSize, history, className,transports }) => {
     const { mobileVerified } = useContext(UserContext);

     return (
         <React.Fragment>
             <DropdownButton disabled={!mobileVerified} className={`text-nowrap ml-2 fa fa-bullhorn`} variant={btnStyle || "primary"} size="sm" title="Create Campaign" id="create-campaign-btn">
                <Dropdown.Item eventKey="1" onClick={() => { toggleTransport(Constants.TRANSPORT_SMS); history.push('/engagement/campaigns/create/new') }}>SMS Campaign</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={() => { toggleTransport(Constants.TRANSPORT_EMAIL); history.push('/engagement/campaigns/create/new') }}>Email Campaign</Dropdown.Item>
            </DropdownButton>
             {!mobileVerified &&
                 <OverlayTrigger
                     placement="left-start"
                     delay={{ show: 250, hide: 400 }}
                     overlay={renderTooltip}
                 >
                     <Button variant="link"><FaIcon icon={faInfoCircle} /></Button>
                 </OverlayTrigger>
             }
         </React.Fragment>
 
     );
 }
 
 
 export default CampaignCreateButton;