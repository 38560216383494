import React, { PureComponent } from "react";
import { Button, Popover, Overlay } from '@shoutout-labs/shoutout-themes';
import moment from 'moment';
import { DateRange } from './../../lib/react-date-range/Components';
import {FaIcon} from '@shoutout-labs/shoutout-themes';
import {faCalendar} from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import './DateRangeSelectorV2.css';

const defaultRanges = {
    'Today': {
        startDate: (now) => {
            return now;
        },
        endDate: (now) => {
            return now;
        }
    },
    'Last 7 Days': {
        startDate: (now) => {
            return now.subtract(6, 'days');
        },
        endDate: (now) => {
            return now;
        }
    },
    'Last 14 Days': {
        startDate: (now) => {
            return now.subtract(13, 'days');
        },
        endDate: (now) => {
            return now;
        }
    },
    'Last 28 Days': {
        startDate: (now) => {
            return now.subtract(27, 'days');
        },
        endDate: (now) => {
            return now;
        }
    },
    'Last 90 Days': {
        startDate: (now) => {
            return now.subtract(89, 'days');
        },
        endDate: (now) => {
            return now;
        }
    },
    'Last 120 Days': {
        startDate: (now) => {
            return now.subtract(119, 'days');
        },
        endDate: (now) => {
            return now;
        }
    }
};

const minDate = moment().subtract(729, 'days').toDate();

class DateRangeSelector extends PureComponent {
    constructor() {
        super();
        this._triggerDateSelection = this._triggerDateSelection.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._onClickDone = this._onClickDone.bind(this);
        this.state = {
            showSelection: false,
            selection: ''
        };

    }

    _handleChange(payload, source) {
        this.tempFromDate = moment(payload.startDate).format("YYYY-MM-DD");
        this.tempToDate = moment(payload.endDate).format("YYYY-MM-DD");
        this.tempSelection = payload.selection;
    }

    _triggerDateSelection() {
        if (this.props.triggerDateSelection) {
            this.props.triggerDateSelection();
        } else {
            let showSelection = !this.state.showSelection;
            this.setState({ showSelection });
        }

    }
    _onClickDone() {
        this._triggerDateSelection();
        this.fromDate = this.tempFromDate;
        this.toDate = this.tempToDate;

        let selection = this.tempSelection ? this.tempSelection : moment(this.fromDate).format("MMM DD, YYYY") + ' - ' + moment(this.toDate).format("MMM DD, YYYY");
        this.setState({ selection });
        this.props.onClickLoad(this.fromDate, this.toDate, selection);
    }
    componentDidMount() {
        this.fromDate = this.props.startDate;
        this.toDate = this.props.endDate;
        this.setState({ selection: this.props.defaultSelection });
        
    }
    render() {
        const dateRanges = this.props.customDateRanges || defaultRanges;
        return (
            <div className={`date-range-selector ${this.props.className}`}>

                <Button variant="default" size="sm" className={`search-pre-sec ${this.props.selectBtnClassNames||""}`} onClick={this._triggerDateSelection} ref={(btn) => { this.chooseBtn = btn; }} ><FaIcon icon={faCalendar} />&nbsp;{this.props.selection||this.state.selection}
                </Button>

                <Overlay
                    show={this.props.showSelection || this.state.showSelection}
                    target={this.chooseBtn}
                    placement="bottom"
                    containerPadding={20}
                >
                    <Popover id="data-range-selection">
                        <DateRange
                            linkedCalendars={true}
                            ranges={dateRanges}
                            onInit={this._handleChange}
                            onChange={this._handleChange}
                            theme={{
                                Calendar: { width: 200 },
                                PredefinedRanges: { marginLeft: 16, marginTop: 16, marginBottom: 0, marginRight: 16 }
                            }}
                            selectedRange={this.props.selection||this.state.selection || this.props.defaultSelection}
                            maxDate={this.props.maxDate}
                            rangedCalendars={true}
                            format="YYYY-MM-DD"
                            minDate={moment(minDate).format("YYYY-MM-DD")}
                        />
                        <div className="text-right pb-3 pr-3">
                            <Button className="mr-2" size="sm" variant="danger" onClick={this._triggerDateSelection}>Cancel</Button>
                            <Button size="sm" variant="primary" onClick={this._onClickDone}>Done</Button>
                        </div>
                    </Popover>
                </Overlay>

            </div>
        );
    }
}

export default DateRangeSelector;