import React, { useCallback, useMemo } from 'react';
import {
    Row, Col, Card
} from '@shoutout-labs/shoutout-themes';
import { FaIcon, Button } from '@shoutout-labs/shoutout-themes';
import { faEnvelope, faComment, faCommentAlt } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import Constants from './../../../../Constants';
import { LoadingComponent } from './../../../utils/UtilComponents';
import { exportText } from 'shoutout_email_editor';
import { HtmlCompilerService } from './../../../../services/Services';


import './CampaignSetup.css';

const getContactsSummary = (campaignType, selectedSegment, excludedPeople, selectedPeopleCount, selectedEvent, selectedFilters) => {
    return (
        campaignType !== Constants.CAMPAIGN_TRIGGER ?
            selectedSegment.id !== '' ?
                <div className="d-flex justify-content-between align-items-center">
                    <span className="mr-2">Selected Segment:</span>
                    <span className="font-weight-bold">{selectedSegment.name} {excludedPeople.length ?
                        <small className="text-muted">&nbsp;&nbsp;
                             Excluding {excludedPeople.length}&nbsp;
                             contacts</small> : null}</span>
                </div> :
                selectedFilters.length ? null : <div className="d-flex justify-content-between align-items-center">
                    <span className="mr-2">Selected Contacts:</span>
                    <span className="font-weight-bold">{selectedPeopleCount}</span>
                </div> :
            <div className="d-flex justify-content-between align-items-center">
                <span className="mr-2">Selected Event:</span>
                <span className="font-weight-bold">{selectedEvent.title}</span>
            </div>
    );
}
const CampaignSummary = ({ campaignName, isSchedule, scheduleDate, scheduleTime, selectedSegment, transports, selectedPeople, selectedPeopleCount, campaignType, selectedEvent, isCreatingCampaign, excludedPeople, selectedFilters, showEmailPreview, senderIdMap, email, editorState, fileName}) => {

    // const {loadRecentCampaigns}=useContext(HomeContext);
    const viewEmailPreview = useCallback(() => {
        showEmailPreview(HtmlCompilerService.compileStateToHtml(email.editorState))
    }, [showEmailPreview, email.editorState]);

    const contactSummary = getContactsSummary(campaignType, selectedSegment, excludedPeople, selectedPeopleCount, selectedEvent, selectedFilters);
    const transportType = useMemo(() => {
        if (transports.includes(Constants.TRANSPORT_EMAIL)) return Constants.TRANSPORT_EMAIL;

        return Constants.TRANSPORT_SMS;
    }, [transports])


    return (
        <>
            <Card className="text-left p-5 onload-transition">
                {/* <Card.Title className="px-5 mx-5">Confirm sending {transports.indexOf(Constants.TRANSPORT_SMS) >= 0 ? 'SMS' : 'E-mail'} message(s)</Card.Title> */}
                <Card.Body className="px-4 mx-5">
                    <Row className="mb-3">
                        <Col lg={8} className="mx-auto">
                            <p className="font-weight-semi-bold">Campaign Name</p>
                            <p className="">{campaignName}</p>
                        </Col>
                    </Row>
                    <hr className="hr-dashed" />
                    <Row className="mb-3">
                        <Col lg={8} className="mx-auto">
                            <p className="font-weight-semi-bold">From</p>
                            <div className="d-flex"> {transports.map(transport => {
                                return <p key={transport}>
                                    {/* {
                                        transport === Constants.TRANSPORT_EMAIL ?
                                            <FaIcon className="mr-2" icon={faEnvelope} /> :
                                            transport === Constants.TRANSPORT_MESSENGER ?
                                                <FaIcon className="mr-2" icon={faComment} /> :
                                                <FaIcon className="mr-2" icon={faCommentAlt} />
                                    } */}
                                    {senderIdMap[transport]}</p>

                            })}</div>
                        </Col>
                    </Row>
                    <hr className="hr-dashed" />
                    <Row className="mb-3">
                        <Col lg={8} className="mx-auto">
                            <p className="font-weight-semi-bold">To</p>
                            <div>
                                {selectedSegment.length !== 0 && excludedPeople.length === 0 && selectedSegment.map((segment) => {
                                    return (
                                        <span className="p-2 mr-1 mb-1 summary-to font-weight-normal badge badge-pill" key={segment.id}>
                                            {segment.name}
                                        </span>
                                    )
                                })
                                }
                                {selectedSegment.length !== 0 && excludedPeople.length > 0 && selectedSegment.map((segment) => {
                                            return (
                                                <span className="p-2 mr-1 mb-1 summary-to font-weight-normal badge badge-pill" key={segment.id}>
                                                    "{segment.name}" segment selected excluding {excludedPeople.length} people
                                                </span>
                                            )
                                        })
                                        }
                                {selectedPeople.length > 0 && selectedPeople.map((people) => {
                                    return (
                                        <span className="p-2 mr-1 mb-1 summary-to font-weight-normal badge badge-pill" key={people.id}>
                                            {people.name}  -  {transportType === Constants.TRANSPORT_EMAIL ? people.email : people.mobile_number}
                                        </span>
                                    )
                                })
                                }
                                {fileName && <span className="p-2 mr-1 mb-1 summary-to font-weight-normal badge badge-pill">
                                    {fileName}
                                </span>
                                }
                            </div>
                        </Col>
                    </Row>

                    {transportType === Constants.TRANSPORT_SMS &&
                        <>
                            <hr className="hr-dashed" />
                            <Row className="mb-3">
                                <Col lg={8} className="mx-auto">
                                    <p className="font-weight-semi-bold">Message</p>
                                    <p className="like-pre">{exportText(editorState)}</p>
                                </Col>
                            </Row>
                        </>
                    }
                    {transportType === Constants.TRANSPORT_EMAIL &&
                        <>
                            <hr className="hr-dashed" />
                            <Row className="mb-3">
                                <Col lg={8} className="mx-auto">
                                    <p className="font-weight-semi-bold">Subject</p>
                                    <p>{email.subject}</p>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col lg={8} className="mx-auto">
                                    <p className="font-weight-semi-bold">Body</p>
                                    <Button variant="transparent" size="sm" onClick={viewEmailPreview} className="p-0">
                                        Preview
                                            </Button>

                                </Col>
                            </Row>
                        </>
                    }
                    {contactSummary && <>
                        <hr className="hr-dashed" />
                        <Row>
                            <Col lg={8} className="mx-auto">
                                <p className="font-weight-semi-bold">Launch Campaign</p>
                                <p>{isSchedule ? <span>{scheduleDate} at {scheduleTime}</span> : 'Now'}</p>
                            </Col>
                        </Row>
                    </>}
                </Card.Body>
            </Card>
            {isCreatingCampaign && <LoadingComponent />}
        </>
    )
};
export default CampaignSummary;