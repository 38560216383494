import { fetchGet, fetchPost, fetchPut, fetchDelete } from './CommonServiceUtils';
import Constants from './../Constants';
const BASE_URL = `${Constants.BASE_API_URL}couponservice/`;

class ShoutOUTCouponsService {
    static getCouponTypes() {
        return fetchGet(BASE_URL + 'types');
    }
    static addCouponCodes(typeId, codes, quantity) {
        return fetchPost(BASE_URL + 'codes', { typeId, codes, quantity });
    }
    static createCoupontype(typeName) {
        return fetchPut(BASE_URL + 'types', { typeName });
    }
    static deleteCouponType(typeId) {
        return fetchDelete(BASE_URL + 'types/' + typeId);
    }
}

export default ShoutOUTCouponsService;