/**
 * Created by madura on 1/26/17.
 */
import React, { useMemo, useState, useCallback, useEffect } from "react";
import {
    Card,
    DropdownItem,
    Typeahead,
    Button,
    Form,
    FaIcon
} from '@shoutout-labs/shoutout-themes';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { faTimes, faCheck } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { UtilService } from '../../services/Services';
import './People.css';

const FilterInput = ({ selectedKey, selectedFilterOption, searchKey, changeSearchKey }) => {



    const changeTypeaheadInput = useCallback((data) => {
        changeSearchKey(data.length ? data[0].label : '');
    }, [changeSearchKey])
    const changeTextInput = useCallback((e) => {
        changeSearchKey(e.target.value);
    }, [changeSearchKey])

    if (selectedKey.id === 'tags') {
        const selected = searchKey ? [{ id: searchKey, label: searchKey }] : [];
        return (
            <div>
                <Typeahead
                    options={selectedKey.dataList}
                    placeholder={selectedFilterOption._inputPlaceholder}
                    onChange={changeTypeaheadInput}
                    selected={selected}
                />
            </div>
        );
    }
    if (selectedFilterOption.name === 'more than' || selectedFilterOption.name === 'less than') {
        return (
            <div>
                <Form.Control
                    type="string"
                    placeholder={selectedFilterOption._inputPlaceholder}
                    onChange={changeTextInput}
                    value={searchKey} />

            </div>
        );
    }
    return (
        <>
            <Form.Control
                type={selectedKey.type}
                placeholder={selectedFilterOption._inputPlaceholder}
                onChange={changeTextInput}
                value={searchKey} />
        </>
    );

}

const SubmitButton = ({ addFilter }) => {
    return (
        <Button variant="dark" size="sm" className="border-0 px-2 ml-2" onClick={addFilter} type="button">Add Filter</Button>
    )
}

const FiltersPreview = ({ filterList, deleteFilter }) => {

    const _deleteFilter = useCallback((e) => {
        deleteFilter(e.currentTarget.dataset.index);
    }, [deleteFilter])

    if (filterList.length === 0) {
        return null;
    }


    return (
        <div className="mb-2">
            {filterList.map(({ selectedKey, searchKey, selectedFilterOption }, index) => {
                return (
                    <div className="d-inline-block mb-2">
                        <div className="d-flex align-items-center" key={`f-${index}`}>
                            {index > 0 && <small className="mx-2 text-black">and</small>}
                            <div className="d-flex align-items-center bg-filter rounded-1 pl-2 "><div className="small filter-line-clamp">{selectedKey.label} {selectedFilterOption.name} {searchKey}</div> <Button className="rounded-circle" size="sm" variant="default" data-index={index} onClick={_deleteFilter}><FaIcon className="text-primary" icon={faTimes} /></Button></div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


const PeopleFilters = ({ columnNameMap, applyFilters, clearFilters, addFilter, filterList, deleteFilter }) => {
    //  const [searchKey, set]

    const [filterData, setFilterData] = useState({
        selectedKey: null,
        selectedFilterOption: null,
        searchKey: ""
    });

    const keys = useMemo(() => {

        return Object.keys(columnNameMap).reduce((result, key) => {
            if (!key.startsWith('_') || key === '_last_seen_on' || key === '_created_on') {
                result.push(key);
            }
            return result;
        }, []).sort((a, b) => a - b)
    }, [columnNameMap]);


    const filtersList = useMemo(() => {

        return Object.entries(columnNameMap).reduce((result, [key, value]) => {
            if (!key.startsWith('_') || key === '_last_seen_on' || key === '_created_on') {
                result.push({ id: key, ...value });
            }
            return result;
        }, [])

    }, [columnNameMap])

    const filterOptions = useMemo(() => {
        if (filterData.selectedKey && Object.keys(filterData.selectedKey).length > 0) {
            return UtilService.getFilters(filterData.selectedKey.type);
        }
        return [];
    }, [filterData.selectedKey])


    const selectFilterKey = useCallback((selectedFilterKey) => {
        if (selectedFilterKey && selectedFilterKey.length > 0) {
            // setSelectedKey(selectedFilterKey[0]);
            setFilterData(
                {
                    selectedFilterOption: null,
                    searchKey: "",
                    selectedKey: selectedFilterKey[0]
                }
            )
        }
        else {
            setFilterData({
                selectedFilterOption: null,
                searchKey: "",
                selectedKey: null
            })
        }
    }, [setFilterData, filterData])
    const selectFilterOptions = useCallback((eventKey) => {

        setFilterData({ ...filterData, searchKey: "", selectedFilterOption: filterOptions[parseInt(eventKey)] })
    }, [filterOptions, setFilterData, filterData])

    const changeSearchKey = useCallback((searchKey) => {
        setFilterData({ ...filterData, searchKey: searchKey })
    }, [filterData, setFilterData])

    const _addFilter = useCallback(() => {

        addFilter(filterData);

        setFilterData({
            selectedKey: null,
            selectedFilterOption: null,
            searchKey: ""

        })
    }, [addFilter, filterData, setFilterData])


    return (
        <>
            <p className="font-weight-bold mb-2">Filter Contacts</p>
            <Card className=" border-0 mb-3">
                <Card.Body>
                    <div className="d-flex align-items-center w-100">
                        <FiltersPreview filterList={filterList} deleteFilter={deleteFilter} />
                    </div>
                    <div className="d-flex align-items-center">
                        {keys.length ? <React.Fragment>
                            <Typeahead
                                id="filterKeySelector"
                                options={filtersList}
                                placeholder="Type attribute name"
                                // onChange={data => {
                                //     onChangeFilterSearchKey(data.length ? data[0].label : '', filterKey);
                                // }}
                                // selected={selected}
                                labelKey="label"
                                onChange={selectFilterKey}
                                selected={filterData.selectedKey ? [filterData.selectedKey] : []}
                            // className="ml-2"
                            />
                            {filterData.selectedKey && Object.keys(filterData.selectedKey).length > 0 &&
                                <DropdownButton bsPrefix="btn btn-transparent btn-sm d-flex align-items-center" size="sm" title={filterData.selectedFilterOption ? filterData.selectedFilterOption.name : 'Select'}
                                    onSelect={selectFilterOptions}>
                                    {filterOptions.map((filter, i) => {
                                        return (<DropdownItem eventKey={i} key={filter.id + '-' + i}>{filter.name}</DropdownItem>)
                                    })
                                    }
                                </DropdownButton>
                            }

                            {filterData.selectedFilterOption && (filterData.selectedFilterOption._inputVisible ?
                                <React.Fragment><Form.Group className="mb-0 pt-0" controlId="filter-people" size="sm">
                                    <FilterInput selectedKey={filterData.selectedKey} selectedFilterOption={filterData.selectedFilterOption} searchKey={filterData.searchKey} changeSearchKey={changeSearchKey} />

                                </Form.Group>
                                    <SubmitButton addFilter={_addFilter} />
                                </React.Fragment>
                                :
                                <SubmitButton addFilter={_addFilter} />
                            )
                            }

                        </React.Fragment> :
                            <div>
                                <small className="font-weight-bold mb-0">No filters</small>
                                <br />
                                <small className="text-muted">Filters will be available once you create some contacts</small>
                            </div>

                        }

                    </div>

                    {filterList.length > 0 &&
                        <div className="mt-2 text-right">
                            <Button type="button" className="border-0" size="sm" variant="outline-danger" onClick={clearFilters}><FaIcon className="mr-2" icon={faTimes} />Clear Filters</Button>
                            <Button className="border-0 ml-2" size="sm" type="button" variant="outline-success" onClick={applyFilters}><FaIcon className="mr-2" icon={faCheck} />Apply Filters</Button>
                        </div>
                    }
                </Card.Body>

            </Card>
        </>
    );
};



const PeopleFilterWrapper = ({ applyFilters, selectedFilters, columnNameMap, ...rest }) => {
    const [filterList, setFilterList] = useState([]);
    const addFilter = useCallback((filter) => {
        const newFilterList = [...filterList, filter];

        setFilterList(newFilterList);
        applyFilters(newFilterList);
    }, [filterList, setFilterList, applyFilters])

    const _applyFilters = useCallback((e) => {
        e.preventDefault();
        applyFilters(filterList);
    }, [applyFilters, filterList])

    const deleteFilter = useCallback((filterIndex) => {
        const newFilterList = [...filterList];
        newFilterList.splice(filterIndex, 1);
        setFilterList(newFilterList);
        applyFilters(newFilterList);
    }, [filterList, setFilterList, applyFilters])

    useEffect(() => {
        if (selectedFilters.length > 0) {
            const newFilterList = selectedFilters.map(({ columnName, searchKey, _selectedFilter }) => {
                const filter = UtilService.getFilterById(_selectedFilter);
                const columnNameMapItem = columnNameMap[columnName];

                return { searchKey, selectedFilterOption: filter, selectedKey: { ...columnNameMapItem, id: columnName } }
            })
            setFilterList(newFilterList);

        } else {
            setFilterList([]);
        }
    }, [selectedFilters])
    return (
        <PeopleFilters filterList={filterList} addFilter={addFilter} {...rest} applyFilters={_applyFilters} columnNameMap={columnNameMap} deleteFilter={deleteFilter} />
    )
}
export default PeopleFilterWrapper;