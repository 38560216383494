import { fetchDelete, fetchGet, fetchPost, fetchPut } from './CommonServiceUtils';
import Constants from './../Constants';
const AccountServiceBaseUrl = `${Constants.BASE_API_URL}accountservice/`;
const ACCOUNT_SERVICE2 = `${Constants.BASE_API_URL}authservice/`;
//const SHOUTOUT_UTILITY_SERVICE = process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://api3.getshoutout.com/senderservice" : "https://betaapi.getshoutout.com/utilityservice";
const SHOUTOUT_UTILITY_SERVICE = `${Constants.BASE_API3_URL}senderservice/`;

class AccountService {
    static deleteUserAccount() {
        return fetchDelete(AccountServiceBaseUrl + "gdpr/users");
    }

    static deleteSenderId(senderId) {
        return fetchDelete(`${SHOUTOUT_UTILITY_SERVICE}/senders/${senderId}`);
    }


    static resendVerificationLink() {
        return fetchGet(ACCOUNT_SERVICE2 + "resendverification");
    }

    static mobileVerification() {
        return fetchPost(ACCOUNT_SERVICE2 + "verify");
    }

    static otpCodeVerification(otpCode) {
        return fetchPut(`${ACCOUNT_SERVICE2}verify?channel=mobile`, { otpCode: otpCode });
    }

    static changeMobile(mobile) {
        return fetchPut(ACCOUNT_SERVICE2 + "update", { mobile: mobile });
    }

}

export default AccountService;