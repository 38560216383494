import React, { PureComponent } from "react";
import { Button } from '@shoutout-labs/shoutout-themes';
import moment from 'moment';
class DateRangeSelectorDays extends PureComponent {
    constructor() {
        super();
        this.dateRanges = [{ label: 'Last 7 days', value: 7 }, { label: 'Last 14 days', value: 14 }, { label: 'Last 28 days', value: 28 }, { label: 'Last 90 days', value: 90 }];
        this._changeDateRangeByOption = this._changeDateRangeByOption.bind(this);
        this.state = {
            fromDate: moment().subtract(6, 'days').format("YYYY-MM-DD"),
            toDate: moment().format("YYYY-MM-DD"),
            selectedDateRange: this.dateRanges[0]
        };
    }
    
    componentDidMount() {
        this.setState({ fromDate: this.props.defaultFromDate || this.state.fromDate, toDate: this.props.defaultToDate || this.state.toDate, selectedDateRange: this.props.selectedDateRange || this.state.selectedDateRange });
    }
    _changeDateRangeByOption(e) {
  
  
            const dateRange=this.dateRanges[parseInt(e.currentTarget.dataset.index)]
            let fromDate = moment().subtract(dateRange.value, 'days').format("YYYY-MM-DD"),
                toDate = moment().format("YYYY-MM-DD");
                
            this.setState({ selectedDateRange: dateRange, fromDate, toDate });
            this.props.onClickLoad(fromDate, toDate);

   
    }
    render() {
        return (
    
                 <div className="d-flex">
                            {this.dateRanges.map((item ,index)=> {
                                return <Button className="mr-2 border-0" onClick={this._changeDateRangeByOption} data-index={index} size="sm" variant={this.state.selectedDateRange.label === item.label ? "primary" : ""}  key={item.value}>{item.label}</Button>
                            })}
                </div>
               
        );
    }
}

export default DateRangeSelectorDays;