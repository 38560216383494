/**
 * Created by madura on 1/23/17.
 */

import { ShoutOUTService } from './../../services/Services';
import { notify } from "react-notify-toast";
import { createCampaignFromPeople } from './CampaignCreateActions';
import { transform, map, omit, concat } from 'lodash';
import Constants from './../../Constants';
import {
    showAddTagLoader,
    showTagDeleteDialog,
    showDeleteTagLoader
} from './../actions/PeopleImportActions';


export const SEARCH = 'SEARCH';
export const REQUEST_PEOPLE = 'REQUEST_PEOPLE';
export const RECEIVE_PEOPLE = 'RECEIVE_PEOPLE';
export const SORT_PEOPLE = 'SORT_PEOPLE';
export const CLICK_SELECT_ALL = 'CLICK_SELECT_ALL';
export const SELECT_PEOPLE = 'SELECT_PEOPLE';
export const SHOW_CONFIRM_DELETE = 'SHOW_CONFIRM_DELETE';
export const SELECT_FILTER = 'SELECT_FILTER';
export const SELECT_FILTER_TYPE = 'SELECT_FILTER_TYPE';
export const CHANGE_FILTER_SEARCH_KEY = 'CHANGE_FILTER_SEARCH_KEY';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const SHOW_ADD_OR_EDIT_PERSON = 'SHOW_ADD_OR_EDIT_PERSON';
export const CREATE_PERSON = 'CREATE_PERSON';
export const PERSON_CREATED = 'PERSON_CREATED';
export const CLICK_CREATE_CAMPAIGN = 'CLICK_CREATE_CAMPAIGN';
export const SELECT_SEGMENT = 'SELECT_SEGMENT';
export const CREATE_SEGMENT_NAME = 'CREATE_SEGMENT_NAME';
export const SEGMENT_CREATED = 'SEGMENT_CREATED';
export const SEGMENT_CREATE_ERROR = 'SEGMENT_CREATE_ERROR';
export const SHOW_ADD_OR_EDIT_PERSON_LOADER = 'SHOW_ADD_OR_EDIT_PERSON_LOADER';
export const LOAD_MORE_PEOPLE = 'LOAD_MORE_PEOPLE';

const PEOPLE_ACTIONS = {
    DELETING: 'PEOPLE_DELETING',
    DELETED: 'PEOPLE_DELETED',
    DELETE_ERROR: 'PEOPLE_DELETE_ERROR',
    SEARCHING: 'PEOPLE_SEARCHING',
    CHANGE_SEARCH_TEXT: 'CHANGE_SEARCH_TEXT',
    SHOW_ADD_TAG_DIALOG: 'SHOW_ADD_TAG_DIALOG',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    SEGMENT_CREATING: 'SEGMENT_CREATING'

};
export default PEOPLE_ACTIONS;

let shoutout = new ShoutOUTService();

const receivedPeople = (peopleResult, isLoadMore, isSearch) => {
    return {
        type: RECEIVE_PEOPLE,
        peopleList: peopleResult.Items,
        peopleCount: peopleResult.TotalCount,
        isLoadMore,
        isSearch
    }
};

const _filterContacts = (filters, lastEvalKey, loadMore, isSearch) => {
    return (dispatch) => {
        filters = map(filters, filter => {
            return omit(filter, ['columnType', '_selected'])
        });
        dispatch({ type: REQUEST_PEOPLE, filters, showLoader: !loadMore });
        return shoutout.filterContacts(Constants.CONTACTS_LOAD_BATCH_SIZE, filters, lastEvalKey).then(res => {
            dispatch(receivedPeople(res, loadMore, isSearch));
        });
    }
};

const getPeople = (lastEvaluatedKey, isLoadMore, selectedFilters, isSearch) => {
    return (dispatch) => {
        if (selectedFilters && selectedFilters.length) {
            dispatch(_filterContacts(selectedFilters, lastEvaluatedKey, isLoadMore, isSearch));
        } else {
            dispatch({ type: REQUEST_PEOPLE, filters: [], showLoader: !isLoadMore });
            shoutout.getContacts(Constants.CONTACTS_LOAD_BATCH_SIZE, lastEvaluatedKey).then(res => {
                dispatch(receivedPeople(res, isLoadMore, isSearch));
            }).catch(e => {
                dispatch(receivedPeople([], isLoadMore, isSearch))
            });
        }
    };
};

const search = (searchText, selectedFilters) => {
    return (dispatch) => {
        dispatch({ type: PEOPLE_ACTIONS.SEARCHING, searchText });
        if (searchText) {//TODO: Send current filters along with this inorder to search inside segments
            let filters = [{
                'columnName': '$text',
                '_selectedFilter': 0,
                'searchKey': JSON.stringify(searchText),
                'columnType': 'search',
                '_selected': true
            }];
            if (selectedFilters && selectedFilters.length) {
                filters = concat(filters, selectedFilters);
            }
            dispatch(_filterContacts(filters, null, false, true));
        } else {
            dispatch(getPeople(null, false, selectedFilters, true))

        }
    };
};

const onChangeSearchText = (searchText) => {
    return {
        type: PEOPLE_ACTIONS.CHANGE_SEARCH_TEXT,
        searchText
    }
};


const sortPeople = ({ sortBy, sortDirection }) => {
    return {
        type: SORT_PEOPLE,
        sortBy: sortBy,
        sortDirection: sortDirection
    }
};

const selectAll = (isChecked, selectAll, selectAllStatus) => {
    return {
        type: CLICK_SELECT_ALL,
        isSelectAll: (!selectAll && selectAllStatus) ? false : (!selectAll)
    }
};


const selectPeople = (personData, isChecked, rowIndex) => {
    return {
        type: SELECT_PEOPLE,
        isSelect: isChecked,
        personData,
        rowIndex: rowIndex
    }
};
const showAddTagDialog = (isShow) => {
    return { type: PEOPLE_ACTIONS.SHOW_ADD_TAG_DIALOG, isShow };
};
const createTag = (tagName, selectedPeople) => {
   const newPeople= Object.values(selectedPeople).map(person => {
        if (!person.tags) {
            person.tags = [tagName];
        } else if (!person.tags.includes(tagName)) {
            person.tags.push(tagName);
        }
        person._selected=false;
        return person;

    })
    return (dispatch) => {
        dispatch(showAddTagLoader(true));
        return shoutout.addOrUpdatePeople(newPeople).then(res => {
            dispatch(showAddTagLoader(false));
            notify.show('Tag created', 'success');
            dispatch(showAddTagDialog(false));
            dispatch(getPeople());

        }, err => {
            dispatch(showAddTagLoader(false));
            notify.show('Can not create tag', 'error');
        });
    }
};

const deleteTag = (tagIndex, person) => {
    const deletedTagList = person.tags.splice(tagIndex, 1); //TODO: implement a endpoint for tag delete
    person['_deleted_tags'] = deletedTagList;
    return (dispatch) => {
        dispatch(showDeleteTagLoader(true));
        return shoutout.addOrUpdatePeople([person]).then(res => {
            dispatch(showDeleteTagLoader(false));
            dispatch(showTagDeleteDialog(false));
            notify.show('Tag deleted', 'success');
        }, err => {
            dispatch(showDeleteTagLoader(false));
            notify.show('Can not delete tag', 'error');
        });
    }
};

const showConfirmDelete = (isShow) => {
    return {
        type: SHOW_CONFIRM_DELETE,
        showConfirmDelete: isShow
    };
};


const deleteSelectedPeople = (selectedPeople, selectAll, selectAllStatus, selectedFilters, selectedSegmentId, peopleList) => {
    let peopleForDelete = [];
    let excludeIds = [];
    let selectedFiltersArray;
    if (selectAll || selectAllStatus) {//select all contacts
        if (selectedFilters.length) {
            selectedFiltersArray = [selectedFilters];
        }
        else if (selectedSegmentId === Constants.ALL_SEGMENT.id) {
            selectedFiltersArray = [Constants.ALL_SEGMENT.segment.data];
        }
        if (!selectAll && selectAllStatus && selectedFiltersArray.length) {//deselect few contacts
            excludeIds = peopleList.filter(item => !item._selected).map(item => item.id);
        }
    }
    else {
        peopleForDelete = transform(selectedPeople, (result, value, key) => {
            result.push(key);
        }, []);
    }

    return (dispatch) => {
        dispatch({ type: PEOPLE_ACTIONS.DELETING });

        return shoutout.deleteContacts(peopleForDelete, selectedFiltersArray, excludeIds).then(res => {
            dispatch({ type: PEOPLE_ACTIONS.DELETED });
            notify.show('Contacts deleted', 'success');
            dispatch(getPeople(null, false, selectedFilters));
        }, err => {
            dispatch({ type: PEOPLE_ACTIONS.DELETE_ERROR });
            notify.show('Cannot delete contacts', 'error');
        });
    }
};


const applySelectedFilters = (selectedFilters) => {


    const transformedFilters = selectedFilters.map(({ searchKey, selectedFilterOption, selectedKey }) => {

        const mappedFilterObj = {
            'columnName': selectedKey.id,
            '_selectedFilter': selectedFilterOption.id,
            'columnType': selectedKey.type,
            '_selected': true
        };
        if (searchKey) {
            mappedFilterObj['searchKey'] = searchKey
        }
        return mappedFilterObj;
    })
    return (dispatch) => {
        dispatch({
            type: APPLY_FILTERS,
            isFilterSelected: selectedFilters.length > 0,
            selectedFilters: transformedFilters
        });
        dispatch(_filterContacts(transformedFilters));
    }
};



const onSelectSegment = (segmentId, selectedSegment) => {

    return (dispatch) => {
        if (segmentId === Constants.ALL_SEGMENT.id) {
            dispatch({
                type: SELECT_SEGMENT,
                selectedSegment: Constants.ALL_SEGMENT.segment,
                segmentId
            });
            dispatch(getPeople());

        } else {


            dispatch({
                type: SELECT_SEGMENT,
                selectedSegment: selectedSegment,
                segmentId
            });
            dispatch(_filterContacts(selectedSegment.data));
        }
    }
};

const clearAllFilters = () => {
    return (dispatch) => {
        dispatch({ type: PEOPLE_ACTIONS.CLEAR_FILTERS });
        dispatch(onSelectSegment(Constants.ALL_SEGMENT.id, null));
    }
};

const loadMorePeople = (lastEvalKey, selectedFilters) => {
    //TODO: handle if filter selected
    return (dispatch) => {
        dispatch({ type: LOAD_MORE_PEOPLE });
        dispatch(getPeople(lastEvalKey, true, selectedFilters));
    }
};

const createSegmentName = (segmentName) => {
    return {
        type: CREATE_SEGMENT_NAME,
        segmentName: segmentName
    }
};

const showAddEditPerson = (isShow, person) => {
    return {
        type: SHOW_ADD_OR_EDIT_PERSON,
        isShow: isShow,
        person: person
    }
};

const showAddEditPersonLoader = (isShow) => {
    return {
        type: SHOW_ADD_OR_EDIT_PERSON_LOADER,
        isShow: isShow
    }
};


const createPerson = (person) => {
    person = omit(person, ['_selected']);
    return (dispatch) => {
        dispatch(showAddEditPersonLoader(true));
        return shoutout.addOrUpdatePeople([person]).then(res => {
            dispatch(showAddEditPersonLoader(false));
            notify.show('Contact created', 'success');
            dispatch(showAddEditPerson(false));
            dispatch({ type: PERSON_CREATED, person: res })
            dispatch(getPeople());
        }, err => {
            dispatch(showAddEditPersonLoader(false));
            notify.show('Can not add contact', 'error');
        });
    }
};


const createCampaign = (isSelectAll, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople,transport) => {
    return (dispatch) => {
        if (isFilterSelected) {
            selectedSegment = null;
        } else {
            selectedFilters = [];
        }
        dispatch(createCampaignFromPeople(selectedSegment, isSelectAll, selectedPeople, selectedFilters, selectedPeopleCount, excludedPeople,transport));
    };
};



export {
    onChangeSearchText,
    sortPeople,
    selectAll,
    selectPeople,
    createTag,
    deleteTag,
    showConfirmDelete,
    deleteSelectedPeople,
    // selectFilter,
    // selectFilterType,
    // changeFilterSearchKey,
    applySelectedFilters,
    onSelectSegment,
    clearAllFilters,
    loadMorePeople,
    createSegmentName,
    showAddEditPerson,
    showAddEditPersonLoader,
    createPerson,

    createCampaign,
    receivedPeople,
    getPeople,
    search,
    showAddTagDialog
}
