/**
 * Created by madura on 1/24/17.
 */
import React from 'react';
import { Button, Badge, Card, Form, ReactVirtualized, FaIcon } from '@shoutout-labs/shoutout-themes';
import { faEdit, faComment } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import Avatar from 'react-user-avatar';


import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


import moment from 'moment';
import {
    PeopleGettingStartedGuideContainer
} from './../../redux/containers/PeopleListContainer';


import PropTypes from 'prop-types';
import Constants from './../../Constants';
import UserContext from './../../contexts/UserContext';
import './People.css';

const { Column, Table, AutoSizer, InfiniteLoader } = ReactVirtualized
const tagColorMap = {};

const getAvatar = (rowData) => {
    const { name, profileImage } = rowData;

    return <Avatar src={profileImage || (name ? '' : 'assets/images/avatar.png')} name={(name || 'Unknown').charAt(0)} size={30} />
};

const selectAllLabel = () => {
    return <small>All</small>
}

class PeopleListView extends React.PureComponent {
    componentDidUpdate() {
        if (this.checkAllBox) {
            if (!this.props.selectAll && this.props.selectAllStatus) {
                this.checkAllBox.indeterminate = true;
            } else if (this.checkAllBox.indeterminate) {
                this.checkAllBox.indeterminate = false;
            }
        }
    }

    getSelectAllOption(isDisabled, selectAll, selectAllStatus, onClickSelectAll) {
        let selectAllCheckBox = (<Form.Check className="" custom type="checkbox" checked={selectAll} ref={el => this.checkAllBox = el} onChange={(e) => onClickSelectAll(e.target.checked, selectAll, selectAllStatus)} disabled={isDisabled} label={selectAllLabel()} id="select-all" />)


        if (isDisabled) {
            return <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={<Tooltip id='select-all-disable'>
                Looking to select all? You can use select all only with filters
            </Tooltip>}><div>{selectAllCheckBox}</div>
            </OverlayTrigger>

        }
        return selectAllCheckBox;
    }

    _onClickPerson = ({ columnData, dataKey, event }) => {
        if (dataKey === "name" || dataKey === "mobile_number" || dataKey === "email") {
            if (event.target.getAttribute('data-id'))
                this.props.history.push('people/' + event.target.getAttribute('data-id'));
        }
    }

    render() {
        const { peopleList, peopleCount, sortBy, sortDirection, selectAll, selectAllStatus, sort, onClickSelectAll, selectPeople, selectedPeopleCount, showAddEditPerson, loadMorePeople, isLoadingPeople, selectedFilters, showPeopleLoader, showGettingStartedGuide, isDisableSelectAll, totalPeopleCount, showSendMessage, tagList, showTags } = this.props;


        tagList.forEach((item) => {
            if (item) {
                tagColorMap[item] = item.charCodeAt(0) % 5;
            }
        });


        return (
            <UserContext.Consumer>
                {({ scopes, mobileVerified }) => (

                    <Card className="people-list-content border-0">
                        <Card.Body>
                            {showGettingStartedGuide ? <PeopleGettingStartedGuideContainer /> :
                                <div className="table-content people-table-height">
                                    <div className="pt-2 pl-2">
                                        <p>Selected <span className="font-weight-bold bg-primary text-white px-1">{selectedPeopleCount < 10 ? "0" + selectedPeopleCount : selectedPeopleCount}</span> contacts out of {totalPeopleCount}</p>

                                    </div>
                                    <InfiniteLoader
                                        isRowLoaded={(index) => {
                                            return !!peopleList[index];
                                        }}

                                        loadMoreRows={() => {
                                            if (!isLoadingPeople && peopleList.length < peopleCount) {
                                                loadMorePeople(peopleList.length, selectedFilters);
                                            }
                                        }}
                                        rowCount={showPeopleLoader ? 100 : peopleCount}
                                    >
                                        {({ onRowsRendered, registerChild }) => (
                                            <AutoSizer>
                                                {({ height, width }) => {
                                                    return (
                                                        <Table
                                                            width={width}
                                                            height={530}
                                                            headerHeight={50}
                                                            rowHeight={50}
                                                            rowCount={showPeopleLoader ? 100 : (peopleCount > peopleList.length ? (peopleList.length + 2) : peopleCount)}
                                                            rowGetter={({ index }) => {
                                                                return showPeopleLoader ? { _loading: true } : (peopleList[index] || { _loading: true });
                                                            }}
                                                            sortBy={sortBy}
                                                            sortDirection={sortDirection}
                                                            sort={sort}
                                                            noRowsRenderer={() => {
                                                                return <div className="text-center">No people found.</div>;
                                                            }}
                                                            onRowsRendered={onRowsRendered}
                                                            ref={registerChild}
                                                            onColumnClick={this._onClickPerson}
                                                        >
                                                            <Column
                                                                disableSort
                                                                dataKey='_profile_pic'
                                                                width={70}
                                                                headerRenderer={() =>
                                                                    this.getSelectAllOption(isDisableSelectAll, selectAll, selectAllStatus, onClickSelectAll)
                                                                }

                                                                cellRenderer={({ rowData, rowIndex }) => {
                                                                    return (rowData._loading ?
                                                                        <div className="people-loading">&nbsp;</div> :
                                                                        <Form.Check className="d-flex align-items-center people-check" custom onChange={(e) => {
                                                                            e.stopPropagation();
                                                                            selectPeople(rowData, e.target.checked, rowIndex);
                                                                        }}
                                                                            checked={rowData._selected || false} id={rowData.id} label={getAvatar(rowData)} type="checkbox" />);
                                                                }
                                                                }

                                                            />


                                                            <Column
                                                                dataKey='name'
                                                                width={120}
                                                                flexGrow={1}
                                                                label="Name"
                                                                cellRenderer={({ cellData, rowData }) => {

                                                                    return (rowData._loading ?
                                                                        <div className="people-loading">&nbsp;</div> :
                                                                        <a className="link-color link-pointer" data-id={rowData.id}>{cellData ? cellData :
                                                                            <span className="text-muted text-small" data-id={rowData.id}>Unknown</span>}</a>);
                                                                }}
                                                            />
                                                            <Column
                                                                label='Contact'
                                                                dataKey=''
                                                                width={180}
                                                                flexGrow={1}
                                                                cellRenderer={({ cellData, rowData }) => {
                                                                    return (rowData._loading ?
                                                                        <div className="people-loading">&nbsp;</div> :
                                                                        <>
                                                                            <small className="link-color link-pointer" data-id={rowData.id}>{rowData.email ? rowData.email : <span className="text-muted text-small" data-id={rowData.id}>Unknown</span>}</small><br />
                                                                            <small className="link-color link-pointer" data-id={rowData.id}>{rowData.mobile_number ? rowData.mobile_number : <span className="text-muted text-small" data-id={rowData.id}>Unknown</span>}</small>
                                                                        </>);
                                                                }}
                                                            />



                                                            {Constants.BUILD_APP === Constants.APP_LOYALTY &&
                                                                <Column
                                                                    label='Last Seen On'
                                                                    dataKey='_last_seen_on'
                                                                    width={130}
                                                                    cellRenderer={({ cellData, rowData }) => {
                                                                        return (rowData._loading ?
                                                                            <div className="people-loading">&nbsp;</div> : (cellData ?
                                                                                <small>{moment(cellData).fromNow()}</small> :
                                                                                <span className="text-muted text-small">Unknown</span>));
                                                                    }}
                                                                />}

                                                            <Column
                                                                disableSort
                                                                label='Tags'
                                                                dataKey='tags'
                                                                className="column"
                                                                width={180}
                                                                cellRenderer={({ cellData, rowData }) => {
                                                                    let elem;

                                                                    if (cellData && cellData.length) {

                                                                        const renderTooltip = (props) => (

                                                                            <Tooltip className="tooltiptext" id="button-tooltip"  {...props}>

                                                                                {cellData.map((val, index) => (index > 1) && <Badge style={{ margin: 3 }} className={`p-1 tag-${tagColorMap[val]}`} key={val}>{val}</Badge>)}

                                                                            </Tooltip>

                                                                        )
                                                                        let tags = cellData.map(tag => {

                                                                            return <span key={tag}><Badge className={`p-1 tag-${tagColorMap[tag]}`}>{tag}</Badge>&nbsp;&nbsp;</span>

                                                                        });
                                                                        elem = (tags.length < 3 ? <div>{tags}</div> : <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}><span key={tags}><>{tags[0]}{tags[1]}</>&nbsp;&nbsp;</span></OverlayTrigger>);
                                                                    } else {
                                                                        elem = <span className="text-muted text-small">No Tags</span>;
                                                                    }
                                                                    return (rowData._loading ?
                                                                        <div className="people-loading">&nbsp;</div> : elem);
                                                                }}
                                                                flexGrow={1}
                                                            />

                                                            <Column disableSort width={60} dataKey='_action'
                                                                cellRenderer={({ rowData }) => {
                                                                    return (rowData._loading ? null :
                                                                        <div className="text-success">
                                                                            {(!!mobileVerified) && <span className="action-link" onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                showAddEditPerson(true, rowData);//TODO: handle these with onClickPerson
                                                                            }}><FaIcon icon={faEdit} /></span>}
                                                                            &nbsp;&nbsp;

                                                                            {(scopes[Constants.USER_ACCESS_CONTROLS.MODULES.LITE_MESSAGES.moduleId].write && !!mobileVerified) && <span className="action-link" onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                showSendMessage(rowData);
                                                                            }}><FaIcon icon={faComment} /></span>
                                                                            }
                                                                        </div>);
                                                                }} />


                                                        </Table>)
                                                }}

                                            </AutoSizer>)}
                                    </InfiniteLoader>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                )}


            </UserContext.Consumer>



        );
    }
}

PeopleListView.propTypes = {
    peopleList: PropTypes.array.isRequired
};
export default PeopleListView;


// <OverlayTrigger placement="top"
//     overlay={<Tooltip
//         id="button-tooltip">{tags}</Tooltip>}>
//     <div>{tags}</div>
// </OverlayTrigger>