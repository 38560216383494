/**
 * Created by madura on 2/7/17.
 */
import { Col, Row, Card, Button, Badge, ButtonToolbar, DropdownItem, FaIcon } from '@shoutout-labs/shoutout-themes';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { faCheck, faQuestionCircle, faChevronLeft, faPhoneAlt, faEnvelope, faQuestion, faCommentAlt, faTimes, faCog, faCrown } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import Avatar from 'react-user-avatar';
import React, { Component } from 'react';
import { UtilService } from "./../../../services/Services";
import Shoutout from "./../../../services/Shoutout";
import moment from 'moment';
import { pick, has, map, chain, startCase, mapKeys, orderBy } from 'lodash';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { Link } from 'react-router-dom';
import { DateRangeSelectorContainer } from './../../../redux/containers/UtilContainer';
import JSONTree from 'react-json-tree';
import { ConfirmBox } from './../../utils/UtilComponents';
import { LoadingComponent } from './../../utils/UtilComponents';
import BootstrapMultiSelect from './../../../lib/boostrap-multiselect/BoostrapMultiSelect';
import UserContext from './../../../contexts/UserContext';
import Constants from './../../../Constants';
import './PersonProfile.css';

const theme = {
    scheme: 'default',
    author: 'chris kempson (http://chriskempson.com)',
    base00: '#181818',
    base01: '#282828',
    base02: '#383838',
    base03: '#585858',
    base04: '#b8b8b8',
    base05: '#d8d8d8',
    base06: '#e8e8e8',
    base07: '#f8f8f8',
    base08: '#ab4642',
    base09: '#dc9656',
    base0A: '#f7ca88',
    base0B: '#a1b56c',
    base0C: '#86c1b9',
    base0D: '#7cafc2',
    base0E: '#ba8baf',
    base0F: '#a16946'
};

class PersonProfile extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.contactId = props.match['params']['contactId'];
        this.state = {
            person: null,
            personDates: null,
            personOther: null,
            timeline: [],
            contactLoaded: false,
            timelineLoaded: false,
            showConfirmDelete: false,
            sendingForgetRequest: false,
            selectedEvents: {}
        };
        this.changeActivityDateRange = this.changeActivityDateRange.bind(this);
        this.setTagIndex = this.setTagIndex.bind(this);
        this._onClickForget = this._onClickForget.bind(this);
        this._onClickExport = this._onClickExport.bind(this);
        this._sendForgetContact = this._sendForgetContact.bind(this);
        this._closeConfirmForget = this._closeConfirmForget.bind(this);

    }

    setTagIndex(index) {
        this.setState({ tagIndex: index });
    }

    loadActivities(contactId, fromDate, toDate) {
        return new Promise((resolve, reject) => {
            Shoutout.getActivities(contactId, fromDate, toDate).then(result => {
                resolve(result.Items)
            }).catch((error) => {
                console.error('error loading message log', error);
                resolve([]);
            });
        });
    }

    loadPerson(contactId) {
        let ctx = this;
        Shoutout.getContact(contactId).then(result => {
            ctx.setState({ person: pick(result, ['name', 'email', 'mobile_number', 'user_id', 'tags', '_mobile_number_valid', '_email_valid', 'loyalty_id', 'birth_date']), personData: result });
            ctx.setState({
                personDates: chain(result)
                    .pick(['_created_on', '_modified_on', '_last_seen_on'])
                    .mapValues((val) => {
                        return moment(val).format("YYYY-MM-DD h:mm a");
                    })
                    .value(),
                personOther: chain(result)
                    .omit(['id', 'owner_id', 'name', 'email', 'mobile_number', 'user_id', 'tags', '_created_on', '_modified_on', '_last_seen_on', '_mobile_number_valid', '_email_valid', 'loyalty_id', 'birth_date'])
                    .mapKeys((value, key) => {
                        return startCase(key);
                    })
                    .value(),
                contactLoaded: true
            });
        }).catch((error) => {
            ctx.setState({
                contactLoaded: true
            })
        });
    }

    loadMessageLog(contactId, fromDate, toDate) {
        return new Promise((resolve, reject) => {
            Shoutout.getMessageLogByContactId(contactId, fromDate, toDate).then(result => {

                resolve(result.Items);
            }).catch((error) => {
                console.error('error loading message log', error);
                resolve([]);
            });
        });
    }

    buildTimeline(contactId, fromDate, toDate) {
        let messageLogPromise = this.loadMessageLog(contactId, fromDate, toDate);
        let activitiesPromise = this.loadActivities(contactId, fromDate, toDate);

        this.setState({ timelineLoaded: false, timeline: [] });
        Promise.all([messageLogPromise, activitiesPromise]).then((results) => {

            let messageLog = map(results[0], function (messageData) {
                let transport = messageData.transport ? messageData.transport : 'SMS';
                let templateId = transport === 'SMS' ? -1 : -2;
                return {
                    title: transport,
                    templateId: templateId,

                    item_data: pick(messageData, ['message', 'subject']),
                    _item_data_hidden: transport === 'EMAIL',
                    item_type: 'message',
                    createdAt: moment(messageData.createdOn).format("YYYY-MM-DD h:mm a"),
                    contact_id: messageData.contactId
                };
            });
            let activityLog = map(results[1], function (actRecord) {

                if (!has(actRecord, 'templateId')) {
                    let actInfo = pick(this.props.events[actRecord._id], ['templateId']);
                    actRecord.templateId = actInfo.templateId;
                }
                actRecord.item_data = mapKeys(actRecord.activityData, function (value, key) {
                    return startCase(key);
                });
                actRecord.createdAt = moment(actRecord.createdOn).format("YYYY-MM-DD h:mm a");
                return actRecord;
            });
            let timeline = orderBy(activityLog.concat(messageLog), ['createdAt'], ['desc']);
            this.setState({ timelineLoaded: true, timeline });
        }, (error) => {
            this.setState({ timelineLoaded: false });
        });
    }

    _getEventTemplate(event, index) {

        let title = 'Other', icon = 'pencil-alt', iconColor = '#A1887F', templateColor = "#7B1FA2";
        if (this.props.events[event.eventId]) {

            const { _title, _icon, _iconColor, _templateColor } = this.props.events[event.eventId];
            title = _title;
            icon = _icon;
            iconColor = _iconColor;
            templateColor = _templateColor;
        }



        return (<TimelineEvent title={title}
            createdAt={event.createdAt}
            icon={<FaIcon icon={icon} />}
            iconColor={iconColor}
            key={index}
            container="card"
            cardHeaderStyle={{ backgroundColor: templateColor }}
        >
            <JSONTree data={event.item_data} hideRoot={true} theme={
                {
                    extend: theme,
                    valueLabel: {
                        color: '#1a3d55'
                    },
                    valueText: {
                        color: '#1a3d55'
                    }
                }
            } valueRenderer={raw => { return JSON.parse(raw) }} />
        </TimelineEvent>)
    }

    componentDidMount() {
        let fromDate = moment().subtract(6, 'days').format("YYYY-MM-DD");
        this.loadPerson(this.contactId);
        this.buildTimeline(this.contactId, fromDate, moment().format("YYYY-MM-DD"));
    }

    changeActivityDateRange(fromDate, toDate) {
        this.buildTimeline(this.contactId, fromDate, toDate);
    }
    getMobileNumberValidityIcon() {
        if (has(this.state.person, '_mobile_number_valid')) {
            if (this.state.person._mobile_number_valid) {
                return <span className="text-success"><FaIcon icon={faCheck} /></span>
            }
            return <span className="text-danger"><FaIcon icon={faQuestionCircle} /></span>
        }
        return null;
    }

    _onClickForget(e) {
        e.stopPropagation();
        this.setState({ showConfirmDelete: true });
    }

    _closeConfirmForget() {
        this.setState({ showConfirmDelete: false });
    }
    _sendForgetContact() {
        this.setState({ sendingForgetRequest: true });
        Shoutout.forgetContact(this.contactId).then(res => {
            this.setState({ sendingForgetRequest: false });
            this.props.showAlert("Success", "Your request has been placed.", "success");
            this._closeConfirmForget();
            this.props.navigateToPeople();
            this.props.history.push('/people');
        }, err => {
            this.props.showAlert("Try Again", "Couldn't place your order at the moment. Please try again.", "danger");
            this.setState({ sendingForgetRequest: false });
            this._closeConfirmForget();
        });

    }

    _onClickExport(e) {
        e.stopPropagation();
        Shoutout.exportContact(this.contactId).then(res => {
            this.props.showAlert("Success", "A CSV file will be downloaded.", "success");

            let filename = this.contactId + ".csv";
            let blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename);
            } else {
                let link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    let url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }, err => {
            this.props.showAlert("Try Again", "Couldn't place your order at the moment. Please try again.", "danger");
        });

    }

    _onSelectItem = (eventId) => {
        const { selectedEvents } = this.state;
        if (selectedEvents[eventId]) {
            delete selectedEvents[eventId];
        } else {
            selectedEvents[eventId] = true;
        }
        this.setState({ selectedEvents })
    }


    _onDeselectItem = (e) => {
        e.stopPropagation();
        let eventId = e.currentTarget.dataset['id'];
        this._onSelectItem(eventId);
    }

    closeDialog = () => {
        this.props.showDialog(false);
    }

    setTagDelete = (e) => {
        this.setTagIndex(e.currentTarget.dataset.index);
        this.props.showDialog(true);
    }

    render() {
        const { onDeleteTag, showModal, showLoader, showSendMessage } = this.props;
        const { timeline, selectedEvents } = this.state;
        const isEmptySelectedEvents = Object.keys(selectedEvents).length === 0;
        const filteredTimeline = timeline.filter(item => {
            if (isEmptySelectedEvents) {
                return true;
            }
            if (selectedEvents[item.eventId]) {
                return true;
            }
            return false;
        })


        return (
            <div className="person-profile container-fluid pt-4 px-4 onload-transition">
                <div className="mt-2">
                    <h4 className="font-weight-bold">Contacts</h4>
                    {/* <small className="text-muted"><Link to="/">Dashboard</Link><span className="text-capitalize">{UtilService.setBreadCrumb(this.props.location.pathname)}</span></small> */}
                </div>
                <Row className="pt-3">
                    {this.state.contactLoaded ? <Col sm={12} md={3}>
                        <Card className="border-0">
                            <Card.Body>
                                <div>
                                    <Link className="text-decoration-none text-dark" to="/people"> <FaIcon icon={faChevronLeft} /> Back</Link>
                                </div>
                                <div className="mt-3 px-2 text-center">
                                    <Avatar className="people-page" src={this.state.person.profileImage} name={(this.state.person.name || 'Unknown').charAt(0)} textSizeRatio={2} size={80} />
                                    <h6 className="mb-3">{this.state.person.name}</h6>
                                    {this.state.person.mobile_number ? <div className="mt-2 text-left">
                                        <div className="text-small font-weight-bold border-muted border-bottom d-table">
                                            <FaIcon className="mr-2" icon={faPhoneAlt} />
                                            Phone
                                        </div>
                                        <small className="text-muted">
                                            {this.state.person.mobile_number}
                                            {this.state.person._mobile_number_valid ? <FaIcon className="ml-2 text-success" icon={faCheck} /> : <FaIcon className="ml-2 text-warning" icon={faQuestion} />}
                                        </small>
                                    </div> : null}
                                    {this.state.person.email ? <div className="mt-2 text-left">
                                        <div className="text-small font-weight-bold border-muted border-bottom d-table">
                                            <FaIcon className="mr-2" icon={faEnvelope} />
                                            Email
                                        </div>
                                        <small className="text-muted">
                                            {this.state.person.email}
                                            {this.state.person._email_valid ? <FaIcon className="ml-2 text-success" icon={faCheck} /> : <FaIcon className="ml-2 text-warning" icon={faQuestion} />}
                                        </small>
                                    </div> : null}
                                    {this.state.person.loyalty_id ? <div className="mt-2 text-left">
                                        <div className="text-small font-weight-bold border-muted border-bottom d-table">
                                            <FaIcon className="mr-2" icon={faCrown} />
                                            Loyalty ID
                                        </div>
                                        <small className="text-muted">
                                            {this.state.person.loyalty_id}
                                            {/* {this.state.person._email_valid ? <FaIcon className="ml-2 text-success" icon={faCheck} /> : <FaIcon className="ml-2 text-warning" icon={faQuestion} />} */}
                                        </small>
                                    </div> : null}
                                    {this.context.scopes[Constants.USER_ACCESS_CONTROLS.MODULES.LITE_MESSAGES.moduleId].write && <>
                                        <hr className="dashed" />

                                        <div className="text-right mt-2">
                                            <Button variant="dark" size="sm" onClick={() => { showSendMessage(this.state.person) }}><FaIcon className="mr-2" icon={faCommentAlt} />Send Message</Button>
                                        </div>
                                    </>
                                    }
                                </div>

                                {/* <small className="text-muted">{this.state.personDates._last_seen_on ? 'Last seen on' : ''}</small>
                                    <h6>{this.state.personDates._last_seen_on ? this.state.personDates._last_seen_on : ''}</h6>
                                    {this.state.person.birth_date ? <h6><FaIcon icon="birthday-cake" /> {moment(this.state.person.birth_date).format("YYYY-MM-DD")} </h6> : ''}
                                    {this.state.person.loyalty_id ? <h6><FaIcon icon="tags" /> {this.state.person.loyalty_id} </h6> : ''} */}



                            </Card.Body>
                        </Card>
                        <Card className="mt-2 border-0">
                            <Card.Body>
                                <div>
                                    <h6 className="font-weight-bold">Tags</h6>
                                    <div>
                                        {this.state.person.tags ? this.state.person.tags.map((tag, index) => {
                                            return (<Badge key={index} className="mr-2 mb-2" variant="secondary"><div className="text-truncate">{tag}<FaIcon className="ml-2 delete" icon={faTimes} onClick={this.setTagDelete} /></div></Badge>);
                                        }) : <small>No Tags Found</small>}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="my-2 border-0">
                            <Card.Body>
                                <div className="word-break-all">
                                    <h6 className="font-weight-bold">Other</h6>
                                    {this.state.personDates._created_on ?
                                        <div className="text-left my-3">
                                            <div className="text-small font-weight-bold border-muted border-bottom d-table">
                                                Created On
                                            </div>
                                            <small className="text-muted">
                                                {this.state.personDates._created_on}
                                            </small>
                                        </div> : ''}
                                    {this.state.personDates._modified_on ?
                                        <div className="text-left my-3">
                                            <div className="text-small font-weight-bold border-muted border-bottom d-table">
                                                Modified On
                                            </div>
                                            <small className="text-muted">
                                                {this.state.personDates._modified_on}
                                            </small>
                                        </div> : ''}
                                    {this.state.personOther ? map(this.state.personOther, (value, key) => {
                                        return (
                                            <div className="text-left my-3" key={key}><div className="text-small font-weight-bold border-muted border-bottom d-table">{key}</div> <small className="text-muted">{value === true ? "Yes" : value === false ? "No" : value || value}</small></div>
                                        );
                                    }) : ''}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col> : <Col sm={12} md={3} className="text-center">
                        <LoadingComponent />
                    </Col>}
                    <Col sm={12} md={9}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <h6 className="mb-0 font-weight-bold">Activities for</h6>
                                <DateRangeSelectorContainer onClickLoad={this.changeActivityDateRange} defaultSelection="Last 7 Days" maxDate={moment().format("YYYY-MM-DD")} />
                            </div>
                            <div>
                                <ButtonToolbar>
                                    <DropdownButton
                                        alignRight
                                        variant="primary"
                                        title={<span><FaIcon icon={faCog} /> Settings</span>}
                                        size="sm"
                                        id="person-actions"
                                    >
                                        <DropdownItem onClick={this._onClickForget}>Forget this contact</DropdownItem>
                                        <DropdownItem onClick={this._onClickExport}>Export this contact</DropdownItem>
                                    </DropdownButton>
                                </ButtonToolbar>
                            </div>



                        </div>
                        <Row>
                            <Col lg={2} md={3} sm={4} xs={12}>
                                <BootstrapMultiSelect options={this.props.events} selectedOptions={selectedEvents} title="Filter Activities" onSelectItem={this._onSelectItem} size="sm" />
                            </Col>
                            <Col xs={12} className="mt-2">

                                {map(selectedEvents, (item, key) => {

                                    const event = this.props.events[key];

                                    return <span key={`label-${key}`}><Badge style={{ backgroundColor: event._templateColor }} className="text-white">{event._title}&nbsp;<span data-id={key} onClick={this._onDeselectItem}><FaIcon icon={faTimes} className="cursor-pointer" /></span></Badge>&nbsp;&nbsp;</span>;
                                }
                                )}
                            </Col>
                        </Row>
                        <br />
                        <Timeline>
                            {this.state.timelineLoaded ? filteredTimeline.map((event, index) => {
                                return (
                                    this._getEventTemplate(event, index)
                                );
                            }) : <LoadingComponent />}
                        </Timeline>
                    </Col>
                </Row>

                <ConfirmBox show={showModal} onHide={this.closeDialog} confirmCallback={() => {
                    onDeleteTag(this.state.tagIndex, this.state.personData);
                }} title="Confirm Delete Tag" message="Are you sure that you want to delete this tag?" disableActions={showLoader} />

                <ConfirmBox show={this.state.showConfirmDelete} onHide={this._closeConfirmForget} confirmCallback={this._sendForgetContact} title="Confirm Forget" message="Are you sure that you want forget all the details about this user?" disableActions={this.state.sendingForgetRequest} />
            </div>
        );
    }
}

export default PersonProfile;
