import React, { useState, useEffect, useRef, useCallback, useImperativeHandle } from 'react';
import { Col, Form, Typeahead } from '@shoutout-labs/shoutout-themes';
import { UtilService } from './../../services/Services';
import { LoadingComponent } from './../utils/UtilComponents';
import { countries } from 'country-data';
import FlagIconFactory from 'react-flag-icon-css';
import './BillingInfo.css';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const countryList = countries.all.sort((a, b) => a.name - b.name);

function BillingInfo(props, ref) {

    const [validMobile, setValidMobile] = useState(false);
    const [validEmail, setValidEmail] = useState(true);
    const [billingInfo, setBillingInfo] = useState({ name: '', streetAddress: '', streetAddress2: '', city: '', state: '', zip: '', country: '', email: '', phone: '' });
    const [isValidated, setIsValidated] = useState(false);
    const [isValidatedCountry, setIsValidatedCountry] = useState(false);
    const [countryData, setCountryData] = useState([]);
    const mobile = useRef();
    const formRef = useRef();
    // const callOnChange = useCallback(({ billingInfo, validMobile = validMobile, validEmail = validEmail }) => {
    //     if (props.onChangeBillingInfo) {
    //         // props.onChangeBillingInfo(billingInfo);
    //     }
    // }, [props.onChangeBillingInfo])

    const onChange = useCallback((e) => {
        const { name: key, value } = e.target;
        billingInfo[key] = value;
        setBillingInfo({ ...billingInfo, [key]: value });

        if (key === 'email') {
            const isValidEmail = UtilService.validateEmail(value);
            setValidEmail({ validEmail: isValidEmail });
            // callOnChange({ billingInfo, validEmail: isValidEmail });
        }

        if (key === 'country') {
            // const countryInput = value;
            if (value === "") {
                setIsValidatedCountry(false);
            }
            else {
                setIsValidatedCountry(true);
            }
        }

    }, [billingInfo, setBillingInfo])



    const onMobileNumberChange = useCallback((status, value, countryData, number, id) => {
        const newBillingInfo = { ...billingInfo };

        const mobile = number.replace(/[^0-9]/gi, '');
        if (status) {
            const country = countryData.name.replace(/ *\([^)]*\) */g, "").trim();

            if (!newBillingInfo['country'])
                newBillingInfo['country'] = country;

        }
        newBillingInfo['phone'] = mobile;

        setBillingInfo(newBillingInfo);


        setValidMobile(status);

        // callOnChange({ billingInfo, validMobile: status });
    }, [billingInfo, setBillingInfo, setValidMobile])



    useEffect(() => {
        if (props.billingInfo) {
            setBillingInfo(props.billingInfo);
            if (props.billingInfo.country) {
                const countryDetail = countryList.find((item) => item.alpha2 === props.billingInfo.country || item.name === props.billingInfo.country);
                setCountryData([countryDetail]);
            }
        }
    }, [props.billingInfo])

    // useEffect(() => {
    //     setBillingInfo({ ...billingInfo, country: countryData?.alpha2 || "" })
    // }, [countryData])

    useImperativeHandle(ref, () => {
        return {
            validation() {
                const {
                    name,
                    streetAddress,
                    streetAddress2,
                    city,
                    state,
                    zip,
                    // country,
                    email,
                    phone } = billingInfo;
                const country = countryData.length > 0 ? countryData[0].alpha2 : billingInfo.country;
                const isValid = streetAddress && streetAddress2 && city && state && zip && country && email && phone && name && (validMobile) && (validEmail);

                setIsValidated(true)

                if (isValid) {
                    return { ...billingInfo, country };
                }
                return false

            }
        }
    }, [setIsValidated, validMobile, validEmail, billingInfo, countryData])



    return (
        <div>
            {props.isLoading ? <LoadingComponent /> :
                <div>
                    <Form className={isValidated ? 'was-validated' : ''} ref={formRef}>
                        <Form.Group controlId="name" size="sm">
                            <Form.Label>Organization/Individual Name:</Form.Label>
                            <Form.Control type="text" placeholder="Sam De Silva" name="name" onChange={onChange} value={billingInfo["name"]} required />
                            <div className="invalid-feedback">
                                <p><small>Please provide your organization name or your name if you are not a company.</small></p>
                            </div>
                        </Form.Group>

                        <Form.Row>
                            <Form.Group controlId="email" size="sm" as={Col}>
                                <Form.Label>
                                    Email:
                                </Form.Label>

                                <Form.Control type='email' name='email' placeholder="sam@mycompany.com" onChange={onChange} value={billingInfo['email']} required />
                                {validEmail ? null :
                                    <p><small className="text-danger">Invalid Email</small></p>}

                            </Form.Group>

                            {!props.isLoadingBillingInfo && <Form.Group controlId="phone" size="sm" as={Col}>
                                <Form.Label>
                                    Phone:
                                </Form.Label>
                                <Form.MobileNumberInput id="mobile" defaultCountry={'lk'}
                                    ref={mobile} onPhoneNumberBlur={onMobileNumberChange} onPhoneNumberChange={onMobileNumberChange} required
                                    format={true} formatOnInit={true} value={billingInfo.phone} inputClassName={`form-control shadow-0 bg-transparent form-control-sm w-100`} />

                            </Form.Group>}


                        </Form.Row>

                        <Form.Row>
                            <Form.Group controlId="streetAddress" size="sm" as={Col}>
                                <Form.Label>
                                    Street Address:
                                </Form.Label>
                                <Form.Control type='text' name='streetAddress' placeholder='410/33, Bauddhaloka Mawatha' onChange={onChange} value={billingInfo['streetAddress']} required />
                                <div className="invalid-feedback">
                                    <p><small>Please provide your street address.</small></p>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="streetAddress2" size="sm" as={Col}>
                                <Form.Label>
                                    Street Address2:
                                </Form.Label>
                                <Form.Control type='text' name='streetAddress2' placeholder='Colombo 07' onChange={onChange} value={billingInfo['streetAddress2']} required />
                                <div className="invalid-feedback">
                                    <p><small>Please provide your street address.</small></p>
                                </div>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group controlId="city" size="sm" as={Col}>
                                <Form.Label>
                                    City:
                                </Form.Label>
                                <Form.Control type='text' name='city' placeholder='Colombo' onChange={onChange} value={billingInfo['city']} required />
                                <div className="invalid-feedback">
                                    <p><small>Please provide your city.</small></p>
                                </div>
                            </Form.Group>

                            <Form.Group controlId="zip" size="sm" as={Col}>
                                <Form.Label>
                                    Zip:
                                </Form.Label>
                                <Form.Control type='text' name='zip' placeholder='00700' onChange={onChange} value={billingInfo['zip']} required />
                                <div className="invalid-feedback">
                                    <p><small>Please provide your zip code.</small></p>
                                </div>
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                            <Form.Group controlId="state" size="sm" as={Col}>
                                <Form.Label>
                                    State:
                                </Form.Label>
                                <Form.Control type='text' name='state' placeholder='Western' onChange={onChange} value={billingInfo['state']} required />
                                <div className="invalid-feedback">
                                    <p><small>Please provide your state.</small></p>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="country" size="sm" as={Col}>
                                <Form.Label>
                                    Country:
                                </Form.Label>
                                <Typeahead
                                    id="countrySelector"
                                    onChange={setCountryData}
                                    placeholder={'Select Country'}
                                    isValid={isValidatedCountry}
                                    // isInvalid={!isValidatedCountry}
                                    selected={countryData}
                                    options={countryList}
                                    labelKey={option => `${option.name}`}
                                    renderMenuItemChildren={(result, props) => {
                                        return <span><span className="default-flag"><FlagIcon code={result.alpha2.toLowerCase()} /></span>&nbsp;&nbsp;{result.name}</span>
                                    }}
                                    filterBy={['name']}
                                    size="sm"
                                />

                                <div className="invalid-feedback">
                                    <p><small>Please provide your country.</small></p>
                                </div>
                            </Form.Group>
                        </Form.Row>

                        {props.children}
                    </Form>

                </div>}

        </div>
    )
}

BillingInfo = React.forwardRef(BillingInfo)
export default BillingInfo;


