import React, { Component } from 'react';

import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { LoadingPaperPlane } from './UtilComponents';
class BiAxisChartWidget extends Component {

    render() {
        const { dataset, title, isLoading = false, barOptions = { xAxis: {}, yAxis: {}, bar: [] }, lineOptions = { yAxis: {}, lines: [] } } = this.props;


        return (
            <div className="bar-chart-widget text-small">
                {title && <h6 className="chart_title text-center font-weight-bold">{title}</h6>}
                {isLoading ? <LoadingPaperPlane /> :

                    <ResponsiveContainer width="100%" height={250} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                        <ComposedChart height={250} data={dataset} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                            <CartesianGrid stroke='#f5f5f5' />
                            <XAxis type={barOptions.xAxis.type || "number"} dataKey={barOptions.xAxis.key} />
                            <YAxis type={"category"} yAxisId="left" orientation="left" stroke="#00AEFF" {...barOptions.yAxis} />
                            <YAxis type={"category"} yAxisId="right" orientation="right" stroke="#3dd660" {...lineOptions.yAxis} />
                            <Tooltip />
                            <Legend />
                            {barOptions.bar.map(item => {
                                return (<Bar yAxisId="left" key={item.key} dataKey={item.key} fill={item.color} name={item.label} />)
                            })}
                            {lineOptions.lines.map((item) => {
                                return (<Line yAxisId="right" dot={false} key={item.key} type={item.type || "monotone"} dataKey={item.key || 'value'} stroke={item.color} name={item.label} />)
                            })}
                        </ComposedChart>

                    </ResponsiveContainer>
                }
            </div>
        );
    }
};

export default BiAxisChartWidget;