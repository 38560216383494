import React, { useState, useCallback } from 'react';
import { Button, Card, Table, Form, Typeahead, Row, Col, FaIcon } from '@shoutout-labs/shoutout-themes';
import FlagIconFactory from 'react-flag-icon-css';
import { countries } from 'country-data';
import Constants from '../../Constants';
import LoadingComponent from '../utils/LoadingComponent';
import './numberPurchase.css';
import { faSync, faSearch } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });
const availableCountrySet = countries.all.filter(country => country.alpha2 === 'US' || country.alpha2 === 'CA');

const NumberPurchaseSelect = ({ newNumbers, isLoadingNewNumbers, setNumber, showNewNumbers, number }) => {

    const [selectedAreaCode, setSelectedAreaCode] = useState('');
    const [selectedType, setSelectedType] = useState('toll-free');
    const [selectedCountry, setSelectedCountry] = useState('CA');

    const selectNumber = useCallback((e) => {

        const item = e.currentTarget.dataset.id;
        const selectedPhone = newNumbers.find(newNumber => newNumber.phoneNumber === item);

        setNumber(
            {
                "phoneNumber": selectedPhone.phoneNumber,
                "monthlyCost": selectedPhone.monthlyCost,
                "activationCost": selectedPhone.activationCost,
                "type": selectedPhone.type,
                "currency": selectedPhone.currency,
                "countryISO": selectedPhone.countryISO,
                "countryName": selectedPhone.countryName,
                "providerId": selectedPhone.providerId,

            }
        )

    }, [setNumber, newNumbers]);

    const onSelectAreaCode = useCallback((selections) => {

        if (selections.length > 0) {
            setSelectedAreaCode(selections[0]);
        }

    }, [setSelectedAreaCode]);

    const onSelectType = useCallback((type) => {

        setSelectedType(type);

    }, [setSelectedType]);

    const onSelectCountry = useCallback((selections) => {

        if (selections.length > 0) {
            setSelectedCountry(selections[0].alpha2);
        }

    }, [setSelectedCountry]);

    const onSearchNumbers = useCallback(() => {

        if (selectedAreaCode === 'Any') {
            showNewNumbers(selectedCountry, selectedType);
        } else {
            showNewNumbers(selectedCountry, selectedType, selectedAreaCode);
        }

    }, [selectedCountry, showNewNumbers, selectedType, selectedAreaCode]);

    const onRefreshNumbers = useCallback(() => {

        showNewNumbers(selectedCountry, selectedType, selectedAreaCode);

    }, [selectedCountry, showNewNumbers, selectedType, selectedAreaCode]);

    return (
        <Card className="text-left px-4 onload-transition border-0 mb-0">
            <Card.Body className="px-3 mx-2 mb-0 pb-0">
                <Row className="mb-1">
                    <Col lg={3} className="">
                        <Form.Group controlId="countries">
                            <Typeahead
                                id="select-country"
                                // defaultSelected={availableCountrySet}
                                onChange={(selections) => onSelectCountry(selections)}
                                placeholder={'Select Country'}
                                options={availableCountrySet}
                                labelKey={option => `${option.name}`}
                                renderMenuItemChildren={(result, props) => {
                                    return <span><span className="default-flag">{result.alpha2 && <FlagIcon code={result.alpha2.toLowerCase()} />}</span>&nbsp;&nbsp;{result.name}</span>
                                }}
                                multiple={false}
                                filterBy={['name']}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                    <Col lg={4} className="">
                        <Form.Control as="select" required onChange={(e) => {
                            onSelectType(e.target.value)
                        }}>
                            <option key="2" value="toll-free" >Toll Free Numbers</option>
                            <option key="1" value="local" >Local Numbers</option>
                        </Form.Control>
                    </Col>
                    <Col lg={3} className="">
                        <Typeahead
                            id="select-areaCodeUS"
                            onChange={onSelectAreaCode}
                            placeholder={'Select Area Code'}
                            options={['Any', ...Constants.AREA_CODES[selectedCountry]]}
                            labelKey={option => `${option}`}
                            renderMenuItemChildren={(result, props) => {
                                return <span>{result}</span>
                            }}
                            multiple={false}
                            disabled={selectedType !== 'local'}
                            size="sm"
                        />
                    </Col>
                    <Col lg={2} className="">
                        <Button className="number-select-search-btn" variant="primary" onClick={onSearchNumbers}><FaIcon className="mr-2" icon={faSearch} />Search</Button>
                    </Col>
                </Row>

                <div className={newNumbers.length !== 0 && 'table-custom-height'}>
                    {isLoadingNewNumbers ? <LoadingComponent /> : <Table striped hover responsive className="no-border mt-2 scrollTable">
                        <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th>Monthly Fee</th>
                                <th>Setup Fee</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            {newNumbers.length > 0 ? newNumbers.map(numberNew => {
                                return (
                                    <tr key={numberNew._id}>
                                        <td>
                                            {numberNew.phoneNumber}
                                        </td>
                                        <td>
                                            {`${numberNew.currency} ${numberNew.monthlyCost}`}
                                        </td>
                                        <td>{numberNew.activationCost !== 0 ?
                                            `${numberNew.currency} ${numberNew.activationCost}`
                                            :
                                            <span>Free</span>
                                        }
                                        </td>
                                        <td className="text-right">
                                            <Form.Check
                                                type="checkbox"
                                                name="select"
                                                label=""
                                                id={numberNew.phoneNumber}
                                                data-id={numberNew.phoneNumber}
                                                checked={numberNew.phoneNumber === number.phoneNumber}
                                                onChange={selectNumber}
                                            />
                                        </td>
                                    </tr>
                                )
                            }

                            ) :
                                ''
                            }
                        </tbody>

                    </Table>}
                </div>

                {!isLoadingNewNumbers && newNumbers.length === 0 && <p className="text-center mt-2"> To view phone numbers, select the above filters and click ‘Search’</p>}
                {
                    !isLoadingNewNumbers && newNumbers.length > 0 &&
                    <Row>
                        <Col lg={12} className="mx-auto text-center">
                            <Button className="text-center" size="sm" variant="link" onClick={onRefreshNumbers}><FaIcon className="mr-2" icon={faSync} />Load More Numbers</Button>
                        </Col>
                    </Row>
                }
            </Card.Body>
        </Card>
    )
}

export default NumberPurchaseSelect;