import React, { Component } from 'react';

import { BillingService } from './../../services/Services';
import { notify } from 'react-notify-toast';
import { BillingInfo, CardPayment } from './BillingComponents';
import UserContext from './../../contexts/UserContext';
import './BillingPurchasePlan.css';
import { Row, Col, Card, Button, FaIcon } from '@shoutout-labs/shoutout-themes';
import { faCreditCard, faUniversity } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { ElementsConsumer } from '@stripe/react-stripe-js';
const stepNames = ['Billing Info', 'Purchase'];

class BillingPurchasePlan extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.validateInput = React.createRef();
        this.cardRef = React.createRef();
        this.state = {
            selectedPlan: {}, selectedPaymentOption: "card-payment", billingInfo: {
                name: '',
                streetAddress: '',
                streetAddress2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                email: '',
                phone: ''
            },
            isProcessingPurchase: false,
            activeStep: 0,
            // saveBillingInfo: false,
            isValidBillingInfo: false,
            isLoadingBillingInfo: false,
            isLoadingPurchase: false,
            currency: ''
        };

        this.onChangePaymentOption = this.onChangePaymentOption.bind(this);
        this.onChangeBillingInfo = this.onChangeBillingInfo.bind(this);
        this.placeOrder = this.placeOrder.bind(this);
        this.onSelectPlan = this.onSelectPlan.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.onClickNextBillingInfo = this.onClickNextBillingInfo.bind(this);
        this.onClickProgress = this.onClickProgress.bind(this);
        // this.onChangeSaveBillingInfo = this.onChangeSaveBillingInfo.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
    }
    componentDidMount() {

        if (this.props.location.search) {
            let queryParams = new URLSearchParams(this.props.location.search);
            const message = queryParams.get('message'),
                paymentStatus = queryParams.get('paymentStatus');
            if (paymentStatus === "success") {

                this.props.showAlert("Payment Success", "Payment is success. Credit has been added to your account", true, "success");


            } else {
                this.props.showAlert("Payment Failed", message, true, "danger");
            }

        }

        if (!this.props.selectedPlan.id) {
            this.props.history.push({
                pathname: '/billing',
                state: { plan: this.props.selectedPlan }
            });
        }

        this.setState({ billingInfo: this.props.billingInfo });
        // if (this.props.selectedPlan.id) {
        //     this.setState({ isLoadingBillingInfo: true });
        //     BillingService.getBillingInfo().then(res => {

        //         let billingInfo = res && res.billingInfo ? res.billingInfo : {};

        //         this.setState({ billingInfo: billingInfo, isLoadingBillingInfo: false });

        //     }, err => {
        //         this.setState({ isLoadingBillingInfo: false });
        //     });
        // } else {
        //     this.props.history.push('/billing');
        // }

    }


    onChangePaymentOption(option) {

        this.setState({ selectedPaymentOption: option, currency: (option === "card-payment" ? "USD" : this.state.currency) });
    }

    onChangeBillingInfo(billingInfo, isValid) {
        this.setState({ billingInfo, isValidBillingInfo: isValid });
    }

    _updateBillingInfo(billingInfo) {
        BillingService.updateBillingInfo(billingInfo).then(res => {

        }, err => {

        });
    }

    placeOrder(customerName, organization) {
        this.setState({ isProcessingPurchase: true });
        this._updateBillingInfo(this.state.billingInfo);
        const { name, quantity, id } = this.props.selectedPlan;
        const {
            streetAddress,
            streetAddress2,
            city,
            state,
            zip,
            country,
            email,
            phone } = this.state.billingInfo;

        const orderRequest = {
            planName: name,
            planQuantity: quantity,
            planId: id,
            billingInfo: {
                name: customerName,
                streetAddress,
                streetAddress2,
                city,
                state,
                zip,
                country,
                email,
                phone
            },
            currencyCode: this.state.currency || "USD"
        };
        if (organization) {
            orderRequest.organization = organization;
        }

        BillingService.placeBankTransfer(orderRequest).then(res => {
            // this.props.showAlert("Order Placed", "Your order has been placed. Please do the payment and reply back to the email you recived with the payment slip.", true, "success");
            this.setState({ isProcessingPurchase: false });
            this.props.history.push({
                pathname: '/billing/success',
                state: { plan: this.props.selectedPlan, paymentMethod: "bankTransfer" }
            });
        }, err => {
            this.setState({ isProcessingPurchase: false });
            notify.show("Couldn't place the order. Please contact support.", "error");
        });

    }

    getClassName(className, i) {
        let { activeStep } = this.state;
        return className + "-" + (activeStep === i ? 'doing' : (activeStep > i ? 'done' : 'todo'));
    }

    onSelectPlan(selectedPlan) {
        this.setState({ selectedPlan, activeStep: 1 });
    }

    async onClickNextBillingInfo() {
        const validationData = this.validateInput.current.validation();
        this.setState({ isLoadingPurchase: false })
        if (validationData) {
            // if (this.state.saveBillingInfo) {


            // }
            try {
                this.setState({ isLoadingPurchase: true })
                await BillingService.updateBillingInfo(validationData);
                this.setState({ billingInfo: validationData, activeStep: 1 });
                this.setState({ isLoadingPurchase: false })
            } catch (e) {
                notify.show("Problem occur when saving your billing details. Please retry", "error");
                this.setState({ billingInfo: validationData });
                this.setState({ isLoadingPurchase: false })
            }
            // this.setState({ activeStep: 1 });
        }
    }
    gotoPrevious() {
        let { activeStep } = this.state;
        activeStep = activeStep - 1;
        this.setState({ activeStep });
    }
    // onChangeSaveBillingInfo(e) {
    //     this.setState({ saveBillingInfo: e.target.checked });
    // }
    _showBankTransfer() {
        return this.props.selectedPlan.metaData.type !== "subscription";
    }

    onChangeCurrency(e) {
        this.setState({ currency: e.target.value });
    }

    payByCard = async (e) => {
        try {
            e.preventDefault();
            this.setState({ isProcessingPurchase: true });
            const response = await this.cardRef.current.submit();
            this.props.showAlert("Payment Success", "Payment is success");
            this.context.loadUser();
            this.props.history.push({
                pathname: '/billing/success',
                state: { plan: this.props.selectedPlan, paymentMethod: "cardPayment" }
            });

        } catch (e) {
            this.setState({ isProcessingPurchase: false });
            this.props.showAlert("Payment Failed", e.message || "Please check the provided card details", true, "error");
        }
    }

    onClickProgress(e) {
        e.preventDefault();
        let { activeStep } = this.state;
        const clickResp = parseInt(e.target.id);
        if (activeStep < clickResp) {
            this.onClickNextBillingInfo();
        }
        if (activeStep > clickResp) {
            this.gotoPrevious();
        }
    }

    render() {

        const { selectedPaymentOption, isProcessingPurchase, activeStep, currency, billingInfo } = this.state;
        const { selectedPlan } = this.props;
        const quantity = selectedPlan.quantity || 1;
        const { name, organization, username } = this.context;
        return (
            // <UserContext.Consumer>
            //     {({ name, organization, username }) => (
            <ElementsConsumer>{
                ({ stripe }) => (
                    <div className="container-fluid billing-purchase billing-purchase-height text-center">
                        <ol className="progtrckr py-4 px-0">
                            {stepNames.map((name, i) => (
                                <li className={this.getClassName("progtrckr", i)} key={i} value={i}>
                                    <em>{i + 1}</em>
                                    <small className="step-name" id={i} onClick={this.onClickProgress}>{name}</small>
                                    <span className="progress-line"></span>
                                </li>)
                            )
                            }
                        </ol>

                        <Row className="justify-content-center">
                            {
                                activeStep === 0 ? (

                                    <Col lg={8} sm={10} xs={12}>
                                        <Card>
                                            <Card.Body>
                                                <h4 className="font-weight-bold text-left">Billing Info</h4>
                                                <div className="text-left">
                                                    <BillingInfo ref={this.validateInput} onChangeBillingInfo={this.onChangeBillingInfo} billingInfo={this.state.billingInfo} isLoadingBillingInfo={this.state.isLoadingBillingInfo} />
                                                    {/* <Form.Group>

                                                                <Form.Check name="save-billing" id="save-settings" onChange={this.onChangeSaveBillingInfo} checked={this.state.saveBillingInfo} label="Save billing details in my account" />
                                                            </Form.Group> */}
                                                </div>
                                                <br />
                                            </Card.Body>
                                            <Card.Footer>
                                                <Row>
                                                    <Col xs={6}>
                                                        <div className="text-left">
                                                            <Button variant="dark" size="sm" onClick={() => this.props.history.push('/billing')}>Previous</Button>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <div className="text-right">
                                                            <Button variant="primary" size="sm" disabled={this.state.isLoadingPurchase} onClick={this.onClickNextBillingInfo} >&nbsp;&nbsp;Next&nbsp;&nbsp;</Button>
                                                        </div>
                                                    </Col>
                                                </Row>


                                            </Card.Footer>
                                        </Card>

                                    </Col>


                                ) : (

                                    <>
                                        <Col sm={7} md={7} xs={12}>
                                            {/* <CheckoutForm billingInfo={this.state.billingInfo} merchantId={ownerId} planName={selectedPlan.name} amount={selectedPlan.amount} quantity={selectedPlan.quantity} planId={selectedPlan.id}
                                                        customerName={name} customerOrganization={organization}
                                                        recurrencePeriod={selectedPlan.metaData.recurrencePeriod} billingType={selectedPlan.metaData.type}
                                                    > */}
                                            <Card className="border-0 text-center w-100">
                                                <Card.Body>
                                                    <br />

                                                    <p className="text-left font-weight-bodld">Select Payment Method</p><br />
                                                    <Row>
                                                        <Col xs={4}>
                                                            <Card className={`select-option ${selectedPaymentOption === "card-payment" ? "bg-primary text-white" : "bg-default"} border-0`} id="card-payment" onClick={() => this.onChangePaymentOption("card-payment")} >
                                                                <Card.Body>
                                                                    <div><FaIcon icon={faCreditCard} /></div>
                                                                    <small>Card Payment</small>
                                                                </Card.Body>
                                                            </Card>

                                                        </Col>
                                                        {this._showBankTransfer() && <Col xs={4}>
                                                            <Card className={`select-option ${selectedPaymentOption === "bank-transfer" ? "bg-primary text-white" : "bg-default"} border-0`} id="bank-transfer" onClick={() => this.onChangePaymentOption("bank-transfer")}>
                                                                <Card.Body>
                                                                    <div><FaIcon icon={faUniversity} /></div>
                                                                    <small>Bank Transfer</small>
                                                                </Card.Body>
                                                            </Card>

                                                        </Col>}
                                                    </Row>
                                                    <br />
                                                    {selectedPaymentOption === "card-payment" &&
                                                        <CardPayment billingInfo={billingInfo} ref={this.cardRef} selectedPlan={selectedPlan} />

                                                    }
                                                    {selectedPaymentOption === "bank-transfer" &&
                                                        <div className="text-left">
                                                            {/* <p>How to upload the bank receipt</p>
                                                                        <br />
                                                                        <ol className="ml-n4">
                                                                            <li>Go to the "Invoices"</li>
                                                                            <li>Select your invoice from the invoices list</li>
                                                                            <li>Click the upload button</li>
                                                                            <li>Upload your bank receipt</li>
                                                                        </ol> */}
                                                            <p>
                                                                Our finance team will check and add credits to your
                                                                ShoutOUT account. Usually it takes up to 1 working day.
                                                                <br />
                                                                You can always check the status of your invoice
                                                                from the invoices list.
                                                                <br />
                                                                Thank you!
                                                            </p>
                                                        </div>
                                                    }


                                                </Card.Body>

                                                <Card.Footer className="bg-white border-0">
                                                    <Row>
                                                        <Col xs={6} className="text-left">
                                                            <Button variant="dark" size="sm" onClick={this.gotoPrevious}>Previous</Button>
                                                        </Col>
                                                        <Col xs={6} className="text-right">
                                                            {selectedPaymentOption === "bank-transfer" ?
                                                                <Button className="float-right" type="button" variant="primary" size="sm" disabled={isProcessingPurchase} onClick={(e) => { e.preventDefault(); this.placeOrder(name || username, organization) }}>{isProcessingPurchase ? "Sending Request..." : "Place Order"}</Button> :
                                                                <Button className="float-right" type="button" variant="primary" size="sm" disabled={isProcessingPurchase || !stripe} onClick={this.payByCard}>{isProcessingPurchase ? "Sending Request..." : `Pay ${selectedPlan.currency} ${selectedPlan.currency_amount * quantity}`}</Button>
                                                            }
                                                        </Col>
                                                    </Row>


                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                        <Col sm={4} md={4} xs={12}>
                                            <Card className="border-0 text-left text-primary">
                                                <Card.Body>
                                                    <h5 className="opacity-p7">You've to pay</h5>
                                                    <h2 className="font-weight-bold">{selectedPlan.currency} {selectedPlan.currency_amount * quantity}</h2>
                                                    <hr className="my-0 dashed mx-n3" />
                                                    <br />
                                                    <h6 className="mb-0 opacity-p7">Plan Name</h6>
                                                    <h6>{selectedPlan.metaData.type === "ondemand" ? "Pay-As-You-Go" : "Monthly"} - {selectedPlan.name}</h6>
                                                    <br />
                                                    <h6 className="mb-0 opacity-p7">Quantity</h6>
                                                    <h6>{quantity}</h6>
                                                    <br />
                                                    {selectedPlan?.metaData.type !== "subscription" && <>
                                                        <h6 className="mb-0 opacity-p7">Total {selectedPlan.metaData.packageInclude}</h6>
                                                        <h6>{(selectedPlan.category === "SMS" ? (selectedPlan?.credits?.creditAmountMap?.platform || 1) : (selectedPlan.blockSize)) * (quantity)}</h6>
                                                    </>}
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    </>

                                )

                            }
                        </Row>
                    </div>
                )
            }</ElementsConsumer>

            //     )}
            // </UserContext.Consumer>
        );
    }
}

export default BillingPurchasePlan;