import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Constants from './../../../../Constants';
import { UtilService } from './../../../../services/Services';
import { filter } from 'lodash';
import { FaIcon, Card, Row, Col, Form, Button, Modal, Badge, InputGroup } from '@shoutout-labs/shoutout-themes';
import { faPlus, faUserPlus, faUpload, faSearch, faTimes, faInfo } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import DatePicker from './../../../../lib/react-16-bootstrap-date-picker';
import TimePicker from './../../../custom/TimeSelector';
import UserContext from './../../../../contexts/UserContext';
import moment from 'moment';
import ConfirmBox from './../../../utils/ConfirmBox';
import './CampaignSetup.css';
import { NavLink } from 'react-router-dom';

const today = new Date();
const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
let time;

let confirmCallback = null;
let tempLastAction = null;
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
const CampaignSetup = ({ campaignName, onChangeCampaignName,
    history, scheduleOn, setScheduleTime, scheduleDate,
    isSchedule, setCampaignSchedule, scheduleTime,
    setScheduleDate, campaignType, transports, selectedSegment,
    senderIdsSms, selectedSenderIdSms, senderIdsEmail, selectedSenderIdEmail,
    onSelectSenderId, senderIds, fileName,
    selectedSegmentId, onSelectSegment, segments, clickedImportPeople, showAddEditPerson, selectedFilters, selectedPeople, selectedPeopleDetails,
    deselectPerson, excludedPeople
}) => {

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [scheduleStart, setScheduleStart] = useState(moment().add(30, 'm').format("HH:mm"));
    const [lastAction, setLastAction] = useState(null);
    const [segmentId, setSegmentId] = useState('');
    const [segmentsCampaign, setSegmentsCampaign] = useState([]);
    const [show, setShow] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [segmentKeys, setSegmentKeys] = useState([]);
    //const [file, setFile] = useState("");
    const [country, setCountry] = useState("");

    useEffect(() => {
        UtilService.getIpInfo().then(resp => {
            if (resp) {
                setCountry(resp.country);
            }
        });

    }, [])

    const handleClose = useCallback(() => {

        setShow(false);
        setSearchKey("");
        setSegmentKeys([]);

    }, [setShow, setSearchKey, setSegmentKeys]);

    const handleShow = useCallback(() => setShow(true), [setShow]);


    const keys = useMemo(() => Object.keys(segments), [segments]);

    // console.log(keys)


    const changeAction = useCallback((action, callback) => {
        if (lastAction !== action && ((action !== "import" && selectedFilters.length) || (action !== "create" && selectedPeople.length) || (action !== "segment" && selectedSegmentId))) {

            setShowConfirmDialog(true);
            confirmCallback = callback;
            tempLastAction = action;

        } else {
            callback();
            setLastAction(action);
        }
        // if(action === "import"){
        //     setFile(fileName);
        // }

    }, [lastAction, selectedFilters.length, selectedPeople.length, selectedSegmentId]);

    const closeConfirmDialog = useCallback(() => {
        setShowConfirmDialog(false);
    }, [setShowConfirmDialog]);

    const confirmAction = useCallback(() => {
        closeConfirmDialog();
        confirmCallback();
        setLastAction(tempLastAction);
    }, [closeConfirmDialog]);


    const onChangeSegments = useCallback((e) => {

        let selectedSegmentList = [];
        let id = '';
        if (e.target.id === 'All') {
            id = 0;
        }
        else {
            id = e.target.id;
        }
        if (segments[id].name === 'All') {

            if (segmentsCampaign.length > 0) {
                selectedSegmentList = [];
                setSegmentId("");
                setSegmentsCampaign(selectedSegmentList);

            } else {
                // selectedSegmentList = keys;
                selectedSegmentList = [id];
                setSegmentId(id);

                setSegmentsCampaign(keys);
            }

        } else {
            if (segmentsCampaign.includes(id)) {

                let newSegmentsCampaign = segmentsCampaign
                if (segmentsCampaign[0] === "0") {
                    newSegmentsCampaign = newSegmentsCampaign.filter(seg => seg !== "0");
                }
                selectedSegmentList = newSegmentsCampaign.filter(seg => seg !== id);
            } else {
                selectedSegmentList = [...segmentsCampaign, id];

            }
            setSegmentsCampaign(selectedSegmentList);
        }
        const selectedSegments = selectedSegmentList.map((key) => {
            return segments[key];
        });
        // setSegmentsCampaign(selectedSegmentList);
        changeAction("segment", () => { onSelectSegment(selectedSegments) });

    }, [segmentsCampaign, setSegmentsCampaign, changeAction, onSelectSegment, segments, setSegmentId, keys]);

    const removeSegment = useCallback((id) => {
        let remainingSegments = [];
        setSegmentId("");
        if (segments[id].name !== 'All') {
            remainingSegments = segmentsCampaign.filter(segment => segment !== id);
        }

        const selectedSegments = remainingSegments.map((key) => {
            return segments[key];

        });
        setSegmentsCampaign(remainingSegments);
        changeAction("segment", () => { onSelectSegment(selectedSegments) });

    }, [setSegmentsCampaign, segmentsCampaign, changeAction, onSelectSegment, segments, setSegmentId]);

    const removePerson = useCallback((e) => {
        const id = e.currentTarget.dataset.id;
        console.debug("ID remove:", id);
        // People remove function
        deselectPerson(id);
    }, [deselectPerson]);

    const onSearch = useCallback((e) => {
        const searchKey = e.target.value;
        searchKey ? setSearchKey(searchKey.toLowerCase()) : setSearchKey('');

        if (searchKey) {
            const searchedSegment = filter(keys, key => {

                return segments[key].name.toLowerCase().search(searchKey) > -1;
            });

            setSegmentKeys(searchedSegment);


        };
    }, [keys, segments])



    useEffect(() => {
        if (moment(scheduleDate).isSame(moment(), 'date')) {
            const nextScheduleTime = moment().add(30, 'm');
            const remainder = 15 - (nextScheduleTime.minute() % 15);
            const roundedTime = moment(nextScheduleTime).add(remainder, "minutes").format("HH:mm")
            setScheduleTime(scheduleOn, roundedTime)
        }
        else {
            setScheduleTime(scheduleOn, "00:00");
        }

    }, []);


    const transportType = useMemo(() => {
        if (transports.includes(Constants.TRANSPORT_EMAIL)) return Constants.TRANSPORT_EMAIL;

        return Constants.TRANSPORT_SMS;
    }, [transports])

    return (
        <UserContext.Consumer>
            {({ scopes }) => (
                <Card className="text-left p-4 onload-transition campaign-setup-height">
                    <Card.Body className="px-5 mx-5">
                        <Form.Group className="flex-nowrap" controlId="campaign-name">
                            <Row>
                                <Col lg={8} className="mx-auto">
                                    <Form.Group className="mb-0">
                                        <Form.Label>
                                            <p className="font-weight-semi-bold">Campaign Name</p>
                                        </Form.Label>
                                        <Form.Control type="text" placeholder="Special discount" onChange={(e) => {
                                            onChangeCampaignName(e.target.value)
                                        }} value={campaignName} />
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Form.Group>

                        <Form.Group className="flex-nowrap" controlId="sender-id">
                            <Row>
                                <Col lg={8} className="mx-auto">

                                    <Form.Group>
                                        <Form.Label>
                                            <p className="font-weight-semi-bold">From</p>
                                        </Form.Label>
                                        {transportType === Constants.TRANSPORT_SMS &&
                                            <Form.Control as="select" required disabled={transportType === Constants.TRANSPORT_SMS && senderIdsSms.length === 0} onChange={(e) => {
                                                onSelectSenderId(e.target.value, Constants.TRANSPORT_SMS, senderIds)
                                            }}
                                            >
                                                {senderIdsSms.map(item => {
                                                    return (<option key={item.senderId} value={item.senderId}
                                                        selected={item.senderId === selectedSenderIdSms}>{item.displayName || item.senderId}</option>)
                                                })}
                                            </Form.Control>
                                        }
                                        {/* {
                                            transportType === Constants.TRANSPORT_SMS && senderIdsSms.length === 0 ? <p>No Sender IDs</p> : null
                                        } */}
                                        {transportType === Constants.TRANSPORT_EMAIL &&
                                            <Form.Control as="select" disabled={transportType === Constants.TRANSPORT_EMAIL && senderIdsEmail.length === 0} required onChange={(e) => {
                                                onSelectSenderId(e.target.value, Constants.TRANSPORT_EMAIL, senderIds)
                                            }}>
                                                {senderIdsEmail.map(item => {
                                                    return (<option key={item._id}
                                                        selected={item.senderId === selectedSenderIdEmail}>{item.senderId}</option>)
                                                })}
                                            </Form.Control>
                                        }
                                        {/* {
                                            transportType === Constants.TRANSPORT_EMAIL && senderIdsEmail.length === 0 ? <p>No Sender IDs</p> : null
                                        } */}
                                    </Form.Group>
                                    <div className="d-flex align-items-center">
                                        <NavLink to={transportType === Constants.TRANSPORT_EMAIL ? "/senders/email" : ((country === "us" || country === "ca") ? "/senders/numbers" : "/senders")} className="senderId-link btn btn-outline-primary btn-sm">
                                            <FaIcon className="mr-2" icon={faPlus} /> <span>New {(country === "us" || country === "ca") ? "Phone Number" : "Sender ID"}</span>
                                        </NavLink>
                                        {((transportType === Constants.TRANSPORT_EMAIL && senderIdsEmail.length === 0) || (transportType === Constants.TRANSPORT_SMS && senderIdsSms.length === 0)) && <small className="text-secondary ml-2"><FaIcon className="mr-2" icon={faInfo} />You need to request a Sender ID to send a campaign</small>}
                                    </div>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group className="flex-nowrap" controlId="campaign-name">
                            <Row>
                                <Col lg={8} className="mx-auto">


                                    <Form.Group>
                                        <Form.Label>
                                            <p className="font-weight-semi-bold">To</p>
                                        </Form.Label>

                                        <div className="border border-text-box">
                                            {selectedPeople.length > 0 ? null : (selectedSegment.length === 0 && !fileName) && <p>No segments selected.</p>}
                                            {selectedSegment.length !== 0 && excludedPeople.length === 0 && selectedSegment.map((segment) => {
                                                return (
                                                    <Badge pill variant="transparent" key={segment.id} className="font-weight-normal mb-1 mr-1 p-2 summary-to">
                                                        <p>{segment.name} <span className="pointer" onClick={() => removeSegment(segment.id)}> <FaIcon className="ml-2" icon={faTimes} /></span></p>
                                                    </Badge>
                                                )
                                            })
                                            }
                                            {selectedSegment.length !== 0 && excludedPeople.length > 0 && selectedSegment.map((segment) => {
                                                return (
                                                    <Badge pill variant="transparent" key={segment.id} className="font-weight-normal mb-1 mr-1 p-2 summary-to">
                                                        <p>"{segment.name}" segment selected excluding {excludedPeople.length} people <span className="pointer" onClick={() => removeSegment(segment.id)}> <FaIcon className="ml-2" icon={faTimes} /></span></p>
                                                    </Badge>
                                                )
                                            })
                                            }
                                            {selectedPeople.map((people) => {
                                                return (
                                                    <Badge pill variant="transparent" className="font-weight-normal mb-1 mr-1 p-2 summary-to" key={people.id}>
                                                        <p>{people.name}  -  {transportType === Constants.TRANSPORT_EMAIL ? people.email : people.mobile_number} <span className="pointer" data-id={people.id} onClick={removePerson}> <FaIcon className="ml-2" icon={faTimes} /></span></p>
                                                    </Badge>
                                                )
                                            })
                                            }
                                            {fileName && <Badge pill variant="transparent" className="font-weight-normal mb-1 mr-1 p-2 summary-to">
                                                {fileName}
                                            </Badge>
                                            }
                                        </div>

                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <h6 className="font-weight-bold mb-0">Select From Segments</h6>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <InputGroup className="pt-0 flex-nowrap flex-row mb-3">

                                                    <Form.Control
                                                        placeholder="Search segment by name"
                                                        aria-label="Search segment by name"
                                                        onChange={onSearch}
                                                        value={searchKey}
                                                    />
                                                    <InputGroup.Append className="bring-to-top">
                                                        <Button className="rounded-circle search-icon" variant="primary"><FaIcon icon={faSearch} /></Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                                {segmentKeys.length !== 0 ?
                                                    segmentKeys.map((key) => {
                                                        return (<Form.Check
                                                            className="segment-set"
                                                            type="checkbox"
                                                            label={segments[key].name}
                                                            name={key === "0" ? "All" : key}
                                                            id={key === "0" ? "All" : key}
                                                            key={key}
                                                            checked={segmentsCampaign.includes(key)}
                                                            onChange={onChangeSegments}
                                                            disabled={segmentId && segmentId !== key}
                                                        />)
                                                    }) :
                                                    keys.map((key) => {
                                                        return (<Form.Check
                                                            className="segment-set"
                                                            type="checkbox"
                                                            label={segments[key].name}
                                                            name={key === "0" ? "All" : key}
                                                            id={key === "0" ? "All" : key}
                                                            key={key}
                                                            checked={segmentsCampaign.includes(key)}
                                                            onChange={onChangeSegments}

                                                        // disabled={segmentId && segmentId !== key}
                                                        //disabled={selectedSegment && selectedSegment[0].name !== segments[key].name}
                                                        />)

                                                    })}

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="btn btn-primary btn-sm" onClick={handleClose}>Done</Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <ConfirmBox show={showConfirmDialog} onHide={closeConfirmDialog} confirmCallback={confirmAction} title="Are you sure ?" message="Current contacts selection will be cleared. Do you want to proceed ?" />
                                    </Form.Group>

                                    {selectedPeople.length > 0 && <> <small>You have selected {selectedPeople.length} people</small> <br /></>}

                                    <div className="d-flex mt-2">
                                        {lastAction === "segment" ?
                                            <button className="mb-0 segment-name pointer mr-2 btn btn-outline-primary btn-sm" onClick={() => changeAction("segment", handleShow)}>
                                                <FaIcon className="mr-2" icon={faPlus} />
                                                Add Segments
                                            </button> :
                                            <button className="mb-0 segment-name pointer mr-2 btn btn-outline-primary btn-sm" onClick={() => changeAction("segment", handleShow)}>
                                                <FaIcon className="mr-2" icon={faPlus} />
                                                Add Segments
                                            </button>
                                        }

                                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write &&
                                            <>
                                                {lastAction === "create" ?
                                                    <button className="mb-0 segment-name pointer mr-2 btn btn-outline-primary btn-sm" variant="primary" onClick={() => changeAction("create", showAddEditPerson)}>
                                                        <FaIcon className="mr-2" icon={faUserPlus} />Add Manually
                                                    </button> :
                                                    <button className="mb-0 segment-name pointer mr-2 btn btn-outline-primary btn-sm" variant="primary" onClick={() => changeAction("create", showAddEditPerson)}>
                                                        <FaIcon className="mr-2" icon={faUserPlus} />Add Manually
                                                    </button>
                                                }
                                                {/* {lastAction === "import" ?
                                                    <p className="mb-2 segment-name pointer" variant="primary" onClick={() => changeAction("import", clickedImportPeople)}>
                                                        <FaIcon className="mr-2" icon={faUpload} />Import People
                                                    </p> :
                                                    <p className="mb-2 segment-name pointer" variant="primary" onClick={() => changeAction("import", clickedImportPeople)}>
                                                        <FaIcon className="mr-2" icon={faUpload} />Import People
                                                    </p>
                                                } */}
                                            </>}

                                    </div>

                                </Col>
                            </Row>
                        </Form.Group>
                        {campaignType !== Constants.CAMPAIGN_TRIGGER && <Form.Group className="flex-nowrap">
                            <Row>
                                <Col lg={8} sm={3} xs={12} className="mx-auto">
                                    <Form.Label className="mr-2 font-weight-semi-bold">Launch Campaign</Form.Label>
                                    <div className={`d-flex align-items-center height-39 ${isSchedule && "justify-content-between"}`}>
                                        <Form.Check type="radio" id="now" inline name="schedule" checked={!isSchedule} onChange={() => setCampaignSchedule(false)} label="Now" />

                                        {'     '}
                                        <Form.Check type="radio" id="later" inline name="schedule" checked={isSchedule} onChange={() => setCampaignSchedule(true)} label="Later" />
                                        {isSchedule &&
                                            <Form.Group controlId="schedule" className="d-flex mb-0 ml-auto">
                                                <div className="mr-2 w-100">
                                                    {/* <DatePicker id="schedule-date" calendarPlacement="top" minDate={date} dateFormat="YYYY-MM-DD" showClearButton={false}
                                                        value={scheduleDate}
                                                        onChange={(value, formatted) => setScheduleDate(scheduleOn, formatted)}
                                                    /> */}
                                                    <input className="form-control" type="date" value={scheduleDate} min={moment(date).format("YYYY-MM-DD")} onChange={(e, formatted) => setScheduleDate(scheduleOn, e.target.value)} id="schedule-date" />
                                                </div>
                                                <TimePicker id="schedule-time" step={15} start={moment(scheduleDate).isSame(moment(), 'date') ? scheduleStart : "00:00"} value={scheduleTime}
                                                    onChange={(time) => setScheduleTime(scheduleOn, time)} />
                                                {/* <input className="form-control" type="time" step={15} value={scheduleTime} onChange={(time) => setScheduleTime(scheduleOn, time)}/> */}
                                                {/* start={scheduleStart} */}
                                            </Form.Group>}
                                    </div>
                                </Col>
                            </Row>
                        </Form.Group>}
                    </Card.Body>
                </Card>
            )}
        </UserContext.Consumer>
    )
}
export default CampaignSetup;