import React, { useState, useMemo, useCallback } from 'react';
import { Col, Row, Card, Tabs, Tab, Button, Accordion, Table, FaIcon, Badge, Modal, ModalFooter, ModalBody, FileUploader } from '@shoutout-labs/shoutout-themes';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import FlagIconFactory from 'react-flag-icon-css';
import Constants from './../../Constants';
import { UtilService } from './../../services/Services';
import AccountNotVerifiedNotificationButton from './../utils/AccountNotVerifiedNotificationButton';
import { faPhoneAlt, faChevronDown, faChevronRight, faTrash, faUpload, faTimes, faEye, faCopy, faKey, faUsers, faInfocCircle, faCheckCircle, faTimesCircle, faSpinner, faInfoCircle } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';


const SettingsButton = ({ children, eventKey, setCurrentKey, currentKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        if (eventKey !== currentKey) {

            setCurrentKey(eventKey)
        }
        else setCurrentKey(null)
    }
    );

    return (
        <div
            type="button"
            size="sm"
            className="d-flex justify-content-start align-items-center sms-sender-button"
            variant="transparent"
            onClick={decoratedOnClick}
        >
            {children}
        </div>
    );
}

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const ArtifactStatus = {
    PENDING_SUBMISSION: 0,
    PENDING_REVIEW: 1,
    APPROVED: 2,
    REJECTED: 3
}

const SMSSenders = ({ smsSenderIds, showSenderRequest, onClickDownloadAgreement, onClickGenerate, downloadingAgreementId, generatingArtifactId, showUploadAgreementModal, onClickDeleteSenderId, mobileVerified }) => {
    const [currentKey, setCurrentKey] = useState(null);
    // const smsSenderIdsFiltered = smsSenderIds.filter(({ phoneNumberId }) => !phoneNumberId);

    const clearTitleCase = useCallback((title) => {
        return title.replace(/_/g, ' ').toLowerCase();
    }, [])

    return (<>
        <div className="d-flex justify-content-end align-items-center my-1">
            {/* <h6 className="font-weight-bold mb-0 mr-auto">Sender IDs</h6> */}
            <Button variant="primary" size="sm" type="submit" className={!mobileVerified && 'disabled'} disabled={!mobileVerified} onClick={() => { showSenderRequest(Constants.TRANSPORT_SMS); }}>Request Sender ID</Button>
            <AccountNotVerifiedNotificationButton show={!mobileVerified} />
        </div>
        <Row className="senders-tab-height">
            <Col xs={12} md={12} className="mt-3">
                <div>
                    {smsSenderIds.length > 0 ? smsSenderIds.map(senderId => {
                        return (
                            <Accordion defaultActiveKey="0" key={senderId._id}>
                                <Card className="mb-3 border-0">
                                    <Card.Header className="bg-white">
                                        <SettingsButton currentKey={currentKey} setCurrentKey={setCurrentKey} eventKey={senderId._id}>
                                            <p>{senderId.senderId}</p>
                                            {senderId.metadata ? senderId.metadata.enableInternationalUsage && <small className="international-numbers">Enabled International SMS</small> : ''}
                                            <Badge className="ml-auto" variant={senderId.status === 1 ? "success" : (senderId.status === 2 ? "secondary" : (senderId.status === 3 ? "danger" : "warning"))}>
                                                {senderId.status === 1 ? "Verified" : (senderId.status === 2 ? "Verifying Documents" : (senderId.status === 3 ? "Activation Failed" : "Action Pending"))}
                                            </Badge>
                                            {senderId.ownerId !== "default" && <Button className="ml-2 border-0" size="sm" variant="outline-danger" data-id={senderId._id} onClick={onClickDeleteSenderId}><FaIcon className="mr-2" icon={faTrash} />Delete</Button>}
                                            <FaIcon className={`text-dark ml-2 ${senderId.status === 1 && "d-none"}`} icon={senderId.registeredCountries ? faChevronDown : faInfoCircle} />
                                        </SettingsButton>
                                    </Card.Header>
                                    {senderId.registeredCountries ? !(senderId.status === 1) && <Accordion.Collapse eventKey={senderId._id}>
                                        <Card.Body>
                                            <>
                                                {senderId.registeredCountries.map((country, index) => {
                                                    return (<div className="border-secndary border-bottom mb-3" key={`${index}-${country}`}>
                                                        <div className="d-flex mb-2">
                                                            <p>
                                                                <FlagIcon code={country.countryISOCode.toLowerCase()} />
                                                                <span className="ml-2">{country.countryName || country.countryISOCode}</span>
                                                            </p>
                                                            {/* {country.status === 1 ? 
                                                                    <Badge className="ml-auto" variant="success">
                                                                        Activated
                                                                    </Badge> : 
                                                                    <Badge className="ml-auto" variant="danger">
                                                                        Deactivated
                                                                    </Badge>
                                                                } */}
                                                        </div>

                                                        {UtilService.sortAlphabetically(country.artifacts).map(({ artifactType, _id, status, artifactId, isTemplateAvailable }) => {
                                                            return (<>
                                                                <div className="d-flex justify-content-start align-items-center mb-2 bg-artifacts" key={`${index}-${_id}`}>
                                                                    <p className="mb-0 ml-2 mr-auto text-capitalize"> <FaIcon className={`mr-2 text-${status === ArtifactStatus.PENDING_SUBMISSION ? "primary" : status === ArtifactStatus.PENDING_REVIEW ? "warning" : status === ArtifactStatus.APPROVED ? "success" : "danger"}`} icon={status === ArtifactStatus.PENDING_SUBMISSION ? "info-circle" : status === ArtifactStatus.PENDING_REVIEW ? "spinner" : status === ArtifactStatus.APPROVED ? "check-circle" : "times-circle"} />{clearTitleCase(artifactType)}</p>
                                                                    {isTemplateAvailable && <Button className="ml-2 border-0" size="sm" variant="outline-primary" data-type={artifactType} data-name={senderId._id} data-id={country._id} onClick={onClickGenerate} disabled={generatingArtifactId === country._id}>
                                                                        <FaIcon className="mr-2" icon={generatingArtifactId === country._id ? "spinner" : "download"} />Download Agreement
                                                                    </Button>}
                                                                    <Button size="sm" className="border-0" variant="outline-dark" data-type={artifactType} data-name={senderId._id} data-id={country._id} name="Upload Signed Agreement" onClick={showUploadAgreementModal}>
                                                                        <FaIcon className="mr-2" icon={faUpload} />Upload <span className="text-capitalize">{clearTitleCase(artifactType)}</span>
                                                                    </Button>
                                                                    {artifactId !== "" &&
                                                                        <Button className="ml-2 border-0" size="sm" variant="outline-primary" data-type={country._id} data-name={senderId._id} data-id={artifactId} onClick={onClickDownloadAgreement} disabled={downloadingAgreementId === country._id}>
                                                                            <FaIcon className="mr-2" icon={downloadingAgreementId === country._id ? "spinner" : "eye"} />View Agreement
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            </>)
                                                        })}
                                                    </div>
                                                    )
                                                })}
                                            </>

                                        </Card.Body>
                                    </Accordion.Collapse> : ''}
                                </Card>
                            </Accordion>
                        );
                    })
                        :
                        <p className="text-center"> No SMS Sender IDs</p>
                    }
                </div>

            </Col>
        </Row>
    </>
    )
}
export default SMSSenders;