import React from 'react';
// import { BillingPlans } from './../../redux/containers/BillingContainer';
import BillingPlans from './../billing/BillingPlans'
const BillingPage = (props) => {
    return (
        <div className="billings padding-top-container px-4 container-fluid onload-transition">
            <BillingPlans {...props} />
        </div>
    );
}

export default BillingPage;