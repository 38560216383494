const handleErrors = async(response) => {
    if (!response.ok) {
        let errorResponse;
        try {
          errorResponse = await response.json();
        } catch (e) {
          throw Error(response.statusText);
        }
        throw Error(errorResponse?.error);
      }
      return response;
}

const parseJson = (response) => {
    try {
        return response.json();
    } catch (e) {
        console.error("Cannot parse:", response);
        return Promise.reject("Can not parse");
    }
}

const parseText = (response) => {
    if (response) {
        try {
            return response.text();
        } catch (e) {
            console.error("Cannot parse:", response);
            return Promise.reject("Can not parse");
        }
    }
    return {};
}

const fetchGet = (url, track,headers) => {
    return fetch(url, {
        method: 'GET',
        headers: headers || {},
        isProtected: true,
        showLoader: true,
        track
    }).then(handleErrors).then(parseJson)
}

const fetchGetText = (url, showLoader = true, track) => {
    return fetch(url, {
        method: 'GET',
        headers: {},
        isProtected: true,
        showLoader,
        track
    }).then(handleErrors).then(parseText)
}

const fetchPostText = (url,body, showLoader = true, track) => {
    return fetch(url, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        isProtected: true,
        showLoader,
        body: JSON.stringify(body),
        track
    }).then(handleErrors).then(parseText)
}

const fetchPost = (url, body, track) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: true,
        showLoader: true,
        body: JSON.stringify(body),
        track
    }).then(handleErrors).then(parseJson)
}
const fetchPut = (url, body, track) => {
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: true,
        showLoader: true,
        body: JSON.stringify(body),
        track
    }).then(handleErrors).then(parseJson)
}
const fetchDelete = (url, body, track) => {
    return fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        isProtected: true,
        showLoader: true,
        body: body ? JSON.stringify(body) : "",
        track
    }).then(handleErrors)
}

const fetchPostMultipart = async (url, body,track) => {
   
    return fetch(url, { headers: {}, method: 'POST', body ,isProtected: true,
    showLoader: true,track}).then(handleErrors).then(parseJson);


}

const parseJsonWithHeaders = (response) => {
    try {
        return {data:response.json(), headers:response.headers};
    } catch (e) {
        console.error("Cannot parse:", response);
        return Promise.reject("Can not parse");
    }
}

const fetchGetWithHeaders = (url, track,headers) => {
    return fetch(url, {
        method: 'GET',
        headers: headers || {},
        isProtected: true,
        showLoader: true,
        track
    }).then(handleErrors).then(parseJsonWithHeaders)

}

const jsonToQueryParam = (json) => {
    let result = '';
    if (json) {
        Object.entries(json).map(([key, value]) => {

            if (value !== null || value !== undefined || value === "") {
                if (Array.isArray(value)) {

                    value.forEach(element => {
                        result += `${result ? '&' : ''}${key}[]=${element}`;
                    });
                } else {
                    result += `${result ? '&' : ''}${key}=${value}`;
                }

            }
        })
    }

    return result;
}


export { handleErrors, parseJson, fetchGet, fetchPost, fetchPut, fetchDelete, fetchGetText, fetchPostText ,fetchPostMultipart,parseJsonWithHeaders, fetchGetWithHeaders,jsonToQueryParam};