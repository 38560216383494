import React, { useState, useCallback, useEffect } from 'react';
import { useToggle } from './../../hooks';
import { notify } from "react-notify-toast";
import { ShoutOUTService } from "./../../services/Services";
import { Modal, Button, Form, Col, Accordion, Card } from '@shoutout-labs/shoutout-themes';
import Constants from '../../Constants';
import UserAccessTemplates, { ACCESS_CONTROL_DATA } from './../../constants/UserAccessTemplates';
import './AddTeamMember.css';

const { USER_ACCESS_CONTROLS } = Constants;
const initialFormData = { name: '', email: '', role: 'user' };
const OTHER_GROUP = "Other";

const DEFAULT_ACCESS_KEY_LEVELS = {
    read: 0,
    write: 1,
    delete: 2
}
// const MODULE_NAME_MAP = {
//     [USER_ACCESS_CONTROLS.MODULES.APPS.moduleId]: "Apps",
//     [USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId]: "Campaigns",
// }
const MODULE_DATA_GROUP = {
    [USER_ACCESS_CONTROLS.MODULES.APPS.moduleId]: {
        label: "Apps",
        group: Constants.APP_ENGAGEMENT
    },
    [USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId]: {
        label: "Campaigns",
        group: Constants.APP_ENGAGEMENT
    },
    [USER_ACCESS_CONTROLS.MODULES.COMMON.moduleId]: {
        label: "Common",
        group: Constants.APP_ENGAGEMENT
    },
    [USER_ACCESS_CONTROLS.MODULES.LITE_MESSAGES.moduleId]: {
        label: "Send Message",
        group: Constants.APP_LITE
    },
    [USER_ACCESS_CONTROLS.MODULES.LOYALTY_ANALYTICS.moduleId]: {
        label: "Analytics",
        group: Constants.APP_LOYALTY
    },
    [USER_ACCESS_CONTROLS.MODULES.LOYALTY_CONFIGURATIONS.moduleId]: {
        label: "Configurations",
        group: Constants.APP_LOYALTY
    },
    [USER_ACCESS_CONTROLS.MODULES.LOYALTY_POINTS.moduleId]: {
        label: "Points",
        group: Constants.APP_LOYALTY
    },
    [USER_ACCESS_CONTROLS.MODULES.LOYALTY_REWARDS.moduleId]: {
        label: "Rewards",
        group: Constants.APP_LOYALTY
    },
    [USER_ACCESS_CONTROLS.MODULES.LOYALTY_TRANSACTIONS.moduleId]: {
        label: "Transactions",
        group: Constants.APP_LOYALTY
    },
    [USER_ACCESS_CONTROLS.MODULES.LOYALTY_USERS.moduleId]: {
        label: "Users",
        group: Constants.APP_COLLECT
    },
    [USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId]: {
        label: "People",
        group: Constants.APP_ENGAGEMENT
    },
    [USER_ACCESS_CONTROLS.MODULES.POLL_COMPETITION.moduleId]: {
        label: "Competition",
        group: Constants.APP_GAMIFICATION
    },
    [USER_ACCESS_CONTROLS.MODULES.POLL_EXPORTS.moduleId]: {
        label: "Export",
        group: Constants.APP_GAMIFICATION
    },
    [USER_ACCESS_CONTROLS.MODULES.POLL_REPORTS.moduleId]: {
        label: "Reports",
        group: Constants.APP_GAMIFICATION
    },
    [USER_ACCESS_CONTROLS.MODULES.RESPONSE.moduleId]: {
        label: "Responses",
        group: Constants.APP_ENGAGEMENT
    },
    [USER_ACCESS_CONTROLS.MODULES.ROOT.moduleId]: {
        label: "Root",
        group: Constants.APP_ENGAGEMENT
    },
    [USER_ACCESS_CONTROLS.MODULES.SYSTEM.moduleId]: {
        label: "System",
        group: Constants.APP_ENGAGEMENT
    },
    [USER_ACCESS_CONTROLS.MODULES.KLIP_USERS.moduleId]: {
        label: "Users",
        group: Constants.APP_KLIP
    },
    [USER_ACCESS_CONTROLS.MODULES.KLIP_USERS.moduleId]: {
        label: "Users",
        group: Constants.APP_KLIP
    },
    [USER_ACCESS_CONTROLS.MODULES.KLIP_ADD_POINTS.moduleId]: {
        label: "Points Add",
        group: Constants.APP_KLIP
    },
    [USER_ACCESS_CONTROLS.MODULES.KLIP_REDEEM_POINTS.moduleId]: {
        label: "Points Redeem",
        group: Constants.APP_KLIP
    },


    [USER_ACCESS_CONTROLS.MODULES.KLIP_REWARD_REDEEM.moduleId]: {
        label: "Redeem Reward",
        group: Constants.APP_KLIP
    },
    [USER_ACCESS_CONTROLS.MODULES.KLIP_REWARD_CLAIM.moduleId]: {
        label: "Claim Reward",
        group: Constants.APP_KLIP
    },
    [USER_ACCESS_CONTROLS.MODULES.TEAMS.moduleId]: {
        label: "Team Members",
        group: OTHER_GROUP
    }


}


const AccordionButton = ({ children, onClick, ...rest }) => {
    return (
        <Button {...rest} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
        }}>{children}</Button>
    )
}


const AddTeamMember = ({ showTeamMembersModal, closeTeamMemberForm, teamMemberDetail, onAddTeamMember }) => {
    const [isCreating, toggleIsCreating] = useToggle(false);
    const [formData, setFormData] = useState({ ...initialFormData });
    const [accessScopes, setAccessScopes] = useState([...ACCESS_CONTROL_DATA]);


    const applyScopeTemplate = useCallback((scopeTemplate) => {

        const newAccessScope = ACCESS_CONTROL_DATA.map((item) => {
            const scopeUpdate = scopeTemplate.find(({ moduleId }) => moduleId === item.moduleId);
            if (scopeUpdate) {
                return { ...item, ...scopeUpdate };
            }
            return item;
        });
        setAccessScopes(newAccessScope);
    }, [setAccessScopes])

    useEffect(() => {
        if (teamMemberDetail) {
            if (teamMemberDetail.scopes) {
                setFormData({ ...initialFormData, ...teamMemberDetail, role: '' });
                // TODO: Map existing scope
            } else {
                setFormData({ ...initialFormData, ...teamMemberDetail });
            }


        }
    }, [teamMemberDetail])

    useEffect(() => {
        if (formData.role && formData.role !== 'custom' && UserAccessTemplates[formData.role]) {
            applyScopeTemplate(UserAccessTemplates[formData.role]);
        }
    }, [formData.role])

    const addTeamMember = useCallback(async (event) => {
        event.preventDefault();


        try {

            //request new team members
            toggleIsCreating();
            const { name, email } = formData;
            const createMemberResponse = await ShoutOUTService.requestNewMembers({ name, email, scopes: accessScopes });

            toggleIsCreating();
            onAddTeamMember(createMemberResponse);
            notify.show("Member added", "success");
        } catch (e) {
            notify.show("Can't add member. This email may already associated with another account.", "error");
            toggleIsCreating();
        }

    }, [toggleIsCreating, formData, onAddTeamMember, accessScopes])

    const onChange = useCallback((event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }, [setFormData, formData])

    const setNoAccess = useCallback((e) => {
        const moduleId = e.target.dataset.id;

        setAccessScopes(
            accessScopes.map((item) => {
                if (item.moduleId === moduleId) {
                    for (const [key] of Object.entries(item)) {
                        if (key !== "moduleId") {
                            item[key] = false;
                        }
                    }
                }
                return item;
            })
        );
    }, [accessScopes, setAccessScopes])

    const changeAccessScope = useCallback((e) => {
        const { id, label } = e.target.dataset;

        const newAccessLevel = DEFAULT_ACCESS_KEY_LEVELS[label];
        setAccessScopes(
            accessScopes.map((item) => {
                if (item.moduleId === id) {
                    for (const [key] of Object.entries(item)) {
                        if (key !== "moduleId") {
                            if (key === label) {
                                item[key] = true;
                            } else if (DEFAULT_ACCESS_KEY_LEVELS[key] < newAccessLevel) {
                                item[key] = true;
                            }
                        }
                    }
                }
                return item;
            })
        );
    }, [accessScopes, setAccessScopes])


    const ScopeTable = useCallback(({ accessScopes, group, title }) => {
        return (
            <Card body className="mb-3">
                <h6><strong>{title}</strong></h6>
                <table className="w-100 scope-table">

                    <thead>
                        <th></th>
                        <th>No Access</th>
                        <th>View</th>
                        <th>Modify</th>
                        <th>Delete</th>
                    </thead>
                    <tbody>
                        {accessScopes.map(({ moduleId, read, write, delete: del }) => {
                            if (MODULE_DATA_GROUP[moduleId].group !== group) {
                                return null;
                            }

                            const noAccessChecked = !(read || write || del);

                            return (
                                <tr key={moduleId}>
                                    <td>{MODULE_DATA_GROUP[moduleId].label}</td>
                                    <td>
                                        <Form.Check id={`${moduleId}-noaccess`} data-id={moduleId} type="radio" label="" checked={noAccessChecked} onChange={setNoAccess} />
                                    </td>
                                    <td>
                                        <Form.Check id={`${moduleId}-read`} type="radio" data-id={moduleId} data-label="read" label="" disabled={read === undefined} checked={read || false} onChange={changeAccessScope} />
                                    </td>
                                    <td>
                                        <Form.Check id={`${moduleId}-write`} type="radio" data-id={moduleId} data-label="write" label="" disabled={write === undefined} checked={write || false} onChange={changeAccessScope} />
                                    </td>
                                    <td>
                                        <Form.Check id={`${moduleId}-del`} type="radio" data-id={moduleId} data-label="delete" label="" disabled={del === undefined} checked={del || false} onChange={changeAccessScope} />
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </Card>
        )
    }, [setNoAccess, changeAccessScope]
    )

    return (
        <Modal bsPrefix="modal" show={showTeamMembersModal} onHide={closeTeamMemberForm} size="lg" className="add-team-member">
            <Modal.Header closeButton>
                <h6 className="mb-0 font-weight-bold">Add Team Member</h6>
            </Modal.Header>

            <>

                <Form horizontal onSubmit={addTeamMember}>
                    <Modal.Body className="p-2">
                        <Form.Group>
                            <Col as={Form.Label} xs={12} md={3}>
                                Name
                            </Col>
                            <Col xs={12}>
                                <Form.Control type="text" placeholder="sami" name="name" onChange={onChange} value={formData.name} required />
                            </Col>
                        </Form.Group>

                        <Form.Group>
                            <Col as={Form.Label} xs={12} md={3}>
                                Email
                            </Col>
                            <Col xs={12}>
                                <Form.Control type="email" placeholder="samj@test.com" name="email" onChange={onChange} value={formData.email} required />
                            </Col>
                        </Form.Group>

                        <Form.Group>
                            <Col as={Form.Label} xs={12} md={3}>
                                Role
                            </Col>
                            <Col xs={12} >
                                <Form.Control as="select" name="role" placeholder="select" onChange={onChange} value={formData.role} required >
                                    <option value="user">User</option>
                                    <option value="collect">Collect User</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Accordion>
                            <div className="text-center">
                                <Accordion.Toggle as={AccordionButton} variant="link" eventKey="0">
                                    View Advanced Options
                                </Accordion.Toggle>
                            </div>

                            <Accordion.Collapse eventKey="0">
                                <div className="mx-3">


                                    <ScopeTable accessScopes={accessScopes} group={Constants.APP_ENGAGEMENT} title="Engagement" />

                                    {Constants.BUILD_APP === Constants.APP_LOYALTY &&
                                        <>
                                            <ScopeTable accessScopes={accessScopes} group={Constants.APP_LOYALTY} title="Loyalty" />
                                            <ScopeTable accessScopes={accessScopes} group={Constants.APP_KLIP} title="Klip" />
                                        </>}

                                    <ScopeTable accessScopes={accessScopes} group={Constants.APP_LITE} title="Lite" />
                                    <ScopeTable accessScopes={accessScopes} group={Constants.APP_COLLECT} title="Collect" />
                                    <ScopeTable accessScopes={accessScopes} group={Constants.APP_GAMIFICATION} title="Poll" />
                                    <ScopeTable accessScopes={accessScopes} group={OTHER_GROUP} title="Other" />


                                </div>


                            </Accordion.Collapse>

                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                            <Button variant="primary" size="sm" type="submit" disabled={isCreating}>{isCreating ? "Saving..." : "Save"}</Button>
                    </Modal.Footer>

                </Form>
            </>

        </Modal>
    )
}
export default AddTeamMember;