import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Switch, Route, Link, useHistory } from "react-router-dom";
import { Col, Row, Card, Tabs, Tab, Button, Table, FaIcon, Badge, Modal, ModalFooter, ModalBody, FileUploader, Nav } from '@shoutout-labs/shoutout-themes';
import { AccountService, ShoutOUTService, ShoutOUTUtilityService, UtilService } from "./../../services/Services";
import Constants from './../../Constants';
import { ConfirmBox } from './../utils/UtilComponents';
import UserContext from './../../contexts/UserContext';
import AccountNotVerifiedNotificationButton from './../utils/AccountNotVerifiedNotificationButton';
import OnBoardingContext, { StepNames } from './../../contexts/OnboardingContext';
import SenderRequest from "./../sender_request/SenderRequest";
import SenderRequestSMS from "./../sender_request/SenderRequestSMS";
import NumberPurchaseModal from './NumberPurchaseModal';
import { notify } from "react-notify-toast";
import SMSSenders from './SMSSenders';
import LoadingComponent from '../utils/LoadingComponent';
import { faTrash, faTimes, faChevronRight } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import FlagIconFactory from 'react-flag-icon-css';


const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const PhoneNumbers = ({ mobileVerified, onBoardingContext, showNumberRequest, isLoadingPurchasedNumbers, purchasedNumbers, showNumberDelete }) => {
    return (
        <>
            <div className="d-flex justify-content-end align-items-center my-1">
                {/* <h6 className="font-weight-bold mb-0 mr-auto">Phone Numbers</h6> */}
                <Button variant="primary" size="sm" type="submit" className={(!mobileVerified && !onBoardingContext.steps[StepNames.STEP5]) && 'disabled'} disabled={!mobileVerified && !onBoardingContext.steps[StepNames.STEP5]} onClick={() => { showNumberRequest() }}>Purchase Phone Number</Button>
                <AccountNotVerifiedNotificationButton show={!mobileVerified} />
            </div>
            <Row className="senders-tab-height">
                <Col xs={12} md={12} className="mt-3">
                    {isLoadingPurchasedNumbers ? <LoadingComponent /> :
                        <div>{purchasedNumbers.length > 0 ? purchasedNumbers.map(purchasedNumber => {
                            return (
                                <Card className="border-0 mb-3" key={purchasedNumber._id}>
                                    <Card.Header className="bg-white border-0">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <p className="mr-4">{purchasedNumber.phoneNumber}</p>
                                            <p className="mr-4 text-capitalize">{purchasedNumber.type}</p>
                                            <p><FlagIcon code={purchasedNumber.countryISO.toLowerCase()} /><span className="ml-2">{purchasedNumber.countryName}</span></p>
                                            <Button className="ml-auto border-0" size="sm" variant="outline-danger" data-id={purchasedNumber._id} onClick={showNumberDelete}><FaIcon className="mr-2" icon={faTrash} />Delete</Button>
                                        </div>
                                    </Card.Header>
                                </Card>)
                        }) :
                            <p className="text-center"> No Purchased Phone Numbers</p>
                        }</div>
                    }
                </Col>
            </Row>
        </>
    )
}

const EmailSenders = ({ mobileVerified, showSenderRequest, emailSenderIds, onClickDeleteEmailSenderId }) => {
    return (
        <>
            <div className="d-flex justify-content-end align-items-center my-1">
                {/* <h6 className="font-weight-bold mb-0 mr-auto">Email Sender IDs</h6> */}
                {/* <Button variant="primary" size="sm" type="submit" className={!mobileVerified && 'disabled'} disabled={!mobileVerified} onClick={() => { showSenderRequest(Constants.TRANSPORT_EMAIL) }}>Request Email Sender ID</Button> */}
                <Button 
                    variant="primary" 
                    size="sm" 
                    type="submit" 
                    className='disabled' 
                    disabled 
                    onClick={() => { showSenderRequest(Constants.TRANSPORT_EMAIL) }}
                >
                    Request Email Sender ID
                </Button>
                <AccountNotVerifiedNotificationButton show={!mobileVerified} />
            </div>
            <Row className="senders-tab-height">
                <Col xs={12} md={12} className="mt-3">
                    {emailSenderIds.length > 0 ? emailSenderIds.map(senderId => {
                        return (
                            <Card className="border-0 mb-3" key={senderId._id}>
                                <Card.Header className="bg-white border-0">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <p className="mr-2">{senderId.senderId.substring(0, senderId.senderId.indexOf('<'))}</p>
                                        <p className="text-muted">{senderId.senderId.substring(senderId.senderId.indexOf('<') + 1, senderId.senderId.indexOf('>'))}</p>
                                        <Badge className="ml-auto" variant={senderId.status === 1 ? "success" : "warning"}>{senderId.status === 1 ? "Verified" : "Not Verified"}</Badge>
                                        {senderId.ownerId !== "default" && <Button className="ml-2 border-0" size="sm" variant="outline-danger" data-id={senderId._id} onClick={onClickDeleteEmailSenderId}><FaIcon className="mr-2" icon={faTrash} />Delete</Button>}
                                    </div>
                                </Card.Header>
                            </Card>
                        )
                    }) :
                        <p className="text-center"> No Email Sender IDs</p>
                    }
                </Col>
            </Row>
        </>
    )
}

const Senders = ({ smsSenderIds, emailSenderIds, sentSenderIdRequest, showAlert, location }) => {

    const history = useHistory();

    const [showSenderReqModal, setShowSenderReqModal] = useState(false);
    const [showSenderReqModalSMS, setShowSenderReqModalSMS] = useState(false);
    const [senderIdType, setSenderIdType] = useState(Constants.TRANSPORT_SMS);
    const [downloadingAgreementId, setDownloadingAgreementId] = useState('');
    const [generatingArtifactId, setGeneratingArtifactId] = useState();
    const [uploadModalName, setUploadModalName] = useState('');
    const [showSenderIdDeleteConfirm, setShowSenderIdDeleteConfirm] = useState(false);
    const [showUploadAgreement, setShowUploadAgreement] = useState(false);
    const [agreementFileName, setAgreementFileName] = useState('')
    const [uploadingAgreement, setUploadingAgreement] = useState(false);
    const [isSendingDeleteRequest, setIsSendingDeleteRequest] = useState(false);
    const [showEmailSenderIdDeleteConfirm, setShowEmailSenderIdDeleteConfirm] = useState(false);
    const [showNumberDeleteConfirm, setShowNumberDeleteConfirm] = useState(false);
    const [showNumberRequestModal, setShowNumberRequestModal] = useState(false);
    const [isLoadingPurchasedNumbers, setIsLoadingPurchasedNumbers] = useState(true);
    const [purchasedNumbers, setPurchasedNumbers] = useState([]);
    const [selectedNumberId, setSelectedNumberId] = useState('');
    const [selectedSenderId, setSelectedSenderId] = useState({ senderId: '', countryId: '', artifactId: '', artifactType: '' });
    const [filesUploaded, setFilesUploaded] = useState();
    const [country, setCountry] = useState('lk');

    const onBoardingContext = useContext(OnBoardingContext);

    const showSenderRequest = useCallback((senderIdType) => {
        if (senderIdType === Constants.TRANSPORT_SMS) {
            setShowSenderReqModalSMS(true);
        }
        else {
            setSenderIdType(senderIdType);
            setShowSenderReqModal(true);

        }
    }, [setShowSenderReqModalSMS, setSenderIdType, setShowSenderReqModal]);

    const hideSenderIdDeleteConfirm = useCallback(() => {
        setShowSenderIdDeleteConfirm(false);

    }, [setShowSenderIdDeleteConfirm])

    // Deletes Sender Id

    const onClickDeleteSenderId = useCallback((e) => {
        const senderId = e.target.dataset['id'];
        e.stopPropagation();
        setSelectedSenderId({ senderId: senderId })
        setShowSenderIdDeleteConfirm(true);
    }, [setSelectedSenderId, setShowSenderIdDeleteConfirm]);

    const deleteSenderId = useCallback((e) => {
        setIsSendingDeleteRequest(true)
        AccountService.deleteSenderId(selectedSenderId.senderId).then(res => {
            showAlert("Success", "Your Sender ID has been deleted");
            setIsSendingDeleteRequest(false)
            hideSenderIdDeleteConfirm();
            setSelectedSenderId({ senderId: '' })
            sentSenderIdRequest();
        }, err => {
            showAlert("Couldn't Delete", "Unable to process your request at the moment. Try again.", "danger");
            hideSenderIdDeleteConfirm();
            setIsSendingDeleteRequest(false)
            setSelectedSenderId({ senderId: '' })
        });

    }, [setIsSendingDeleteRequest, setSelectedSenderId, sentSenderIdRequest, selectedSenderId, hideSenderIdDeleteConfirm, showAlert])

    const onClickDeleteEmailSenderId = useCallback((e) => {
        e.stopPropagation();
        const senderId = e.target.dataset['id'];
        setSelectedSenderId({ senderId: senderId })
        setShowEmailSenderIdDeleteConfirm(true)
    }, [setShowEmailSenderIdDeleteConfirm, setSelectedSenderId]);

    const hideEmailSenderIdDeleteConfirm = useCallback(() => {
        setShowEmailSenderIdDeleteConfirm(false)
    }, [setShowEmailSenderIdDeleteConfirm]);

    // Deletes Email Sender Id
    const deleteEmailSenderId = useCallback((e) => {
        setIsSendingDeleteRequest(true)
        AccountService.deleteSenderId(selectedSenderId.senderId).then(res => {
            showAlert("Success", "Your Email Sender ID has been deleted");
            setIsSendingDeleteRequest(false)
            hideEmailSenderIdDeleteConfirm();
            setSelectedSenderId({ senderId: '' })
            sentSenderIdRequest();
        }, err => {
            showAlert("Couldn't Delete", "Unable to process your request at the moment. Try again.", "danger");
            hideEmailSenderIdDeleteConfirm();
            setIsSendingDeleteRequest(false)
            setSelectedSenderId({ senderId: '' })
        });

    }, [setIsSendingDeleteRequest, setIsSendingDeleteRequest, setSelectedSenderId, selectedSenderId]);

    const closeSenderRequest = useCallback((result) => {
        setShowSenderReqModal(false);
        setShowSenderReqModalSMS(false);
        sentSenderIdRequest(result)
    }, [setShowSenderReqModal, setShowSenderReqModalSMS, sentSenderIdRequest]);


    const onClickDownloadAgreement = useCallback((e) => {
        // e.stopPropagation();
        const senderId = e.target.dataset['name'];
        const registeredCountryId = e.target.dataset['type'];
        const artifactId = e.target.dataset['id'];
        setDownloadingAgreementId(registeredCountryId);
        ShoutOUTService.downloadArtifacts(senderId, registeredCountryId, artifactId).then(res => {
            const fileName = 'Agreement-' + artifactId;
            const url = res.url;
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            setDownloadingAgreementId();
        });

    }, [setDownloadingAgreementId]);

    const onClickGenerate = useCallback((e) => {
        const senderId = e.target.dataset['name'];
        const registeredCountryId = e.target.dataset['id'];
        const artifactType = e.target.dataset['type'];
        setGeneratingArtifactId(registeredCountryId)
        ShoutOUTService.generateArtifacts(senderId, registeredCountryId, artifactType).then(res => {
            let fileName = 'Artifact-' + artifactType + '.pdf';
            let url = 'data:application/octet-stream;base64,' + res;
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        }, err => {
            console.log(err)
        });
        setGeneratingArtifactId()
    }, [setGeneratingArtifactId])



    const showUploadAgreementModal = useCallback((e) => {
        const senderId = e.currentTarget.dataset['name'];
        const countryId = e.currentTarget.dataset['id'];
        const artifactType = e.currentTarget.dataset['type'];
        // setSelectedSenderId(selectedSenderId);
        // setSelectedSenderId({senderId:selectedSenderId})
        const name = e.currentTarget.name;
        setUploadModalName(name);
        setSelectedSenderId({ senderId: senderId, countryId: countryId, artifactType: artifactType })

        setShowUploadAgreement(true)
    }, [setSelectedSenderId, setUploadModalName, setShowUploadAgreement]);

    const onDrop = useCallback((e) => {
        setFilesUploaded(e.target.files[0]);
        setAgreementFileName(e.target.files[0].name);

    }, [setAgreementFileName, setFilesUploaded]);


    const onClickUpload = useCallback(() => {
        const formData = new FormData();
        const { senderId, countryId, artifactType } = selectedSenderId;
        formData.append('file', filesUploaded);
        formData.append('senderId', senderId);
        formData.append('registeredCountryId', countryId);
        formData.append('artifactType', artifactType);
        setUploadingAgreement(true)
        return new Promise(async (resolve, reject) => {
            try {
                await ShoutOUTService.uploadArtifacts(formData);
                resolve();
                setShowUploadAgreement(false);
                setUploadingAgreement(false)
                sentSenderIdRequest()
            } catch (e) {
                console.error(e);
                reject();
                setShowUploadAgreement(false);
                setUploadingAgreement(false)
            }
        });

    }, [setShowUploadAgreement, filesUploaded, agreementFileName, setUploadingAgreement, sentSenderIdRequest])



    const closeUploadAgreementModal = useCallback(() => {
        setShowUploadAgreement(false)
        setUploadModalName('');
        removeAgreementFile();
    }, [setShowUploadAgreement, setUploadModalName]);



    const onDropRejected = useCallback(({ type }) => {
        if (type === "large file") {
            notify.show("Selected file is too large. Maximum allowed size is 3MB.", "error");
        }
        else if (type === "invalid file")
            notify.show("Invalid file type. Please select an image or a PDF", "error");
        else {
            notify.show("Invalid file selected.", "error");
        }
    }, []);

    const removeAgreementFile = useCallback(() => {
        setFilesUploaded();
        setAgreementFileName('');
    }, [setFilesUploaded, setAgreementFileName]);


    const showPurchasedNumbers = useCallback(async () => {
        try {

            const result = await ShoutOUTUtilityService.getPurchasedNumbers();
            setPurchasedNumbers(result)
            setIsLoadingPurchasedNumbers(false)

        } catch (e) {
            setIsLoadingPurchasedNumbers(false)
        }
    }, [setPurchasedNumbers, setIsLoadingPurchasedNumbers]);

    const showNumberDelete = useCallback((e) => {
        const id = e.target.dataset['id'];
        setSelectedNumberId(id)
        setShowNumberDeleteConfirm(true)
    }, [setShowNumberDeleteConfirm, setSelectedNumberId])

    const hideNumberDelete = useCallback(() => {
        setShowNumberDeleteConfirm(false)
        setSelectedNumberId('')
    }, [setShowNumberDeleteConfirm, setSelectedNumberId])

    const deletePurchasedNumber = useCallback(async () => {
        setIsSendingDeleteRequest(true)
        ShoutOUTUtilityService.deletePurchasedNumber(selectedNumberId).then(res => {
            notify.show("Number delete successfull", "success");
            showPurchasedNumbers();
            setIsSendingDeleteRequest(false)
            setShowNumberDeleteConfirm(false)
        }, err => {
            notify.show("Number delete failed", "error");
            setIsSendingDeleteRequest(false)
        });

    }, [setIsSendingDeleteRequest, setShowNumberDeleteConfirm, selectedNumberId]);

    const showNumberRequest = useCallback(() => {
        setShowNumberRequestModal(true)
    }, [setShowNumberRequestModal]);

    const closeNumberRequest = useCallback(() => {
        setShowNumberRequestModal(false)
        showPurchasedNumbers();

    }, [setShowNumberRequestModal, showPurchasedNumbers]);

    useEffect(() => {

        showPurchasedNumbers();

    }, [smsSenderIds]);

    const toNumbers = () => {
        history.push('/senders/numbers')
    }

    useEffect(() => {
        UtilService.getIpInfo().then(resp => {
            if (resp) {
                setCountry(resp.country);
            }
        });
    }, [])

    return (
      
        <UserContext.Consumer>
            {({ name, email, mobile, smsCreditsOnDemand, smsCreditsPlan, emailCreditsOnDemand, emailCreditsPlan, organization, emailVerified, mobileVerified }) => (
                <div className="container-fluid px-4 padding-top-container onload-transition">
                    <div className="mt-2">
                        <h4 className="font-weight-bold">Senders</h4>
                        {/* <small className="text-muted"><Link to="/">Dashboard</Link><span className="text-capitalize">{UtilService.setBreadCrumb(location.pathname)}</span></small> */}
                    </div>
                    <Row className="pt-3">
                        <Col className="12">
                       


                            <Nav className="d-flex ml-n3">
                                {
                                    (country === "us" || country === "ca") ?
                                        <>
                                            <Nav.Item>
                                                <Nav.Link as={Link} eventKey="numbers" onClick={toNumbers} to="/senders/numbers" active={location.pathname === '/senders/numbers'}>Phone Numbers</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link as={Link} eventKey="sms" href="/senders" to="/senders" active={location.pathname === '/senders'}>SMS Senders</Nav.Link>
                                            </Nav.Item>

                                        </>
                                        :
                                        <>
                                            <Nav.Item>
                                                <Nav.Link as={Link} eventKey="sms" href="/senders" to="/senders" active={location.pathname === '/senders'}>SMS Senders</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link as={Link} eventKey="numbers" onClick={toNumbers} to="/senders/numbers" active={location.pathname === '/senders/numbers'}>Phone Numbers</Nav.Link>
                                            </Nav.Item>
                                        </>
                                }
                                <Nav.Item>
                                    <Nav.Link as={Link} eventKey="email" href="/senders/email" to="/senders/email" active={location.pathname === '/senders/email'}>Email Senders</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <div className="tabs">
                                <Switch >
                                    <Route name="Numbers" exact path='/senders/numbers'>
                                        <PhoneNumbers mobileVerified={mobileVerified} onBoardingContext={onBoardingContext} showNumberRequest={showNumberRequest} isLoadingPurchasedNumber={isLoadingPurchasedNumbers} purchasedNumbers={purchasedNumbers} showNumberDelete={showNumberDelete} />
                                    </Route>
                                    <Route name="Email Senders" exact path='/senders/email'>
                                        <EmailSenders mobileVerified={mobileVerified} showSenderRequest={showSenderRequest} emailSenderIds={emailSenderIds} onClickDeleteEmailSenderId={onClickDeleteEmailSenderId} />
                                    </Route>
                                    <Route name="SMS Senders">
                                        <SMSSenders smsSenderIds={smsSenderIds} showSenderRequest={showSenderRequest} onClickDownloadAgreement={onClickDownloadAgreement} onClickGenerate={onClickGenerate} downloadingAgreementId={downloadingAgreementId} generatingArtifactId={generatingArtifactId} showUploadAgreementModal={showUploadAgreementModal} onClickDeleteSenderId={onClickDeleteSenderId} mobileVerified={mobileVerified} />
                                    </Route>
                                </Switch>
                            </div>

                            <NumberPurchaseModal show={showNumberRequestModal} onHide={closeNumberRequest} />


                            <SenderRequest showModal={showSenderReqModal} company={organization} userName={name} onCloseCallback={closeSenderRequest} senderIdType={senderIdType} />

                            <SenderRequestSMS showModal={showSenderReqModalSMS} company={organization} userName={name} onCloseCallback={closeSenderRequest} senderIdType={senderIdType} />

                            <Modal id="modal" bsPrefix="modal" show={showUploadAgreement}
                                onHide={closeUploadAgreementModal}>

                                <Modal.Header closeButton>
                                    <h6 className="mb-0 font-weight-bold">{uploadModalName}</h6>
                                </Modal.Header>

                                <ModalBody className="text-left">

                                    {/* {agreementFileName === '' ?
                                        <FileUploader
                                            accept="image/*,application/pdf"
                                            onDropRejected={onDropRejected}

                                            multiple={false}
                                            onDropAccepted={onDrop}
                                        /> :


                                        <div><p>Click upload to continue</p><span><strong> {agreementFileName}&nbsp;</strong><Button variant="link"
                                            onClick={removeAgreementFile}><FaIcon
                                                icon={faTimes}
                                            /></Button>&nbsp;&nbsp;</span>
                                        </div>
                                    } */}
                                    <input onChange={onDrop} type="file" />
                                </ModalBody>
                                <ModalFooter className="text-right">
                                    <Button className="mr-2" variant="dark" size="sm" onClick={closeUploadAgreementModal} disabled={uploadingAgreement}>Close</Button>
                                    <Button variant="primary" size="sm" onClick={onClickUpload} disabled={uploadingAgreement}>{uploadingAgreement ? "Uploading..." : "Upload"}</Button>
                                </ModalFooter>
                            </Modal>

                        </Col>


                        <ConfirmBox show={showSenderIdDeleteConfirm} onHide={hideSenderIdDeleteConfirm} confirmCallback={deleteSenderId}
                            title="Are you sure?" message="You are about to delete your Sender ID. This action isn't reversible. Are you sure?"
                            disableActions={isSendingDeleteRequest} variant="danger" />

                        <ConfirmBox show={showEmailSenderIdDeleteConfirm} onHide={hideEmailSenderIdDeleteConfirm} confirmCallback={deleteEmailSenderId}
                            title="Are you sure?" message="You are about to delete your Email Sender ID. This action isn't reversible. Are you sure?"
                            disableActions={isSendingDeleteRequest} variant="danger" />

                        <ConfirmBox show={showNumberDeleteConfirm} onHide={hideNumberDelete} confirmCallback={deletePurchasedNumber}
                            title="Are you sure?" message="You are about to delete a Phone Number. This action isn't reversible. Are you sure?"
                            disableActions={isSendingDeleteRequest} variant="danger" />
                    </Row>


                </div>
            )

            }

        </UserContext.Consumer>
    );
}
export default Senders