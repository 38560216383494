import React from "react";
import {
    Button,
    Modal
} from '@shoutout-labs/shoutout-themes';

class ConfirmSegmentDeleteDialog extends React.PureComponent {
    render() {
        const { show, onHide, okCallback, segmentName } = this.props;
        return (
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <h6 className="mb-0 font-weight-bold">Confirm Delete</h6>
                </Modal.Header>
                <Modal.Body>
                    <div>You are about to delete the segment "{segmentName}". Are you sure?</div>
                </Modal.Body>

                <Modal.Footer>
                    <Button className="mr-2" variant="dark" size="sm" onClick={onHide}>Cancel</Button>
                    <Button variant="danger" size="sm" onClick={okCallback}>Delete</Button>
                </Modal.Footer>

            </Modal>
        );
    }
};

export default ConfirmSegmentDeleteDialog;