import React from 'react';
import {FaIcon,Card,} from '@shoutout-labs/shoutout-themes';
import {faArrowDown,faArrowUp} from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
const StatBoxWidget = (count, growth, title) => {
    return (
    
<Card>
    <Card.Body className="text-center">
            <h2 data-to="300" data-speed="1500">{count || 0}</h2>
            <p className="count-text ">{title}</p>
            <p className="text-center">{growth < 0 &&
                <small className="text-danger">
                    <FaIcon icon={faArrowDown} />&nbsp;&nbsp;{Math.abs(growth)}%
                        </small>}
                {growth > 0 &&
                    <small className="text-success">
                        <FaIcon icon={faArrowUp} />&nbsp;&nbsp;{growth}%
                        </small>}
                        {!growth &&
                    <small className="text-info">
                      0%
                        </small>}
            </p>
            </Card.Body>
            </Card>
       
    );
};

export default StatBoxWidget;