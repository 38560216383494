import React from 'react';
import { Button } from '@shoutout-labs/shoutout-themes';
import {AnalyticsService} from './../../services/Services';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this._reload = this._reload.bind(this);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.error(error, info);
        AnalyticsService.trackFailedActivity({data:{error,info}});
    }
    _reload(e) {
        e.stopPropagation(); 
        window.location.replace(window.location.origin);
    }

    render() {
        if (this.state.hasError||!this.props.children) {
            // You can render any custom fallback UI
            return (
                <div className="text-center something-went-wrong-margin">
                    <img width="200" src="assets/images/error.gif"/>
                    <h3 className="font-weight-bold">Something went wrong.</h3>
                    <Button size="sm" variant="outline-dark" onClick={this._reload}>Go Back to Dashboard</Button>
                </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;