import React from 'react';
//import Script from 'react-load-script';
import Constants from './../../Constants';
import { Form } from '@shoutout-labs/shoutout-themes';
class CheckoutForm extends React.Component {
    //2CO production url: https://www.2checkout.com/checkout/purchase  SID: 103131430
    //2CO testing : 'https://sandbox.2checkout.com/checkout/purchase SID: 901336941

    constructor() {
        super();
        this.state = { isLoading: false };
        this._onLoadScripts = this._onLoadScripts.bind(this);
    }

    _onLoadScripts() {
        this.setState({ isLoading: false });
    }
    render() {
        const { billingInfo, children, merchantId, planName, amount, quantity, planId, customerName, customerOrganization, recurrencePeriod, billingType } = this.props;
        const { name,
            streetAddress,
            streetAddress2,
            city,
            state,
            zip,
            country,
            email,
            phone } = billingInfo;

        return (
            <div>{/*<Script
                url="https://www.2checkout.com/static/checkout/javascript/direct.min.js"
                onLoad={this._onLoadScripts}
            >

            </Script>*/}
                <Form action='https://www.2checkout.com/checkout/purchase' method='post' inline>

                    <input type='hidden' name='sid' value='103131430' />
                    <input type='hidden' name='mode' value='2CO' />
                    <input type='hidden' name='li_0_type' value='product' />
                    <input type='hidden' name='li_0_product_id' value={planId} />
                    <input type='hidden' name='li_0_name' value={planName} />
                    <input type='hidden' name='li_0_quantity' value={quantity} />
                    <input type='hidden' name='li_0_price' value={amount} />
                    {billingType === 'subscription' &&
                        [<input type='hidden' name='li_0_recurrence' value={recurrencePeriod} key='recurrence' />,
                        <input type='hidden' name='li_0_duration' value='Forever' key='recurrence-period' />
                        ]

                    }
                    <input type='hidden' name='card_holder_name' value={name} />
                    <input type='hidden' name='street_address' value={streetAddress} />
                    <input type='hidden' name='street_address2' value={streetAddress2} />
                    <input type='hidden' name='city' value={city} />
                    <input type='hidden' name='state' value={state} />
                    <input type='hidden' name='zip' value={zip} />
                    <input type='hidden' name='country' value={country} />
                    <input type='hidden' name='email' value={email} />
                    <input type='hidden' name='phone' value={phone} />
                    <input type='hidden' name='owner_id' value={merchantId} />
                    <input type='hidden' name='custom_name' value={customerName} />
                    <input type='hidden' name='custom_organization' value={customerOrganization} />
                    <input type='hidden' name='custom_origin' value='shoutout' />
                    <input type='hidden' name='custom_redirect_url' value='https://web.getshoutout.com/billing/purchase' />
                    <input type='hidden' name='custom_product' value={Constants.BUILD_APP===Constants.APP_LOYALTY?'loyalty':'engage'} />
                    {this.state.isLoading ? <div>Please wait...</div> : children}
                </Form>
            </div>
        );
    }
}

export default CheckoutForm;