/**
 * Created by madura on 2/7/17.
 */
import React, { useState, useContext, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PeopleListContainer, {
    PeopleFilterContainer,
    PeopleSegmentContainer,
    PeopleSegmentCreateContainer,
    PeopleActionsContainer,
    PeopleActionsOnboardingContainer
} from './../../redux/containers/PeopleListContainer';
import { UtilService } from "./../../services/Services";
import UserContext from './../../contexts/UserContext';
import { Row, Col, FaIcon, Modal, Button } from '@shoutout-labs/shoutout-themes';
import { faChevronRight } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import Constants from './../../Constants';
import { setTags } from 'redux/actions/PeopleImportActions';

const PeoplePageContent = withRouter(({ history, contactsAvailable, location }) => {

    const [showSegments, setShowSegments] = useState(true);
    const [showFilters, setShowFilters] = useState(true);
    const [showTagsModal, setShowTagsModal] = useState(false);
    const [tags, setTags] = useState(null);
    const { scopes } = useContext(UserContext);


    const onClickSegments = useCallback(() => {
        setShowSegments(!showSegments)
    }, [setShowSegments, showSegments])
    const onClickFilters = useCallback(() => {
        setShowFilters(!showFilters)
    }, [setShowFilters, showFilters])

    const showTags = useCallback((isShow, tags) => {
        setShowTagsModal(isShow)
        setTags(tags)
    },[setShowTagsModal, setTags])

    const closeShowTags = useCallback(() => {
        setShowTagsModal(false)
        setTags(null);
    },[setShowTagsModal, setTags])

    return (
        <div className="people-wrapper container-fluid px-4 padding-top-container onload-transition">
            <div className="mt-2">
                <h4 className="font-weight-bold">Contacts</h4>
                {/* <small className="text-muted"><Link to="/">Dashboard</Link><span className="text-capitalize">{UtilService.setBreadCrumb(location.pathname)}</span></small> */}
            </div>
            <Row className="h-100 pt-3">
                <Col xs={12}>
                    <div className="mb-3 border-0">

                        <PeopleActionsContainer showSegments={showSegments} onClickSegments={onClickSegments} showFilters={showFilters} onClickFilters={onClickFilters} />

                    </div>
                    <div className="d-flex h-100">
                        <div className={`${showSegments ? "show-segments  mr-3" : "hide-segments"}`}>
                            {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write && <PeopleSegmentCreateContainer />}
                            <PeopleSegmentContainer />
                        </div>
                        <div className="w-100">
                            <div className={`${showFilters ? "show-filters" : "hide-filters"}`} >
                                <PeopleFilterContainer />
                            </div>
                            <PeopleListContainer showTags={showTags} history={history} />
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal id="viewTags" bsPrefix="modal" show={showTagsModal}
                onHide={closeShowTags}>
                <Modal.Header closeButton>
                    <h6 className="mb-0 font-weight-bold">Tags</h6>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div>{tags}</div>
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={closeShowTags} variant="dark" size="sm">Close</Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}
)

export default PeoplePageContent;
