/**
 * Created by madura on 11/23/16.
 */

//const SHOUTOUT_ACCOUNTS_API = 'https://api.getshoutout.com/accountservice/';//'http://localhost:3000/';

const SHOUTOUT_ACCOUNTS_API = process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://api.getshoutout.com/accountservice/":"https://betaapi.getshoutout.com/accountservice/";

class AccountService {

    getServiceToken() {
        let token = localStorage.getItem('token');
        let url = SHOUTOUT_ACCOUNTS_API + 'accounts/tokens';
        return fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            isProtected: true
        }).then(function (response) {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        });
    }

}

export default AccountService;