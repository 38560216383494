import React, { Component } from "react";
import { ShoutOUTService } from "./../../services/Services";
import {
    Row,
    Col,
    Card,
    FaIcon
} from '@shoutout-labs/shoutout-themes';
import {faCheck,faFrown} from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { Link } from 'react-router-dom';
import {LoadingComponent} from './../utils/UtilComponents';

class EmailSenderIdVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: '',
            statusStyle: null,
            reqInProgress: false
        };
    }


    componentDidMount() {
        this.setState({ reqInProgress: true });
        ShoutOUTService.emailSenderIdVerify(this.props.token).then(res => {
            this.setState({ status: 'success', reqInProgress: false });
        }, err => {
            this.setState({ reqInProgress: false, status: 'failed' });
        })
    }

    render() {
        return (
            <div className="container">
                <Row>
                    <Col xs={12} sm={8}  md={6}  lg={6}  className="content-wrapper">
                        <Card>
                            <Card.Body>
                                <div className="text-right"><Link to="/people" activeClassName="active-link">
                                    <small>Go to dashboard</small>
                                </Link>
                                </div><br />
                                <div className="text-center">
                                    {this.state.reqInProgress ? <LoadingComponent/> :
                                        this.state.status === 'success' ? <div><span className="text-success"><FaIcon icon={faCheck} size="2x"/></span><br /><h4>Successfully verified your sender Id</h4></div> :
                                            <div><span className="text-warning"><FaIcon icon={faFrown} size="2x" /></span><br /><h4>Seems something went wrong</h4></div>
                                    }
                                </div>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </div>
        );
    }
}
export default EmailSenderIdVerify;
