import { fetchPost, fetchGet, fetchDelete } from './CommonServiceUtils';
import Constants from './../Constants';

const BASE_URL = `${Constants.BASE_API_URL}hookservice/`;


class ShoutOUTHooksService {
    static createHook(name, pageId, service, pageToken) {
        return fetchPost(`${BASE_URL}integrations`, { name, identifier: pageId, service, serviceMetadata: { pageToken, pageId, pageName:name } })
    }

    static getIntegrations() {
        return fetchGet(`${BASE_URL}integrations`);
    }


    static createLeadAdHook(name, identifier, service, leadFormId, leadFormName, pageId, pageName, userAccessToken, userId) {
        return fetchPost(`${BASE_URL}integrations`, { name, identifier, service, serviceMetadata: { leadFormId, leadFormName, pageId, pageName, userAccessToken, userId } })
    }
    static getLeadIntegrations() {
        return fetchGet(`${BASE_URL}`);
    }


    static getTypeFormAcccessToken(code, redirectUri) {
        return fetchGet(`${BASE_URL}typeform/token?code=${code}&redirect_uri=${redirectUri}`)
    }
    static getTypeAccessIntegrations() {
        return fetchGet(`${BASE_URL}`);
    }
  
    static disconnectFromHook(id) {
        return fetchDelete(`${BASE_URL}integrations/${id}`);
    }
    
    static connectTypeForm(name,identifier,service,accessToken,formId,formName){
        return fetchPost(`${BASE_URL}integrations`, { name, identifier, service, serviceMetadata: {accessToken,formId,formName} })
    }

}

export default ShoutOUTHooksService;