import PEOPLE_ACTIONS, {
    SEARCH,
    REQUEST_PEOPLE,
    RECEIVE_PEOPLE,
    SORT_PEOPLE,
    CLICK_SELECT_ALL,
    SELECT_PEOPLE,
    SHOW_CONFIRM_DELETE,
    SELECT_FILTER,
    SELECT_FILTER_TYPE,
    CHANGE_FILTER_SEARCH_KEY,
    SHOW_ADD_OR_EDIT_PERSON,
    SELECT_SEGMENT,
    APPLY_FILTERS,
    CREATE_SEGMENT_NAME,
    SEGMENT_CREATED,
    SHOW_ADD_OR_EDIT_PERSON_LOADER,
    SEGMENT_CREATE_ERROR
} from './../actions/PeopleActions';

import {
  RECEIVE_COLUMN_NAME_MAP
} from './../actions/CommonActions';
import { sortBy, omit } from 'lodash';
import update from 'immutability-helper';
import Constants from './../../Constants';
const initialState = {
    searchText: '',
    peopleList: [],//TODO: convert this to an object {id:{details}} structure for easy operation
    peopleCount: 0,
    totalPeopleCount: 0,
    sortBy: 'last_seen_on',
    sortDirection: 'DESC',
    selectAll: false,
    selectAllStatus: false,// this is only changed if user click on select all, deselect all
    selectedPeople: {},
    isDisableSelectAll: false,
    selectedPeopleCount: 0,
    actions: {
        delete: {
            showConfirmDeleteDialog: false,
            showDeleteLoader: false
        },
        add: {
            showCreatePersonDialog: false,
            selectedPerson: {}
        },
        createSegment: {
            segmentName: '',
            isCreating: false
        }
    },
    selectedSegment: Constants.ALL_SEGMENT,
    isFilterSelected: false,
    customColumnNameMap: {},
    selectedFilters: [],
    isLoadingPeople: false,
    showAddEditPersonLoader: false,
    showPeopleLoader: false,
    isAddMoreTags: false,
    selectedPeopleDetails : []
};


const REDUCER_ACTIONS = {
    _SHOW_DELETE_LOADER: '_SHOW_DELETE_LOADER',
    _SET_SELECTED_PEOPLE: '_SET_SELECTED_PEOPLE',
    _SET_PEOPLE_COUNT: '_SET_PEOPLE_COUNT',
    _SET_TOTAL_PEOPLE_COUNT: '_SET_TOTAL_PEOPLE_COUNT',
    _SET_SELECTED_PEOPLE_COUNT: '_SET_SELECTED_PEOPLE_COUNT',
    //_SET_COLUMN_NAME_MAP: '_SET_COLUMN_NAME_MAP',
    _SET_CUSTOM_COLUMN_NAME_MAP: '_SET_CUSTOM_COLUMN_NAME_MAP',
    _SHOW_PEOPLE_LOADER: '_SHOW_PEOPLE_LOADER',
    _SET_SELECT_ALL: '_SET_SELECT_ALL',
    _SET_SELECT_ALL_STATUS: '_SET_SELECT_ALL_STATUS',
    _SET_SELECTED_FILTERS: '_SET_SELECTED_FILTERS'
};

const definedColumnNames = ['name', 'email', 'mobile_number', '_last_seen_on', '_created_on', '_modified_on', 'tags', 'user_id', 'total_points'];

const peopleReducer = (state = initialState, action) => {

    switch (action.type) {

        case SEARCH: {
            return state;
        }

        case REQUEST_PEOPLE: {
            if (action.showLoader) {
                state = peopleReducer(state, { type: REDUCER_ACTIONS._SHOW_PEOPLE_LOADER, isShow: action.showLoader });
            }
            return update(state, {
                isLoadingPeople: { $set: true }
            });
        }

        case RECEIVE_PEOPLE: {
            if (state.showPeopleLoader) {
                state = peopleReducer(state, { type: REDUCER_ACTIONS._SHOW_PEOPLE_LOADER, isShow: false });
            }
            if (action.isSearch || action.isLoadMore) {
                let newPeopleList = action.peopleList;
                let selectedPeople = state.selectedPeople;
                if (state.selectAllStatus) {
                    newPeopleList.forEach(person => {
                        if (selectedPeople[person.id] === false) {//person may be deselect
                            person._selected = false;
                        } else {
                            selectedPeople[person.id] = true;
                            person._selected = true;
                        }
                    });
                } else {
                    newPeopleList.forEach(person => {
                        person._selected = selectedPeople[person.id];
                    });
                }

                if (action.isSearch) {
                    if (!state.searchText) {
                        state = peopleReducer(state, { type: REDUCER_ACTIONS._SET_TOTAL_PEOPLE_COUNT, totalPeopleCount: action.peopleCount });
                    }
                    state = peopleReducer(state, { type: REDUCER_ACTIONS._SET_PEOPLE_COUNT, peopleCount: action.peopleCount });
                    return update(state, {
                        peopleList: { $set: newPeopleList },
                        isLoadingPeople: { $set: false },
                        isDisableSelectAll: { $set: !!state.searchText }
                        //selectAll: {$set: false}
                    });
                }
                else {
                    return update(state, {
                        peopleList: { $push: newPeopleList },
                        peopleCount: { $set: action.peopleCount },
                        isLoadingPeople: { $set: false }
                    });
                }
            }

            else {
                state = peopleReducer(state, { type: REDUCER_ACTIONS._SET_TOTAL_PEOPLE_COUNT, totalPeopleCount: action.peopleCount });
                return update(state, {
                    peopleList: { $set: action.peopleList },
                    peopleCount: { $set: action.peopleCount },
                    isLoadingPeople: { $set: false },
                    selectAll: { $set: false },
                    selectedPeople: { $set: {} },
                    selectedPeopleCount: { $set: 0 },
                    isDisableSelectAll: { $set: false }
                });
            }
        }

        case SORT_PEOPLE: {
            let peopleList = state.peopleList;
            peopleList = sortBy(peopleList, action.sortBy);
            if (action.sortDirection === 'DESC') {
                peopleList = peopleList.reverse();
            }
            return Object.assign({}, state, {
                peopleList: peopleList,
                sortBy: action.sortBy,
                sortDirection: action.sortDirection
            });
        }

        case CLICK_SELECT_ALL: {
            let selectedPeople = {}, selectedCount = 0;
            if (action.isSelectAll) {
                state.peopleList.forEach(person => {
                    selectedPeople[person.id] = true;
                });
                selectedCount = state.peopleCount;
            }
            let peopleList = state.peopleList;
            peopleList.forEach((person => {
                person._selected = action.isSelectAll;
            }));
            return update(state, {
                selectAll: { $set: action.isSelectAll },
                selectedPeopleCount: { $set: selectedCount },
                selectedPeople: { $set: selectedPeople },
                peopleList: { $set: peopleList },
                selectAllStatus: { $set: action.isSelectAll }
            });
        }

        case SELECT_PEOPLE: {
            let selectedPeople = state.selectedPeople;
            let selectedPeopleCount = state.selectedPeopleCount;
            if (action.isSelect) {
                selectedPeople[action.personData.id] = action.personData;
                selectedPeopleCount++;
            } else {
                selectedPeopleCount--;
                delete selectedPeople[action.personData.id];
                state = peopleReducer(state, { type: REDUCER_ACTIONS._SET_SELECT_ALL, isSelectAll: false })
            }
            return update(state, {
                selectedPeople: { $set: selectedPeople },
                selectedPeopleCount: { $set: selectedPeopleCount },
                selectedPeopleDetails : { $set : Object.values(selectedPeople)},
                peopleList: { [action.rowIndex]: { _selected: { $set: action.isSelect } } }
            });
        }

        case SHOW_CONFIRM_DELETE: {
            return update(state, {
                actions: {
                    delete: { showConfirmDeleteDialog: { $set: action.showConfirmDelete } }
                }
            });
        }
        case RECEIVE_COLUMN_NAME_MAP: {
            // let newState = peopleReducer(state, {
            //     type: REDUCER_ACTIONS._SET_COLUMN_NAME_MAP,
            //     columnNameMap: action.columnNameMap
            // });
            return peopleReducer(state, {
                type: REDUCER_ACTIONS._SET_CUSTOM_COLUMN_NAME_MAP,
                customColumnNameMap: omit(action.columnNameMap, definedColumnNames)
            });
        }

        case SELECT_FILTER: {
            return update(state, {
                columnNameMap: {
                    [action.filterKey]: {
                        _selected: { $set: action.isSelect },
                        _selectedFilter: { $set: action.selectedFilter }
                    }
                }
            });
        }

        case SELECT_FILTER_TYPE: {
            let selectedFilter = action.selectedFilter;
            selectedFilter._searchKey = state.columnNameMap[action.filterKey]._selectedFilter._searchKey;
            return update(state, {
                columnNameMap: {
                    [action.filterKey]: {
                        _selectedFilter: { $set: selectedFilter }
                    }
                }
            });
        }

        case CHANGE_FILTER_SEARCH_KEY: {
            return update(state, {
                columnNameMap: {
                    [action.filterKey]: {
                        _selectedFilter: {
                            _searchKey: {
                                $set: action.filterSearchKey
                            }
                        }
                    }
                }
            });
        }
        case APPLY_FILTERS: {
            state = peopleReducer(state, { type: PEOPLE_ACTIONS.CHANGE_SEARCH_TEXT, searchText: '' });
            return update(state, {
                isFilterSelected: {
                    $set: action.isFilterSelected
                },
                selectAll: { $set: false },
                selectedPeopleCount: { $set: 0 },
                selectedPeople: { $set: {} },
                selectedFilters: { $set: action.selectedFilters }
            });
        }


        case SELECT_SEGMENT: {
            //     let newState = peopleReducer(state, {
            //         type: REDUCER_ACTIONS._SET_COLUMN_NAME_MAP,
            //         columnNameMap: action.columnNameMap
            //     });
            //     //newState = peopleReducer(newState, { type: PEOPLE_ACTIONS.CHANGE_SEARCH_TEXT, searchText: '' });
            //    // newState = peopleReducer(newState, { type: REDUCER_ACTIONS._SET_SELECT_ALL_STATUS, selectAllStatus: false });
            //     if (action.segmentId !== Constants.ALL_SEGMENT.id) {
            //         newState = peopleReducer(newState, { type: REDUCER_ACTIONS._SET_SELECTED_FILTERS, selectedFilters: action.selectedSegment.data })
            //     } else {
            //         newState = peopleReducer(newState, { type: REDUCER_ACTIONS._SET_SELECTED_FILTERS, selectedFilters: [] })
            //     }
            return update(state, {
                searchText: { $set: '' },
                selectAllStatus: { $set: false },
                selectedSegment: {
                    segment: {
                        $set: action.selectedSegment
                    },
                    id: { $set: action.segmentId }
                },
                selectAll: { $set: false },
                selectedPeopleCount: { $set: 0 },
                selectedPeople: { $set: {} },
                isFilterSelected: { $set: false },
                selectedFilters: { $set: action.segmentId !== Constants.ALL_SEGMENT.id ? action.selectedSegment.data : [] }
            });
        }

        case CREATE_SEGMENT_NAME: {
            return update(state, {
                actions: {
                    createSegment: { segmentName: { $set: action.segmentName } },
                }
            });
        }

        case SHOW_ADD_OR_EDIT_PERSON: {
            return update(state, {
                actions: {
                    add: {
                        showCreatePersonDialog: {
                            $set: action.isShow
                        },
                        selectedPerson: { $set: action.person }
                    }
                }
            });
        }

        case SHOW_ADD_OR_EDIT_PERSON_LOADER: {
            return update(state, {
                showAddEditPersonLoader: { $set: action.isShow }
            });
        }

        case PEOPLE_ACTIONS.SEGMENT_CREATING: {
            return update(state, {
                actions: {
                    createSegment: {
                        isCreating: { $set: true }
                    },
                }
            });
        }
        case SEGMENT_CREATED: {
            return update(state, {
                actions: {
                    createSegment: {
                        segmentName: { $set: '' },
                        isCreating: { $set: false }
                    },
                }
            });
        }
        case SEGMENT_CREATE_ERROR: {
            return update(state, {
                actions: {
                    createSegment: {
                        isCreating: { $set: false }
                    },
                }
            });
        }

        case PEOPLE_ACTIONS.DELETING: {
            return peopleReducer(state, { type: REDUCER_ACTIONS._SHOW_DELETE_LOADER, isShow: true });
        }

        case PEOPLE_ACTIONS.DELETED: {
            let newState = peopleReducer(state, { type: REDUCER_ACTIONS._SHOW_DELETE_LOADER, isShow: false });
            newState = peopleReducer(newState, { type: REDUCER_ACTIONS._SET_SELECTED_PEOPLE, selectedPeople: {} });
            newState = peopleReducer(newState, {
                type: REDUCER_ACTIONS._SET_SELECTED_PEOPLE_COUNT,
                selectedPeopleCount: 0
            });
            newState = peopleReducer(newState, { type: REDUCER_ACTIONS._SET_SELECT_ALL_STATUS, selectAllStatus: false });
            return peopleReducer(newState, { type: SHOW_CONFIRM_DELETE, showConfirmDelete: false });
        }

        case PEOPLE_ACTIONS.DELETE_ERROR: {
            return peopleReducer(state, { type: REDUCER_ACTIONS._SHOW_DELETE_LOADER, isShow: false });
        }

        case PEOPLE_ACTIONS.CHANGE_SEARCH_TEXT: {
            return update(state, { searchText: { $set: action.searchText } });
        }

        case PEOPLE_ACTIONS.SHOW_ADD_TAG_DIALOG: {
            return update(state, { isAddMoreTags: { $set: action.isShow } });
        }

        case PEOPLE_ACTIONS.CLEAR_FILTERS: {
            return update(state, { selectedFilters: { $set: initialState.selectedFilters } });
        }
        case REDUCER_ACTIONS._SHOW_DELETE_LOADER: {
            return update(state, {
                actions: {
                    delete: { showDeleteLoader: { $set: action.isShow } }
                }
            });
        }

        case REDUCER_ACTIONS._SET_SELECTED_PEOPLE: {
            return update(state, { selectedPeople: { $set: action.selectedPeople } });
        }

        case REDUCER_ACTIONS._SET_SELECTED_PEOPLE_COUNT: {
            return update(state, { selectedPeopleCount: { $set: action.selectedPeopleCount } });
        }

        // case REDUCER_ACTIONS._SET_COLUMN_NAME_MAP: {
        //     return update(state, { columnNameMap: { $set: action.columnNameMap } });
        // }

        case REDUCER_ACTIONS._SET_CUSTOM_COLUMN_NAME_MAP: {
            return update(state, { customColumnNameMap: { $set: action.customColumnNameMap } });
        }
        case REDUCER_ACTIONS._SHOW_PEOPLE_LOADER: {
            return update(state, { showPeopleLoader: { $set: action.isShow } })
        }
        case REDUCER_ACTIONS._SET_SELECT_ALL: {
            return update(state, { selectAll: { $set: action.isSelectAll } })
        }
        case REDUCER_ACTIONS._SET_SELECT_ALL_STATUS: {
            return update(state, { selectAllStatus: { $set: action.selectAllStatus } })
        }
        case REDUCER_ACTIONS._SET_PEOPLE_COUNT: {
            return update(state, { peopleCount: { $set: action.peopleCount } })
        }
        case REDUCER_ACTIONS._SET_TOTAL_PEOPLE_COUNT: {
            return update(state, { totalPeopleCount: { $set: action.totalPeopleCount } })
        }
        case REDUCER_ACTIONS._SET_SELECTED_FILTERS: {
            return update(state, { selectedFilters: { $set: action.selectedFilters } })
        }
        default:
            return state;
    }
};


export default peopleReducer;
