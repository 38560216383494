import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Card, Button, FaIcon } from '@shoutout-labs/shoutout-themes';
import { faCheckCircle, faHourglass } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';


const BillingSuccess = () => {
    const location = useLocation();
    const plan = location.state.plan;
    const paymentMethod = location.state.paymentMethod;

    return (
        <div className="container mt-5">
            <Row className="text-center justify-content-center">
                <Col lg={8}>
                    <Card className="border-0 my-3">
                        <Card.Body>
                            {paymentMethod === "cardPayment" ? <> <h6 className="text-success font-weight-bold"><FaIcon className="mr-2" icon={faCheckCircle} /> Payment Successful!</h6></> :
                                <> <h6 className="text-warning font-weight-bold"><FaIcon className="mr-2" icon={faHourglass} /> Order Placed</h6></>}
                            {paymentMethod === "cardPayment" ? <p> Your payment has been received.</p> : <p>Your order has been placed</p>}
                            <div className="text-center my-4 py-2 bg-muted my-4 py-2">
                                <small>Payment Details</small>
                                <br />
                                <small>Plan Name: <strong>{plan.name}</strong></small>
                                {plan.metaData.type === "ondemand" && <><br />
                                    <small>Quantity: <strong>{plan.quantity}</strong></small> </>}
                                <br />
                                <small>Total: <strong>{plan.currency} {plan.quantity === undefined ? plan.currency_amount : (plan.currency_amount * plan.quantity)}</strong></small>
                                <br />
                            </div>
                            {paymentMethod === "cardPayment" ?
                                <p>The invoice will be sent to your registered email shortly.</p> :
                                <>
                                    <p>Please make the payment and reply back to <br />the email you received with the payment slip</p>
                                    <hr />
                                    <p>Bank Details</p>
                                    <div className="d-flex">
                                        <div className="card border-0 p-4 text-left mr-3 w-50">
                                            <small>For Sri Lanka
                                        <br />Bank Name: Sampath Bank PLC
                                        <br />Account Name: ShoutOut Labs Pvt Ltd
                                        <br />Account No: 0218 1000 2058
                                        <br />Branch: Borella Super Branch, Sri Lanka
                                        <br />SWIFT code: BSAM LKLX</small>
                                        </div>
                                        <div className="card border-0 p-4 text-left w-50">
                                            <small>For US
                                                <br /> Account No: 9800125435
                                                <br />Bank: Evolve Bank & Trust, 6070 Poplar Ave, Suite 200, Memphis, TN 38119
                                            </small>
                                        </div>
                                    </div>
                                </>
                            }
                            <br />
                            <p>Thank you!</p>
                            <hr className="my-0 dashed" />
                            <Link to="/billing" className="btn btn-dark btn-sm mt-2">Go to billing</Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}
export default BillingSuccess;