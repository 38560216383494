import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Card, Badge, OverlayTrigger, Tooltip, Button, FaIcon, Modal } from '@shoutout-labs/shoutout-themes';
import { faCreditCard, faUniversity, faDownload, faUpload } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import LoadingComponent from './../utils/LoadingComponent';
import { Column, Table, AutoSizer, SortDirection } from 'react-virtualized';
import { ImageUploader } from './../utils/UtilComponents'
// import { DateRangeSelector } from './../utils/UtilComponents';
import { sortBy as _sortBy, reverse } from 'lodash';
import moment from 'moment';
import { BillingService } from './../../services/Services';
const BillingInvoices = () => {

    const [sortDirection, setSortDirection] = useState(SortDirection.DESC);
    const [sortBy, setSortBy] = useState("createdon");
    const [invoiceList, setInvoiceList] = useState([]);
    const [showUpModal, setShowUpModal] = useState(false);
    const [uploadedReceipt, setUploadedReceipt] = useState("");
    const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {

        if (!showUpModal) {
            BillingService.getInvoices().then(res => {
                setInvoiceList(res)
                setIsLoading(false)
            });
        }

    }, [showUpModal])


    const sort = useCallback(({ sortBy, sortDirection }) => {
        setSortDirection(sortDirection)
        setSortBy(sortBy)
    }, [setSortDirection, setSortBy])

    const handleUploadReceipt = (e) => {
        // this.setState({ uploadedReceipt: e.target.files[0] });
        setUploadedReceipt(e.target.files[0])
    }

    const toggleUploadModal = useCallback((e) => {
        setShowUpModal(!showUpModal)
        setSelectedInvoiceId(e.currentTarget.id)
        // this.setState({ showUploadModal: !this.state.showUploadModal, selectedInvoiceId: e.currentTarget.id })
    }, [setShowUpModal, setSelectedInvoiceId])

    const onClickDownloadPdf = useCallback((invoice) => {

        BillingService.getInvoice(invoice._id).then(res => {
            const fileName = 'INVOICE-' + invoice.invoiceId + '.pdf';
            const url = 'data:application/octet-stream;base64,' + res;
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        });
    }, [])

    const uploadReceipt = useCallback(async () => {
        const formData = new FormData();
        const file = uploadedReceipt
        formData.append('file', file, file.name);
        const id = selectedInvoiceId;

        await BillingService.uploadPaymentSlip({ formData, id })
        setShowUpModal(!showUpModal)


    }, [])

    const sortedList = _sortBy(invoiceList, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
        reverse(sortedList);
    }
    return (
        <>
            <Row>
                {/* <Col lg={12} sm={12}> */}
                {/* <DateRangeSelector selectedDateRange={this.selectedDateRange} onSubmit={this._onClickLoad} defaultSelection="Last 7 Days" selectBtnClassNames="btn-sm ml-n2" /> */}
                {/* <div className="d-flex">
                        <div className="mr-3">
                            <small><span className="text-success font-weight-bold">Paid</span> - Payment Received</small>
                        </div>
                        <div className="mr-3">
                            <small><span className="text-danger font-weight-bold">Pending</span> - To be paid</small>
                        </div>
                        <div className="mr-3">
                            <small><span className="text-warning font-weight-bold">Verifying</span> - Bank payment need to be verified</small>
                        </div>
                    </div>
                </Col> */}
                <Col lg={12} sm={12}>
                    <Card className="border-0 mt-3">
                        <Card.Body>
                            {isLoading ? < LoadingComponent /> :
                                <div>
                                    {invoiceList.length !== 0 ?
                                        <AutoSizer disableHeight>
                                            {({ width }) => (
                                                <Table
                                                    ref='InvoiceTable'
                                                    disableHeader={false}
                                                    headerHeight={40}
                                                    height={400}
                                                    rowHeight={40}
                                                    rowGetter={({ index }) => sortedList[index]}
                                                    rowCount={invoiceList.length || 0}
                                                    sortBy={sortBy}
                                                    sortDirection={sortDirection}
                                                    width={width}
                                                    sort={sort}
                                                // headerClassName="text-small"
                                                // gridClassName="text-small"
                                                >
                                                    <Column
                                                        label="Invoice ID"
                                                        dataKey='invoiceId'
                                                        width={90}
                                                        flexGrow={1}
                                                    />
                                                    <Column
                                                        label="Created On"
                                                        dataKey='createdOn'
                                                        width={110}
                                                        flexGrow={1}
                                                        cellRenderer={({ cellData }) => {
                                                            return moment(cellData).format('ll');
                                                        }}
                                                    />
                                                    <Column
                                                        label="Package"
                                                        dataKey='packageInfo'
                                                        width={120}
                                                        flexGrow={1}
                                                        cellRenderer={({ cellData }) => {
                                                            return cellData.planName;
                                                        }}

                                                    />
                                                    <Column
                                                        label="Type"
                                                        dataKey='paymentType'
                                                        width={120}
                                                        flexGrow={1}
                                                        cellRenderer={({ cellData }) => {
                                                            return <span>{cellData === "ondemand" ? "On Demand" : "Subscription"}</span>;
                                                        }}

                                                    />
                                                    <Column
                                                        label="Quantity"
                                                        dataKey='packageInfo'
                                                        width={70}
                                                        flexGrow={1}
                                                        cellRenderer={({ cellData }) => {
                                                            return cellData.planQuantity;
                                                        }}

                                                    />
                                                    <Column
                                                        label="Method"
                                                        dataKey='paymentMethod'
                                                        width={90}
                                                        flexGrow={1}
                                                        cellRenderer={({ cellData }) => {
                                                            return <span><FaIcon className="mr-1" icon={cellData === "online" ? faCreditCard : faUniversity} />{cellData}</span>;
                                                        }}
                                                    />
                                                    <Column
                                                        label="Amount"
                                                        dataKey='amount'
                                                        width={70}
                                                        flexGrow={1}
                                                        cellRenderer={({ cellData, rowData }) => {
                                                            return <span>{rowData.currency && rowData.currency.toUpperCase() || "USD"} {cellData}</span>;
                                                        }}
                                                    />
                                                    <Column
                                                        label="Status"
                                                        dataKey='paymentStatus'
                                                        width={55}
                                                        flexGrow={1}
                                                        cellRenderer={({ cellData }) => {
                                                            console.log("status", cellData)
                                                            return <div className="text-center">
                                                                <small><Badge className={`p-2 w-100 font-weight-bold text-${cellData === "success" ? "success" : cellData === "pending" ? "warning" : cellData === "failed" ? "secondary" : cellData === "canceled" ? "danger" : "primary"}`}>{cellData === "success" ? "Success" : cellData === "pending" ? "Pending" : cellData === "failed" ? "Failed" : cellData === "canceled" ? "Cancelled" : "Verifying"}</Badge></small>
                                                            </div>;
                                                        }}
                                                    />
                                                    <Column
                                                        label=""
                                                        dataKey='paymentStatus'
                                                        width={150}
                                                        flexGrow={1}
                                                        cellRenderer={({ rowData }) => {
                                                            return (<><OverlayTrigger overlay={<Tooltip id={rowData.invoiceId}>Download a pdf copy of the invoice</Tooltip>}>
                                                                <Button className="invoice-action-buttons" variant="primary" size="sm" onClick={() => onClickDownloadPdf(rowData)}><small><FaIcon icon={faDownload} /></small> </Button>
                                                            </OverlayTrigger>
                                                                {rowData.paymentStatus === "pending" && <Button className="ml-2 invoice-action-buttons" variant="success" size="sm" id={rowData._id} onClick={toggleUploadModal}><small><FaIcon className="text-white" icon={faUpload} /></small> </Button>}
                                                                {/* <Button className="ml-2 invoice-action-buttons" variant="danger" size="sm"><small><FaIcon icon={faTimes} /></small> </Button> */}
                                                            </>);
                                                        }}
                                                    />

                                                </Table>
                                            )}
                                        </AutoSizer>
                                        :
                                        <p className="p-4">No Invoices</p>}
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            <Modal show={showUpModal} onHide={toggleUploadModal}>
                <Modal.Header closeButton>
                    <h6 className="mb-0 font-weight-bold">Upload Invoice Here</h6>
                </Modal.Header>
                <Modal.Body>
                    <label>Upload the Receipt here</label>
                    <input onChange={handleUploadReceipt} type="file" />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="mr-2" variant="dark" size="sm" onClick={toggleUploadModal}>Cancel</Button>
                    <Button variant="primary" size="sm" onClick={uploadReceipt}>Upload</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default BillingInvoices;