import { fetchGet, fetchPost, fetchPut, fetchDelete } from './CommonServiceUtils';
import Constants from './../Constants';
const BASE_URL = `${Constants.BASE_API_URL}loyaltyservice2/`;

class ShoutOUTLoyaltyService {

    static getLoyaltyConfig() {
        return fetchGet(BASE_URL + 'configs');

        /*return new Promise((resolve, reject) => {
            resolve(
                {
                    rewards: {
                        portalName: "Nexus",
                        portalUrl: "https://klip.getshoutout.com"
                    },
                    points: {
                        rate: 100,
                        startUp: 10,
                        referral: 20
                    },
                    tiers: {
                        data: {
                            '123': {
                                name: "Silver",
                                points: 0,
                                imageUrl: "http://www.iconhot.com/icon/png/business-clean/256/trophy-1.png",
                                benefits: ["1% discount"]
                            },
                            '124': {
                                name: "Gold",
                                points: 1500,
                                imageUrl: "http://icons.iconarchive.com/icons/custom-icon-design/pretty-office-7/128/Trophy-gold-icon.png",
                                benefits: ["5% discount", "VIP parking"]
                            },
                            '125': {
                                name: "Platinum",
                                points: 10000,
                                imageUrl: "http://cdn.wolfire.com/blog/trophies/strongtrophy.png",
                                benefits: ["10% discount", "VIP parking", "Priority queue"]
                            }
                        },
                        config: {
                            immediateTierUpdate: true,
                            immediateTierDowngrade: false
                        }
                    }
                }
            )
        })*/
    }
    static updateConfig({ _id, ...rest }) {
        return fetchPut(`${BASE_URL}configs/${_id}`, { ...rest });
    }

    static createReward(rewardObj) {
        return fetchPost(BASE_URL + "rewards", rewardObj);
    }
    static updateReward({ _id, name, points, imageUrls, metadata = {}, userRedeemable, status }) {
        const { countPerRedemption, couponResult, totalCount, usedCount, ...rest } = metadata;
        return fetchPut(BASE_URL + "rewards", { _id, name, points, imageUrls, metadata: { ...rest }, userRedeemable, status });
    }

    static addCodes(_id, codes = [], codesCount = 0) {
        const metadata = {};
        if (codes.length > 0) {
            metadata.codes = codes;
        }
        if (codesCount > 0) {
            metadata.codesCount = codesCount;
        }
        return fetchPut(BASE_URL + "rewards", { _id, metadata });

    }
    static getRewards() {
        return fetchGet(BASE_URL + "rewards");
    }
    static getTransactions(fromDate = '', toDate = '') {
        return fetchGet(BASE_URL + "transactions?fromDate=" + fromDate + "&toDate=" + toDate);
    }
    static deleteReward(rewardId) {
        return fetchDelete(BASE_URL + "rewards/" + rewardId);
    }
    static getSalesStat({ fromDate, toDate, compareFromDate, compareToDate }, type = "series") {
        return fetchGet(BASE_URL + 'salestats?fromDate=' + fromDate + '&toDate=' + toDate + '&select=' + type + '&compareFromDate=' + compareFromDate + '&compareToDate=' + compareToDate);
    }

    static getRewardRedemptionLog(fromDate, toDate) {
        return fetchGet(`${BASE_URL}redemptionlogs?fromDate=${fromDate}&toDate=${toDate}`);

    }

    static getRewardRedemptionLogSummary(fromDate, toDate) {
        return fetchGet(`${BASE_URL}redemptionlogs/summary`);

    }

    static getRedeemAuthorize(status) {
        return fetchGet(`${BASE_URL}redeemauthorize${status ? '?status=' + status : ''}`);
    }
    static rejectRedeemReward(id) {
        return fetchPost(`${BASE_URL}redeemauthorize`, {
            "_id": id,
            "status": "REJECTED"
        }
        )
    }
    static approveRedeemReward(id) {
        return fetchPost(`${BASE_URL}redeemauthorize`, {
            "_id": id,
            "status": "APPROVED"
        }
        )
    }

    static getAnnouncements = () => {
        return fetchGet(`${BASE_URL}announcements`);
    }

    static updateAnnouncement = ({ _id, title, description, imageUrl, validFrom, validTo, customerSegments, actionLink, status }) => {
        return fetchPut(`${BASE_URL}announcements/${_id}`, { title, description, imageUrl, validFrom, validTo, customerSegments, actionLink, status });
    }
    static addAnnouncement = ({ title, description, imageUrl, validFrom, validTo, customerSegments, actionLink, status }) => {
        return fetchPost(`${BASE_URL}announcements`, { title, description, imageUrl, validFrom, validTo, customerSegments, actionLink, status });

    }
    static deleteAnnouncement = (id) => {
        return fetchDelete(`${BASE_URL}announcements/${id}`);

    }
}

export default ShoutOUTLoyaltyService;