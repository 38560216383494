import { fetchGet, fetchPut, fetchGetText, fetchPost, fetchPostMultipart } from './CommonServiceUtils';
// const SHOUTOUT_ACCOUNT_SERVICE = 'https://api.getshoutout.com/accountservice/';
import Constants from './../Constants';
const BILLING_SERVICE = `${Constants.BASE_API3_URL}paymentservice/`; // "https://d21uxcjmtd.execute-api.us-east-1.amazonaws.com/api/paymentservice/";
class BillingService {
    // static getInvoices() {
    //     return fetchGet(SHOUTOUT_ACCOUNT_SERVICE + 'invoices');

    // }

    static getInvoice(id) {
        return fetchGetText(BILLING_SERVICE + 'invoices/download/' + id, false);
    }

    // static getBillingInfo() {
    //     return fetchGet(SHOUTOUT_ACCOUNT_SERVICE + 'accounts/users?query=billing');
    // }

    // static updateBillingInfo(billingInfo) {

    //     return fetchPut(SHOUTOUT_ACCOUNT_SERVICE + 'accounts/users', { billingInfo }, { 'name': 'update-billing-info', 'category': 'billing' });

    // }

    // static placeBillingOrder({ planName, planQuantity, planId, billingInfo, currencyCode }) {
    //     return fetchPost(SHOUTOUT_ACCOUNT_SERVICE + 'paymentprocess', { planName, planQuantity, planId, billingInfo, currencyCode });
    // }

    static getPlans() {

        return fetchGet(BILLING_SERVICE + 'packages?version=v4');
    }

    static getSMSPricing() {
        return fetchGet(BILLING_SERVICE + 'globalprices');
        // return new Promise((resolve) => {
        //     resolve(
        //         [
        //             { code: "LK", name: "Sri Lanka", prefix: "94", credits: "1" },
        //             { code: "CA", name: "Canada", prefix: "1", credits: "1" },
        //             { code: "US", name: "United States", prefix: "1", credits: "1" },
        //             { code: "IN", name: "India", prefix: "91", credits: "1" },
        //             { code: "BZ", name: "Belize", prefix: "501", credits: "2" },
        //             { code: "FO", name: "Faroe Islands", prefix: "298", credits: "3" },
        //             { code: "BN", name: "Brunei", prefix: "673", credits: "3" },
        //             { code: "PY", name: "Paraguay", prefix: "595", credits: "3" },
        //             { code: "CF", name: "Central African Republic", prefix: "236", credits: "3" },
        //             { code: "MV", name: "Maldives", prefix: "960", credits: "3" },
        //             { code: "GM", name: "Gambia", prefix: "220", credits: "3" },
        //             { code: "BH", name: "Bahrain", prefix: "973", credits: "4" },
        //             { code: "VI", name: "Virgin Islands, US", prefix: "1340", credits: "4" },
        //             { code: "IL", name: "Israel", prefix: "972", credits: "4" },
        //             { code: "CK", name: "Cook Islands", prefix: "682", credits: "4" },
        //             { code: "ZA", name: "South Africa", prefix: "27", credits: "4" },
        //             { code: "DK", name: "Denmark", prefix: "45", credits: "4" },
        //             { code: "GQ", name: "Equatorial Guinea", prefix: "240", credits: "4" },
        //             { code: "TH", name: "Thailand", prefix: "66", credits: "5" },
        //             { code: "TR", name: "Turkey", prefix: "90", credits: "5" },
        //             { code: "GL", name: "Greenland", prefix: "299", credits: "5" },
        //             { code: "MT", name: "Malta", prefix: "356", credits: "5" },
        //             { code: "LI", name: "Liechtenstein", prefix: "423", credits: "5" },
        //             { code: "GI", name: "Gibraltar", prefix: "350", credits: "5" },
        //             { code: "KM", name: "Comoros", prefix: "269", credits: "5" },
        //             { code: "AE", name: "United Arab Emirates", prefix: "971", credits: "5" },
        //             { code: "ZW", name: "Zimbabwe", prefix: "263", credits: "5" },
        //             { code: "MO", name: "Macau", prefix: "853", credits: "5" },
        //             { code: "SC", name: "Seychelles", prefix: "248", credits: "5" },
        //             { code: "MU", name: "Mauritius", prefix: "230", credits: "6" },
        //             { code: "ME", name: "Montenegro", prefix: "382", credits: "6" },
        //             { code: "SG", name: "Singapore", prefix: "65", credits: "6" },
        //             { code: "CN", name: "China", prefix: "86", credits: "6" },
        //             { code: "SL", name: "Sierra Leone", prefix: "232", credits: "6" },
        //             { code: "SR", name: "Suriname", prefix: "597", credits: "6" },
        //             { code: "CG", name: "Republic Of The Congo", prefix: "242", credits: "6" },
        //             { code: "MZ", name: "Mozambique", prefix: "258", credits: "6" },
        //             { code: "BF", name: "Burkina Faso", prefix: "226", credits: "6" },
        //             { code: "BB", name: "Barbados", prefix: "1246", credits: "7" },
        //             { code: "BM", name: "Bermuda", prefix: "1441", credits: "7" },
        //             { code: "IS", name: "Iceland", prefix: "354", credits: "7" },
        //             { code: "MY", name: "Malaysia", prefix: "60", credits: "7" },
        //             { code: "SA", name: "Saudi Arabia", prefix: "966", credits: "7" },
        //             { code: "TT", name: "Trinidad and Tobago", prefix: "1868", credits: "7" },
        //             { code: "GB", name: "United Kingdom", prefix: "44", credits: "7" },
        //             { code: "LT", name: "Lithuania", prefix: "370", credits: "7" },
        //             { code: "PL", name: "Poland", prefix: "48", credits: "7" },
        //             { code: "RS", name: "Serbia", prefix: "381", credits: "7" },
        //             { code: "LA", name: "Laos", prefix: "856", credits: "7" },
        //             { code: "SV", name: "El Salvador", prefix: "503", credits: "7" },
        //             { code: "IR", name: "Iran", prefix: "98", credits: "7" },
        //             { code: "CR", name: "Costa Rica", prefix: "506", credits: "7" },
        //             { code: "PH", name: "Philippines", prefix: "63", credits: "7" },
        //             { code: "PG", name: "Papua New Guinea", prefix: "675", credits: "7" },
        //             { code: "PE", name: "Peru", prefix: "51", credits: "7" },
        //             { code: "AG", name: "Antigua and Barbuda", prefix: "1268", credits: "7" },
        //             { code: "PR", name: "Puerto Rico", prefix: "1787", credits: "7" },
        //             { code: "SI", name: "Slovenia", prefix: "386", credits: "7" },
        //             { code: "BT", name: "Bhutan", prefix: "975", credits: "7" },
        //             { code: "PK", name: "Pakistan", prefix: "92", credits: "8" },
        //             { code: "LB", name: "Lebanon", prefix: "961", credits: "8" },
        //             { code: "TC", name: "Turks and Caicos Islands", prefix: "1649", credits: "8" },
        //             { code: "BS", name: "Bahamas", prefix: "1242", credits: "8" },
        //             { code: "CO", name: "Colombia", prefix: "57", credits: "8" },
        //             { code: "HR", name: "Croatia", prefix: "385", credits: "8" },
        //             { code: "AI", name: "Anguilla", prefix: "1264", credits: "8" },
        //             { code: "AW", name: "Aruba", prefix: "297", credits: "8" },
        //             { code: "BQ", name: "Bonaire, Sint Eustatius and Saba", prefix: "599", credits: "8" },
        //             { code: "CW", name: "Curacao", prefix: "599", credits: "8" },
        //             { code: "DM", name: "Dominica", prefix: "1767", credits: "8" },
        //             { code: "ER", name: "Eritrea", prefix: "291", credits: "8" },
        //             { code: "GH", name: "Ghana", prefix: "233", credits: "8" },
        //             { code: "HT", name: "Haiti", prefix: "509", credits: "8" },
        //             { code: "KR", name: "South Korea", prefix: "82", credits: "8" },
        //             { code: "KY", name: "Cayman Islands", prefix: "1345", credits: "8" },
        //             { code: "SX", name: "Sint Maarten (Dutch Part)", prefix: "1721", credits: "8" },
        //             { code: "VU", name: "Vanuatu", prefix: "678", credits: "8" },
        //             { code: "MS", name: "Montserrat", prefix: "1664", credits: "8" },
        //             { code: "MX", name: "Mexico", prefix: "52", credits: "8" },
        //             { code: "VE", name: "Venezuela", prefix: "58", credits: "8" },
        //             { code: "KW", name: "Kuwait", prefix: "965", credits: "8" },
        //             { code: "LC", name: "Saint Lucia", prefix: "1758", credits: "8" },
        //             { code: "JM", name: "Jamaica", prefix: "1876", credits: "8" },
        //             { code: "GT", name: "Guatemala", prefix: "502", credits: "9" },
        //             { code: "PT", name: "Portugal", prefix: "351", credits: "9" },
        //             { code: "HN", name: "Honduras", prefix: "504", credits: "9" },
        //             { code: "MN", name: "Mongolia", prefix: "976", credits: "9" },
        //             { code: "VC", name: "Saint Vincent and The Grenadines", prefix: "1784", credits: "9" },
        //             { code: "TG", name: "Togo", prefix: "228", credits: "9" },
        //             { code: "WS", name: "Samoa", prefix: "685", credits: "9" },
        //             { code: "HK", name: "Hong Kong", prefix: "852", credits: "9" },
        //             { code: "TW", name: "Taiwan", prefix: "886", credits: "9" },
        //             { code: "VG", name: "Virgin Islands, British", prefix: "1284", credits: "9" },
        //             { code: "GY", name: "Guyana", prefix: "592", credits: "9" },
        //             { code: "CZ", name: "Czechia", prefix: "420", credits: "9" },
        //             { code: "AD", name: "Andorra", prefix: "376", credits: "9" },
        //             { code: "GD", name: "Grenada", prefix: "1473", credits: "9" },
        //             { code: "LU", name: "Luxembourg", prefix: "352", credits: "9" },
        //             { code: "RW", name: "Rwanda", prefix: "250", credits: "10" },
        //             { code: "DO", name: "Dominican Republic", prefix: "1809", credits: "10" },
        //             { code: "BO", name: "Bolivia", prefix: "591", credits: "10" },
        //             { code: "CL", name: "Chile", prefix: "56", credits: "10" },
        //             { code: "ID", name: "Indonesia", prefix: "62", credits: "10" },
        //             { code: "AO", name: "Angola", prefix: "244", credits: "10" },
        //             { code: "AU", name: "Australia", prefix: "61", credits: "10" },
        //             { code: "LY", name: "Libya", prefix: "218", credits: "10" },
        //             { code: "BJ", name: "Benin", prefix: "229", credits: "10" },
        //             { code: "BR", name: "Brazil", prefix: "55", credits: "10" },
        //             { code: "GA", name: "Gabon", prefix: "241", credits: "10" },
        //             { code: "GR", name: "Greece", prefix: "30", credits: "10" },
        //             { code: "RU", name: "Russia", prefix: "7", credits: "10" },
        //             { code: "SD", name: "Sudan", prefix: "249", credits: "10" },
        //             { code: "SS", name: "South Sudan", prefix: "211", credits: "10" },
        //             { code: "MD", name: "Moldova", prefix: "373", credits: "10" },
        //             { code: "NA", name: "Namibia", prefix: "264", credits: "10" },
        //             { code: "PA", name: "Panama", prefix: "507", credits: "10" },
        //             { code: "QA", name: "Qatar", prefix: "974", credits: "10" },
        //             { code: "NI", name: "Nicaragua", prefix: "505", credits: "10" },
        //             { code: "KN", name: "Saint Kitts and Nevis", prefix: "1869", credits: "10" },
        //             { code: "VN", name: "Vietnam", prefix: "84", credits: "10" },
        //             { code: "SE", name: "Sweden", prefix: "46", credits: "10" },
        //             { code: "KE", name: "Kenya", prefix: "254", credits: "10" },
        //             { code: "SZ", name: "Swaziland", prefix: "268", credits: "11" },
        //             { code: "GU", name: "Guam", prefix: "1671", credits: "11" },
        //             { code: "MW", name: "Malawi", prefix: "265", credits: "11" },
        //             { code: "CD", name: "Democratic Republic of the Congo", prefix: "243", credits: "11" },
        //             { code: "ZM", name: "Zambia", prefix: "260", credits: "11" },
        //             { code: "KH", name: "Cambodia", prefix: "855", credits: "11" },
        //             { code: "OM", name: "Oman", prefix: "968", credits: "11" },
        //             { code: "NE", name: "Niger", prefix: "227", credits: "11" },
        //             { code: "TO", name: "Tonga", prefix: "676", credits: "11" },
        //             { code: "FM", name: "Micronesia", prefix: "691", credits: "11" },
        //             { code: "LR", name: "Liberia", prefix: "231", credits: "11" },
        //             { code: "PW", name: "Palau", prefix: "680", credits: "11" },
        //             { code: "MK", name: "Macedonia", prefix: "389", credits: "11" },
        //             { code: "ET", name: "Ethiopia", prefix: "251", credits: "11" },
        //             { code: "AR", name: "Argentina", prefix: "54", credits: "11" },
        //             { code: "CH", name: "Switzerland", prefix: "41", credits: "12" },
        //             { code: "NO", name: "Norway", prefix: "47", credits: "12" },
        //             { code: "CV", name: "Cape Verde", prefix: "238", credits: "12" },
        //             { code: "IE", name: "Ireland", prefix: "353", credits: "12" },
        //             { code: "PS", name: "Palestinian Territory", prefix: "970", credits: "12" },
        //             { code: "MP", name: "Northern Mariana Islands", prefix: "1670", credits: "12" },
        //             { code: "LV", name: "Latvia", prefix: "371", credits: "12" },
        //             { code: "EC", name: "Ecuador", prefix: "593", credits: "12" },
        //             { code: "IQ", name: "Iraq", prefix: "964", credits: "12" },
        //             { code: "NR", name: "Nauru", prefix: "674", credits: "12" },
        //             { code: "RO", name: "Romania", prefix: "40", credits: "12" },
        //             { code: "UG", name: "Uganda", prefix: "256", credits: "12" },
        //             { code: "BD", name: "Bangladesh", prefix: "880", credits: "12" },
        //             { code: "FR", name: "France", prefix: "33", credits: "13" },
        //             { code: "EG", name: "Egypt", prefix: "20", credits: "13" },
        //             { code: "MA", name: "Morocco", prefix: "212", credits: "13" },
        //             { code: "TN", name: "Tunisia", prefix: "216", credits: "13" },
        //             { code: "SO", name: "Somalia", prefix: "252", credits: "13" },
        //             { code: "SB", name: "Solomon Islands", prefix: "677", credits: "13" },
        //             { code: "TD", name: "Chad", prefix: "235", credits: "13" },
        //             { code: "NG", name: "Nigeria", prefix: "234", credits: "13" },
        //             { code: "CU", name: "Cuba", prefix: "53", credits: "13" },
        //             { code: "KI", name: "Kiribati", prefix: "686", credits: "13" },
        //             { code: "BA", name: "Bosnia and Herzegovina", prefix: "387", credits: "13" },
        //             { code: "TZ", name: "Tanzania", prefix: "255", credits: "13" },
        //             { code: "HU", name: "Hungary", prefix: "36", credits: "14" },
        //             { code: "CY", name: "Cyprus", prefix: "357", credits: "14" },
        //             { code: "ES", name: "Spain", prefix: "34", credits: "14" },
        //             { code: "KZ", name: "Kazakhstan", prefix: "7", credits: "14" },
        //             { code: "AM", name: "Armenia", prefix: "374", credits: "14" },
        //             { code: "BI", name: "Burundi", prefix: "257", credits: "14" },
        //             { code: "CI", name: "Ivory Coast", prefix: "225", credits: "14" },
        //             { code: "FI", name: "Finland", prefix: "358", credits: "14" },
        //             { code: "JP", name: "Japan", prefix: "81", credits: "14" },
        //             { code: "LS", name: "Lesotho", prefix: "266", credits: "14" },
        //             { code: "MG", name: "Madagascar", prefix: "261", credits: "14" },
        //             { code: "DZ", name: "Algeria", prefix: "213", credits: "14" },
        //             { code: "BY", name: "Belarus", prefix: "375", credits: "14" },
        //             { code: "SM", name: "San Marino", prefix: "378", credits: "15" },
        //             { code: "AL", name: "Albania", prefix: "355", credits: "15" },
        //             { code: "UA", name: "Ukraine", prefix: "380", credits: "15" },
        //             { code: "KG", name: "Kyrgyzstan", prefix: "996", credits: "15" },
        //             { code: "SK", name: "Slovakia", prefix: "421", credits: "15" },
        //             { code: "AF", name: "Afghanistan", prefix: "93", credits: "15" },
        //             { code: "IT", name: "Italy", prefix: "39", credits: "15" },
        //             { code: "ST", name: "Sao Tome and Principe", prefix: "239", credits: "15" },
        //             { code: "AT", name: "Austria", prefix: "43", credits: "15" },
        //             { code: "EE", name: "Estonia", prefix: "372", credits: "15" },
        //             { code: "DE", name: "Germany", prefix: "49", credits: "15" },
        //             { code: "FJ", name: "Fiji", prefix: "679", credits: "15" },
        //             { code: "XK", name: "Kosovo", prefix: "383", credits: "15" },
        //             { code: "MR", name: "Mauritania", prefix: "222", credits: "15" },
        //             { code: "YE", name: "Yemen", prefix: "967", credits: "15" },
        //             { code: "UY", name: "Uruguay", prefix: "598", credits: "15" },
        //             { code: "CM", name: "Cameroon", prefix: "237", credits: "15" },
        //             { code: "GE", name: "Georgia", prefix: "995", credits: "16" },
        //             { code: "NL", name: "Netherlands", prefix: "31", credits: "16" },
        //             { code: "BW", name: "Botswana", prefix: "267", credits: "16" },
        //             { code: "NZ", name: "New Zealand", prefix: "64", credits: "16" },
        //             { code: "BE", name: "Belgium", prefix: "32", credits: "16" },
        //             { code: "TL", name: "East Timor", prefix: "670", credits: "16" },
        //             { code: "MM", name: "Myanmar", prefix: "95", credits: "16" },
        //             { code: "ML", name: "Mali", prefix: "223", credits: "17" },
        //             { code: "DJ", name: "Djibouti", prefix: "253", credits: "17" },
        //             { code: "NP", name: "Nepal", prefix: "977", credits: "17" },
        //             { code: "TJ", name: "Tajikistan", prefix: "992", credits: "17" },
        //             { code: "BG", name: "Bulgaria", prefix: "359", credits: "18" },
        //             { code: "UZ", name: "Uzbekistan", prefix: "998", credits: "18" },
        //             { code: "JO", name: "Jordan", prefix: "962", credits: "18" },
        //             { code: "SN", name: "Senegal", prefix: "221", credits: "19" },
        //             { code: "SY", name: "Syria", prefix: "963", credits: "19" },
        //             { code: "TM", name: "Turkmenistan", prefix: "993", credits: "19" },
        //             { code: "GW", name: "Guinea-Bissau", prefix: "245", credits: "19" },
        //             { code: "AS", name: "American Samoa", prefix: "1684", credits: "20" },
        //             { code: "MH", name: "Marshall Islands", prefix: "692", credits: "20" },
        //             { code: "AC", name: "Ascension Island", prefix: "247", credits: "20" },
        //             { code: "PM", name: "Saint Pierre and Miquelon", prefix: "508", credits: "20" },
        //             { code: "MQ", name: "Martinique", prefix: "596", credits: "23" },
        //             { code: "AZ", name: "Azerbaijan", prefix: "994", credits: "24" },
        //             { code: "GN", name: "Guinea", prefix: "224", credits: "25" },
        //             { code: "NC", name: "New Caledonia", prefix: "687", credits: "25" },
        //             { code: "GP", name: "Guadeloupe", prefix: "590", credits: "25" },
        //             { code: "MC", name: "Monaco", prefix: "377", credits: "25" },
        //             { code: "GF", name: "French Guiana", prefix: "594", credits: "26" },
        //             { code: "RE", name: "Réunion Island", prefix: "262", credits: "27" },
        //             { code: "PF", name: "French Polynesia", prefix: "689", credits: "27" },
        //             { code: "YT", name: "Mayotte", prefix: "269", credits: "32" }
        //         ],

        //     )
        // })
    }



    static getPaymentMethods() {
        return fetchGet(`${BILLING_SERVICE}paymentmethods`);
    }

    static createPaymentIntent({ planId, paymentMethodId, quantity }) {
        return fetchPost(`${BILLING_SERVICE}paymentintents`, { planId, paymentMethodId, quantity });

    }
    static createSubscription({ planId, paymentMethodId, token }) {
        const subscriptionReq = { planId };
        if (paymentMethodId) {
            subscriptionReq.paymentMethodId = paymentMethodId;
        } else {
            subscriptionReq.token = token;
        }
        return fetchPost(`${BILLING_SERVICE}subscriptions`, subscriptionReq);
    }


    static unsubscribeSubscription(id) {

        return fetchGet(`${BILLING_SERVICE}subscriptions/unsubscribe/${id}`);
    }


    static placeBankTransfer({ planName, planQuantity, planId, billingInfo, currencyCode }) {
        return fetchPost(BILLING_SERVICE + 'banktransfer', { quantity: planQuantity, planId });
    }

    static uploadPaymentSlip({ formData, id }) {
        return fetchPostMultipart(`${BILLING_SERVICE}banktransfer/upload/${id}`, formData);
    }

    static getBillingInfo() {
        return fetchGet(`${BILLING_SERVICE}users`);
    }

    static updateBillingInfo({ name, phone, city, country, email, state, streetAddress, streetAddress2, zip }) {

        return fetchPut(`${BILLING_SERVICE}users`, { billingDetails: { name, phone, city, country, email, state, country, streetAddress, streetAddress2, zip } }, { 'name': 'update-billing-info', 'category': 'billing' });

    }


    static getInvoices() {
        return fetchGet(BILLING_SERVICE + 'invoices');

    }

}
export default BillingService;