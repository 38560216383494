import React, {  useCallback } from 'react';
import { Form, FaIcon, Row, Col, Container } from '@shoutout-labs/shoutout-themes';
import {AnalyticsService} from './../../services/Services';
import { faCheck, faTimes } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';


import './SenderRequest.css'

const SmsSenderIdPattern = /^(?=.*[A-Za-z])[A-Za-z.\s\d]{3,11}$/;

//allow allowed characters in the beginning  /^(?=.*[A-Za-z])[A-Za-z.\s\d]{3,11}$/

const SenderRequestDetails = ({ details, setDetails }) => {


    const triggerSupportChat = useCallback(() => {
        AnalyticsService.trackActivity({
            "name": "trigger-chatBox"
        });
    },[])

    const onChangeInput = useCallback((e) => {
        e.stopPropagation();
        setDetails({ ...details, [e.target.name]: e.target.value });
    }, [details, setDetails]);


    const setSmsSenderID = useCallback((e) => {
        const senderId = e.target.value;
        const isValid = senderId.match(SmsSenderIdPattern);

        setDetails({ ...details, senderId, isValid });
    }, [details, setDetails]);

    const handleUsageChange = useCallback((e) => {
        const usage = e.currentTarget.dataset.name;
        if (usage === "yes") {
            setDetails({ ...details, usage: true, });
        }
        else {
            setDetails({ ...details, usage: false, });
        }

    }, [details, setDetails])

    const handlePurposeChange = useCallback((e) => {
        const purpose = e.currentTarget.dataset.name;
        setDetails({ ...details, purpose: purpose });

    }, [details, setDetails]);


    // senderId: '',
    // company: '',
    // sample: '',
    // isValid: false,
    // isSending: false,
    // requestSent: false,
    // emailName: '',
    // businessNature: '',
    // designation: '',
    // userName: '',
    // countries: [],
    // usage: 'transactional',
    // purpose: ''

    return (
        <div className="text-left">
            <Form.Group controlId="sms-id">
                <Form.Label>Sender ID name
         </Form.Label>
                <div className="d-flex align-items-center">
                    <Form.Control type="input" placeholder="Your Sender ID"
                        required onChange={setSmsSenderID} value={details.senderId} maxLength={11} minLength={3} />
                    <div className="ml-n4">{details.isValid ? <span className="text-success"><FaIcon icon={faCheck} /></span> : <span className="text-danger"><FaIcon icon={faTimes} /></span>}</div>
                </div>

            </Form.Group>
            <Form.Group controlId="sms-id-description">
                <ul>
                    <li className="text-left ml-n3">
                        <small>Minimum 3 characters</small>
                    </li>
                    <li className="text-left ml-n3">
                        <small>Maximum 11 characters</small>
                    </li>
                    <li className="text-left ml-n3">
                        <small>Only alphanumeric characters</small>
                    </li>
                </ul>
            </Form.Group>

            <Form.Group controlId="countries">
                <Form.Label>
                    Countries *
                </Form.Label>
                {/* <Typeahead
                    id="select-country"
                    defaultSelected={[{ ...allCountries }]}
                    onChange={(selections) => {
                        if (selections && selections.length > 0) {
                            setDetails({ ...details, countries: selections });
                        } else {
                            setDetails({ countries: null });
                        }
                    }}
                    selected={details.countries}
                    placeholder={'Select Country'}
                    options={countryList}
                    labelKey={option => `${option.name}`}
                    renderMenuItemChildren={(result, props) => {
                        return <span><span className="default-flag">{result.alpha2 && <FlagIcon code={result.alpha2.toLowerCase()} />}</span>&nbsp;&nbsp;{result.name}</span>
                    }}
                    multiple={true}
                    filterBy={['name']}
                    size="sm"
                /> */}
                <Form.Control as="select" name="countryISOCodes">
                    <option value="LK">Sri Lanka</option>
                </Form.Control>

                <small>Country that you want to register your Sender ID. If you don't see the country in the dropdown <button className="btn btn-sm btn-transparent" onClick={triggerSupportChat}>contact us</button></small>
            </Form.Group>

            <Form.Group controlId="usage">
                <Form.Label>Do you want to send messages Internationally
         </Form.Label>
                <Container className="px-0">
                    <Row noGutters>
                        <Col>
                            <Form.Check
                                type="radio"
                                label="Yes"
                                name="usage"
                                id="radio1"
                                data-name="yes"
                                checked={details.usage === true}
                                onChange={handleUsageChange}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                type="radio"
                                label="No"
                                name="usage"
                                id="radio2"
                                data-name="no"
                                checked={details.usage === false}
                                onChange={handleUsageChange}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form.Group>


            <Form.Group controlId="purpose">
                <Form.Label>Purpose
         </Form.Label>
                <Container className="px-0">
                    <Row noGutters>
                        <Col id="purpose">
                            <Form.Check
                                type="radio"
                                label="Transactional"
                                name="purpose"
                                id="check01"
                                data-name="transactional"
                                checked={details.purpose === "transactional"}
                                onChange={handlePurposeChange}
                            />
                        </Col>
                        <Col id="purpose">
                            <Form.Check
                                type="radio"
                                label="Promotional"
                                name="purpose"
                                id="check02"
                                data-name="promotional"
                                checked={details.purpose === "promotional"}
                                onChange={handlePurposeChange}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form.Group>


            <Form.Group controlId="sample">
                <Form.Label>Sample SMS Content
             
                </Form.Label>

                <Form.Control as="textarea" type="input" placeholder="Type here" name="sample"
                    required onChange={onChangeInput} value={details.sample} />
                        <small>Please share sample content you intend to send</small>
            </Form.Group>
        </div>
    )
}
export default SenderRequestDetails;