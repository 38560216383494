import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Modal, Button, Form } from '@shoutout-labs/shoutout-themes';
import { AccountService, AnalyticsService, UtilService } from '../../services/Services';
import { notify } from 'react-notify-toast';
import UserContext from './../../contexts/UserContext';
import './OtpVerify.css';


const OtpVerify = ({ verify, setVerify, mobile, onVerify, allowResendOTP, expireInSeconds }) => {

    const [otp, setOtp] = useState("");
    const [isSending, setIsSending] = useState(false);
    const { loadUser } = useContext(UserContext);

    const handleChangeOtp = useCallback((e) => {
        setOtp(e.target.value);
    }, [setOtp]);

    const onAccept = useCallback(async (e) => {
        e.preventDefault();
        //const otp = `${otp1}${otp2}${otp3}${otp4}${otp5}`;

        try {
            setIsSending(true);
            await AccountService.otpCodeVerification(otp);
            notify.show("Mobile verification success", "success");

            setOtp("");
            loadUser();
            setVerify(false);
        } catch (e) {
            notify.show("Mobile verification failed. Please try again.", "error");
        } finally {
            setIsSending(false);

        }
    }, [setVerify, otp, setOtp, setIsSending, loadUser]);

    const onClose = useCallback(() => {
        setVerify(false);
    }, [setVerify]);

    const triggerSupportChat = useCallback(() => {
        AnalyticsService.trackActivity({
            "name": "trigger-chatBox"
        });
    }, []);


    return (
        <Modal show={verify} onHide={onClose} className="border-0">
            <Modal.Header closeButton>
                <h6 className="mb-0 font-weight-bold">Verify Phone Number</h6>
            </Modal.Header>
            <Modal.Body className="natural-text">
                <p>Enter the OTP sent to +{mobile}</p>
                <div id="otp" className="d-flex mt-2">
                    <Form.Control id="5" className="my-2 pl-verify form-control otp-digit" type="text" onChange={handleChangeOtp} />

                </div>
                <div className="d-flex align-items-center my-2">
                    <p>Didn't receive the OTP yet? </p>  
                    <Button variant="outline-danger" size="sm" className="btn btn-outline-danger border-0 btn-sm" disabled={!allowResendOTP} onClick={onVerify}>Resend OTP</Button>
                    <small className="ml-2">Resend in {UtilService.getMinAndSeconds(expireInSeconds)}</small>
                </div>
                <div className="d-flex mt-3">
                    <small>If you're still having issues, please  </small>
                    <small onClick={triggerSupportChat} className="pointer support">
                        contact our support team
                    </small>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-sm btn-primary" onClick={onAccept} disabled={isSending}>{isSending ? "Verifying..." : "Verify"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OtpVerify;