import { connect } from './../../contexts/ContextUtils';
import { BillingPage,  BillingInvoices, BillingPurchasePlan, BillingSuccess } from './../../components/billing/BillingComponents';
import { requestInvoices, navigateToPurchase, requestBillingInfo } from './../actions/BillingActions';
import { showAlertBox } from './../actions/UtilActions';

const BillingPageContainer = connect(
    (state) => {//map state to props
        return {
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
            // init: () => { dispatch(requestBillingInfo()) },
            navigateToPurchase: (selectedPlan) => { dispatch(navigateToPurchase(selectedPlan)) },
            getBillingInfo: () => dispatch(requestBillingInfo())
        }
    }
)(BillingPage);

const BillingPurchasePlanContainer = connect(
    (state) => {//map state to props
        return {
            selectedPlan: state.billing.selectedPlan,
            billingInfo: state.billing.billingInfo
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
            showAlert: (title, message, isShow, alertType) => dispatch(showAlertBox(title, message, isShow, alertType))
        }
    }
)(BillingPurchasePlan);

// const BillingPlansContainer = connect(
//     (state) => {//map state to props
//         return {
//             plans: state.billing.plans,
//             subscriptions: state.billing.subscriptions
//         };
//     },
//     (dispatch, ownProps) => {// map dispatch to props
//         return {
//             getBillingInfo: () => dispatch(requestBillingInfo())
//         }
//     }
// )(BillingPlans);

const BillingInvoicesContainer = connect(
    (state) => {//map state to props
        return {
            invoiceList: state.billing.invoices
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
            requestInvoices: () => { dispatch(requestInvoices()) }
        }
    }
)(BillingInvoices);

const BillingSuccessContainer = connect(
    (state) => {//map state to props
        return {
            selectedPlan: state.billing.selectedPlan,
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
            // init: () => { dispatch(requestBillingInfo()) },
            // navigateToPurchase: (selectedPlan) => { dispatch(navigateToPurchase(selectedPlan)) }
        }
    }
)(BillingSuccess);


export default BillingPageContainer;
export {
    BillingInvoicesContainer,
    BillingPurchasePlanContainer,
    BillingSuccessContainer
}