import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Alert, Button } from '@shoutout-labs/shoutout-themes';
import UserContext from './contexts/UserContext';
import { AccountService } from './services/Services';
import { notify } from 'react-notify-toast';
import './NotificationBar.css';

const NotificationContext = React.createContext({ show: false });


const MessageTypes = {
    NEW_CONTENT: "NEW_CONTENT",
    ACCOUNT_PENDING_VERIFICATION: "ACCOUNT_PENDING_VERIFICATION"
}
const NotificationContextWrapper = ({ children }) => {
    const [show, setShow] = useState(false);
    const toggleShow = useCallback(() => {
        setShow(!show)
    }, [show])

    return (<NotificationContext.Provider value={{ show: show, toggleShow }}>
        {children}
    </NotificationContext.Provider>);
}

const ReloadModal = () => {
    const onClick = useCallback(() => {
        window.location.reload(window.location.href);
    }, [])
    return (
        <React.Fragment>
            <p> We just got better! please reload </p>

            <Button size="sm" variant="success" onClick={onClick}>Reload</Button>
        </React.Fragment>
    )
}

const AccountPendingVerification = () => {
    const [resendingLink, setResendingLink] = useState(false);

    const resendVerificationLink = useCallback(async () => {
        try {
            setResendingLink(true);
            await AccountService.resendVerificationLink();
            notify.show("Link resent. Please check your email.", "success");
        } catch (e) {
            notify.show("Link resend failed. Please try again.", "error");
        } finally {
            setResendingLink(false);
        }
    }, [])
    return (
        <p className="mb-0"> Some functionalities may not available until you verify your account. Please check your email and find the verification link. <Button variant="link" size="sm" onClick={resendVerificationLink} disabled={resendingLink}>{resendingLink ? "Resending..." : "Resend Link"}</Button></p>
    )
}
const getContent = (messageType) => {
    switch (messageType) {
        case MessageTypes.NEW_CONTENT: {
            return (<ReloadModal />)
        }

        case MessageTypes.ACCOUNT_PENDING_VERIFICATION: {
            return (<AccountPendingVerification />)
        }

        default: {
            return null;
        }
    }
}
const NotificationBar = () => {
    const { show, toggleShow } = useContext(NotificationContext);
    const { emailVerified } = useContext(UserContext);
    const [messageType, setMessageType] = useState("");
    useEffect(() => {

        window.addEventListener("newContentAvailable", () => {
            setMessageType(MessageTypes.NEW_CONTENT);
            toggleShow()
        });

        // setMessageType(MessageTypes.ACCOUNT_PENDING_VERIFICATION);
        // toggleShow()

        return () => {
            window.removeEventListener("newContentAvailable", toggleShow);
        }
    }, [])

    useEffect(() => {
        if (!emailVerified) {
            setMessageType(MessageTypes.ACCOUNT_PENDING_VERIFICATION);
            toggleShow();
        }
    }, [emailVerified])


    return (
        show ?
            <Alert variant="transparent" className="text-center notification-bar mb-n5">
                <div className="my-2 mx-4 p-3 bg-light">
                    {getContent(messageType)}
                </div>
            </Alert>
            : null
    )
}
export default (props) => (<NotificationContextWrapper><NotificationBar {...props} /></NotificationContextWrapper>);

export { NotificationContext }