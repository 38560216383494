/**
 * Created by madura on 2/8/17.
 */
import { lazy } from 'react';
//import MessengerApp from './components/integrations/facebook/MessengerApp';
//
// import FacebookLeadAd from './components/integrations/facebook/facebook_lead_ad/FacebookLeadAd'
// import typeForm from './components/integrations/typeForm/TypeForm'
import { faFacebookMessenger, faFacebook, faWpforms } from '@shoutout-labs/shoutout-themes/es/FaIconsBrands';

//import{faFacebook} from '@shoutout-labs/shoutout-themes/es/FaIconsBrands';
//import {faCheck} from '@shoutout-labs/shoutout-themes/es/FaBrands';

const FacebookLeadAd = lazy(() => import('./components/integrations/facebook/facebook_lead_ad/FacebookLeadAd'));
const MessengerApp = lazy(() => import('./components/integrations/facebook/messengerApp/MessengerApp'));
const APP_LOYALTY = "LOYALTY";
const APP_ENGAGEMENT = "ENGAGE";
const APP_LITE = "LITE";
const APP_COLLECT = "COLLECT";
const APP_GAMIFICATION = "POLL";
const APP_KLIP = "KLIP";
const BASE_API_URL = process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://api.getshoutout.com/" : "https://betaapi.getshoutout.com/";
const BASE_API2_URL = process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://api2.getshoutout.com/" : "https://betaapi.getshoutout.com/";
const BASE_API3_URL = process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://api3.getshoutout.com/" : "https://betaapi.getshoutout.com/";

const OPTOUT_TEMPLATES = {
    TEMPLATE1: "*SMS STOP {{keyword}} to {{mobileNumber}}",
    TEMPLATE2: "*SMS STOP to {{mobileNumber}}",
    TEMPLATE3: "*Reply STOP to opt-out",
}

const MESSAGE_REVIEW_STATUS = {
    PENDING : "PENDING",
    APPROVED : "APPROVED",
    REJECTED : "REJECTED"
}


export default {
    BASE_API_URL,
    BASE_API2_URL,
    BASE_API3_URL,
    TRANSPORT_SMS: 'SMS',
    TRANSPORT_WHATSAPP: 'WHATSAPP',
    TRANSPORT_MESSENGER: 'FACEBOOK_MESSENGER',
    MAX_SMS_LENGTH: 459,
    MAX_SMS_PAGES: 3,
    TRANSPORT_EMAIL: 'EMAIL',
    CAMPAIGN_BROADCAST: 'BROADCAST',
    CAMPAIGN_TRIGGER: 'EVENT',
    CONTACTS_LOAD_BATCH_SIZE: 100,
    MESSAGE_REVIEW_STATUS,
    CAMPAIGN_STATUS: {

        RUNNING: {
            name: 'Running',
            icon: 'hourglass',
            className: 'status-running',
            showReport: false,
            enabledDelete: false
        },

        SCHEDULED: {
            name: 'Scheduled',
            icon: 'clock',
            className: 'status-scheduled',
            showReport: false,
            enabledDelete: true
        },
        FAILED: {
            name: 'Failed',
            icon: 'times-circle',
            className: 'status-failed',
            showReport: true,
            enabledDelete: true
        },
        REJECTED: {
            name: 'Rejected',
            icon: 'ban',
            className: 'status-failed',
            showReport: false,
            enabledDelete: true
        },
        REVIEWING: {
            name: 'Reviewing',
            icon: 'search',
            className: 'status-warning',
            showReport: false,
            enabledDelete: false
        },
        CREATING: {
            name: 'Creating',
            icon: 'sync',
            className: 'status-creating',
            showReport: false,
            enabledDelete: false
        },

        INSUFFICIENT_CREDITS: {
            name: 'Insufficient Credits',
            icon: 'exclamation-triangle',
            className: 'status-warning',
            chargingFailed: true,
            showReport: true,
            enabledDelete: true
        },

        FINISHED: {
            name: 'Finished',
            icon: 'check-circle',
            className: 'status-finished',
            showReport: true,
            enabledDelete: true
        },

        LISTENING: {
            name: 'Listening',
            icon: 'assistive-listening-systems',
            className: 'status-listening',
            showReport: true,
            enabledDelete: true
        },

    },



    LOG_STATUS_MAP: {
        '1001': 'SENT', //successfully submitted
        '1002': 'FAILED', //Invalid url
        '1003': 'FAILED', //Authentication failed
        '1004': 'FAILED', //Invalid data for type field
        '1005': 'FAILED', //Invalid data for message field
        '1006': 'INVALID DESTINATION', //Invalid MSISDN
        '1007': 'INVALID SENDER ID', //Invalid data for source field
        '1008': 'FAILED', //Invalid data for dlr field
        '1010': 'FAILED', //Internal error
        '1011': 'FAILED', //Response timeout
        '1012': 'FAILED', //No routes
        '1013': 'UNKNOWN',
        '1014': 'BOUNCED',
        '1015': 'BLOCKED',
        '1016': 'DELIVERED',
        '1025': 'NO CREDITS', //Insufficient credit
        '1026': 'SUBMITTED'
    },
    MSG_STATUS_TO_CODE_MAP: {
        'SENT': '1001',
        'INVALID_SENDER_ID': '1007',
        'NO_CREDITS': '1025',
        'INVALID_RECEIVER': '1006',
        'SUBMITTED': '1026'
    },
    ALL_SEGMENT: {
        id: "0",
        segment: {
            name: 'All', data: [{
                "columnName": "id",
                "_selectedFilter": "4",
                "searchKey": "",
                "columnType": "string"
            }]
        }
    },
    RESERVED_ATTRIBUTES: ['user_id', 'mobile_number', 'email', '_created_on', 'name', 'tags', 'last_seen_on', '_modified_on', 'id'],
    ENGAGE_APP_URL: 'https://web.getshoutout.com',
    LOYALTY_APP_URL: 'https://app.topnotch.club',
    EVENT_TEMPLATES: {
        DEFAULT: 0,
        LOYALTY_POINT_COLLECTED: 10,
        LOYALTY_POINT_REDEEMED: 11,
        LOYALTY_REGISTERED: 12,
        LOYALTY_REWARD_REDEEMED: 13,
        ENGAGEMENT_CAMPAIGN_CREATED: 14,
        ENGAGEMENT_COUPON_ISSUED: 15,
        ENGAGEMENT_FORM_RESPONSE: 16,
        ENGAGEMENT_CUSTOM_EVENT: 17,
        MESSAGE_INCOMING_SMS: 18,
        MESSAGE_OUTGOING_SMS: 19,
        MESSAGE_OUTGOING_EMAIL: 20,
        MESSAGE_INCOMING_EMAIL: 21
    },
    MODULE_IDS: {
        LOYALTY_MODULE: 1,
        ENGAGEMENT_MODULE: 2
    },
    CAMPAIGN_PROGRESS_STATUS: {
        PENDING: 0,
        IN_PROGRESS: 1,
        SUCCESS: 2,
        FAILED: 3
    },
    SHORT_URL_DOMAIN: 'https://e-url.co',
    BUILD_APP: process.env.REACT_APP_PRODUCT, //"LOYALTY or ENGAGE"
    APP_LOYALTY,
    APP_ENGAGEMENT,
    APP_LITE,
    APP_COLLECT,
    APP_GAMIFICATION,
    APP_KLIP,
    INTEGRATION_APPS: {
        FACEBOOK_MESSENGER: {
            id: 'FACEBOOK_MESSENGER',
            icon: faFacebookMessenger,
            name: 'Facebook Messenger',
            path: 'fbmessenger',
            description: 'Connect with your customers via Facebook Messenger triggered messages',
            label: 'Beta',
            component: MessengerApp
        },
        FACEBOOK_LEAD_AD: {
            id: 'FACEBOOK_LEAD_AD',
            icon: faFacebook,
            name: 'Facebook Lead Ad',
            path: 'fbleadad',
            component: FacebookLeadAd
        },
        SHOUTOUT_FORM: {
            id: 'SHOUTOUT_FORM',
            name: 'ShoutOUT Forms',
            path: 'shoutoutforms'
        },
        TYPEFORM_APP: {
            id: 'TYPEFORM',
            icon: faWpforms,
            name: 'typefrom app',
            path: 'typeform',
            // component: typeForm
        },
        SHOUTOUT_POLL: {
            id: 'SHOUTOUT_POLL',
            name: 'ShoutOUT Poll',
            path: 'shoutoutpoll'
        },
        WHATSAPP: {
            id: 'WHATSAPP',
            name: 'WhatsApp',
            path: 'whatsapp'
        }
    },
    SMS_OPTOUT: {
        TYPE_TRANSACTIONAL: 'transactional',
        TYPE_BOTH: 'both',
        TYPE_PROMOTIONAL: 'promotional',
        TEMPLATE1: OPTOUT_TEMPLATES.TEMPLATE1,
        TEMPLATE2: OPTOUT_TEMPLATES.TEMPLATE2,
        TEMPLATE3: OPTOUT_TEMPLATES.TEMPLATE3,
        COUNTRIES: {
            "all": {//specify the max support length number format
                template: OPTOUT_TEMPLATES.TEMPLATE1,
                mobileNumber: "0777123456",
                keyword: "ABC",
                optoutKeywordRequired: true
            },
            "sri lanka": {
                mobileNumber: "0777123456",
                template: OPTOUT_TEMPLATES.TEMPLATE1,
                keyword: "ABC",
                optoutKeywordRequired: true
            },
            "united states": {
                mobileNumber: "4431234567",
                template: OPTOUT_TEMPLATES.TEMPLATE3,
                optoutKeywordRequired: false
            },
            "australia": {
                mobileNumber: "04xxxxxxxx",
                template: OPTOUT_TEMPLATES.TEMPLATE3,
                keyword: "ABC",
                optoutKeywordRequired: true
            }
        }
    },
    USER_ACCESS_CONTROLS: {
        ACCESS_KEY: {
            DELETE: "delete",
            READ: "read",
            WRITE: "write"
        },
        MODULES: {
            APPS: {
                moduleId: "apps"
            },
            CAMPAIGNS: {
                moduleId: "campaigns"
            },
            COMMON: {
                moduleId: "common"
            },
            LITE_MESSAGES: {
                moduleId: "litemessages"
            },
            LOYALTY_ANALYTICS: {
                moduleId: "loyaltyanalytics"
            },
            LOYALTY_CONFIGURATIONS: {
                moduleId: "loyaltyconfigurations"
            },
            LOYALTY_POINTS: {
                moduleId: "loyaltypoints"
            },
            LOYALTY_REWARDS: {
                moduleId: "loyaltyrewards"
            },
            LOYALTY_TRANSACTIONS: {
                moduleId: "loyaltytransactions"
            },
            LOYALTY_USERS: {
                moduleId: "loyaltyusers"
            },
            KLIP_ADD_POINTS: {
                moduleId: "klippointscollect"
            },
            KLIP_REDEEM_POINTS: {
                moduleId: "klippointsredeem"
            },
            KLIP_USERS: {
                moduleId: "klipusers"
            },
            KLIP_REWARD_CLAIM: {
                moduleId: "kliprewardsclaim"
            },
            KLIP_REWARD_REDEEM: {
                moduleId: "kliprewardsredeem"
            },
            PEOPLE: {
                moduleId: "people"
            },
            POLL_COMPETITION: {
                moduleId: "pollcompetitions"
            },
            POLL_EXPORTS: {
                moduleId: "pollexports"
            },
            POLL_REPORTS: {
                moduleId: "pollreports"
            },
            RESPONSE: {
                moduleId: "response"
            },
            ROOT: {
                moduleId: "root"
            },
            SYSTEM: {
                moduleId: "system"
            },
            TEAMS: {
                moduleId: "team"
            }

        }
    },
    AREA_CODES: {
        US: [
            '205',
            '251',
            '256',
            '334',
            '907',
            '480',
            '520',
            '602',
            '623',
            '928',
            '479',
            '501',
            '870',
            '209',
            '213',
            '310',
            '323',
            '408',
            '415',
            '424',
            '510',
            '530',
            '559',
            '562',
            '619',
            '626',
            '650',
            '661',
            '707',
            '714',
            '760',
            '805',
            '818',
            '831',
            '858',
            '909',
            '916',
            '925',
            '949',
            '951',
            '303',
            '719',
            '720',
            '970',
            '203',
            '860',
            '302',
            '202',
            '689',
            '239',
            '305',
            '321',
            '352',
            '386',
            '407',
            '561',
            '727',
            '754',
            '772',
            '786',
            '813',
            '850',
            '863',
            '904',
            '941',
            '954',
            '762',
            '229',
            '404',
            '478',
            '678',
            '706',
            '770',
            '912',
            '808',
            '208',
            '730',
            '779',
            '217',
            '224',
            '309',
            '312',
            '447',
            '618',
            '630',
            '708',
            '773',
            '815',
            '847',
            '219',
            '260',
            '317',
            '574',
            '765',
            '812',
            '319',
            '515',
            '563',
            '641',
            '712',
            '316',
            '620',
            '785',
            '913',
            '270',
            '502',
            '606',
            '859',
            '225',
            '318',
            '337',
            '504',
            '985',
            '207',
            '240',
            '301',
            '410',
            '443',
            '339',
            '351',
            '413',
            '508',
            '617',
            '774',
            '781',
            '857',
            '978',
            '231',
            '248',
            '269',
            '313',
            '517',
            '586',
            '616',
            '734',
            '810',
            '906',
            '947',
            '989',
            '218',
            '320',
            '507',
            '612',
            '651',
            '763',
            '952',
            '228',
            '601',
            '662',
            '769',
            '314',
            '417',
            '573',
            '636',
            '660',
            '816',
            '406',
            '308',
            '402',
            '702',
            '775',
            '603',
            '201',
            '551',
            '609',
            '732',
            '848',
            '856',
            '862',
            '908',
            '973',
            '505',
            '575',
            '212',
            '315',
            '347',
            '516',
            '518',
            '585',
            '607',
            '631',
            '646',
            '716',
            '718',
            '845',
            '914',
            '917',
            '252',
            '336',
            '704',
            '828',
            '910',
            '919',
            '980',
            '701',
            '216',
            '234',
            '330',
            '419',
            '440',
            '513',
            '567',
            '614',
            '740',
            '937',
            '405',
            '580',
            '918',
            '503',
            '541',
            '971',
            '215',
            '267',
            '412',
            '484',
            '570',
            '610',
            '717',
            '724',
            '814',
            '878',
            '401',
            '803',
            '843',
            '864',
            '605',
            '423',
            '615',
            '731',
            '865',
            '901',
            '931',
            '210',
            '214',
            '254',
            '281',
            '325',
            '361',
            '409',
            '430',
            '432',
            '469',
            '512',
            '682',
            '713',
            '806',
            '817',
            '830',
            '832',
            '903',
            '915',
            '936',
            '940',
            '956',
            '972',
            '979',
            '435',
            '801',
            '802',
            '276',
            '434',
            '540',
            '571',
            '703',
            '757',
            '804',
            '206',
            '253',
            '360',
            '425',
            '509',
            '681',
            '304',
            '262',
            '414',
            '608',
            '715',
            '920',
            '307'
        ],
        CA: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '806',
            '343',
            '365',
            '367',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '705',
            '709',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905'
        ]
    }
};
