
import React from 'react';
import { AddCard } from './BillingComponents';
const AddPaymentMethod = () => {

    return (

        <AddCard />


    )

}

export default AddPaymentMethod;