import React, { useContext, forwardRef } from 'react';
import { Card, Row, Col } from '@shoutout-labs/shoutout-themes';
import FlagIconFactory from 'react-flag-icon-css';
import Context from './../../contexts/ContextUtils';
import CardPayment from './../billing/CardPayment';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

const NumberPurchaseSummary = forwardRef(({ number, isPurchasing }, ref) => {

    const { store } = useContext(Context);

    return (
    <>
        {!isPurchasing ? <Card className="text-left p-4 onload-transition border-0">
            <Card.Title className="px-5 mx-5">Purchase Summary</Card.Title>
            <Card.Body className="px-5 mx-5 mb-0 pb-0">
                <Row className="mb-3">
                    <Col lg={4} className="mx-auto">
                        <p className="font-weight-semi-bold">Country</p>
                    </Col>
                    <Col lg={8} className="mx-auto">
                        <p className=""><FlagIcon code={number.countryISO.toLowerCase()} />  {number.countryName}</p>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col lg={4} className="mx-auto">
                        <p className="font-weight-semi-bold">Phone Number</p>
                    </Col>
                    <Col lg={8} className="mx-auto">
                        <p className="">{number.phoneNumber}</p>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col lg={4} className="mx-auto">
                        <p className="font-weight-semi-bold">Type</p>
                    </Col>
                    <Col lg={8} className="mx-auto">
                        {number.type === 'toll-free' && <p className="">Toll Free</p>}
                        {number.type === 'local' && <p className="">Local</p>}
                    </Col>
                </Row>

                {number.type === 'local' &&
                    <Row className="mb-3">
                        <Col lg={4} className="mx-auto">
                            <p className="font-weight-semi-bold">Area Code</p>
                        </Col>
                        <Col lg={8} className="mx-auto">
                            <p className="">{number.phoneNumber.substr(2, 3)}</p>
                        </Col>
                    </Row>
                }

                <Row className="mb-3">
                    <Col lg={4} className="mx-auto">
                        <p className="font-weight-semi-bold">Monthly Fee</p>
                    </Col>
                    <Col lg={8} className="mx-auto">
                        <p className="">{`${number.currency} ${number.monthlyCost}`}</p>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col lg={4} className="mx-auto">
                        <p className="font-weight-semi-bold">Setup Fee</p>
                    </Col>
                    <Col lg={8} className="mx-auto">
                        {number.activationCost !== 0 ?
                            `${number.currency} ${number.activationCost}`
                            :
                            <p>Free</p>
                        }
                    </Col>
                </Row>
                <Card.Title className="mb-3">Select Card</Card.Title>
                <div>
                    <CardPayment billingInfo={store.billing.billingInfo} ref={ref} hideAddCard={true} />
                </div>
            </Card.Body>
        </Card>
        :
    <p>Purchasing Phone Number...</p>
}
        </>
    )
}
);
export default NumberPurchaseSummary;