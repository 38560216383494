import React, { useState, useContext, useCallback, useRef, useEffect } from 'react';
import { Row, Col, Card, FaIcon } from '@shoutout-labs/shoutout-themes';
import BillingPackageList from './BillingPackageList';
import Constants from './../../Constants';
import { BillingService, AnalyticsService, UtilService } from './../../services/Services';
import UserContext from './../../contexts/UserContext';
import { LoadingPaperPlane } from './../utils/UtilComponents';
import Context from './../../contexts/ContextUtils';
import { faInfo, faChevronDown, faChevronUp } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { useHistory } from 'react-router-dom';
import './BillingPlans.css';
import './CountrySelector.css';

const transportSMS = Constants.TRANSPORT_SMS.toLowerCase(), transportEmail = Constants.TRANSPORT_EMAIL.toLowerCase();


const BillingPackages = ({ navigateToPurchase, getBillingInfo }) => {

    const [selectedTransport, setSelectedTransport] = useState(transportSMS);
    const [countryDetails, setCountryDetails] = useState({ value: 1, country: { name: "Sri Lanka", code: "lk" } })
    const [filterTriggered, setFilterTriggered] = useState("")
    const [filterCountries, setFilterCountries] = useState([])
    const [countryBillingMap, setCountryBillingMap] = useState([])
    const [selector, setSelector] = useState(false);
    const [billingLoading, setBillingLoading] = useState(false);
    const countryDrop = useRef();

    const { store } = useContext(Context);
    const { smsCreditsOnDemand = 0, smsCreditsPlan = 0, emailCreditsOnDemand = 0, emailCreditsPlan = 0 } = useContext(UserContext);

    const history = useHistory();

    const onToggleTransport = useCallback((e) => {
        const transport = e.target.id;
        setSelectedTransport(transport);
    }, [setSelectedTransport]);


    // const onSelectPackage = useCallback((plan) => {
    //     navigateToPurchase(plan);
    //     history.push('/billing/purchase');
    // }, [history, navigateToPurchase]);

    const onUnsubscribe = useCallback(() => {
        getBillingInfo();
    }, [getBillingInfo]);

    useEffect(() => {
        setBillingLoading(false)
        const fetchPricing = async () => {
            setBillingLoading(true)
            const countriesBillingMap = await BillingService.getSMSPricing()
            setBillingLoading(false)
            const sortedCountryBillingMap = countriesBillingMap.sort(function (a, b) {
                if (a.countryName < b.countryName) { return -1; }
                if (a.countryName > b.countryName) { return 1; }
                return 0;
            });
            setCountryBillingMap(sortedCountryBillingMap)
            UtilService.getIpInfo().then(resp => {
                const selectedCountry = resp && (countriesBillingMap.find(country => (country.countryISOCode.toLowerCase() === resp.country.toLowerCase())))
                setCountryDetails({ value: selectedCountry.credits, country: { name: selectedCountry.countryName, code: selectedCountry.countryISOCode.toLowerCase() }, notes: selectedCountry.notes });
            });
        }
        fetchPricing();

    }, [])

    const onDropToggle = useCallback((e) => {
        const countryName = e.target.id;
        const selectedCountry = countryBillingMap.find(country => (country.countryName === countryName));
        setCountryDetails({ value: selectedCountry.credits, country: { name: countryName, code: selectedCountry.countryISOCode.toLowerCase() }, notes: selectedCountry.notes });
        if (filterTriggered !== "") {
            setFilterTriggered("");
        }
        setSelector(!selector);

    }, [setSelector, setCountryDetails, setFilterTriggered, countryBillingMap, selector])

    const onDropDown = useCallback(() => {
        setSelector(!selector);
        setFilterCountries(countryBillingMap)
    }, [setSelector, selector, setFilterCountries, countryBillingMap])

    const onSMSCountryInput = useCallback((e) => {
        const searchCountry = e.target.value.toLowerCase();
        setFilterTriggered(searchCountry);
        const filteredCountries = filterTriggered ? countryBillingMap.filter(country => country.countryName.toLowerCase().includes(filterTriggered)) : countryBillingMap;
        setFilterCountries(filteredCountries)
    }, [setFilterCountries, setFilterTriggered, filterTriggered])

    return (

        <>
            {billingLoading ? <LoadingPaperPlane /> :

                <Col className="mt-3 px-0">

                    <div className="d-flex justify-content-center align-items-center">
                        <div className="btn-group" role="group">
                            <button id="sms" onClick={onToggleTransport} type="button" className={`btn btn-${selectedTransport === transportSMS ? "primary" : "secondary"}`}>SMS</button>
                            <button id="email" onClick={onToggleTransport} type="button" className={`btn btn-${selectedTransport === transportEmail ? "primary" : "secondary"}`}>Email</button>
                        </div>
                    </div>

                    <Row className="justify-content-center mt-3">

                        {selectedTransport === transportSMS && <Col lg={12} className="my-3 px-4 text-right">
                            <small>Send messages to </small>
                            <div ref={countryDrop} className="dropdown d-inline">
                                <button className="btn btn-light sms-country-selector border-0" type="button" onClick={onDropDown}>
                                    <div className="d-flex align-items-center text-truncate text-dark px-2 my-auto">
                                        <div className={`d-inline-block mr-2 sms-country-selector-flag ${countryDetails.country.code}`}></div>
                                        <div className="text-truncate sms-country-selector-text">{countryDetails.country.name}</div>
                                        <FaIcon className="ml-2" icon={selector ? faChevronUp : faChevronDown} />
                                    </div>
                                </button>
                                <ul className={`country dropdown-menu scrollable-menu ${selector ? "show" : "hide"}`}>
                                    <li className="text-dark px-3 my-auto">
                                        <input className="border-0 w-100 sms-country-selector-input" type="text" autoComplete="off" placeholder="Search.." id="sms-country-search" autoFocus onChange={onSMSCountryInput} />
                                    </li>
                                    {filterCountries.map(({ countryName, countryISOCode }, index) => {
                                        return (
                                            <li className="sms-country-selector-item text-dark px-3 my-auto" onClick={onDropToggle} key={index} id={countryName} value={countryName}><div className={`d-inline-block mr-2 sms-country-selector-flag ${countryISOCode.toLowerCase()}`}></div>{countryName}</li>
                                        );
                                    }
                                    )}
                                </ul>
                            </div>
                            <small className="sms-country-selector-text ml-2"><strong>{countryDetails.value * (1)}</strong> Credits / SMS</small>
                        </Col>}

                        <Col lg={12}>

                            {selectedTransport !== transportSMS ?
                                <Card className="border-0 mx-1">
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-left">
                                                {selectedTransport === transportSMS ? <><small>Monthly Subscribed Credits</small>
                                                    <br />
                                                    <small className="extra-small text-muted">SMS credits come from the monthly subscription plan</small></> :
                                                    <small>Total Remaining Email Credits</small>}
                                                <p className="font-weight-bold mb-0">
                                                    {selectedTransport === transportSMS ?
                                                        smsCreditsOnDemand + smsCreditsPlan :
                                                        emailCreditsOnDemand + emailCreditsPlan
                                                    }
                                                </p>

                                            </div>


                                            <div className="text-center">
                                                <small>Pay-As-You-Go Credits</small>
                                                <p className="font-weight-bold mb-0">
                                                    {selectedTransport === transportSMS ?
                                                        smsCreditsOnDemand :
                                                        emailCreditsOnDemand
                                                    }
                                                </p>

                                            </div>
                                            <div className="text-center">
                                                <small>Monthly Subscribed Credits</small>
                                                <p className="font-weight-bold mb-0">
                                                    {selectedTransport === transportEmail ?
                                                        emailCreditsPlan :
                                                        smsCreditsPlan
                                                    }
                                                </p>

                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                : null}

                        </Col>
                    </Row>

                    <BillingPackageList plans={store.billing.plans} selectedTransport={selectedTransport} navigateToPurchase={navigateToPurchase} currentSubscriptions={store.billing.subscriptions} onUnsubscribePackage={onUnsubscribe} selectedCountry={countryDetails} smsCreditsOnDemand={smsCreditsOnDemand} smsCreditsPlan={smsCreditsPlan} getBillingInfo={getBillingInfo} />

                </Col>
            }
        </>
    )
}
export default BillingPackages;