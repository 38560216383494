import React from 'react';
import {
    Form, Button
} from '@shoutout-labs/shoutout-themes';
import Constants from './../../Constants';

const SendEmailMessage = ({ body, subject,history, emailSenderId, toAddress,actionFrom,changeSenderId,senderIds ,onChangeToAddress,hideSendMessage}) => {
    return (<Form>
                <Form.Group controlId="fromEmail">
                    <Form.Label>
                        From
                    </Form.Label>
                    <Form.Control as="select" onChange={(e) => { changeSenderId(e.target.value, Constants.TRANSPORT_EMAIL) }}
                        value={emailSenderId} required disabled={actionFrom === "campaigns"}>
                        {senderIds.EMAIL && senderIds.EMAIL.map(item => {
                            return item.status === 1 ? <option key={item.senderId} value={item.senderId}>{item.senderId}</option> : null;
                        })}
                    </Form.Control>
                    {/* helpblock was here */}
                    <div className="text-center">
                        <small><Button variant="link" size="xsmall" onClick={(e) => { e.preventDefault(); history.push("/senders"); hideSendMessage() }}>
                            Request your Sender ID</Button> to send messages from your business name.
                        </small>
                    </div>
                </Form.Group>
                <Form.Group controlId="toEmail">
                    <Form.Label>
                         To
                    </Form.Label>
                    <Form.Control
                        placeholder="To Email address" value={toAddress} onChange={(e) => { onChangeToAddress(e.target.value) }} disabled={actionFrom === "people"} />
                    </Form.Group>
                {actionFrom !== "campaigns"?<Form.Group controlId="EmailBody">
                    <Form.Label>
                        Message
                    </Form.Label>
                    <Form.Control as="textarea"
                        placeholder="Hello!"
                        required value={body} />
                    </Form.Group>:null
                }
            </Form>);
        };

export default SendEmailMessage;