import React from 'react';
import { Popover, OverlayTrigger, FaIcon } from '@shoutout-labs/shoutout-themes';
import { faInfoCircle } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';

const popover = (
    <Popover id="popover">

        <Popover.Content>
            You need to verify your phone number in order to do this action
      </Popover.Content>
    </Popover>
);

const AccountNotVerifiedNotificationButton = ({ show }) => {
    if (show) {
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={popover}>
                <div className="btn btn-link no-decoration text-secondary">
                    <FaIcon icon={faInfoCircle} size="sm"/>
                </div>
            </OverlayTrigger>
        )
    }
    return null;
}
export default AccountNotVerifiedNotificationButton;