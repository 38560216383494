import React, { Component } from "react";
import {Link} from 'react-router-dom';
import { AccountService, ShoutOUTService, UtilService } from "./../../services/Services";
import {
    Table,
    Button,
    Row,
    Col,
    Card,
    Modal,
    ModalBody,
    ModalFooter,
    Avatar,
    OverlayTrigger,
    Tooltip,
    Badge,
    FaIcon,
    FileUploader,
    CardDeck,
    Tabs,
    Tab
} from '@shoutout-labs/shoutout-themes';
import SenderRequest from "./../sender_request/SenderRequest";
import CopyToClipboard from "react-copy-to-clipboard";
import { notify } from "react-notify-toast";
import { faPhoneAlt, faEnvelope, faCommentAlt, faTrash, faUpload, faTimes, faEye, faCopy, faKey, faUsers } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import Constants from './../../Constants';
import { ConfirmBox } from './../utils/UtilComponents';

import { LoadingComponent } from './../utils/UtilComponents';
import UserContext from './../../contexts/UserContext';
import AddTeamMember from './AddTeamMember';
import AccountNotVerifiedNotificationButton from './../utils/AccountNotVerifiedNotificationButton';
import "./MyProfile.css";





class MyProfile extends Component {

    constructor() {
        super();
        this.state = {
            showSenderReqModal: false,
            showApiKeyModal: false,
            showTeamMembersModal: false,
            showTeamMemberLoader: false,
            showTeamMemberCreateLoader: true,
            showTeamMemberDeleteLoader: false,
            showApiKeyLoader: false,
            showCreateApiKeyLoader: true,
            senderIdType: Constants.TRANSPORT_SMS,
            showAccountDeleteConfirm: false,
            showSenderIdDeleteConfirm: false,
            isSendingDeleteRequest: false,
            showUploadAgreement: false,
            agreementFileName: '',
            uploadingAgreement: false,
            downloadingAgreementId: '',

            apiKeys: [],
            teamMembers: []

        };
        this.shoutout = new ShoutOUTService();
        this.onSubmit = this.onSubmit.bind(this);
        this.showApiKey = this.showApiKey.bind(this);
        this.closeApiKey = this.closeApiKey.bind(this);
        this.showSenderRequest = this.showSenderRequest.bind(this);
        this.closeSenderRequest = this.closeSenderRequest.bind(this);
        this.showAddMemberForm = this.showAddMemberForm.bind(this);
        this.closeTeamMemberForm = this.closeTeamMemberForm.bind(this);


        this._gotoBilling = this._gotoBilling.bind(this);

        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this._onClickDeleteAccount = this._onClickDeleteAccount.bind(this);
        this._hideAccountDeleteConfirm = this._hideAccountDeleteConfirm.bind(this);
        this._deleteAccount = this._deleteAccount.bind(this);

        this._deleteSenderId = this._deleteSenderId.bind(this);
        this._deleteEmailSenderId = this._deleteEmailSenderId.bind(this);

        this._onClickDeleteSenderId = this._onClickDeleteSenderId.bind(this);
        this._hideSenderIdDeleteConfirm = this._hideSenderIdDeleteConfirm.bind(this);

        this._onClickDeleteEmailSenderId = this._onClickDeleteEmailSenderId.bind(this);
        this._hideEmailSenderIdDeleteConfirm = this._hideEmailSenderIdDeleteConfirm.bind(this);

        this._closeUploadAgreementModal = this._closeUploadAgreementModal.bind(this);
        this._showUploadAgreement = this._showUploadAgreement.bind(this);
        this._onDrop = this._onDrop.bind(this);
        this._removeAgreementFile = this._removeAgreementFile.bind(this);
        this._onClickUploadAgreement = this._onClickUploadAgreement.bind(this);
        this._onClickDownloadAgreement = this._onClickDownloadAgreement.bind(this);
    }

    onClickDeleteTeamMember = (event) => {

        this.setState({ showDeleteModal: true, userId: Number(event.currentTarget.dataset.userid) });
    }

    closeDeleteModal() {
        this.setState({ showDeleteModal: false });
    }

    _gotoBilling() {
        this.props.history.push('/billing');
    }


    closeApiKey() {
        this.setState({ showApiKeyModal: false });
    }

    showSenderRequest(senderIdType) {
        this.setState({ showSenderReqModal: true, senderIdType });
    }

    closeSenderRequest(result) {
        this.setState({ showSenderReqModal: false, warning: '' });
        if (result) {
            this.props.sentSenderIdRequest(result);
        }
    }

    //update modal state
    showApiKey(key) {
        this.setState({ hiddenKey: key, showApiKeyModal: true });
    }

    onSubmit(event) {
        //create new api key
        this.setState({ showCreateApiKeyLoader: false });
        this.shoutout.getNewApiKey().then(function (createApiKey) {
            notify.show("Api key created", "success");
            let apiKeysRaw = this.state.apiKeysRaw;
            apiKeysRaw.push(createApiKey);
            this.setState({
                apiKeys: this.generateRows(apiKeysRaw),
                apiKeysRaw: apiKeysRaw,
                showCreateApiKeyLoader: true
            });
        }.bind(this)).catch(function (ex) {
            this.setState({ showCreateApiKeyLoader: true });
            notify.show("Can't create Api key", "error");
            console.error('parsing failed', ex);
        });
    }

    showAddMemberForm(event) {
        this.setState({ showTeamMembersModal: true });
    }

    closeTeamMemberForm() {
        this.setState({ showTeamMembersModal: false });
    }

    deleteTeamMembers = () => {
        const ctx = this;
        this.setState({ showTeamMemberDeleteLoader: true });
        const { userId } = this.state;
        this.shoutout.deleteTeamMember(userId).then(() => {
            notify.show("Member deleted", "success");
            ctx.setState({ showDeleteModal: false, showTeamMemberDeleteLoader: false, teamMembers: ctx.state.teamMembers.filter(({ userId: id }) => id !== userId) })

        }).catch(function (ex) {
            notify.show("Can't delete member", "error");
            ctx.setState({ showTeamMemberDeleteLoader: false });
            console.error('parsing failed', ex)
        });
    }

    componentDidMount() {
        let ctx = this;
        //listed created api keys
        ShoutOUTService.getCreatedApiKeys().then(function (listApiKey) {
            ctx.setState({
                apiKeys: ctx.generateRows(listApiKey),
                apiKeysRaw: listApiKey,
                showCreateApiKeyLoader: true,
                showApiKeyLoader: true
            });
        }).catch(function (ex) {
            ctx.setState({ showApiKeyLoader: true });
            console.error('parsing failed', ex);
        });


        //listed team members
        ctx.shoutout.getTeamMembers().then(function (listMembers) {
            ctx.setState({ teamMembers: listMembers, showTeamMemberLoader: true });
        }).catch(function (ex) {
            ctx.setState({ showTeamMemberLoader: true });
            console.error('parsing failed', ex);
        });
    }

    _onClickDeleteAccount(e) {
        e.stopPropagation();
        this.setState({ showAccountDeleteConfirm: true });
    }
    _hideAccountDeleteConfirm() {
        this.setState({ showAccountDeleteConfirm: false });
    }

    _deleteAccount() {
        this.setState({ isSendingDeleteRequest: true });
        AccountService.deleteUserAccount().then(res => {
            this.props.showAlert("Success", "Your account will be deleted. This process may take few days. We will let you know once completed via an Email.");
            this.setState({ isSendingDeleteRequest: false });
            this._hideAccountDeleteConfirm();
        }, err => {
            this.props.showAlert("Couldn't Delete", "Unable to process your request at the moment. Try again.", "danger");
            this._hideAccountDeleteConfirm();
            this.setState({ isSendingDeleteRequest: false });
        });

    }
    // ------------------------------------------------------------------------------

    _onClickDeleteEmailSenderId(e) {
        this.senderId = e.target.dataset['id'];
        e.stopPropagation();
        this.setState({ showEmailSenderIdDeleteConfirm: true });
    }

    _hideEmailSenderIdDeleteConfirm() {
        this.setState({ showEmailSenderIdDeleteConfirm: false });
    }
    // Deletes Email Sender Id
    _deleteEmailSenderId(e) {
        this.setState({ isSendingDeleteRequest: true });
        let senderId = this.senderId;
        AccountService.deleteSenderId(senderId).then(res => {
            this.props.showAlert("Success", "Your Email Sender ID has been deleted");
            this.setState({ isSendingDeleteRequest: false });
            this._hideEmailSenderIdDeleteConfirm();
            this.senderId = '';
            this.props.sentSenderIdRequest();
        }, err => {
            this.props.showAlert("Couldn't Delete", "Unable to process your request at the moment. Try again.", "danger");
            this._hideEmailSenderIdDeleteConfirm();
            this.setState({ isSendingDeleteRequest: false });
            this.senderId = '';
        });

    }

    // ------------------------------------------------------------------------------
    _onClickDeleteSenderId(e) {
        this.senderId = e.target.dataset['id'];
        e.stopPropagation();
        this.setState({ showSenderIdDeleteConfirm: true });
    }

    _hideSenderIdDeleteConfirm() {
        this.setState({ showSenderIdDeleteConfirm: false });
    }
    // Deletes Sender Id
    _deleteSenderId(e) {
        this.setState({ isSendingDeleteRequest: true });
        let senderId = this.senderId;
        AccountService.deleteSenderId(senderId).then(res => {
            this.props.showAlert("Success", "Your Sender ID has been deleted");
            this.setState({ isSendingDeleteRequest: false });
            this._hideSenderIdDeleteConfirm();
            this.senderId = '';
            this.props.sentSenderIdRequest();
        }, err => {
            this.props.showAlert("Couldn't Delete", "Unable to process your request at the moment. Try again.", "danger");
            this._hideSenderIdDeleteConfirm();
            this.setState({ isSendingDeleteRequest: false });
            this.senderId = '';
        });

    }

    _showUploadAgreement(e) {
        this.selectedSenderId = e.target.dataset['id'];
        this.setState({ showUploadAgreement: true });
    }
    _closeUploadAgreementModal() {
        this.setState({ showUploadAgreement: false });
        this._removeAgreementFile();
    }


    _onDrop(files) {
        this.file = files[0] || '';
        this.setState({
            agreementFileName: files.length > 0 ? files[0].name : ''
        });

    }

    _removeAgreementFile() {
        this.file = '';
        this.setState({ agreementFileName: '' });
    }

    _onClickUploadAgreement(e) {
        e.stopPropagation();

        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
            this.setState({ uploadingAgreement: true });
            ShoutOUTService.uploadSmsSenderIdAgreement(this.selectedSenderId, reader.result).then(res => {
                notify.show("Agreement uploaded successfully. Waiting for review", "success");
                this._closeUploadAgreementModal();
                this.setState({ uploadingAgreement: false, agreementFileName: '' });
                this.selectedSenderId = '';
                this.props.sentSenderIdRequest();
            }, err => {
                notify.show("Error uploading agreement. Try again later", "error");
                this.setState({ uploadingAgreement: false });
            });
        };
        reader.onerror = error => {
            notify.show("Error uploading agreement. Try again later", "error");
        };
    }

    _onClickDownloadAgreement(e) {
        e.stopPropagation();
        let ctx = this;
        let senderId = e.target.dataset['id'];
        let senderIdKeyword = e.target.dataset['name'];
        this.selectedSenderId = senderId;
        this.setState({ downloadingAgreementId: senderId });
        ShoutOUTService.downloadSmsSenderIdAgreement(senderId).then(res => {
            let filename = senderIdKeyword + ".pdf";
            fetch("data:application/pdf;base64," + res)
                .then(function (res) { return res.blob(); })
                .then(function (blob) {
                    if (navigator.msSaveBlob) { // IE 10+
                        navigator.msSaveBlob(blob, filename);
                    } else {
                        let link = document.createElement("a");
                        if (link.download !== undefined) { // feature detection
                            // Browsers that support HTML5 download attribute
                            let url = URL.createObjectURL(blob);
                            link.setAttribute("href", url);
                            link.setAttribute("download", filename);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }
                    ctx.setState({ downloadingAgreementId: "" });
                }).catch(err => {
                    console.error(err);
                    notify.show("Error downloading the report. Please retry.", "error");
                    ctx.setState({ downloadingAgreementId: "" });
                });


        }, err => {
            notify.show("Error downloading the report. Please retry.", "error");
            this.setState({ downloadingAgreement: "" });
        });
    }

    onDropRejected = ({ type }) => {
        if (type === "large file") {
            notify.show("Selected file is too large. Maximum allowed size is 3MB.", "error");
        }
        else if (type === "invalid file")
            notify.show("Invalid file type. Please select an image or a PDF", "error");
        else {
            notify.show("Invalid file selected.", "error");
        }
    }

    onAddTeamMember = (addTeamMemberResponse) => {
        this.setState({ teamMembers: [...this.state.teamMembers, addTeamMemberResponse], showTeamMembersModal: false });
    }

    render() {
        const { smsSenderIds, emailSenderIds } = this.props;

        return (

            <UserContext.Consumer>
                {({ name, email, mobile, smsCreditsOnDemand, smsCreditsPlan, emailCreditsOnDemand, emailCreditsPlan, organization, emailVerified }) => (
                    <div className="container-fluid px-4 padding-top-container onload-transition">
                        <div className="mt-2">
                            <h4 className="font-weight-bold">My Profile</h4>
                            {/* <small className="text-muted"><Link to="/">Dashboard</Link><span className="text-capitalize">{UtilService.setBreadCrumb(this.props.location.pathname)}</span></small> */}
                        </div>
                        <Row className="pt-3">
                            <Col lg={12} className="mb-3">
                                <CardDeck>
                                    <Card className="border-0">
                                        <Card.Body>

                                            <h6 className="font-weight-bold">About</h6>
                                            <hr className="dashed" />
                                            <div className="d-flex align-items-center">
                                                <Avatar className="people-page mr-2" name={(name || 'unknown').charAt(0)} textSizeRatio={2} size={50} />
                                                <h5 className="mb-0">{name}</h5>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="text-left my-3 border-muted border-right pr-3">
                                                    <h6 className="mb-0"><FaIcon className="mr-2" icon={faPhoneAlt} /> Phone</h6>
                                                    <small>{mobile || 'Unknown'}</small>
                                                </div>
                                                <div className="text-left my-3 pl-3">
                                                    <h6 className="mb-0"><FaIcon className="mr-2" icon={faEnvelope} /> Email</h6>
                                                    <small>{email || 'Unknown'}</small>
                                                </div>
                                            </div>
                                            <hr className="dashed mt-0" />
                                            <div>
                                                <h6 className="font-weight-bold text-danger mb-0">Danger Zone</h6>
                                                <p className="credits-sub-text my-1">The actions inside this area are dangerous and can not be undone.</p>
                                                <Button className="float-right border-0" size="sm" variant="danger" onClick={this._onClickDeleteAccount}>Delete My Account</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>




                                    <Card className="border-0">
                                        <Card.Body>

                                            <h6 className="font-weight-bold">Plan</h6>

                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h1 className="font-weight-bold text-success mb-0">{emailCreditsPlan}</h1>
                                                    <h6 className="mb-0">Emails</h6>
                                                    <span className="credits-sub-text">Remaining from Plan</span>
                                                </div>
                                                <div className="text-right">
                                                    <h1 className="font-weight-bold text-success mb-0">{emailCreditsOnDemand}</h1>
                                                    <h6 className="mb-0">Emails</h6>
                                                    <span className="credits-sub-text">Remaining Additionally</span>
                                                </div>
                                            </div>
                                            <hr className="my-2 dashed" />
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h1 className="font-weight-bold text-success mb-0">{smsCreditsPlan}</h1>
                                                    <h6 className="mb-0">SMS Credits</h6>
                                                    <span className="credits-sub-text">Remaining from SMS Plan</span>
                                                </div>

                                                <div className="text-right">
                                                    <h1 className="font-weight-bold text-success mb-0">{smsCreditsOnDemand}</h1>
                                                    <h6 className="mb-0">SMS Credits</h6>
                                                    <span className="credits-sub-text">Remaining Additionally</span>
                                                </div>
                                            </div>
                                            <Button className="mt-2 float-right border-0" name="creditsButton" size="sm" variant="dark" onClick={this._gotoBilling}>Buy More</Button>
                                        </Card.Body>
                                    </Card>

                                    <Card className=" border-0">
                                        <Card.Body>

                                            <Table className="profile-assets-table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center py-3">
                                                            <small className="text-muted"><FaIcon className="mr-2" icon={faCommentAlt} />SMS Sender IDs</small>
                                                            <h1 className="display-3 text-dark mb-0">{smsSenderIds.length < 10 ? <>0{smsSenderIds.length}</> : smsSenderIds.length}</h1>
                                                        </td>
                                                        <td className="text-center py-3">
                                                            <small className="text-muted"><FaIcon className="mr-2" icon={faEnvelope} /> Email Sender IDs</small>
                                                            <h1 className="display-3 text-dark mb-0">{emailSenderIds.length < 10 ? <>0{emailSenderIds.length}</> : emailSenderIds.length}</h1>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="text-center py-3">
                                                            <small className="text-muted"><FaIcon className="mr-2" icon={faKey} />API Keys</small>
                                                            <h1 className="display-3 text-dark mb-0">{this.state.apiKeys.length < 10 ? <>0{this.state.apiKeys.length}</> : this.state.apiKeys.length}</h1>
                                                        </td>

                                                        <td className="text-center py-3">
                                                            <small className="text-muted"><FaIcon className="mr-2" icon={faUsers} />Team Members</small>
                                                            <h1 className="display-3 text-dark mb-0">{this.state.teamMembers.length < 10 ? <>0{this.state.teamMembers.length}</> : this.state.teamMembers.length}</h1>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </Card.Body>
                                    </Card>

                                </CardDeck>
                            </Col>


                            <Col className="mt-3">

                                <Tabs defaultActiveKey="api" id="profile-assets" className="border-0 my-2 ml-n3">
                                    <Tab eventKey="api" title="API Keys">
                                        <Card className="border-0">
                                            <Card.Body>
                                                <div className="position-absolute top-right mt-n45">
                                                    <Button variant="dark" size="sm" type="submit" disabled={!emailVerified}
                                                        onClick={this.onSubmit}>Create New</Button>
                                                    <AccountNotVerifiedNotificationButton show={!emailVerified} />
                                                    {!this.state.showCreateApiKeyLoader ? <LoadingComponent /> : null}
                                                </div>
                                                <Row>
                                                    <Col xs={12} md={12} className="profile-assets-view">
                                                        {this.state.showApiKeyLoader ?
                                                            <Table striped hover responsive className="no-border">
                                                                <tbody>{this.state.apiKeys}</tbody>
                                                            </Table> : <LoadingComponent />
                                                        }
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                    <Tab eventKey="team" title="Team Members">
                                        <Card className="border-0">
                                            <Card.Body>
                                                <div className="position-absolute top-right mt-n45">
                                                    <Button variant="dark" size="sm" type="submit"
                                                        onClick={this.showAddMemberForm} disabled={!emailVerified}>Add New</Button>
                                                    <AccountNotVerifiedNotificationButton show={!emailVerified} />
                                                </div>


                                                <Row>
                                                    <Col xs={12} md={12} className="profile-assets-view">
                                                        {this.state.showTeamMemberLoader ? <Table striped hover responsive className="no-border">
                                                            <tbody>
                                                                {
                                                                    this.state.teamMembers.map(({ userId, name, email }, index) => {

                                                                        return (<tr key={email}>
                                                                            <td className="index_td">{index + 1}</td>
                                                                            <td>{name}</td>
                                                                            <td>{email}</td>
                                                                            <td className="index_td">
                                                                                <Button size="sm" variant="outline-danger" data-userid={userId} onClick={this.onClickDeleteTeamMember}>
                                                                                    <FaIcon className="mr-2" icon={faTrash} />Delete
                                                                                </Button>
                                                                            </td>
                                                                        </tr>);
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table> : <LoadingComponent />}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab>
                                </Tabs>

                                <ConfirmBox show={this.state.showApiKeyModal} onHide={this.closeApiKey} confirmCallback={"Copy"}
                                    title="API Key" message={this.state.hiddenKey}
                                    variant="dark" />


                                <AddTeamMember showTeamMembersModal={this.state.showTeamMembersModal} closeTeamMemberForm={this.closeTeamMemberForm} onAddTeamMember={this.onAddTeamMember} />


                                <SenderRequest showModal={this.state.showSenderReqModal} company={organization} userName={name} onCloseCallback={this.closeSenderRequest} senderIdType={this.state.senderIdType} />

                                <Modal id="modal" bsPrefix="modal" show={this.state.showUploadAgreement}
                                    onHide={this._closeUploadAgreementModal}>

                                    <Modal.Header closeButton>
                                        <h6 className="mb-0 font-weight-bold">Upload Signed Agreement</h6>
                                    </Modal.Header>

                                    <ModalBody bsPrefix="modal-body">

                                        {this.state.agreementFileName === '' ?
                                            <FileUploader
                                                accept="image/*,application/pdf"
                                                onDropRejected={this.onDropRejected}

                                                multiple={false}
                                                onDropAccepted={this._onDrop}
                                            /> :


                                            <div><p>Click upload to continue</p><span><strong> {this.state.agreementFileName}&nbsp;</strong><Button variant="link"
                                                onClick={this._removeAgreementFile}><FaIcon
                                                    icon={faTimes}
                                                /></Button>&nbsp;&nbsp;</span>
                                            </div>
                                        }
                                    </ModalBody>
                                    <ModalFooter className="text-right">
                                        <Button className="mr-2" variant="dark" size="sm" onClick={this._closeUploadAgreementModal} disabled={this.state.uploadingAgreement}>Close</Button>
                                        <Button variant="primary" size="sm" onClick={this._onClickUploadAgreement} disabled={this.state.uploadingAgreement || this.state.agreementFileName === ''}>{this.state.uploadingAgreement ? "Uploading..." : "Upload"}</Button>
                                    </ModalFooter>
                                </Modal>


                                <ConfirmBox show={this.state.showDeleteModal} onHide={this.closeDeleteModal} confirmCallback={this.deleteTeamMembers}
                                    title="Are you sure?" message="You are about to delete your team member. This action can not be undone. Are you sure?"
                                    disableActions={this.state.showTeamMemberDeleteLoader} variant="danger" />
                            </Col>
                            <ConfirmBox show={this.state.showAccountDeleteConfirm} onHide={this._hideAccountDeleteConfirm} confirmCallback={this._deleteAccount}
                                title="Are you sure?" message="You are about to delete your account. This action can not be undone. Are you sure?"
                                disableActions={this.state.isSendingDeleteRequest} variant="danger" />

                            <ConfirmBox show={this.state.showSenderIdDeleteConfirm} onHide={this._hideSenderIdDeleteConfirm} confirmCallback={this._deleteSenderId}
                                title="Are you sure?" message="You are about to delete your Sender ID. This action isn't reversible. Are you sure?"
                                disableActions={this.state.isSendingDeleteRequest} variant="danger" />

                            <ConfirmBox show={this.state.showEmailSenderIdDeleteConfirm} onHide={this._hideEmailSenderIdDeleteConfirm} confirmCallback={this._deleteEmailSenderId}
                                title="Are you sure?" message="You are about to delete your Email Sender ID. This action isn't reversible. Are you sure?"
                                disableActions={this.state.isSendingDeleteRequest} variant="danger" />
                        </Row>

                    </div>
                )

                }

            </UserContext.Consumer>
        );
    }

    copySuccess = () => {
        notify.show("Copied", "success")
    }
    //update api key table
    generateRows(logs) {
        let index = 0;
        let ctx = this;
        return logs.map(function (item) {
            index++;
            let api = item.key;
            return <tr key={item.id}>
                <td className="index_td">{index}</td>
                <td>API Key is hidden</td>
                <td className="index_td">
                    <OverlayTrigger placement="auto" overlay={<Tooltip id="tooltip">Show</Tooltip>}>
                        <span className="icon"><FaIcon icon={faEye}
                            onClick={ctx.showApiKey.bind(this, api)} /></span>
                    </OverlayTrigger>
                </td>
                <td className="index_td">
                    <CopyToClipboard text={api}
                        onCopy={ctx.copySuccess}>

                        <span className="icon"><FaIcon icon={faCopy} /></span>

                    </CopyToClipboard>
                </td>
            </tr>;
        });
    }

    //update sender id table
    generateRows2(logs) {
        let index = 0;
        return logs.map(function (item) {
            index++;
            return <tr key={item.senderId}>
                <td className="index_td">{index}</td>
                <td>{item.senderId}</td>
            </tr>;
        });
    }

    //update team members table
    // generateRows3(logs) {
    //     let ctx = this;
    //     let index = 0;
    //     return logs.map(function (item) {
    //         let userId = item.userId;
    //         index++;
    //         return <tr key={item.userId}>
    //             <td className="index_td">{index}</td>
    //             <td>{item.name}</td>
    //             <td>{item.email}</td>
    //             <td className="index_td"><Button size="sm" variant="outline-danger" onClick={ctx.showDeleteModal.bind(this, userId)}><FaIcon className="mr-2" icon={faTrash} />Delete</Button>
    //             </td>
    //         </tr>;
    //     });
    // }
}

export default MyProfile;
