import React, { lazy, useContext, useMemo } from 'react';
import SideNavigationBar from '../navigation/SideNavigationBar';
// import OnboardSection from '../user_onboarding/OnboardSection';
import SendMessageContainer, { AlertBoxContainer, EmailPreviewContainer } from './../../redux/containers/UtilContainer';
import UserContext from './../../contexts/UserContext';
import OnBoardingContext, { StepNames } from './../../contexts/OnboardingContext';

import PeopleImportContainer from './../../redux/containers/PeopleImportContainer';
import { AddOrEditPersonContainer } from './../../redux/containers/PeopleListContainer';
import { Switch, Route } from 'react-router-dom';
import PeoplePage from './../people/PeoplePage';
import { PersonProfileContainer } from './../../redux/containers/PeopleListContainer';
import EngagementModule from './../engagement_module/EngagementModule';
import { MyProfileContainer, SendersContainer } from './../../redux/containers/AppContainer';
import BillingPageContainer, { BillingPurchasePlanContainer, BillingSuccessContainer } from './../../redux/containers/BillingContainer';
import AuthRedirectRoute from './../../AuthRedirectRoute';
import NotificationBar from './../../NotificationBar';
import Constants from '../../Constants';
import OnboardingBanner from '../dashboard_module/OnboardingBanner';
import Senders from './../senders/Senders';
import { useHistory } from 'react-router-dom';
// import MobileVerifyBanner from '../dashboard_module/MobileVerifyBanner';
import { OnboardingContextContainer } from '../../contexts/OnboardingContext';
import { HomeContextContainer } from '../../contexts/HomeContext';
import MainNavigation from './../navigation/MainNavigation';
const DashboardModule = lazy(() => import('./../dashboard_module/DashboardModule'));
const AdministratorModule = lazy(() => import('./../administrator_module/AdministratorModule'));
const AnalyticsModule = lazy(() => import('./../analytics_module/AnalyticsModule'));
const LoyaltyModule = lazy(() => import('./../loyalty_module/LoyaltyModule'));
const IntegrationsModule = lazy(() => import('./../integrations/IntegrationsPage'));


const HomeWrapper = () => {
    return (
        <HomeContextContainer>
            <OnboardingContextContainer>
                <Home />
            </OnboardingContextContainer>
        </HomeContextContainer>
    );
}
const Home = ({location}) => {
    const { scopes } = useContext(UserContext);
    const { showOnboarding } = useContext(OnBoardingContext);
    const history = useHistory();

    return (
        <>
          <MainNavigation/>
            <div className="home-wrapper d-flex">

                <SideNavigationBar />

                <div className="overflow-auto w-100">
                    {showOnboarding && <OnboardingBanner />}
                    {/* { 
                    showBanner && 
                    <OnboardingBanner steps={steps} nextStep={nextStep}/> 
                }
                 */}
                    {/* {
                    showVerifyBanner && 
                    <MobileVerifyBanner/>
                } */}
                    <NotificationBar />

                    <Switch>
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].read &&

                            <Route name="People" exact path="/people" component={PeoplePage} />
                        }
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].read &&
                            <Route name="Profile" exact path="/people/:contactId" component={PersonProfileContainer} />
                        }

                        <Route exact name="My Profile" exact path="/my-profile" component={MyProfileContainer} />

                        <Route name="Senders" path="/senders" component={SendersContainer} />

                        <Route name="Engagement" path="/engagement" component={EngagementModule} />

                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.LOYALTY_ANALYTICS.moduleId].read &&
                            <Route exact={true} name="Analytics Module" path="/analytics" component={AnalyticsModule} />
                        }
                        <Route name="Loyalty Module" path="/loyalty" component={LoyaltyModule} />

                        <AuthRedirectRoute name="Administrator Module" path="/administrator" component={AdministratorModule} allowedRoles={["admin", "finance"]} />

                        <Route exact name="Billing" path="/billing" component={BillingPageContainer} />
                        <Route exact name="BillingSuccess" path="/billing/success" component={BillingSuccessContainer} />
                        <Route exact name="BillingPurchase" path="/billing/purchase" component={BillingPurchasePlanContainer} />
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.APPS.moduleId].read &&
                            <Route name="Integrations" path="/integrations" component={IntegrationsModule} />
                        }
                        <Route name="Dashboard" component={DashboardModule} />
                    </Switch>

                </div>
                <PeopleImportContainer />
                <AddOrEditPersonContainer />
                <SendMessageContainer history={history} />
                <AlertBoxContainer />
                <EmailPreviewContainer />
            </div>
        </>
    )

}

export default HomeWrapper;
