import React, { useContext } from 'react';
import { Button, OverlayTrigger,FaIcon } from '@shoutout-labs/shoutout-themes';
import { faInfoCircle} from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import UserContext from './../../../../contexts/UserContext';
import Constants from './../../../../Constants';
import './CampaignCreate.css';


const renderTooltip = props => (
    <div
        {...props}
        style={{
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            padding: '2px 10px',
            color: 'white',
            borderRadius: 3,
            zIndex: 2000,
            ...props.style,

        }}
    >
        You can not create a campaign till you verify the account.
    </div>
);

const CampaignCreateBtn = ({ toggleTransport, btnStyle, btnSize, history, className,transports }) => {
    const { mobileVerified } = useContext(UserContext);

    return (
        <React.Fragment>
           <Button variant="primary" disabled={!mobileVerified} className="mb-2" onClick={() => { toggleTransport(Constants.TRANSPORT_SMS); history.push('/engagement/campaigns/create/new') }}>SMS Campaign</Button>
           <Button variant="primary" disabled={!mobileVerified} className="mb-2 ml-2" onClick={() => { toggleTransport(Constants.TRANSPORT_EMAIL); history.push('/engagement/campaigns/create/new') }}>Email Campaign</Button>
            {!mobileVerified &&
                <OverlayTrigger
                    placement="left-start"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <Button variant="link"><FaIcon icon={faInfoCircle} /></Button>
                </OverlayTrigger>
            }
        </React.Fragment>

    );
}


export default CampaignCreateBtn;