import React from "react";
import {
  Button,
  Modal, Form
} from '@shoutout-labs/shoutout-themes';

const TemplateNameInput = ({ show, closeTemplateNameInput, showLoader, templateName, onChangeTemplateName, templateType, templateContent, saveTemplate }) => {
  return (
    <Modal show={show} onHide={closeTemplateNameInput}>
      <Modal.Header closeButton>
        <h6 className="mb-0 font-weight-bold">Save Template</h6>
      </Modal.Header>
      <Modal.Body>
        <form>
          <Form.Group
            controlId="template-name-input"
          >
            <Form.Label>Enter the name of your template</Form.Label>
            <Form.Control
              type="text"
              placeholder="Daily Promotion Template"
              value={templateName} onChange={(e) => {
                e.preventDefault();
                onChangeTemplateName(e.target.value);
              }}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="mr-2" variant="dark" size="sm" onClick={closeTemplateNameInput} disabled={showLoader}>Cancel</Button>
        <Button variant="primary" size="sm" onClick={(e) => {
          e.preventDefault();
          saveTemplate(templateContent, templateType, templateName);
        }} disabled={showLoader || !templateName}>{showLoader ? 'Saving...' : 'Save'}</Button>
      </Modal.Footer>

    </Modal>
  );
};

export default TemplateNameInput;
