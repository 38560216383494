import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './AddCard.css';
const AddCard = () => {

    return (
        <div className="stripe-card-container">
            <CardElement options={{
                hidePostalCode: true
            }} />
        </div>
    )
}
export default AddCard;