import React, {
    PureComponent
} from "react";
import {
    Modal
} from '@shoutout-labs/shoutout-themes';
import { TemplateGalleryContainer } from './../../../redux/containers/TemplateContainer';

class TemplateGalleryModal extends PureComponent {
    render() {
        const {
            show,
            closeGallery,
        } = this.props;
        return (<Modal show={show}
            onHide={
                closeGallery
            } size="xl">
            <Modal.Header closeButton>
                <h6 className="mb-0 font-weight-bold">Select Template</h6>
            </Modal.Header>
            <Modal.Body>
                <TemplateGalleryContainer />
            </Modal.Body>

        </Modal>);
    }
}
export default TemplateGalleryModal;