/**
 * Created by madura on 2/6/17.
 */
import React, { Component } from "react";
import {
    Button,
    Modal,
    Form,
    Badge,
    Row,
    Col,
    Alert,
    FaIcon
  } from "@shoutout-labs/shoutout-themes";
import { UtilService } from "./../../services/Services";
import lodash, { remove } from "lodash";
import {
  faPlus,
  faTimes
} from "@shoutout-labs/shoutout-themes/es/FaIconsSolid";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class CreateOrEditPersonDialog extends Component {
    constructor() {

        super();
    
        this.tagRef = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
        this.onMobileNumberChange = this.onMobileNumberChange.bind(this);
        this.addTag = this.addTag.bind(this);
        this.addTagOnEnter = this.addTagOnEnter.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleViewMore = this.toggleViewMore.bind(this);
        this.state = {
          person: { name: "", mobile_number: "", email: "", tags: [] },
          isNewPerson: true,
          mobileNumber: "",
          email: "",
          isMobileNumberValid: true,
          isEmailValid: true,
          alertVisible: false,
          isPersonValid: false,
          viewMore: false,
          customColumnNames: {},
          country: "lk",
        };
      }

   
  _init(customColumnNames) {

    this.setState({
      person: { name: "", mobile_number: "", email: "", tags: [] },
      isNewPerson: true,
      mobileNumber: "",
      email: "",
      isMobileNumberValid: true,
      isEmailValid: true,
      alertVisible: false,
      isPersonValid: false,
      viewMore: false,
      customColumnNames: customColumnNames,
    });
  }

  onSubmit() {

    let person = this.state.person;

    if (person["mobile_number"] !== "" || person["email"] !== "") {

      this.setState({ alertVisible: false });
      let person = this.state.person;

      //todo: change to assign according to user preference

      if (person["mobile_number"] !== "") {
        person["user_id"] = person["mobile_number"];
      } else {
        person["user_id"] = person["email"];
        delete person["mobile_number"];
      }

      if (person.email === "") {
        delete person.email;
      }

      this.props.onSave(this.state.person);
    } else {
      this.setState({ alertVisible: true });

    }

  }

  handleChange(event) {

    let person = this.state.person;
    let key = event.target.name;
    let stateUpdate = {};

    if (key === "email") {
      stateUpdate["email"] = event.target.value;
      stateUpdate["isEmailValid"] =
        emailRegex.test(event.target.value) || event.target.value === "";
      person[key] = stateUpdate["isEmailValid"] ? event.target.value : "";
      stateUpdate["isPersonValid"] =
        (stateUpdate["isEmailValid"] && person[key] !== "") ||
        (this.state.isMobileNumberValid &&
          this.state.person.mobile_number !== "");
    } else {
      person[key] = event.target.value;
    }

    stateUpdate["person"] = person;
    this.setState(stateUpdate);

  }

  onMobileNumberChange(status, value, countryData, number, id) {
    console.debug("Number:", status, value);
    let stateUpdate = {};
    let person = this.state.person;

    if (status) {
      const country = countryData.name.replace(/[^a-zA-Z ]/gi, "").trim();
      person["country"] = country;
      person["mobile_number"] = value;
      stateUpdate["isMobileNumberValid"] = true;
    } else {
      person["mobile_number"] = "";
      stateUpdate["isMobileNumberValid"] = value.length === 0;
    }

    stateUpdate["mobileNumber"] = value;
    stateUpdate["isPersonValid"] =
      (stateUpdate["isMobileNumberValid"] && person["mobile_number"] !== "") ||
      (this.state.isEmailValid && this.state.person.email !== "");
    stateUpdate["person"] = person;
    this.setState(stateUpdate);
  }

  addTag(e) {

    e.preventDefault();
    const person = this.state.person;
    const tagVal = this.tagRef.current.value;
    this.tagRef.current.value = "";
    if (tagVal !== "") {
      person.tags.push(tagVal);
    }
    this.setState({ person: person });
  }

  addTagOnEnter(e) {

    if (e.key === "Enter") {
      e.preventDefault();
      const person = this.state.person;
      const tagVal = this.tagRef.current.value;
      this.tagRef.current.value = "";

      if (tagVal !== "") {
        person.tags.push(tagVal);
      }

      this.setState({ person: person });

    }
  }

  removeTag(e) {

    e.preventDefault();
    const person = this.state.person;
    const tagVal = e.currentTarget.name;
    const tagPlace = person.tags.indexOf(tagVal);

    if (tagPlace !== -1) {
      person.tags.splice(tagPlace, 1);
    }

    this.setState({ person: person });

  }

  getIpInfo = (callback) => {

    UtilService.getIpInfo().then((resp) => {
      callback(resp && resp.country ? resp.country : "");
    });
  };

    toggleViewMore() {
        this.setState({ viewMore: !this.state.viewMore });
    }

    componentWillReceiveProps(nextProps) {

        let customColumnNames = lodash.omit(nextProps.customColumnNames, [
          "id",
          "created_on",
          "modified_on",
          "last_seen_on",
          "_created_on",
          "_modified_on",
          "_last_seen_on",
          "owner_id",
          "selected",
          "profileImage",
          "_email_valid",
          "_mobile_number_valid",
          "_delete_protected",
          "profile_picture",
          "facebook_id",
          "push_notification_arn",
        ]);
    
        if (nextProps.selectedPerson) {
    
          let person = lodash.merge(this.state.person, nextProps.selectedPerson);
          this.setState({
            person: person,
            isNewPerson: false,
            isPersonValid: true,
            customColumnNames: customColumnNames,
          });
    
        } else {
          this._init(customColumnNames);
        }
    
      }

      componentDidMount() {

        this.getIpInfo((country) => {
          this.setState({ country });
        });
    
      }

      _;

      render() {
        return (
          <Modal
            show={this.props.showCreatePersonDialog}
            onHide={this.props.closeCreatePersonDialog}
            size="lg"
            className="border-0"
          >
            <Modal.Header closeButton>
              <h6 className="mb-0 font-weight-bold">
                {this.state.isNewPerson ? "Create Contact" : "Edit Contact"}
              </h6>
            </Modal.Header>
            <Modal.Body>
              <div>
                {this.state.alertVisible && this.state.isNewPerson ? (
                  <Alert variant="danger">
                    <h5>
                      <strong>Mobile Number</strong> or <strong>Email</strong> is required!
                    </h5>
                  </Alert>
                ) : null}
                <Form>
                  <Form.Group controlId="name">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Eg: Sam"
                      onChange={this.handleChange}
                      value={this.state.person.name}
                    />
                  </Form.Group>
                  {(this.state?.isNewPerson || (!this.state?.isNewPerson && this.state?.person?.mobile_number !== '')) && 
                  <Form.Group
                    controlId="mobile"
                    validationState={
                      this.state.isMobileNumberValid ? null : "error"
                    }
                  >
                    <Form.Label>Mobile Number:</Form.Label>
                    <Form.MobileNumberInput
                      id="mobile"
                      disabled={!this.state.isNewPerson}
                      geoIpLookup={this.getIpInfo}
                      defaultCountry={this.state.country}
                      onPhoneNumberBlur={this.onMobileNumberChange}
                      onPhoneNumberChange={this.onMobileNumberChange}
                      inputProps={{ required: true }}
                      inputClassName={`form-control shadow-0 w-100 form-control-sm`}
                      value={
                        this.state.isNewPerson
                          ? this.state.mobileNumber
                          : this.state.person.mobile_number
                      }
                      disableCountryCode={false}
                    />
                  </Form.Group>
                }
                  <Form.Group
                    controlId="email"
                    validationState={this.state.isEmailValid ? null : "error"}
                  >
                    <Form.Label>Email:</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Eg: sam@test.com"
                      onChange={this.handleChange}
                      disabled={!this.state.isNewPerson}
                      value={
                        this.state.isNewPerson
                          ? this.state.email
                          : this.state.person.email
                      }
                    />
                  </Form.Group>
    
                  <Form.Group controlId="tags" size="sm">
                    <Form.Label>Tags:</Form.Label>
                    <Row>
                      <Col>
                        <Form.Control
                          type="tags"
                          placeholder="premium"
                          ref={this.tagRef}
                          onKeyPress={this.addTagOnEnter}
                        />
                        <small>Press Enter to add tags.</small>
                        {/* <Button className="mt-2" variant="transparent" onClick={this.addTag} size="sm"> <FaIcon className="mr-2" icon={faPlus} /> Add Tag</Button> */}
                      </Col>
                    </Row>
                    <div className="mt-2">
                      {this.state.person.tags.length ? (
                        this.state.person.tags.map((tag) => {
                          return (
                            <span key={tag}>
                              <Badge className="badge-tags px-3 mr-2 mb-2">
                                {tag}
                                {tag !== "" && (
                                  <button
                                    type="button"
                                    class="text-white btn btn-icon ml-2 p-0"
                                    aria-label="Dismiss"
                                    name={tag}
                                    onClick={this.removeTag}
                                  >
                                    <FaIcon
                                      icon={faTimes}
                                      className="cursor-pointer"
                                    />
                                </button>
                           )}

                         </Badge>
                       </span>
                     );
                   })
                 ) : (

                   <p>No tags</p>

                 )}
               </div>

             </Form.Group>
        </Form>

        {this.state.isNewPerson && (
            <div className="text-left text-muted">
                <small>
                    This contact won't receive a confirmation message from ShoutOUT. Clicking on 'Create' confirms that you're permitted to send messages to this contact.
                </small>
                <br />
                <br />
            </div>
        )}
        </div>
    </Modal.Body>
    <Modal.Footer>
    <Button
        className="mr-2"
        variant="dark"
        size="sm"
        onClick={this.props.closeCreatePersonDialog}
        disabled={this.props.showLoader}
    >
        Cancel
    </Button>
    <Button
        variant="primary"
        size="sm"
        onClick={this.onSubmit}
        disabled={!this.state.isPersonValid || this.props.showLoader}
    >
        {this.props.showLoader
            ? this.state.isNewPerson
            ? "Creating..."
            : "Saving..."
            : this.state.isNewPerson
            ? "Create"
            : "Save"}
        </Button>
    </Modal.Footer>
    </Modal>
);
}
        
       
}

export default CreateOrEditPersonDialog;