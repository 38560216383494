import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Navbar, Nav, Image, NavDropdown, Popover, Overlay, Button, FaIcon } from '@shoutout-labs/shoutout-themes';
import Avatar from 'react-user-avatar';
import Constants from './../../Constants';
import { BillingService, AnalyticsService, UtilService } from './../../services/Services';
import UserContext from './../../contexts/UserContext';
import { faChevronDown, faChevronUp, faIdBadge, faUsers, faBullhorn, faFileAlt, faSignInAlt, faInbox, faHeart, faHandshake, faChartLine, faPlug, faCogs, faBriefcase, faCreditCard, faUser, faSignOutAlt, faInfo, faComment, faLifeRing, faEnvelope, faCommentAlt } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { withRouter, NavLink, Link } from 'react-router-dom';
import './MainNavigation.css';


const MainNavigation = ({ location, history }) => {
    const [smsCreditsMenuShow, setSmsCreditsMenuShow] = useState(false);
    const [emailCreditsMenuShow, setEmailCreditsMenuShow] = useState(false);
    const [profileMenuShow, setProfileMenuShow] = useState(false);

    const [filterTriggered, setFilterTriggered] = useState("")
    const [filterCountries, setFilterCountries] = useState([])
    const [selector, setSelector] = useState(false);
    const [selectorEmail, setSelectorEmail] = useState(false);
    const [countryBillingMap, setCountryBillingMap] = useState([])
    const [countryDetails, setCountryDetails] = useState({ value: 1, country: { name: "Sri Lanka", code: "lk" } })
    // const [countryDetailsEmail, setCountryDetailsEmail] = useState({ value: 1, country: { name: "Sri Lanka", code: "lk" } })

    const smsCreditsRef = useRef();
    const emailCreditsRef = useRef();
    const profileRef = useRef();
    const node = useRef();
    const emailCountry = useRef();


    const onDropToggle = useCallback((e) => {
        const countryName = e.target.id;
        const selectedCountry = countryBillingMap.find(country => (country.countryName === countryName));
        setCountryDetails({ value: selectedCountry.credits, country: { name: countryName, code: selectedCountry.countryISOCode.toLowerCase() }, notes: selectedCountry.notes });
        if (filterTriggered !== "") {
            setFilterTriggered("");
        }
        setSelector(!selector);

    }, [setSelector, setCountryDetails, setFilterTriggered, countryBillingMap, selector])

    // const onDropToggleEmail = useCallback((e) => {
    //     const countryName = e.target.id;
    //     const selectedCountry = countryBillingMap.find(country => (country.countryName === countryName));
    //     setCountryDetailsEmail({ value: selectedCountry.credits, country: { name: countryName, code: selectedCountry.countryISOCode.toLowerCase() }, notes: selectedCountry.notes });
    //     if (filterTriggered !== "") {
    //         setFilterTriggered("");
    //     }
    //     setSelectorEmail(!selectorEmail);

    // }, [setSelectorEmail, setCountryDetailsEmail, setFilterTriggered, countryBillingMap, selectorEmail])

    // const handleClickEmail = useCallback((e) => {
    //     if (emailCountry.current !== null) {
    //         if (emailCountry.current.contains(e.target)) {
    //             return;
    //         }
    //         setSelectorEmail(false);
    //     }
    // }, [setSelectorEmail]);

    // useEffect(() => {
    //     try {
    //         if (selectorEmail) {
    //             document.addEventListener("mousedown", handleClickEmail);
    //         } else {
    //             document.removeEventListener("mousedown", handleClickEmail);
    //         }
    //     } catch (e) { }
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickEmail);
    //     }
    // }, [selectorEmail]);

    const handleClick = useCallback((e) => {
        if (node.current !== null) {
            if (node.current.contains(e.target)) {
                return;
            }
            setSelector(false);
        }
    }, [setSelector]);

    useEffect(() => {
        try {
            if (selector) {
                document.addEventListener("mousedown", handleClick);
            } else {
                document.removeEventListener("mousedown", handleClick);
            }
        } catch (e) { }
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, [selector]);

    const onDropDown = useCallback(() => {
        setSelector(!selector);
        setFilterCountries(countryBillingMap)
    }, [setSelector, selector, setFilterCountries, countryBillingMap])

    const onSMSCountryInput = useCallback((e) => {
        const searchCountry = e.target.value.toLowerCase();
        setFilterTriggered(searchCountry);
        const filteredCountries = filterTriggered ? countryBillingMap.filter(country => country.countryName.toLowerCase().includes(filterTriggered)) : countryBillingMap;
        setFilterCountries(filteredCountries)
    }, [setFilterCountries, setFilterTriggered, filterTriggered])



    // const onDropDownEmail = useCallback(() => {
    //     setSelectorEmail(!selectorEmail);
    //     setFilterCountries(countryBillingMap)
    // }, [setSelectorEmail, selectorEmail, setFilterCountries, countryBillingMap])

    // const onEmailCountryInput = useCallback((e) => {
    //     const searchCountry = e.target.value.toLowerCase();
    //     setFilterTriggered(searchCountry);
    //     const filteredCountries = filterTriggered ? countryBillingMap.filter(country => country.countryName.toLowerCase().includes(filterTriggered)) : countryBillingMap;
    //     setFilterCountries(filteredCountries)
    // }, [setFilterCountries, setFilterTriggered, filterTriggered])

    const gotoBilling = () => {
        history.push('/billing');
    }

    const goToMyProfile = () => {
        history.push('/my-profile');
        setProfileMenuShow(false);
    }

    const toggleSmsCreditsMenu = useCallback(() => {
        setSmsCreditsMenuShow(!smsCreditsMenuShow);
    }, [setSmsCreditsMenuShow])

    const hideSmsCreditsMenu = useCallback(() => {
        setSmsCreditsMenuShow(false)
    }, [setSmsCreditsMenuShow])

    const toggleEmailCreditsMenu = useCallback(() => {
        setEmailCreditsMenuShow(!emailCreditsMenuShow);
    }, [setEmailCreditsMenuShow])

    const hideEmailCreditsMenu = useCallback(() => {
        setEmailCreditsMenuShow(false)
    }, [setEmailCreditsMenuShow])


    const toggleProfileMenu = useCallback(() => {
        setProfileMenuShow(!profileMenuShow);
    }, [setProfileMenuShow])

    const hideProfileMenu = useCallback(() => {
        setProfileMenuShow(false)
    }, [setProfileMenuShow])


    useEffect(() => {
        const fetchPricing = async () => {
            const countriesBillingMap = await BillingService.getSMSPricing()
            const sortedCountryBillingMap = countriesBillingMap.sort(function (a, b) {
                if (a.countryName < b.countryName) { return -1; }
                if (a.countryName > b.countryName) { return 1; }
                return 0;
            });
            setCountryBillingMap(sortedCountryBillingMap)
            UtilService.getIpInfo().then(resp => {
                const selectedCountry = resp && (countriesBillingMap.find(country => (country.countryISOCode.toLowerCase() === resp.country.toLowerCase())))
                setCountryDetails({ value: selectedCountry.credits, country: { name: selectedCountry.countryName, code: selectedCountry.countryISOCode.toLowerCase() } })
            });
        }
        fetchPricing();
    }, [])

    return (

        <UserContext.Consumer>
            {
                ({ userRole, name, smsCreditsOnDemand, smsCreditsPlan, emailCreditsOnDemand, emailCreditsPlan, logout, token, scopes }) => (
                    <div className="border-bottom-navigation rounded-0 card w-100 position-absolute ml-n3">
                        <Navbar className="top-nav-bar row" bg="white" variant="primary">
                            <Navbar.Brand as={Link} to="/dashboard">
                                {/* <Navbar.Toggle /> */}
                                <Image className="img-fluid w-50 ml-4" src='/assets/images/logo_full_blue.png' alt="ShoutOUT" />
                            </Navbar.Brand>
                            <Navbar.Collapse>
                                <Nav variant="primary" className="ml-auto">

                                    <Nav.Item ref={smsCreditsRef} className="cursor-pointer text-center no-focus-color"
                                        onClick={toggleSmsCreditsMenu}>
                                        <div className="d-flex align-items-center">
                                            <FaIcon icon={faCommentAlt} className={`${(Math.trunc(smsCreditsPlan + smsCreditsOnDemand) < 20) ? 'text-danger' : (Math.trunc(smsCreditsPlan + smsCreditsOnDemand) < 100) ? 'text-warning' : ''} side-nav-icon`} />
                                            <span className="link-text text-dark">{Math.trunc(smsCreditsPlan + smsCreditsOnDemand)} SMS Credits</span>
                                        </div>
                                    </Nav.Item>
                                    <Overlay
                                        show={smsCreditsMenuShow}
                                        target={smsCreditsRef.current}
                                        placement="bottom"
                                        containerClassName="bg-dark"
                                        onHide={hideSmsCreditsMenu}
                                        rootClose={true}
                                    >
                                        <Popover id="credit-menu" className="sidebar-popover border border-muted">
                                            <div className="p-3 navigation-dropdown">

                                                <div className="d-flex">
                                                    <div ref={node} className="dropdown d-inline">
                                                        <button className="bg-transparent p-0 sms-country-selector border-0" type="button" onClick={onDropDown}>
                                                            <div className="d-flex align-items-center text-truncate text-dark my-auto">
                                                                <div className={`d-inline-block mr-2 sms-country-selector-flag ${countryDetails.country.code}`}></div>
                                                                <div className="text-truncate sms-country-selector-text-2">{countryDetails.country.name}</div>
                                                                <FaIcon className="ml-2" icon={selector ? faChevronUp : faChevronDown} />
                                                            </div>
                                                        </button>
                                                        <ul className={`country dropdown-menu scrollable-menu ${selector ? "show" : "hide"}`}>
                                                            <li className="text-dark px-3 my-auto">
                                                                <input className="border-0 w-100 sms-country-selector-input" type="text" autoComplete="off" placeholder="Search.." id="sms-country-search" autoFocus onChange={onSMSCountryInput} />
                                                            </li>
                                                            {filterCountries.map(({ countryName, countryISOCode }, index) => {
                                                                return (
                                                                    <li className="sms-country-selector-item text-dark px-3 my-auto" onClick={onDropToggle} key={index} id={countryName} value={countryName}><div className={`d-inline-block mr-2 sms-country-selector-flag ${countryISOCode.toLowerCase()}`}></div>{countryName}</li>
                                                                );
                                                            }
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-secondary my-2"><strong>{countryDetails.value * (1)}</strong> {countryDetails.value * (1) === 1 ? "Credit" : "Credits"}/SMS</p>
                                                </div>
                                                <p><span className="text-primary"><strong>{Math.trunc((smsCreditsPlan + smsCreditsOnDemand) / countryDetails.value)}</strong> SMS</span> for {countryDetails.country.name}</p>
                                                <hr className="my-2" />
                                                <p className="text-primary"><strong>{smsCreditsPlan}</strong> SMS Credits</p>
                                                <span className="credits-sub-text">Remaining from Monthly Plan</span>
                                                <br />
                                                <p className="text-primary"><strong>{smsCreditsOnDemand}</strong> SMS Credits</p>
                                                <span className="credits-sub-text">Remaining Additionally</span>
                                            </div>
                                        </Popover>
                                    </Overlay>


                                    <Nav.Item ref={emailCreditsRef} className="cursor-pointer text-center no-focus-color mx-2"
                                        onClick={toggleEmailCreditsMenu}>
                                        <div className="d-flex align-items-center">
                                            <FaIcon icon={faEnvelope} className={`${(Math.trunc(emailCreditsPlan + emailCreditsOnDemand) < 20) ? 'text-danger' : (Math.trunc(emailCreditsPlan + emailCreditsOnDemand) < 100) ? 'text-warning' : ''} side-nav-icon`} />
                                            <span className="link-text text-dark">{Math.trunc(emailCreditsPlan + emailCreditsOnDemand)} Email Credits</span>
                                        </div>
                                    </Nav.Item>
                                    <Overlay
                                        show={emailCreditsMenuShow}
                                        target={emailCreditsRef.current}
                                        placement="bottom"
                                        containerClassName="bg-dark"
                                        onHide={hideEmailCreditsMenu}
                                        rootClose={true}
                                    >
                                        <Popover id="credit-menu" className="sidebar-popover border border-muted">
                                            <div className="p-3 navigation-dropdown">

                                                {/* <div className="d-flex">
                                                    <div ref={emailCountry} className="dropdown d-inline">
                                                        <button className="bg-transparent p-0 sms-country-selector border-0" type="button" onClick={onDropDownEmail}>
                                                            <div className="d-flex align-items-center text-truncate text-dark my-auto">
                                                                <div className={`d-inline-block mr-2 sms-country-selector-flag ${countryDetailsEmail.country.code}`}></div>
                                                                <div className="text-truncate sms-country-selector-text-2">{countryDetailsEmail.country.name}</div>
                                                                <FaIcon className="ml-2" icon={selectorEmail ? faChevronUp : faChevronDown} />
                                                            </div>
                                                        </button>
                                                        <ul className={`country dropdown-menu scrollable-menu ${selectorEmail ? "show" : "hide"}`}>
                                                            <li className="text-dark px-3 my-auto">
                                                                <input className="border-0 w-100 sms-country-selector-input" type="text" autoComplete="off" placeholder="Search.." id="sms-country-search" autoFocus onChange={onEmailCountryInput} />
                                                            </li>
                                                            {filterCountries.map(({ countryName, countryISOCode }, index) => {
                                                                return (
                                                                    <li className="sms-country-selector-item text-dark px-3 my-auto" onClick={onDropToggleEmail} key={index} id={countryName} value={countryName}><div className={`d-inline-block mr-2 sms-country-selector-flag ${countryISOCode.toLowerCase()}`}></div>{countryName}</li>
                                                                );
                                                            }
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <small className="sms-country-selector-text ml-2"><strong>{countryDetailsEmail.value * (1)}</strong> Credits/Email</small>
                                                </div> */}
                                                <p><span className="text-primary"><strong>{Math.trunc(emailCreditsPlan + emailCreditsOnDemand)}</strong> Email</span></p>
                                                <hr className="my-2" />
                                                <p className="text-primary"><strong>{emailCreditsPlan}</strong> Email Credits</p>
                                                <span className="credits-sub-text">Remaining from Monthly Plan</span>
                                                <br />
                                                <p className="text-primary"><strong>{emailCreditsOnDemand}</strong> Email Credits</p>
                                                <span className="credits-sub-text">Remaining Additionally</span>
                                            </div>
                                        </Popover>
                                    </Overlay>

                                    <Nav.Item as={Link} to="/billing" name="creditsButton" className="cursor-pointer text-center no-focus-color mx-2 btn btn-primary btn-sm">
                                        <span className="link-text p-0">Buy Credits</span>
                                    </Nav.Item>

                                    <Nav.Item ref={profileRef} className="cursor-pointer text-center no-focus-color mx-2" onClick={toggleProfileMenu}>
                                        <div className="d-flex align-items-center">
                                            <Avatar name={(name || 'unknown').charAt(0)} size={20} className="sidebar-user-avatar" />
                                            <span className="link-text text-dark">{name || "Profile"}</span>
                                        </div>
                                    </Nav.Item>
                                    <Overlay
                                        show={profileMenuShow}
                                        target={profileRef.current}
                                        placement="bottom"
                                        onHide={hideProfileMenu}
                                        rootClose={true}
                                    >
                                        <Popover id="credit-menu" className="sidebar-popover py-3 pl-3 border border-muted">
                                            <Link to="/my-profile" onClick={hideProfileMenu} className="d-flex text-decoration-none text-primary">
                                                <div><FaIcon icon={faUser} /></div>
                                                <div><span className="link-text text-dark">My Profile</span></div>
                                            </Link>
                                            <hr className="ml-n3 my-2" />
                                            <a onClick={logout} className="d-flex text-dark mt-2" name="logout-button">
                                                <div><FaIcon icon={faSignOutAlt} /></div>
                                                <div><span className="link-text text-dark">Logout</span></div>
                                            </a>
                                        </Popover>
                                    </Overlay>


                                    {/* <Nav.Link as={Link} href="/" to="/">Test02</Nav.Link> */}




                                    {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
                                        <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                        <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
                                    </NavDropdown> */}

                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>

                    </div>
                )
            }
        </UserContext.Consumer>
    );
}

export default MainNavigation;