/**
 * Created by asankanissanka on 2/22/17.
 */
import React, { useEffect } from "react";

const LOGOUT_API = process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://getshoutout.com/":"https://sitetest.getshoutout.com/";

const Login = (props) => {

    useEffect(() => {
        if (props.clearStatus)
            props.clearStatus();
        window.location.replace(`${LOGOUT_API}logout?referrer=${window.location.origin}`);
    }, []);


    return (
        <div className="d-flex align-content-center justify-content-center flex-wrap h-100">
            <h2 className="mb-0 font-weight-bold">Redirecting...</h2>
        </div>
    )

}

export default Login;