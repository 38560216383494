//Migrate all admin endpoints to this service

import { fetchGet, fetchPut, fetchPost, fetchDelete,jsonToQueryParam } from './CommonServiceUtils';
import Constants from './../Constants';

const ACCOUNT_SERVICE = `${Constants.BASE_API_URL}accountservice/`;
const SHOUTOUT_API_SVR = `${Constants.BASE_API2_URL}api/`;
//const SHOUTOUT_API_PROVIDERS = process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://api2.getshoutout.com/api/accountservice/admin/providers":`https://betaapi.getshoutout.com/senderservice/admin/providers`;
const CAMPAIGN_SERVICE = `${Constants.BASE_API_URL}campaignservice/`;
const BILLING_SERVICE = `${Constants.BASE_API3_URL}paymentservice/`;//"https://d21uxcjmtd.execute-api.us-east-1.amazonaws.com/api/paymentservice/";
const UTILITY_SERVICE = `${Constants.BASE_API3_URL}senderservice/`;
const SHOUTOUT_CORE_SERVICE = `${Constants.BASE_API_URL}coreservice/`;
const SHOUTOUT_ACCOUNTS = `${Constants.BASE_API2_URL}accounts`;

export default class ShoutOUTAdminServices {

    static getAllInvoices(queryObj) {
        let url =  `${BILLING_SERVICE}admin/invoices?${jsonToQueryParam(queryObj)}`;
        // if (ownerId) {
        //     url += '?ownerId=' + ownerId
        // }
        return fetchGet(url);

    }

    static createInvoice(createInvoiceReq) {
        return fetchPost(ACCOUNT_SERVICE + 'admin/paymentprocess', createInvoiceReq);
    }
    static approveTransaction(payload) {
        return fetchPost(BILLING_SERVICE + 'admin/banktransfer', payload);
    }

    static getUser(userId) {
        return fetchGet(ACCOUNT_SERVICE + 'admin/users/' + userId);

    }

    static updateUser(userId, updatePayload) {
        return fetchPost(SHOUTOUT_API_SVR + 'accounts/admin/users/' + userId, updatePayload);
    }

    static updateBillingInfo({ ownerId, createdOn, modifiedOn, billingInfo }) {
        return fetchPut(ACCOUNT_SERVICE + 'admin/users', { ownerId, createdOn, modifiedOn, billingInfo });
    }

    static downloadPaymentReciept(id){
        return fetchGet(`${BILLING_SERVICE}admin/banktransfer/download/${id}`)
    }

    static getSenderIDList(userId) {
        let url = SHOUTOUT_API_SVR + 'accountservice/admin/senders';
        if (userId) {
            url += "?ownerId=" + userId;
        }
        return fetchGet(url);
    }
    static getProvidersList() {
        return fetchGet(UTILITY_SERVICE + 'admin/providers');
    }

    static updateAccountSenders(updatePayload) {
        return fetchPost(SHOUTOUT_API_SVR + 'accountservice/admin/senders', updatePayload);
    }

    static downloadSMSSenderIdAgreement(userId, senderId, status) {
        return fetchGet(`${SHOUTOUT_API_SVR}accountservice/admin/senders?senderId=${senderId}&ownerId=${userId}${status ? '&status=' + status : ''}`);
    }

    static getCampaigns(progressReviewing) {
        return fetchGet(`${CAMPAIGN_SERVICE}admin/campaigns?limit=20&&progressReviewing=${progressReviewing}`);
    }

    static updateCampaignReviewStatus(campaignId, status) {
        return fetchPut(`${CAMPAIGN_SERVICE}admin/campaigns`, { _id: campaignId, progress: { "reviewing": status } });
    }

    static addNewNumbers(numberPayload){
        return fetchPost(UTILITY_SERVICE + 'admin/numbers', numberPayload);
    }

    static getPurchasedNumbers(queryObj){
        return fetchGet(`${UTILITY_SERVICE}admin/numbers?${jsonToQueryParam(queryObj)}`);
    }

    static deletePurchaseNumber(id, ownerId){
        return fetchDelete(`${UTILITY_SERVICE}admin/numbers/${id}?ownerId=${ownerId}`);
    }

    static artifactDownload(artifactPayload){
        return fetchPost(UTILITY_SERVICE + 'admin/senders/artifacts/download', artifactPayload);
    }

    static getSenderIds(queryObj){
        return fetchGet(`${UTILITY_SERVICE}admin/senders?${jsonToQueryParam(queryObj)}`);
    }

    static updateSenderIdArtifact(artifactPayload){
        return fetchPut(`${UTILITY_SERVICE}admin/senders/artifacts`, artifactPayload);
    }

    static updateSenderId(id, senderIdPayload){
        return fetchPut(`${UTILITY_SERVICE}admin/senders/${id}`, senderIdPayload);
    }

    static getMessageReview(queryObj){
        //queryObj -> status
        return fetchGet(`${SHOUTOUT_CORE_SERVICE}admin/messageReviews?${jsonToQueryParam(queryObj)}`);
    }

    static updateMessageReviewStatus(id, payload){
        //payload --> pendingReviewId,messageReviewStatus
        return fetchPut(`${SHOUTOUT_CORE_SERVICE}admin/messageReviews/${id}`, payload);
    }

    //TODO: Remove dummy data after the integration
    static getCreditHistroy(userId,queryObj){
        //queryObj --> fromDate, toDate, sign
        return fetchGet(`${SHOUTOUT_ACCOUNTS}/admin/users/${userId}/credit-history?${jsonToQueryParam(queryObj)}`, );
        // return new Promise((resolve)=> {
        //     resolve(
        //         [
        //             {date: "8/29/2014 17:55", userId : "3", chargedBy : "-20.0", credits : "40.0", id: "746"},
        //             {date: "8/29/2014 19:03", userId : "3", chargedBy : "-4398.2", credits : "4438.2", id: "747"},
        //             {date: "8/30/2014 17:55", userId : "3", chargedBy : "-56000.0", credits : "60392.6", id: "748"},
        //             {date: "8/30/2014 20:55", userId : "3", chargedBy : "-16800.0", credits : "31216.6", id: "749"},
        //             {date: "8/30/2014 20:55", userId : "3", chargedBy : "-6250.0", credits : "7695.65", id: "750"},
        //             {date: "8/30/2014 20:55", userId : "3", chargedBy : "-6250.0", credits : "6945.65", id: "751"},
        //             {date: "8/30/2014 20:55", userId : "3", chargedBy : "-6250.0", credits : "13195.65", id: "752"},
        //             {date: "8/30/2014 20:55", userId : "3", chargedBy : "-12500.0", credits : "12994.85", id: "753"}
        //         ]
        //     )
        // })
    }

}



