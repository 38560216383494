import { fetchGet, fetchPost, fetchDelete, fetchGetWithHeaders } from './CommonServiceUtils';
import Constants from './../Constants';

//const SHOUTOUT_UTILITY_SERVICE =process.env.REACT_APP_BUILD === "PRODUCTION" ? "https://api3.getshoutout.com/senderservice/" : "https://betaapi.getshoutout.com/utilityservice/";
const SHOUTOUT_UTILITY_SERVICE = `${Constants.BASE_API3_URL}senderservice/`;

class ShoutOUTUtilityService {
    static getNewNumbers(country,type,areaCode) {
        if(areaCode){
            return fetchGet(`${SHOUTOUT_UTILITY_SERVICE}numbers/pool/available?country=${country}&type=${type}&areaCode=${areaCode}`);
        }else{
            return fetchGet(`${SHOUTOUT_UTILITY_SERVICE}numbers/pool/available?country=${country}&type=${type}`);
        }
        
    }

    static refreshNumbers(country,type,areaCode) {
        return fetchGetWithHeaders(`${SHOUTOUT_UTILITY_SERVICE}numbers/pool/available?country=${country}&type=${type}&areaCode=${areaCode}`);
    }

    static purchaseNumbers({phoneNumber, providerId,countryISO,type,paymentMethodId}) {
        return fetchPost(SHOUTOUT_UTILITY_SERVICE + 'numbers/pool/purchase', {phoneNumber, providerId,countryISO,type,paymentMethodId });
    }
    static getPurchasedNumbers() {
        return fetchGet(SHOUTOUT_UTILITY_SERVICE + 'numbers');
    }
    static deletePurchasedNumber(id) {
        return fetchDelete(`${SHOUTOUT_UTILITY_SERVICE}numbers/${id}`);
    }
}

export default ShoutOUTUtilityService;