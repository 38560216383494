/**
 * Created by madura on 1/27/17.
 */
import React, { Component } from "react";
import {
    Button, Card
} from '@shoutout-labs/shoutout-themes';
import { faTimes } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { FaIcon, FileUploader } from '@shoutout-labs/shoutout-themes';

import { notify } from "react-notify-toast";
class PeopleFileUpload extends Component {

    onDropRejected = (rejected) => {

        notify.show("Invalid file type. Please select Excel/CSV file.", "error");

    }
    render() {
        const { fileName, removeFile, onChange } = this.props;

        return (
            <Card>
                <Card.Body>
                    <div className="text-center">
                        {!fileName ?
                            <FileUploader accept="text/csv, application/csv, text/comma-separated-values, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.xls,.xlsx"
                                onDropRejected={this.onDropRejected}
                                maxSize={25145728}
                                multiple={false}
                                onDropAccepted={onChange}
                            /> :
                            <div><p>Click next to proceed</p><span><strong> {fileName}&nbsp;</strong><Button variant="link"
                                onClick={() => {
                                    removeFile()
                                }}><FaIcon
                                    icon={faTimes}

                                /></Button>&nbsp;&nbsp;</span>
                            </div>}

                    </div>
                    <br />
                    <div className="text-left text-warning">
                        <small>We do not send any confirmation messages to opt-in your contacts. By clicking on next, you confirm that you have proper permission to send messages to these contacts.</small>
                        <br /><br />
                    </div>
                    <div className="text-left">
                        <p>Please check your CSV file meets these requirements</p>
                        <ul className="ml-n4">
                            <li><small>File extension must be .csv, .xlsx or .xls (eg: fileName.xlsx)</small></li>
                            <li><small>Must be less than 25MB or 400,000 rows. Split larger files into multiple files</small></li>
                            <li><small>The first row must contain the column names (e.g. name, email, mobile number)</small></li>
                            <li><small>Excel file should not contain multiple sheets</small></li>
                        </ul>
                    </div>
                    <br />
                    <div className="text-left">
                        <p>Sample CSV file</p>
                        <Card className="bg-dark p-3">
                            <small className="text-light">
                                name,email,mobile_number<br />
                                David,david@mycompany.com,94771234567<br />
                                Sam,sam@mycompany.com,94771234568<br />
                                Shone,shone@mycompany.com,94771234569<br />
                            </small>
                        </Card>
                    </div>
                </Card.Body>
            </Card>
        );
    }
};

export default PeopleFileUpload;
