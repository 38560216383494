
 import React from 'react';
 import './CampaignCreate.css';
 import CampaignCreateContainer from './../../../../redux/containers/V2/CampaignCreateContainer';

 const CampaignCreatePage = (props) => {
        return (
            <div className="container campaign-create flex-fill d-flex flex-column onload-transition">
                <h4 className="text-center">Create a Campaign</h4>
            
                <div className="text-center">
                    <CampaignCreateContainer {...props}/>
                </div>
            </div>
      
        );
    };
 
 export default CampaignCreatePage;