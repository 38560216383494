import React, { useState, useCallback } from 'react';
import { Form } from '@shoutout-labs/shoutout-themes';

const SenderRequestLegal = ({ details, setDetails, isSending }) => {


    const onChangeInput = (e) => {
        e.stopPropagation();
        setDetails({ ...details, [e.target.name]: e.target.value });
    }

    const handleUsageChange = useCallback((e) => {
        const item = e.currentTarget.dataset.name;
        setDetails({ ...details, userType: item });
    }, [details, setDetails])



    return (<>
        {!isSending ? <div className="text-left">
            <div key="inline-radio" className="mb-3">
                <Form.Check
                    inline
                    label="Business Purpose"
                    name="formPurpose"
                    type="radio"
                    id="business"
                    data-name="BUSINESS"
                    checked={details.userType === 'BUSINESS'}
                    onChange={handleUsageChange}
                />
                <Form.Check
                    inline
                    label="Individual Purpose"
                    name="formPurpose"
                    type="radio"
                    id="individual"
                    data-name="INDIVIDUAL"
                    checked={details.userType === 'INDIVIDUAL'}
                    onChange={handleUsageChange}
                />
            </div>
            {details.userType === 'BUSINESS' ?
                <div>

                    <Form.Group controlId="company">
                        <Form.Label>Legal Business Name *</Form.Label>

                        <Form.Control type="input" placeholder="My Company" name="company"
                            required onChange={onChangeInput} value={details.company} />
                    </Form.Group>

                    <Form.Group controlId="businessNature">
                        <Form.Label>Nature of Business *</Form.Label>

                        <Form.Control type="input" placeholder="Restaurant" name="businessNature"
                            required onChange={onChangeInput} value={details.businessNature} />
                    </Form.Group>

                    <Form.Group controlId="userName">
                        <Form.Label>Authorized Person Name *</Form.Label>

                        <Form.Control type="input" placeholder="Sam De Silva" name="userName"
                            required onChange={onChangeInput} value={details.userName} />
                    </Form.Group>

                    <Form.Group controlId="designation">
                        <Form.Label>Designation *</Form.Label>

                        <Form.Control type="input" placeholder="Marketing Manager" name="designation"
                            required onChange={onChangeInput} value={details.designation} />
                    </Form.Group>
                </div>
                :
                <div>
                    <Form.Group controlId="company">
                        <Form.Label>Full Name *</Form.Label>

                        <Form.Control type="input" placeholder="Full Name" name="individualName"
                            required onChange={onChangeInput} value={details.individualName} />
                    </Form.Group>

                    <Form.Group controlId="businessNature">
                        <Form.Label>ID Card Number</Form.Label>

                        <Form.Control type="input" placeholder="NIC / Passport / Driving License" name="idNumber"
                            required onChange={onChangeInput} value={details.idNumber} />
                    </Form.Group>
                </div>
            }

        </div> :
        <p>Requesting Sender ID...</p>}
    </>
    )
}
export default SenderRequestLegal