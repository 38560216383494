
import { connect } from '../../../contexts/ContextUtils';
import {
    onChangeEmailSubject,
    onSelectSenderId,
    onChangeCampaignName,
    setCampaignSchedule,
    setScheduleDate,
    setScheduleTime,
    onCreateCampaign,
 
    onChangeEditorState,
    onSelectEvent,
    onSelectSegment,
    toggleTransport,
    onSelectCampaignType,
    selectImportPeople,
    selectCreatePerson,
    clickCreateCampaign,
    initSMSEditor,
    updateMetadata,
    reloadEditor,
    onChangeMessengerTemplate,
    onSelectMessengerTemplate,
    changeActiveStep,
    changeSmsCampaignType,
    deselectPerson
} from '../../actions/CampaignCreateActions';
import {
    saveTemplate,
    chooseTemplate
} from '../../actions/TemplateActions';
import { showEmailPreview } from './../../actions/UtilActions';

//import CampaignSelectEvent from '../../../components/engagement_module/campaigns/create/CampaignSelectEvent';

import CampaignCreate from '../../../components/engagement_module/campaigns/create-v2/CampaignCreate';
import CampaignCreateButton from '../../../components/engagement_module/campaigns/create-v2/CampaignCreateButton';
import CampaignCreateBtn from '../../../components/engagement_module/campaigns/create-v2/CampaignCreateBtn';
import CampaignSetup from '../../../components/engagement_module/campaigns/create-v2/CampaignSetup';
import CampaignDesign from '../../../components/engagement_module/campaigns/create-v2/CampaignDesign';
import CampaignSummary from '../../../components/engagement_module/campaigns/create-v2/CampaignSummary';


import Constants from '../../../Constants';
import moment from 'moment';
import {
    reduce,
    chain
} from 'lodash';
import {
    showSendMessage
} from '../../actions/UtilActions';
import {
    showImportWizard
} from '../../actions/PeopleImportActions';
import {
    showAddEditPerson
} from '../../actions/PeopleActions';

import asyncComponent from '../../../components/utils/AsyncComponent';
import { UtilService } from '../../../services/Services';
import { withRouter } from 'react-router-dom';
import { exportText } from 'shoutout_email_editor';

const SmsEditor = asyncComponent(() => import('../../../components/engagement_module/campaigns/create/SmsEditor'));
const EmailEditor = asyncComponent(() => import('../../../components/engagement_module/campaigns/create/EmailEditor'));
const MessengerEditor = asyncComponent(() => import('../../../components/engagement_module/campaigns/create/MessengerEditor'));
const curlyBracesRegex = new RegExp("\{{[^)]*\}}", "g");

const CampaignCreateContainer = withRouter(connect(
    (state) => { //map state to props
        const selectedPeople=Object.values(state.createCampaign.selectedContacts);

        return {
            isDisableNextStep2: ((state.createCampaign.transports.indexOf('SMS') > -1 && (state.createCampaign.sms.editorState !== "" && !state.createCampaign.sms.editorState.getCurrentContent().hasText() || state.createCampaign.sms.characterOverLimit)) || (state.createCampaign.transports.indexOf('EMAIL') > -1 && (state.createCampaign.email.editorState !== "" && !state.createCampaign.email.editorState.getCurrentContent().hasText() || !state.createCampaign.email.subject))),
            isDisableNextStep1SMS: !state.createCampaign.name || !state.createCampaign.campaignType || getSenderIdsForTransport(state, Constants.TRANSPORT_SMS).length === 0 || (state.createCampaign.transports.length === 0) || (state.createCampaign.selectedSegment.length === 0 && !state.createCampaign.selectedFilters.length && selectedPeople.length === 0),
            isDisableNextStep1Email: !state.createCampaign.name || !state.createCampaign.campaignType || getSenderIdsForTransport(state, Constants.TRANSPORT_EMAIL).length === 0 || (state.createCampaign.transports.length === 0) || (state.createCampaign.selectedSegment.length === 0 && !state.createCampaign.selectedFilters.length && selectedPeople.length === 0),
            name: state.createCampaign.name,
            isScheduled: state.createCampaign.isSchedule,
            launchOn: state.createCampaign.launchOn,
            selectedSegment: state.createCampaign.selectedSegment,
            transports: state.createCampaign.transports,
            selectedPeople: selectedPeople,
            sms: state.createCampaign.sms,
            email: state.createCampaign.email,
            messenger: state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()],
            showSelectSegment: state.createCampaign.showSelectSegment,
            showSelectEvent: state.createCampaign.showSelectEvent,
            isDisableEventNextBtn: !state.createCampaign.selectedEvent,
            campaignType: Constants.CAMPAIGN_BROADCAST,
            activeStep: state.createCampaign.activeStep,
            selectedEvent: state.createCampaign.selectedEvent,
            excludedPeople: state.createCampaign.excludedPeople,
            selectedFilters: state.createCampaign.selectedFilters,
            isCreatingCampaign: state.createCampaign.steps.create.isCreating

        };
    },

    (dispatch, ownProps) => {
        return {
            onCreateCampaign: (name, isScheduled, launchOn, transports, selectedPeople, sms, email, isSelectAll, selectedSegment, campaignType, excludedPeople, selectedFilters, history, messenger, callback) => {
                dispatch(onCreateCampaign(name, isScheduled, launchOn, transports, selectedPeople, sms, email, isSelectAll, selectedSegment, campaignType, excludedPeople, selectedFilters, history, messenger, callback));
            },
            onChangeActiveStep: (newActiveStep) => {
                dispatch(changeActiveStep(newActiveStep))
            }
        };

    }
)(CampaignCreate));
export default CampaignCreateContainer;


const getSenderIdsForTransport = (state, transport) => {
    return chain(state.common.senderIds[transport.toUpperCase()]).filter(item => {
        return item.status === 1;
    }).map(item => {
        return item;
    }).value();

};

const getSenderIdsForMessenger = (state) => {
    return state.common.senderIds[Constants.TRANSPORT_MESSENGER].filter(item => {
        return item.status === 1;
    });

};

const getDisplayNameForSenderId = (senderIds, senderId, transport) => {
    const filteredItem = senderIds[transport].filter(item => {
        return item.senderId === senderId;
    });
    if (filteredItem.length > 0) {
        return filteredItem[0].displayName || filteredItem[0].senderId;
    }
    return ""
}
const getSmsLength = (editorState) => {
    if (editorState) {
        let text = editorState.getCurrentContent().getPlainText();
        return UtilService.getSmsLength(text);
    }
    return 0;
};

const getSmsPageCount = (smsLength, isUnicode, optoutMessageData = { length: 0 }) => {
    const smsLengthWithOptout = smsLength + optoutMessageData.length;
    if (!isUnicode) {
        if (smsLengthWithOptout < 161) {
            return 1;
        } else {
            return Math.ceil(smsLengthWithOptout / 153);
        }
    } else {
        if (smsLengthWithOptout < 71) {
            return 1;
        } else {
            return Math.ceil(smsLengthWithOptout / 67);
        }
    }
};
const getSelectedEvent = (state) => { //Return selected event from events
    return state.event.events.find(event => {
        return event._id === state.createCampaign.selectedEvent;
    });
};

const getEventAttributes = (state) => {
    let selectedEvent = getSelectedEvent(state);
    return selectedEvent ? selectedEvent.attributes : {};
};
const getCustomAttributes = (state) => {

    let peopleAttr = reduce(state.common.columnNameMap, (result, value, key) => {
        if (['tags', '_modified_on', '_created_on', 'user_id', '_email_valid', '_mobile_number_valid'].indexOf(key) < 0) {
            result[key] = { name: value.label, attr: key, type: "person" };
        }
        return result;
    }, {});
    let eventAttr = reduce(getEventAttributes(state), (result, value, key) => {
        if (['tags', '_modified_on', '_created_on', '_email_valid', '_mobile_number_valid'].indexOf(key) < 0) {
            result[key] = { name: value.label, attr: key, type: "event" };
        }
        return result;
    }, peopleAttr);
    return Object.values(eventAttr);
};
export const SmsEditorContainer = connect(
    (state, dispatch) => { //map state to props

        let smsLength = getSmsLength(state.createCampaign.sms.editorState);
        const selectedSenderIdObject = state.common.senderIds[Constants.TRANSPORT_SMS].find(item => {
            return item.senderId === state.createCampaign.sms.selectedSenderId;
        });;// state.common.senderIds[Constants.TRANSPORT_SMS].find(({ senderId }) => senderId === state.createCampaign.sms.selectedSenderId)
        const optoutMessageData = UtilService.calculateOptoutMessageLength(selectedSenderIdObject, state.createCampaign.sms.campaignType);
        let maxCharacterCount = (state.createCampaign.sms.isUnicode ? 201 : 459) - optoutMessageData.length;


        const isCustomAttributesPresent = state.createCampaign.sms.editorState ? exportText(state.createCampaign.sms.editorState).match(curlyBracesRegex) !== null : false;
        return {

            editorState: state.createCampaign.sms.editorState,
            smsLength: smsLength,
            smsPages: getSmsPageCount(smsLength, state.createCampaign.sms.isUnicode, optoutMessageData),
            characterOverLimit: state.createCampaign.sms.characterOverLimit,
            isUnicode: state.createCampaign.sms.isUnicode,
            maxCharacterCount: maxCharacterCount,
            customAttributes: getCustomAttributes(state),
            reloadStatus: state.createCampaign.sms.shouldReload,
            optoutMessageData,
            campaignType: state.createCampaign.sms.campaignType,
            selectedSenderIdObject,
            isCustomAttributesPresent,

        };
    },
    (dispatch, ownProps) => {
        return {

            onChangeEditorState: (editorState) => {
                dispatch(onChangeEditorState(editorState, Constants.TRANSPORT_SMS));
            },

            sendSampleSms: (message) => {
                dispatch(showSendMessage(true, {}, message, 'campaigns', Constants.TRANSPORT_SMS));
            },
            selectTemplate: () => {
                dispatch(chooseTemplate(true, Constants.TRANSPORT_SMS))
            },
            saveTemplate: (templateContent) => {
                dispatch(saveTemplate({ body: templateContent }, Constants.TRANSPORT_SMS))
            },
            initEditor: () => {
                dispatch(initSMSEditor())
            },
            reloadEditor: (status) => {
                dispatch(reloadEditor(Constants.TRANSPORT_SMS, status));
            },
            onChangeSmsCampaignType: (campaignType) => {
                dispatch(changeSmsCampaignType(campaignType));
            }
        };

    }
)(SmsEditor);

export const EmailEditorContainer = connect(
    (state) => { //map state to props
        return {
            subject: state.createCampaign.email.subject,
            editorState: state.createCampaign.email.editorState,
            customAttributes: getCustomAttributes(state),
            metadata: state.createCampaign.metadata,
            billingMetadata: {
                address1: state.billing.billingInfo.streetAddress,
                address2: state.billing.billingInfo.streetAddress2
            },
            reloadStatus: state.createCampaign.email.shouldReload
        };
    },

    (dispatch, ownProps) => {
        return {
            onChangeSubject: (subject) => {
                dispatch(onChangeEmailSubject(subject));
            },

            onChangeEditorState: (editorState) => {
                dispatch(onChangeEditorState(editorState, Constants.TRANSPORT_EMAIL));
            },

            saveTemplate: (templateContent, subject) => {
                dispatch(saveTemplate({ subject, body: templateContent }, Constants.TRANSPORT_EMAIL))
            },
            selectTemplate: () => {
                dispatch(chooseTemplate(true, Constants.TRANSPORT_EMAIL))
            },
            sendSampleEmail: (templateContent, subject) => {
                dispatch(showSendMessage(true, {}, { body: templateContent, subject }, 'campaigns', Constants.TRANSPORT_EMAIL));
            },
            updateMetadata: (newMetadata) => {
                dispatch(updateMetadata(newMetadata));
            },
            reloadEditor: (status) => {
                dispatch(reloadEditor(Constants.TRANSPORT_EMAIL, status));
            }
        };

    }
)(EmailEditor);

const getScheduleDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
};
const getScheduleTime = (date) => {
    return moment(date).format('HH:mm');
};

const getDisplayNameOfMessengerSenderId = ({ createCampaign, common }) => {
    const messengerDetails = createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()];
    if (messengerDetails.selectedSenderId) {
        const selectedSenderIdDetails = common.senderIds[Constants.TRANSPORT_MESSENGER].find(item => item.senderId === messengerDetails.selectedSenderId) || {};
        messengerDetails.displayName = selectedSenderIdDetails.displayName || "";
    }
    return messengerDetails;

}

//  export const CampaignSelectEventContainer = connect(
//      (state) => { //map state to props
//          return {
//              events: state.event.events,
//              selectedEvent: state.createCampaign.selectedEvent
//          };
//      },

//      (dispatch, ownProps) => {
//          return {
//              onSelectEvent: (e) => {
//                  dispatch(onSelectEvent(e.target.value));
//              }
//          };

//      }
//  )(CampaignSelectEvent);


const CampaignSetupContainer = connect(
    (state) => { //map state to props
        // console.debug("STATE",state.createCampaign.selectedContacts)
        return {
            campaignName: state.createCampaign.name,
            segments: state.common.segments,
            // selectedSegmentId: state.createCampaign.selectedSegment.id,
            selectedSegmentId: state.createCampaign.selectedSegment.length && state.createCampaign.selectedSegment[0].id,
            selectedSegment: state.createCampaign.selectedSegment,
            selectedFilters: state.createCampaign.selectedFilters,
            selectedPeople: Object.values(state.createCampaign.selectedContacts),
            transports: state.createCampaign.transports,

            isSchedule: state.createCampaign.isSchedule,
            scheduleOn: state.createCampaign.launchOn,
            scheduleDate: getScheduleDate(state.createCampaign.launchOn),
            scheduleTime: getScheduleTime(state.createCampaign.launchOn),
            senderIdsSms: getSenderIdsForTransport(state, Constants.TRANSPORT_SMS),
            selectedSenderIdSms: state.createCampaign.sms.selectedSenderId,
            senderIdsEmail: getSenderIdsForTransport(state, Constants.TRANSPORT_EMAIL),
            senderIdsMessenger: getSenderIdsForMessenger(state),
            selectedSenderIdEmail: state.createCampaign.email.selectedSenderId,
            selectedSenderIdMessenger: state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()].selectedSenderId,
            senderIds: state.common.senderIds,
            fileName: state.peopleImport.fileName,
            excludedPeople : state.createCampaign.excludedPeople
        };
    },


    (dispatch, ownProps) => {
        return {

            onChangeCampaignName: (name) => {
                dispatch(onChangeCampaignName(name));
            },

            onSelectSegment: (selectedSegmentId, selectedSegment) => {
                dispatch(onSelectSegment(selectedSegmentId, selectedSegment))
            },
            clickedImportPeople: () => {
                dispatch(showImportWizard(true));
                dispatch(selectImportPeople());
            },
            showAddEditPerson: () => {
                dispatch(showAddEditPerson(true));
                dispatch(selectCreatePerson());
            },

            setCampaignSchedule: (isSchedule) => {
                dispatch(setCampaignSchedule(isSchedule));
            },
            setScheduleTime: (currentDate, time) => {
                dispatch(setScheduleTime(currentDate, time));
            },
            setScheduleDate: (currentDate, date) => {
                dispatch(setScheduleDate(currentDate, date));
            },

            onSelectSenderId: (senderId, transport, senderIdList) => {
                dispatch(onSelectSenderId(senderId, transport, senderIdList));
            },
            deselectPerson: (id) => {
                dispatch(deselectPerson(id));
            }
        };

    }
)(CampaignSetup);

export const CampaignDesignContainer = connect(
    (state) => { //map state to props
        return {
            transports: state.createCampaign.transports,
            campaignType: state.createCampaign.campaignType,
            selectedEditor: state.createCampaign.activeEditor
        };
    },

    (dispatch, ownProps) => {
        return {
            
        };

    }
)(CampaignDesign);

export const CampaignConfirmContainer = connect(
    (state) => { //map state to props
        const selectedPeople = Object.values(state.createCampaign.selectedContacts);
        return {
            campaignName: state.createCampaign.name,
            isSchedule: state.createCampaign.isSchedule,
            scheduleDate: getScheduleDate(state.createCampaign.launchOn),
            scheduleTime: getScheduleTime(state.createCampaign.launchOn),
            selectedSegment: state.createCampaign.selectedSegment,
            transports: state.createCampaign.transports,
            selectedPeopleCount: selectedPeople.length,
            selectedPeople: selectedPeople,
            campaignType: state.createCampaign.campaignType,
            selectedEvent: getSelectedEvent(state),
            isCreatingCampaign: state.createCampaign.steps.create.isCreating,
            showSelectSegment: state.createCampaign.showSelectSegment,
            excludedPeople: state.createCampaign.excludedPeople,
            selectedFilters: state.createCampaign.selectedFilters,
            transportDetails: { [Constants.TRANSPORT_SMS]: state.createCampaign.sms, [Constants.TRANSPORT_EMAIL]: state.createCampaign.email, [Constants.TRANSPORT_MESSENGER]: getDisplayNameOfMessengerSenderId(state) },
            senderIdMap: {
                [Constants.TRANSPORT_SMS]: getDisplayNameForSenderId(state.common.senderIds, state.createCampaign[Constants.TRANSPORT_SMS.toLowerCase()].selectedSenderId, Constants.TRANSPORT_SMS),
                [Constants.TRANSPORT_EMAIL]: getDisplayNameForSenderId(state.common.senderIds, state.createCampaign[Constants.TRANSPORT_EMAIL.toLowerCase()].selectedSenderId, Constants.TRANSPORT_EMAIL),
                [Constants.TRANSPORT_MESSENGER]: getDisplayNameOfMessengerSenderId(state)
            },
            email: state.createCampaign.email,
            editorState: state.createCampaign.sms.editorState,
            fileName: state.peopleImport.fileName,

        };
    },
    (dispatch, ownProps) => {
        return {
            showEmailPreview: (html) => { dispatch(showEmailPreview(true, html)) }
        };

    }
)(CampaignSummary);

const CampaignCreateButtonContainerV2 = connect(
    (state) => { //map state to props
        return {
            transports: state.createCampaign.transports,
        };
    },

    (dispatch, ownProps) => {
        return {
            toggleTransport: (transports) => {
                dispatch(toggleTransport(transports));
                dispatch(onSelectCampaignType(Constants.CAMPAIGN_BROADCAST));
            }
        };

    }
)(CampaignCreateButton);

const CampaignCreateStartedBtnContainer = connect(
    (state) => { //map state to props
        return {
            transports: state.createCampaign.transports,
        };
    },

    (dispatch, ownProps) => {
        return {
            toggleTransport: (transports) => {
                dispatch(toggleTransport(transports));
                dispatch(onSelectCampaignType(Constants.CAMPAIGN_BROADCAST));
            }
        };

    }
)(CampaignCreateBtn);



const MessengerEditorContainer = connect(
    (state, dispatch) => { //map state to props

        return {
            customAttributes: getCustomAttributes(state),
            template: state.createCampaign[Constants.TRANSPORT_MESSENGER.toLowerCase()].template,
            messengerIsNewlySelectedTemplate: state.createCampaign.messengerIsNewlySelectedTemplate
        };
    },
    (dispatch, ownProps) => {
        return {

            onChangeTemplate: (template) => {
                dispatch(onChangeMessengerTemplate(template));
            },

            saveTemplate: (template) => {
                dispatch(saveTemplate(template, Constants.TRANSPORT_MESSENGER))
            },
            selectTemplate: () => {
                dispatch(chooseTemplate(true, Constants.TRANSPORT_MESSENGER))
            },
            cancelSelectMessengerTemplate: () => {
                dispatch(onSelectMessengerTemplate(false))
            }
        };

    }
)(MessengerEditor);

export { CampaignSetupContainer, CampaignCreateButtonContainerV2, MessengerEditorContainer, CampaignCreateStartedBtnContainer };
