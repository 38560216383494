import {
    ShoutOUTService
}
    from './../../services/Services';
import {
    notify
} from "react-notify-toast";
import Constants from './../../Constants';

const UTIL_ACTIONS = {
    SHOW_SEND_MESSAGE_DIALOG: 'SHOW_SEND_MESSAGE_DIALOG',
    CHANGE_SEND_MESSAGE_SENDER_ID: 'CHANGE_SEND_MESSAGE_SENDER_ID',
    SENDING_MSG_SEND_MESSAGE: 'SENDING_MSG_SEND_MESSAGE',
    SENT_MSG_SEND_MESSAGE: 'SENT_MSG_SEND_MESSAGE',
    SENT_MSG_ERROR_SEND_MESSAGE: 'SENT_MSG_ERROR_SEND_MESSAGE',
    CHANGE_SEND_MESSAGE_MESSAGE: 'CHANGE_SEND_MESSAGE_MESSAGE',
    CHANGE_SEND_MESSAGE_TO: 'CHANGE_SEND_MESSAGE_TO',
    SHOW_ALERT_BOX: 'SHOW_ALERT_BOX',
    SHOW_EMAIL_PREVIEW: 'SHOW_EMAIL_PREVIEW'
};

const showSendMessage = (isShow, contact = {}, message = '', from = '', transport = Constants.TRANSPORT_SMS) => {
    return {
        type: UTIL_ACTIONS.SHOW_SEND_MESSAGE_DIALOG,
        isShow,
        contact,
        message, from, transport
    }
};

const sendDirectMessage = (from, to, message, transport) => {
    return (dispatch) => {
        dispatch({ type: UTIL_ACTIONS.SENDING_MSG_SEND_MESSAGE });
        if (transport === Constants.TRANSPORT_EMAIL) {
            message.htmlType = true;
        }
        ShoutOUTService.sendMessage(from, to, message, transport).then(res => {
            let response=res.responses[0];
            if (response.status === Constants.MSG_STATUS_TO_CODE_MAP.SENT||response.status === Constants.MSG_STATUS_TO_CODE_MAP.SUBMITTED) {
                dispatch({ type: UTIL_ACTIONS.SENT_MSG_SEND_MESSAGE });
                notify.show("Message Sent", "success");
            } else {

                if (response.status === Constants.MSG_STATUS_TO_CODE_MAP.INVALID_RECEIVER) {
                    notify.show("Invalid mobile number", "error");
                } else if (response.status === Constants.MSG_STATUS_TO_CODE_MAP.INVALID_SENDER_ID) {
                    notify.show("Invalid sender Id. Check your sender Id is valid.", "error");
                } else {
                    notify.show("Message cannot submit", "error");
                }
                dispatch({ type: UTIL_ACTIONS.SENT_MSG_ERROR_SEND_MESSAGE });
            }

        }, err => {
            notify.show("Message cannot submit", "error");
            dispatch({ type: UTIL_ACTIONS.SENT_MSG_ERROR_SEND_MESSAGE });
        });
    }
};

const changeSenderId = (senderId, transport) => {
    return {
        type: UTIL_ACTIONS.CHANGE_SEND_MESSAGE_SENDER_ID,
        senderId,
        transport
    }
};

const changeSendMessageMessage = (message, transport) => {
    return {
        type: UTIL_ACTIONS.CHANGE_SEND_MESSAGE_MESSAGE,
        message,
        transport: transport.toLowerCase()
    }
};

const changeSendMessageTo = (to, transport) => {
    return {
        type: UTIL_ACTIONS.CHANGE_SEND_MESSAGE_TO,
        to,
        transport: transport.toLowerCase()
    };
};

const showAlertBox = (title, message, isShow = true, alertType = 'success') => {
    return {
        type: UTIL_ACTIONS.SHOW_ALERT_BOX,
        title, message, alertType, isShow
    };
};

const showEmailPreview = (isShow, body = '') => {
    return {
        type: UTIL_ACTIONS.SHOW_EMAIL_PREVIEW,
        isShow,
        body
    };
}
export default UTIL_ACTIONS;
export {
    sendDirectMessage,
    showSendMessage,
    changeSenderId,
    changeSendMessageMessage,
    changeSendMessageTo,
    showAlertBox,
    showEmailPreview
};
