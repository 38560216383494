import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert } from '@shoutout-labs/shoutout-themes';
import { NavLink, useLocation } from 'react-router-dom';
import OnBoardingContext, { StepNames } from './../../contexts/OnboardingContext';
import MobileVerifyBanner from '../dashboard_module/MobileVerifyBanner';
import { UtilService } from '../../services/Services';
import './DashboardModule.css';

const NextStepComponent = ({ name, nameTwo, nameThree, url, urlTwo, urlThree, title, subtitle, subtitleTwo }) => {
    return (
        <>
            <h6>{title}</h6>
            <div className="d-flex align-items-center">

                <small className="mr-2 ">{subtitle}</small>
                <small>
                    <NavLink className="text-decoration-none" to={url}>
                        {name}
                    </NavLink>
                </small>
                {subtitleTwo ?
                    <>
                        <small className="mr-2 ml-4">{subtitleTwo}</small>
                        <small>
                            <NavLink className="text-decoration-none" to={urlTwo}>
                                {nameTwo}
                            </NavLink>
                        </small>
                        {urlThree ? <small className="ml-3">
                            <NavLink className="text-decoration-none" to={urlThree}>
                                {nameThree}
                            </NavLink>
                        </small>
                            : null}
                    </> : null
                }
            </div>
        </>
    )
}

const OnboardingBanner = () => {
    const { steps } = useContext(OnBoardingContext);

    const location = useLocation();

    const [country, setCountry] = useState("");

    useEffect(() => {
        UtilService.getIpInfo().then(resp => {
            if (resp) {
                setCountry(resp.country);
            }
        });
    }, [])

    
    const getStepComponent = useCallback(() => {

        if (!steps[StepNames.STEP1]) {
            return (
                <MobileVerifyBanner />
            );
        }

        if (!steps[StepNames.STEP2]) {
            return (
                <NextStepComponent name="Add Contacts" url="/people" title="STEP 02 - Create a Contact" subtitle="Add your contacts to send test message" />
            );
        }

        if (!steps[StepNames.STEP3]) {
            return (
                <NextStepComponent name="Launch a Campaign" url="/engagement/campaigns" title="STEP 03 - Launch a Campaign" subtitle="Use your 20 free credits to launch a test campaign" />
            );
        }
        if (!steps[StepNames.STEP5]) {
            return (
                <NextStepComponent name="Purchase Package" nameTwo={(country === "us" || country === "ca") ? "Purchase Phone Number" : "Request Sender ID"} url="/billing" urlTwo={(country === "us" || country === "ca") ?"/senders/numbers":"/senders"} subtitle="Choose the package that suits you" subtitleTwo={(country === "us" || country === "ca") ?"Get a phone number":"Get your own sender ID"} title="STEP 04 - Go Live" />
            );
        }
        if (!steps[StepNames.STEP4]) {
            return (
                <NextStepComponent name="Purchase Package" nameTwo={(country === "us" || country === "ca") ? "Purchase Phone Number" : "Request Sender ID"} url="/billing" urlTwo={(country === "us" || country === "ca") ?"/senders/numbers":"/senders"} subtitle="Choose the package that suits you" subtitleTwo={(country === "us" || country === "ca") ?"Get a phone number":"Get your own sender ID"} title="STEP 04 - Go Live" />
            );
        }


        return null;
    }, [steps, country])


    // const onSetUrl = useCallback(() => {

    //     switch (nextStep) {
    //         case 'Launch a Campaign':
    //             setUrl("/engagement/campaigns");
    //             break;
    //         case 'Go Live':
    //             setUrl("/my-profile");
    //             break;
    //         case 'Add People':
    //             setUrl("/people");
    //             break;
    //         default:
    //             setUrl("/");
    //     }

    // }, [setUrl, nextStep]);
    return (
        <Alert variant="transparent" className={`p-2 border-0 mt-3 mx-4 alert-color-onboarding ${location.pathname === "/dashboard" || location.pathname === "/" ? "" : "mb-n3"}`}>
            {getStepComponent()}
            {/* <button className="btn btn-primary btn-sm ml-2">
                    <NavLink to={url} className="page-links">
                        {nextStep}
                    </NavLink>
                </button> */}
        </Alert>

    )

};

export default OnboardingBanner;


