import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Modal, Button, Form } from '@shoutout-labs/shoutout-themes';
import { AccountService } from '../../services/Services';
import { notify } from 'react-notify-toast';
import { UtilService } from "./../../services/Services";
import './DashboardModule.css';
import UserContext from './../../contexts/UserContext';

const ChangeMobileNumber = ({ changePhoneNo, onChangePhoneNo, setChangePhoneNo }) => {

    const [mobileNumber, setMobileNumber] = useState();
    const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
    const [isChangingNumber, setIsChangingNumber] = useState(false);
    const [country, setCountry] = useState("lk");
    const { loadUser, mobile } = useContext(UserContext);

    useEffect(() => {
        UtilService.getIpInfo().then(resp => {
            if (resp) {
                setCountry(resp.country);
            }
        });
    }, [])

    const onMobileNumberChange = useCallback(async (status, value, countryData, number, id) => {

        if (status) {

            const mobileNo = number.replace(/[^0-9]/gi, '');
            //setMobileNumber(value);
            setIsMobileNumberValid(true);
            setMobileNumber(mobileNo);

        } else {
            setMobileNumber('');
            setIsMobileNumberValid(value.length === 0);
        }
    }, [setMobileNumber, setIsMobileNumberValid]);


    const setAnotherPhoneNo = useCallback(async () => {
        try {
            setIsChangingNumber(true);
            await AccountService.changeMobile(mobileNumber);
            notify.show("Mobile number updated", "success");
            loadUser();
            setChangePhoneNo(false);

        } catch (e) {
            notify.show("Mobile number updating failed. Please try again.", "error");
        } finally {
            setIsChangingNumber(false);
        }

    }, [setChangePhoneNo, mobileNumber, setIsChangingNumber, loadUser]);


    return (
        <Modal show={changePhoneNo} onHide={onChangePhoneNo} className="border-0" size="lg">
            <Modal.Header closeButton>
                <h6 className="mb-0 font-weight-bold">Change Phone Number</h6>
            </Modal.Header>
            <Modal.Body className="natural-text overflow-unset">
                <p>Your current number is +{mobile}</p>
                <p className="mt-4">New Phone Number</p>
                <Form.Group controlId="phone"
                    validationState={isMobileNumberValid ? null : 'error'}>
                    <Form.MobileNumberInput id="phone"
                        onPhoneNumberBlur={onMobileNumberChange}
                        onPhoneNumberChange={onMobileNumberChange}
                        defaultCountry={country}
                        required
                        inputClassName={`form-control shadow-0 form-control-sm w-100`}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" size="sm" className="border-0" onClick={setAnotherPhoneNo} disabled={isChangingNumber}>{isChangingNumber ? "Updating..." : "Save Changes"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeMobileNumber;