/**
 * Created by asankanissanka on 12/20/16.
 */
import React, { Component } from 'react';
import { ShoutOUTService } from './../../services/Services';
import {
    Form,
    Button,
    Modal,
    FaIcon
} from '@shoutout-labs/shoutout-themes';
import { faCheck, faTimes } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { notify } from 'react-notify-toast';
import Constants from './../../Constants';
import './SenderRequest.css'

const SmsSenderIdPattern = /^[a-zA-Z\d.]{3,11}$/;
const EmailSenderIdPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

const initialState = {
    senderId: '',
    company: '',
    sample: '',
    isValid: false,
    isSending: false,
    requestSent: false,
    emailName: '',
    businessNature: '',
    designation: '',
    userName: '',
    countries: [],
    usage: 'transactional',
    purpose: ''
};
class SenderRequest extends Component {
    constructor() {
        super();
        this.close = this.close.bind(this);
        this.state = {
            ...initialState
        };
        // this._handleChange = this._handleChange.bind(this);
        this._setSmsSenderID = this._setSmsSenderID.bind(this);
        this._handleUsageChange = this._handleUsageChange.bind(this);
        this._handlePurposeChange = this._handlePurposeChange.bind(this);
        this._submitSenderID = this._submitSenderID.bind(this);
        this._setEmailSenderID = this._setEmailSenderID.bind(this);
        this._onChangeInput = this._onChangeInput.bind(this);
        this._checkProps = this._checkProps.bind(this);
    }

    close(result) {
        this.setState({
            ...initialState
        });
        this.props.onCloseCallback(result);
    }

    _setSmsSenderID(e) {
        let senderId = e.target.value;
        let isValid = senderId.match(SmsSenderIdPattern);

        this.setState({ senderId, isValid });
    }

    _onChangeInput(e) {
        e.stopPropagation();
        this.setState({ [e.target.name]: e.target.value });
    }

    _buildEmailSenderId() {
        return this.state.emailName ? this.state.emailName + "<" + this.state.senderId + ">" : this.state.senderId;
    }
    _submitSenderID(e) {
        e.preventDefault();
        const { senderIdType } = this.props;
        let { senderId, company, businessNature,
            designation,
            userName, countries, usage } = this.state;
        senderId = senderId.trim();
        if (senderIdType === Constants.TRANSPORT_EMAIL) {
            senderId = this._buildEmailSenderId();
        }

        this.setState({ isSending: true });
        let ctx = this;
        const transformedCountries = countries.map(({ name }) => {
            return name.toLowerCase();
        })

        ShoutOUTService.requestSenderId(senderId, senderIdType).then(res => {
            notify.show("Sender ID request sent", "success");
            //ctx.close(res);
            ctx.setState({ isSending: false, requestSent: true });
        }, err => {
            notify.show("Sender ID request couldn't send ", "error");
            ctx.setState({ isSending: false });
        })
    }

    _setEmailSenderID(e) {
        let name = e.target.name;
        let value = e.target.value;
        let senderId, isValid = false;
        if (name === "emailName") {
            this.setState({ emailName: value });
        }
        else {
            senderId = value;
            isValid = senderId.match(EmailSenderIdPattern);
            this.setState({ senderId, isValid });
        }


    }

    _checkProps() {
        const { userName, company } = this.props;

        this.setState({ company, userName });

    }

    _handleUsageChange(e) {
        const item = e.currentTarget.dataset.name;

        this.setState({ usage: item });
    }
    _handlePurposeChange(e) {
        const item = e.currentTarget.dataset.name;

        this.setState({ purpose: item });
    }

    render() {
        const { senderIdType } = this.props;


        return (
            <Modal bsPrefix="modal" show={this.props.showModal} onHide={this.close} onEntered={this._checkProps}>
                <Modal.Header closeButton>
                    <h6 className="mb-0 font-weight-bold">Request Email Sender ID</h6>
                </Modal.Header>
                <>

                    {this.state.requestSent ? <Modal.Body>
                        <div>
                            <p className="text-dark">Verification Email has been sent to requested Email address. Please check your inbox.</p>
                            <hr className="mx-n3" />
                            <Button type="button" onClick={this.close} variant="dark" size="sm" className="float-right">Close</Button>
                        </div>
                    </Modal.Body>
                        :

                        <Form className="bg-white" onSubmit={this._submitSenderID}>
                            <Modal.Body>
                                {this.state.isSending ? <p>Requesting Email Sender ID...</p>
                                    :
                                    <>
                                        <Form.Group controlId="email-id">
                                            <Form.Label>Email Address *</Form.Label>
                                            <div className="d-flex align-items-center">
                                                <Form.Control type="email" placeholder="Your sender ID" name="emailSenderID"
                                                    onChange={this._setEmailSenderID} required />
                                                <div className="ml-n4">{this.state.isValid ? <span className="text-success"><FaIcon icon={faCheck} /></span> : <span className="text-danger"><FaIcon icon={faTimes} /></span>}</div>
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="email-name">

                                            <Form.Label>Name *</Form.Label>

                                            <Form.Control type="text" placeholder="Your name which should appear in the email" name="emailName"
                                                onChange={this._setEmailSenderID} required />

                                        </Form.Group><br />
                                        <Form.Group controlId="email-senderid-preview">
                                            <small>Your Sender Id will appear as below:<br />{this._buildEmailSenderId()}</small>
                                        </Form.Group>
                                    </>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn btn-primary btn-sm" disabled={!this.state.isValid || this.state.isSending} type="submit">{this.state.isSending ? 'Sending Request...' : 'Send Request'}</Button>
                            </Modal.Footer>
                        </Form>
                    }
                </>

            </Modal>
        );
    }
}

export default SenderRequest;
