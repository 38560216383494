/**
 * Created by madura on 1/27/17.
 */
import React, { useEffect } from "react";
import { Card, Row, Col, DropdownItem, Typeahead, FaIcon } from '@shoutout-labs/shoutout-themes';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { faCheckCircle } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import AddCustomAttributeDialog from './PeopleAddCustomAttribute';
import { countries } from 'country-data';
import { sortBy } from 'lodash';
import FlagIconFactory from 'react-flag-icon-css';
import { LoadingComponent } from './../../utils/UtilComponents';
import { UtilService } from './../../../services/Services';

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

let countryList = sortBy(countries.all, ['name']);
let selectedHeader = '';

const PeopleMapAttributes = ({ fileHeaders, fileSample, columnNames, country, headerMap, mapHeader, setCountry, selectAddCustomAttr, isSelectCustomAttr, addCustomAttribute, fileUploading, reservedAttributes }) => {


    useEffect(() => {
        UtilService.getIpInfo().then(resp => {
            if (resp) {
                const selectedCountry = countryList && (countryList.find(item => (item.alpha2.toLowerCase() === resp.country.toLowerCase())))
                setCountry(selectedCountry.alpha2, selectedCountry.name);
            }
        });

        //eslint-disable-next-line
    }, [])

    return (
        fileUploading ? <div>
            <LoadingComponent />
        </div> : <div className="map-attributes">
            <p>Preview and map your contacts attribute</p>
            <small>Map your contacts attributes to ShoutOUT attributes.</small>
            {
                fileHeaders.map((header, index) => {
                    return (
                        <Card key={`header-${index}`} className="mt-2">
                            <Card.Body>
                                <div className="d-flex flex-row">
                                    <div className="flex-fill text-left">
                                        <strong>{header}</strong>
                                        {fileSample.map(data => {
                                            return (<p key={data[header]}>{data[header]}</p>)
                                        })
                                        }

                                    </div>

                                    <div className="my-auto text-right">

                                        <DropdownButton
                                            title={headerMap[header] ? columnNames[headerMap[header]].label : "--Select--"}
                                            key={header + '-m'} id={header + '-did'} onSelect={(eventKey) => {
                                                selectedHeader = header;
                                                mapHeader(header, eventKey)
                                            }} className="map-selector" variant="primary">

                                            {Object.keys(columnNames).map((column, index) => {
                                                return (<DropdownItem eventKey={column} key={column + "-" + index}
                                                    disabled={columnNames[column].mapWith !== null}>{columnNames[column].label}</DropdownItem>)
                                            })}
                                            <DropdownItem key="00" divider />
                                            <DropdownItem key="01" onClick={() => {
                                                selectedHeader = header;
                                                selectAddCustomAttr(true)
                                            }}><strong>Create custom attribute</strong></DropdownItem>
                                            <DropdownItem key="02" eventKey="_null">Do not upload</DropdownItem>
                                        </DropdownButton>



                                    </div>
                                    <div className="check-icon my-auto ml-2">
                                        {headerMap[header] &&
                                            <span className="header-map-correct text-success">&nbsp;&nbsp;<FaIcon
                                                icon={faCheckCircle}
                                            /></span>}
                                    </div>
                                </div>
                                {/*<Col sm={2} md={2} xs={2} className="text-left">
                                    <Row>

                                        {headerMap[header] &&
                                        <span className="header-map-correct text-success">&nbsp;&nbsp;<FaIcon
                                            icon={faCheckCircle}
                                            /></span>}
                                    </Row>
                                    <Row>

                                    </Row>
                                </Col>*/}

                                {
                                    headerMap[header] && headerMap[header] === 'mobile_number' &&
                                    <div><br />
                                        <p><strong> To which country does this number base belong?</strong></p>

                                        <Row className="justify-content-center">
                                            <Col xs={12} sm={6} >
                                                <div className="text-left">
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        onChange={(selections) => {
                                                            if (selections && selections.length > 0) {
                                                                setCountry(selections[0].alpha2, selections[0].name);
                                                            } 
                                                        }}
                                                        placeholder={'Select Country'}
                                                        selected={[country]}
                                                        options={countryList}
                                                        labelKey={option => `${option.name}`}
                                                        renderMenuItemChildren={(result, props) => {
                                                            return <span><span className="default-flag"><FlagIcon code={result.alpha2.toLowerCase()} /></span>&nbsp;&nbsp;{result.name}</span>
                                                        }}
                                                        filterBy={['name']}
                                                        size="sm"
                                                        defaultSelected={country.code ? [{ name: country.name, alpha2: country.code }] : []}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="text-muted"><small>NOTE: If selected, the numbers which are not in the standard international format will be automatically corrected.</small></div>
                                    </div>
                                }

                            </Card.Body>
                        </Card>
                    )
                })
            }
            <AddCustomAttributeDialog showAddCustomAttrDialog={isSelectCustomAttr}
                closeAddCustomAttrDialog={() => selectAddCustomAttr(false)}
                onSubmit={(name, type) => addCustomAttribute(name, type, selectedHeader)}
                reservedAttributes={reservedAttributes} />
        </div>

    );
};

export default PeopleMapAttributes;