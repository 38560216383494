import React, { PureComponent } from "react";
import { Button, FileUploader } from '@shoutout-labs/shoutout-themes';
import { notify } from "react-notify-toast";
import { ShoutOUTService } from './../../services/Services';
import { LoadingComponent } from './UtilComponents';


class ImageUploader extends PureComponent {
    constructor() {
        super();
        this.state = { imageUploadingIndicator: false, imageLoadingIndicator: true, src: '' };
        this.reader = new FileReader();
        let ctx = this;
        this.fileType = '';
        this.reader.addEventListener("load", function () {
            ShoutOUTService.uploadImage(ctx.reader.result, ctx.fileType).then(res => {
                ctx.setState({ src: res.fileUrl, imageUploadingIndicator: false });
                ctx.props.onChange(res.fileUrl);
            }, err => {
                notify.show('Cannot upload file', 'error');
            });
        }, false);

        this._onDrop = this._onDrop.bind(this);
        this._imageLoadError = this._imageLoadError.bind(this);
        this._imageOnLoad = this._imageOnLoad.bind(this);
    }


    _changeSrc(src) {
        if (src) {
            this.setState({ imageLoadingIndicator: true, src });
        } else {
            this.setState({ imageLoadingIndicator: false, src });
        }
    }
    _onDrop(files) {
        this._changeSrc('');
        this.setState({
            dropzoneActive: false
        });

        const file = files[0];

        this.setState({ imageUploadingIndicator: true, imageLoadingIndicator: true });
        this.fileType = file.type;
        this.reader.readAsDataURL(file);

    }

    onDropRejected = ({ type }) => {
        if (type === "large file") {
            notify.show("Selected file is too large. Maximum allowed size is 3MB.", "error");
        }
        else if (type === "invalid file")
            notify.show("Invalid file type. Please select an image", "error");
        else {
            notify.show("Invalid file selected.", "error");
        }
    }

    _imageOnLoad() {
        this.setState({ imageLoadingIndicator: false });
    }
    _imageLoadError() {
        notify.show("Can not load the image. Please retry", "error");
        this.setState({ imageLoadingIndicator: false, src: '' });
    }

    componentDidMount() {
        if (this.props.defaultSrc && !this.state.src) {
            this.setState({ src: this.props.defaultSrc });
        }
    }
    render() {
        const { imageUploadingIndicator, src, imageLoadingIndicator } = this.state;

        return (
            !!src ? <div >
                <div className="text-center">
                    <img src={src} width={200} onLoad={this._imageOnLoad} onError={this._imageLoadError} role="presentation" alt="sample view" />
                </div>
                {(imageLoadingIndicator) ?
                    <LoadingComponent /> :
                    <div>
                        <div className="text-center">
                            <br />
                            <Button variant="danger" type="button" size="sm" onClick={(e) => { e.stopPropagation(); this._changeSrc('') }}>Change Image</Button>
                        </div>
                    </div>}
            </div>
                : <div className="text-center">
                    {imageUploadingIndicator ? <LoadingComponent /> :
                        <FileUploader
                            accept="image/*"
                            onDropRejected={this.onDropRejected}
                            maxSize={3145728}
                            multiple={false}
                            onDropAccepted={this._onDrop}
                        />
                       
                    }
                </div>


        );
    }
}
export default ImageUploader;