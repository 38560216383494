import React, { Component } from "react";
import Shoutout from "./../../services/Shoutout";
import {
    Button,
    Row,
    Col,
    Form,
    
    Card,
    Alert
} from '@shoutout-labs/shoutout-themes';
import "./UnsubscriptionPage.css";


class UnsubscriptionPage extends Component {

    constructor(props) {
        super(props);
        console.debug(props);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.state = {
            status: '',
            reqInProgress: false,
            statusStyle: null,
            cId: props.match['params']['cId'],
            oId: props.match['params']['oId'],
            addr: props.match['params']['addr']
        };
        this.shoutout = new Shoutout();
    }


    unsubscribe(event) {
        event.preventDefault();
        this.setState({ status: '', reqInProgress: true, statusStyle: null });
        this.shoutout.subscription('unsubscribe', this.state.cId, this.state.oId, this.state.addr, 'email').then(res => {
            this.setState({ status: "Successfully Unsubscribed", reqInProgress: false, statusStyle: 'success' });
        }).catch(err => {
            this.setState({
                status: "Something went wrong! Please try again",
                reqInProgress: false,
                statusStyle: 'warning'
            });
        });
    }


    render() {
        return (
            <div className="container">
                <Row>
                    <Col xs={12} sm={8}  md={6}  lg={6}  className="login-wrapper">
                        <Card>
                            <Card.Body>
                                <Form horizontal onSubmit={this.unsubscribe}>
                                    <Form.Group className="text-center login-logo">
                                        <img src='/assets/images/crying.png' alt="logo" />
                                    </Form.Group>

                                    <Form.Group className="text-center">
                                        <h3>We are sad to see you go</h3>
                                    </Form.Group>
                                    <Form.Group className="text-center">
                                        <Button className="btn-info" type="submit" disabled={this.state.reqInProgress}>
                                            {this.state.reqInProgress ? 'Unsubscribing...' : 'Unsubscribe'}
                                        </Button>
                                    </Form.Group>
                                    {this.state.status ? (
                                        <Alert className="text-center" variant={this.state.statusStyle || 'info'}>
                                            {this.state.status}
                                        </Alert>) : null}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
export default UnsubscriptionPage;
