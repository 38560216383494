import React, { useContext, useCallback } from "react";
import './PeopleActionBar.css';
import {
    Button,
    FaIcon,
    OverlayTrigger,
    Tooltip, 
    Dropdown
} from '@shoutout-labs/shoutout-themes';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { faChartPie, faUserPlus, faUpload, faDownload, faTag, faBullhorn, faTrashAlt, faFilter } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import SearchPeople from './PeopleSearch';
import { AddTagDialogContainer } from './../../redux/containers/PeopleListContainer';
import { PeopleDeleteContainer } from './../../redux/containers/PeopleListContainer';
import { ShoutOUTService, UtilService } from './../../services/Services';
import { notify } from 'react-notify-toast';
import UserContext from './../../contexts/UserContext';
import { withRouter } from 'react-router-dom';
import Constants from "../../Constants";
import AccountNotVerifiedNotificationButton from './../utils/AccountNotVerifiedNotificationButton';
// const CreateCampaignActionButton = ({ emailVerified, selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, peopleList, history, createCampaign }) => {
//     return (

//         <Button size="sm" variant="transparent"
//             className={`ml-2 rounded-0 text-primary ${!emailVerified && 'disabled'}`}
//             //disabled={!emailVerified}
//             onClick={() => {
//                 const excludedPeople = selectAllStatus ? peopleList.filter(item => !item._selected).map(item => item.id) : [];
//                 createCampaign(selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople);
//                 history.push('/engagement/campaigns/create/new');
//             }}><FaIcon
//                 icon={faBullhorn} />&nbsp;&nbsp;Create
//     Campaign</Button>

//     )
// }

const PeopleActionsBar = withRouter(({ onClickSegments, showSegments, showFilters, onClickFilters, selectedPeopleCount, onClickDelete, onSearch, showAddEditPerson, createCampaign, isSelectAll, selectedSegment, clickedAddMoreTags, selectedPeople, searchText, onChangeSearchText, selectAllStatus, selectedFilters, isFilterSelected, clickedImportPeople, peopleList, history }) => {
    const { scopes, mobileVerified } = useContext(UserContext);
    const _onClickDownload = () => {
        let requestObj = { filters: [selectedFilters] };
        if (!selectAllStatus && selectedPeopleCount > 0) {
            requestObj.includeIds = peopleList.filter(item => item._selected).map(item => item.id);
        }
        ShoutOUTService.exportContacts(requestObj).then(res => {
            // UtilService.downloadCSV(res, 'contacts export.csv').then(res => { }, err => {
            //     notify.show('Couldn\'t export the file. Try again', 'error');
            // })
            notify.show("Contacts are being exported. You would receive an email with a download link shortly.", 'success');
        }, err => {
            notify.show("Couldn't send the request. Try again!", 'error');
        });
    }

    // const alertMsg = () =>{
    //     return(

    //     )//Alert msg
    // }

    const createCampaigns = useCallback((eventKey) => {

        const transport = eventKey === "1" ? Constants.TRANSPORT_SMS : Constants.TRANSPORT_EMAIL;

        const excludedPeople = selectAllStatus ? peopleList.filter(item => !item._selected).map(item => item.id) : [];

        const selectedContacts = peopleList.filter((item) => selectedPeople[item.id]);
        createCampaign(selectAllStatus, [{ id: selectedSegment.id, ...selectedSegment.segment }], selectedContacts, selectedFilters, isFilterSelected, excludedPeople, transport);

        history.push('/engagement/campaigns/create/new');

    }, [history, isFilterSelected, selectedSegment, selectedPeople, selectedFilters, peopleList, selectAllStatus])

    let isPeopleSelected = selectedPeopleCount > 0;
    return (
        <div className="people-action-bar">
            <div className="d-flex align-items-center">
                <Button className="border-0 mr-2" size="sm" variant={showSegments ? "warning" : "outline-warning"} onClick={onClickSegments} ><FaIcon className="mr-2" icon={faChartPie} />Segments</Button>
                <div className="flex-grow-1">
                    <SearchPeople onSearch={onSearch} searchText={searchText} onChangeSearchText={onChangeSearchText} selectedFilters={selectedFilters} />
                </div>
                <div className="ml-2 d-flex align-items-center">

                    {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write && !isPeopleSelected && <>
                        <Button className="border-0 mr-2" size="sm" variant="outline-primary" onClick={() => { showAddEditPerson(true) }} disabled={!mobileVerified}><FaIcon className="mr-2" icon={faUserPlus} />Create Contact</Button>
                        <Button className="border-0 mr-2" size="sm" variant="outline-pink" onClick={clickedImportPeople} disabled={!mobileVerified}><FaIcon className="mr-2" icon={faUpload} />Import Contacts</Button>
                        <AccountNotVerifiedNotificationButton show={!mobileVerified} />
                    </>}

                    <Button className="border-0 mr-2" size="sm" variant={showFilters ? "success" : "outline-success"} onClick={onClickFilters} ><FaIcon className="mr-2" icon={faFilter} />Filter</Button>
                    <Button className="border-0" size="sm" variant="outline-purple" onClick={_onClickDownload} ><FaIcon className="mr-2" icon={faDownload} />Download</Button>



                    {isPeopleSelected && <>

                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].write &&
                            <Button size="sm" variant="outline-dark" onClick={() => {
                                clickedAddMoreTags(true)
                            }} className="ml-2 border-0"><FaIcon icon={faTag}
                                />&nbsp;&nbsp;Tag
                        </Button>}
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.PEOPLE.moduleId].delete &&
                            <Button size="sm" variant="outline-danger"
                                className="ml-2 border-0"
                                onClick={onClickDelete}><FaIcon
                                    icon={faTrashAlt}
                                />&nbsp;&nbsp;Delete</Button>
                        }
                        {scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].write &&
                            <>{
                                mobileVerified ?
                                    <DropdownButton className={`text-nowrap ml-2 fa fa-bullhorn`} variant="primary" size="sm" title="Create Campaign" id="create-campaign-btn">
                                        <Dropdown.Item eventKey="1" onSelect={createCampaigns}>SMS Campaign</Dropdown.Item>
                                        <Dropdown.Item eventKey="2" onSelect={createCampaigns}>Email Campaign</Dropdown.Item>
                                    </DropdownButton>
                                    //                         <Button size="sm" variant="transparent"
                                    //                             className="ml-2 rounded-0 text-primary"
                                    //                             //disabled={!emailVerified}
                                    //                             onClick={() => {
                                    //                                 const excludedPeople = selectAllStatus ? peopleList.filter(item => !item._selected).map(item => item.id) : [];
                                    //                                 createCampaign(selectAllStatus, selectedSegment, selectedPeople, selectedFilters, isFilterSelected, selectedPeopleCount, excludedPeople);
                                    //                                 history.push('/engagement/campaigns/create/new');
                                    //                             }}><FaIcon
                                    //                                 icon={faBullhorn} />&nbsp;&nbsp;Create
                                    // Campaign</Button>
                                    : <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Your phone number is not verified! Please verify to continue.</Tooltip>}>
                                        <span className="d-inline-block">
                                            <Button size="sm" variant="transparent"
                                                className="ml-2 text-primary disabled"
                                            //disabled={!emailVerified}
                                            ><FaIcon className="mr-2" icon={faBullhorn} />Create Campaign</Button>
                                        </span>

                                    </OverlayTrigger>
                            }
                            </>
                        }
                    </>}

                </div>
                <AddTagDialogContainer />
                <PeopleDeleteContainer />
            </div>
        </div>
    );
});


export default PeopleActionsBar;
