import React, { useState, useEffect } from 'react';
import { Form, Button } from '@shoutout-labs/shoutout-themes';
import {UtilService} from './../../services/Services';
import Constants from './../../Constants';
const SendSmsMessage = ({ senderIds, mobileNumber, history, smsSenderId, changeSenderId, onChangeMessage, message, onChangeMobileNumber, actionFrom, hideSendMessage }) => {
    // const { senderIds, mobileNumber, history, smsSenderId, changeSenderId, onChangeMessage, message, onChangeMobileNumber, actionFrom, hideSendMessage } = this.props;

    const [country, setCountry] = useState("");

    useEffect(() => {
        UtilService.getIpInfo().then(resp => {
            if (resp) {
                setCountry(resp.country);
            }
        });
    }, [])
    return (
        <Form>

            <Form.Group controlId="from">
                <Form.Label>
                    From
                </Form.Label>
                <Form.Control as="select" onChange={(e) => { changeSenderId(e.target.value, Constants.TRANSPORT_SMS) }}
                    value={smsSenderId} required disabled={actionFrom === "campaigns"}>

                    {senderIds.SMS && senderIds.SMS.map(item => {
                        return item.status === 1 ? <option key={item.senderId} value={item.senderId}>{item.senderId}</option> : null;
                    })}
                </Form.Control>
                {/* helpblock was here */}
                <div>
                    <p><Button className="pl-0" variant="link" size="sm" onClick={(e) => { e.preventDefault(); history.push("/senders/numbers"); hideSendMessage() }}>{(country === "us" || country === "ca") ? "Purchase Phone Number" :"Request your Sender ID"}</Button>to send messages from {(country === "us" || country === "ca") ? "a unique mobile number":"your business name."}</p>
                </div>
            </Form.Group>
            <Form.Group controlId="to">
                <Form.Label>
                    To
                </Form.Label>
                <Form.Control
                    placeholder="Format: country code+mobile number without leading zero. Eg: 94777123456" value={mobileNumber} onChange={(e) => { onChangeMobileNumber(e.target.value) }} disabled={actionFrom === "people"} />
            </Form.Group>

            <Form.Group controlId="message">
                <Form.Label>
                    Message
                </Form.Label>
                <Form.Control as="textarea"
                    placeholder="Hello!"
                    onChange={(e) => { onChangeMessage(e.target.value); }} required value={message} disabled={actionFrom === "campaigns"} />
            </Form.Group>
        </Form>
    );
}



export default SendSmsMessage;
