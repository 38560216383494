import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UtilService } from "./../../services/Services";
import { Tab, Tabs } from '@shoutout-labs/shoutout-themes';
import { BillingInvoices, BillingPackages } from './BillingComponents';

import './BillingPlans.css';
import './CountrySelector.css';


const BillingPlans = (props) => {
    const [key, setKey] = useState('packages');




    return (
        <div className="mx-n1 mt-3">
            <div className="my-3">
                <h4 className="font-weight-bold my-3 mx-1">Billing</h4>
                {/* <small className="text-muted"><Link to="/">Dashboard</Link><span className="text-capitalize">{UtilService.setBreadCrumb(props.location.pathname)}</span></small> */}
            </div>
            <Tabs className="pt-2 ml-n25" defaultActiveKey="packages" id="billing-selector" activeKey={key}
                onSelect={setKey}>
                <Tab eventKey="packages" title="Packages">
                    <BillingPackages {...props} />
                </Tab>
                <Tab eventKey="invoices" title="Invoices">
                    {key === "invoices" && <BillingInvoices  {...props} />}
                </Tab>
            </Tabs>
        </div>
    )


}

export default BillingPlans;

