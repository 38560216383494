import React from 'react';
import { Modal, Button } from '@shoutout-labs/shoutout-themes';
import SendEmailMessageContainer from './../../redux/containers/SendEmailMessageContainer';
import SendSmsMessageContainer from './../../redux/containers/SendSmsMessageContainer';
import { UtilService } from './../../services/Services';
import Constants from './../../Constants';
import { isEmpty } from 'lodash';

const SendMessage = ({ show, sendMessage, showSendMessage, isSending, senderId, destination, message, transport, ...rest }) => {

    const hideSendMessage = () => {
        showSendMessage(false)
    }

    return (
        <Modal show={show} onHide={hideSendMessage} size="lg">
            <Modal.Header closeButton>
                <h6 className="mb-0 font-weight-bold">Send Message</h6>
            </Modal.Header>

            <Modal.Body>
                <div>
                    {transport === Constants.TRANSPORT_SMS && <SendSmsMessageContainer {...rest} hideSendMessage={hideSendMessage} />}
                    {transport === Constants.TRANSPORT_EMAIL && <SendEmailMessageContainer {...rest} hideSendMessage={hideSendMessage} />}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button className="mr-2" variant="dark" size="sm" onClick={hideSendMessage}>Close</Button>
                <Button variant="primary" size="sm" onClick={() => { sendMessage(senderId, destination, message, transport) }} disabled={isEmpty(senderId) || isEmpty(message) || isEmpty(destination) || isSending}>{isSending ? 'Sending...' : 'Send'}</Button>
            </Modal.Footer>

        </Modal>);


}

export default SendMessage;
