import React, { Component, Suspense } from "react";
import Notifications from "react-notify-toast";
import ErrorBoundary from './components/utils/ErrorBoundaryComponent';
import { Route, Switch } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import { LoadingRocket } from './components/utils/UtilComponents';
import AuthRedirectRoute from './AuthRedirectRoute';
import Home from './components/home/Home';
import VerifyPage from './components/verify/VerifyPage';
import Login from './components/account/views/Login';
import UnsubscriptionPage from './components/unsubscription/UnsubscriptionPage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


import "react-joyride/lib/react-joyride-compiled.css";
// import "bootstrap/dist/css/bootstrap.min.css"; 
import '@shoutout-labs/shoutout-themes/es/themes/shoutout/bootstrap.min.css';
import 'react-virtualized/styles.css';

import "./index.css";


const loadStripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
class App extends Component {
    static contextType = UserContext;

    render() {
     
        return (
            <div className="App overflow-hidden">
                <Notifications options={{ zIndex: 10020 }} />

                {/* <NotificationBar /> */}



                {/*
                <Loader loaded={this.props.isLoaderHidden}/>
*/}
                <ErrorBoundary>
                    <Elements stripe={loadStripePromise}>
                        <Suspense fallback={<LoadingRocket />}>
                            <Switch>
                                <Route name="Unsubscribe" exact path="/unsubscribe/:cId/:oId/:addr" component={UnsubscriptionPage} />
                                <Route name="Verify" exact path="/verify/:type" component={VerifyPage} />
                                <Route name="Login" exact path="/login" component={Login} />
                     
                                    <AuthRedirectRoute name="Home" component={Home} />
                    
                            </Switch>
                        </Suspense>
                    </Elements>
                </ErrorBoundary>
            </div>
        )
    }
}

export default App;
