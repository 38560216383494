import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import { Button, Card } from '@shoutout-labs/shoutout-themes';
import './DashboardModule.css';
import { AccountService, UtilService } from '../../services/Services';
import OtpVerify from './OtpVerify';
import { notify } from 'react-notify-toast';
import UserContext from './../../contexts/UserContext';
import { OnboardingContext, StepNames } from './../../contexts/OnboardingContext';
import ChangeMobileModal from './ChangeMobileModal';


const MobileVerifyBanner = () => {

    const [changePhoneNo, setChangePhoneNo] = useState(false);
    // const [mobileNumber, setMobileNumber] = useState();
    // const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
    const [verify, setVerify] = useState(false);
    //const [isChangingNumber, setIsChangingNumber] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const { loadUser, mobile } = useContext(UserContext);
    const { steps, allowResendOTP, enableResendTimer, expireInSeconds } = useContext(OnboardingContext);

    const disabled = useMemo(() => {
        return steps[StepNames.STEP1];

    }, [steps[StepNames.STEP1]])

    const onVerify = useCallback(async () => {
        try {
            setIsSendingCode(true);
            await AccountService.mobileVerification();
            setVerify(true);
            setIsSendingCode(false);
            notify.show("Verification code sent", "success");

        } catch (e) {
            notify.show("Verification code sending failed. Please try again.", "error");
        } finally {
            setIsSendingCode(false);
            enableResendTimer();
        }
    }, [setVerify, setIsSendingCode, enableResendTimer]);

    const onChangePhoneNo = useCallback(() => {
        setChangePhoneNo(!changePhoneNo);
    }, [setChangePhoneNo, changePhoneNo]);

    // const setAnotherPhoneNo = useCallback(async () => {
    //     try {
    //         setIsChangingNumber(true);
    //         await AccountService.changeMobile(mobileNumber);
    //         notify.show("Mobile number updated", "success");
    //         loadUser();
    //         setChangePhoneNo(false);

    //     } catch (e) {
    //         notify.show("Mobile number updating failed. Please try again.", "error");
    //     } finally {
    //         setIsChangingNumber(false);
    //     }

    // }, [setChangePhoneNo, mobileNumber, setIsChangingNumber, loadUser]);

    // const getIpInfo = useCallback((callback) => {
    //     UtilService.getIpInfo().then(resp => {
    //         callback((resp && resp.country) ? resp.country : '');
    //     });
    // }, []);

    // const onMobileNumberChange = useCallback(async (status, value, countryData, number, id) => {

    //     if (status) {

    //         const mobileNo = number.replace(/[^0-9]/gi, '');
    //         //setMobileNumber(value);
    //         setIsMobileNumberValid(true);
    //         setMobileNumber(mobileNo);

    //     } else {
    //         setMobileNumber('');
    //         setIsMobileNumberValid(value.length === 0);
    //     }
    // }, [setMobileNumber, setIsMobileNumberValid]);

    return (
        <>
            <Card className="border-0 bg-transparent">
                <h6 className="mb-0">STEP 01 - Verify Phone Number</h6>
                <div className="d-flex align-items-center">
                    <small className="text-secondary">Verify your phone number (+{mobile}) to get started.</small>
                    <Button className="btn btn-primary btn-sm ml-2" onClick={onVerify} disabled={isSendingCode || !allowResendOTP}>{isSendingCode ? "Sending code..." : "Verify"}</Button>
                    <Button variant="transparent" className="btn text-primary btn-sm ml-2" onClick={onChangePhoneNo} disabled={isSendingCode || !allowResendOTP}>Change Phone Number</Button>
                    {!allowResendOTP && <small className="text-muted ml-2">Resend in {UtilService.getMinAndSeconds(expireInSeconds)}</small>}
                </div>
            </Card>

            <OtpVerify verify={verify} setVerify={setVerify} mobile={mobile} onVerify={onVerify} allowResendOTP={allowResendOTP} expireInSeconds={expireInSeconds} />
            <ChangeMobileModal changePhoneNo={changePhoneNo} onChangePhoneNo={onChangePhoneNo} setChangePhoneNo={setChangePhoneNo} allowResendOTP={allowResendOTP} />
        </>

    )

};

export default MobileVerifyBanner;


