/**
 * Created by asankanissanka on 2/21/17.
 */
import { connect } from './../../contexts/ContextUtils';

import { getSenderIds } from './../actions/CommonActions';
import MyProfile from './../../components/my_profile/MyProfile';
import Senders from './../../components/senders/Senders';
import { showAlertBox } from './../actions/UtilActions';
import { withRouter } from 'react-router-dom';



const MyProfileContainer = withRouter(connect(
    (state) => {//map state to props
        return {
            smsSenderIds: state.common.senderIds.SMS || [],
            emailSenderIds: state.common.senderIds.EMAIL || []
        };
    },
    (dispatch, ownProps) => {// map dispatch to props
        return {
            sentSenderIdRequest: (newSenderId) => dispatch(getSenderIds()),
            showAlert: (title, message, alertType) => dispatch(showAlertBox(title, message, true, alertType))
        }
    }
)(MyProfile));

const SendersContainer = withRouter(connect(
    (state) => {//map state to props
        return {
            smsSenderIds: state.common.senderIds.SMS || [],
            emailSenderIds: state.common.senderIds.EMAIL || []
        };
    },
    (dispatch, ownProps) => {
        return {
            sentSenderIdRequest: (newSenderId) => dispatch(getSenderIds()),
            showAlert: (title, message, alertType) => dispatch(showAlertBox(title, message, true, alertType))
        }
    }
)(Senders));


export {
    MyProfileContainer,
    SendersContainer
}
