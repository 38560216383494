//Added this context for migration purpose from redux to context
import React, { createContext, useReducer, useEffect, useContext, useCallback } from "react";
import UserContext from './UserContext';
import { ShoutOUTService } from './../services/Services';
import Constants from './../Constants';
const InitialHomeState = {
    recentCampaigns: [],
    isLoadingCampaigns: true
};
const HomeContext = createContext();

const HOME_ACTIONS = {
    SET_LOADING_CAMPAIGNS: "SET_LOADING_CAMPAIGNS",
    SET_RECENT_CAMPAIGNS: "SET_RECENT_CAMPAIGNS"
}


const reducer = (state, { type, ...rest }) => {

    switch (type) {

        case HOME_ACTIONS.SET_RECENT_CAMPAIGNS: {
            return { ...state, recentCampaigns: rest.campaigns, isLoadingCampaigns: false };
        }

        case HOME_ACTIONS.SET_LOADING_CAMPAIGNS: {
            return { ...state, isLoadingCampaigns: rest.status };
        }
        default: {
            return state;
        }
    }
};


const HomeContextContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, InitialHomeState);
    const { isLoggedIn, scopes } = useContext(UserContext);





    const loadRecentCampaigns = useCallback(async () => {

        try {
            
            //setIsLoadingCampaigns(true);
            const result = await ShoutOUTService.getCampaigns(3, 0);
            if (result && result.length > 0) {
                dispatch({ type: HOME_ACTIONS.SET_RECENT_CAMPAIGNS, campaigns: result });
            } else {
                dispatch({ type: HOME_ACTIONS.SET_LOADING_CAMPAIGNS, status: false });
            }


        } catch (e) {
            dispatch({ type: HOME_ACTIONS.SET_LOADING_CAMPAIGNS, status: false });
        }
    }, [dispatch])
    useEffect(() => {
        if (isLoggedIn) {
            if (scopes[Constants.USER_ACCESS_CONTROLS.MODULES.CAMPAIGNS.moduleId].read) {
                loadRecentCampaigns();
            }
        }
    }, [isLoggedIn])




    const value = { ...state, loadRecentCampaigns};
    return (
        <HomeContext.Provider value={value}>{props.children}</HomeContext.Provider>
    );
}


export default HomeContext;
export { HomeContext, HomeContextContainer };