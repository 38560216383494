import { BillingService } from './../../services/Services';
const BILLING_ACTIONS = {
    RECEIVED_CREDITS: 'RECEIVED_CREDITS',
    UPDATING_BILLING_INFO: 'UPDATING_BILLING_INFO',
    RECEIVED_BILLING_INFO: 'RECEIVED_BILLING_INFO',
    RECEIVED_INVOICES: 'RECEIVED_INVOICES',
    SELECT_PURCHASE_PLAN: 'SELECT_PURCHASE_PLAN',
    RECEIVED_BILLING_PLANS: 'RECEIVED_BILLING_PLANS'
};
export default BILLING_ACTIONS;


const receivedBillingInfo = ({ billingDetails, campaignInfo ,subscriptions}) => {
    return {
        type: BILLING_ACTIONS.RECEIVED_BILLING_INFO,
        billingInfo:billingDetails,
        campaignInfo,
        subscriptions
    }
};

const requestUpdateBillingInfo = (name, streetAddress, streetAddress2, city, state, zip, country, email, phone) => {
    return (dispatch) => {
        //TODO: Send to backend + show loader
        dispatch({
            type: BILLING_ACTIONS.UPDATING_BILLING_INFO
        });

        BillingService.updateBillingInfo({ name, streetAddress, streetAddress2, city, state, zip, country, email, phone }).then(res => {
            dispatch(receivedBillingInfo({ name, streetAddress, streetAddress2, city, state, zip, country, email, phone }));
        }, err => {
            console.debug("Error updating:", err);
        })

    }
}

const requestBillingInfo = () => {
    return (dispatch) => {
        BillingService.getBillingInfo().then(res => {
            if (res) {
                dispatch(receivedBillingInfo(res));
            }
        }, err => {

        });
    }
};
const receivedInvoices = (invoiceList) => {
    return {
        type: BILLING_ACTIONS.RECEIVED_INVOICES,
        invoiceList
    }
};
const requestInvoices = () => {
    return (dispatch) => {
        BillingService.getInvoices().then(res => {
            dispatch(receivedInvoices(res.Items));
        })

    }
};


const navigateToPurchase = (selectedPlan) => {
    return (dispatch) => {
        dispatch({
            type: BILLING_ACTIONS.SELECT_PURCHASE_PLAN, selectedPlan
        })

    }
};

const loadBillingPlans = () => {
    return (dispatch) => {
        BillingService.getPlans().then(res => {
            dispatch({ type: BILLING_ACTIONS.RECEIVED_BILLING_PLANS, plans: res });
        })

    }
}


export { requestUpdateBillingInfo, receivedBillingInfo, receivedInvoices, requestInvoices, requestBillingInfo, navigateToPurchase, loadBillingPlans };