import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import Constants from './../../Constants';
import UserContext from './../../contexts/UserContext';
import { BillingService, AnalyticsService, UtilService } from './../../services/Services';
import { Row, Col, Card, Button, InputSpinner, FaIcon, Tabs, Tab, CardDeck } from '@shoutout-labs/shoutout-themes';
import { ConfirmBox } from './../utils/UtilComponents';
import { faInfo, faChevronDown, faChevronUp } from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { useHistory } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import "./Billing.css";

let selectedSubscriptionPackage = {};
const BillingPackageList = ({ plans, selectedTransport, navigateToPurchase, currentSubscriptions, onUnsubscribePackage, getBillingInfo, selectedCountry, smsCreditsOnDemand, smsCreditsPlan }) => {
    const [smsOndemand, setSmsOndemand] = useState({});
    const [smsSubscription, setSmsSubscription] = useState({});
    const [emailSubscription, setEmailSubscription] = useState({});
    const [emailOndemand, setEmailOndemand] = useState({});
    const [emailOndemandQuantity, setEmailOndemandQuantity] = useState(1);
    const [subscribeConfirm, setSubscribeConfirm] = useState(false);
    // const [selectedPackageId, setSelectedPackageId] = useState();
    const [filterTriggered, setFilterTriggered] = useState("")
    const [filterCountries, setFilterCountries] = useState([])
    const [selector, setSelector] = useState(false);
    const [countryBillingMap, setCountryBillingMap] = useState([])
    const [countryDetails, setCountryDetails] = useState({ value: 1, country: { name: "Sri Lanka", code: "lk" } })
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);
    const [purchaseAmount, setPurchaseAmount] = useState(1);

    const packsRow1 = [1, 2, 5, 10, 15, 20]
    const packsRow2 = [25, 30, 35, 40, 45, 50]
    const history = useHistory();
    const node = useRef();

    const userContext = useContext(UserContext);
    useEffect(() => {
        if (plans.length > 0) {
            const transformed = plans.reduce((result, plan) => {
                plan.currency = plan.currency.toUpperCase();
                switch (plan.category) {
                    case Constants.TRANSPORT_SMS: {
                        if (plan.metaData.type === "subscription") {
                            result.smsSubscription[plan.id] = plan;
                        } else {
                            result.smsOndemand[plan.id] = { ...plan, quantity: 1 };
                        }
                        break;
                    }

                    case Constants.TRANSPORT_EMAIL:
                        {
                            if (plan.metaData.type === "subscription") {
                                result.emailSubscription[plan.id] = plan;
                            } else {
                                result.emailOndemand[plan.id] = { ...plan, quantity: 1 };
                            }
                            break;
                        }
                }

                return result;
            }, {
                smsOndemand: {},
                smsSubscription: {},
                emailOndemand: {},
                emailSubscription: {}
            });

            // const transformedSMS = plans.reduce((result, plan) => {
            //     // plan.currency = plan.currency.toUpperCase();
            //     switch (plan.category) {
            //         case Constants.TRANSPORT_SMS:
            //             {
            //                 if (plan.metaData.type === "subscription") {
            //                     result.smsSubscription[plan.id] = plan;

            //                 } else {
            //                     result.smsOndemand[plan.id] = { ...plan, quantity: 1 };
            //                 }
            //                 break;
            //             }
            //     }

            //     return result;
            // }, {
            //     smsOndemand: {},
            //     smsSubscription: {},
            // });

            setSmsOndemand(transformed.smsOndemand);
            setSmsSubscription(transformed.smsSubscription);
            // setSmsSubscription(plansDemo)
            setEmailOndemand(transformed.emailOndemand);
            setEmailSubscription(transformed.emailSubscription);
        }
    }, [plans])



    useEffect(() => {
        const fetchPricing = async () => {
            const countriesBillingMap = await BillingService.getSMSPricing()
            const sortedCountryBillingMap = countriesBillingMap.sort(function (a, b) {
                if (a.countryName < b.countryName) { return -1; }
                if (a.countryName > b.countryName) { return 1; }
                return 0;
            });
            setCountryBillingMap(sortedCountryBillingMap)
            UtilService.getIpInfo().then(resp => {
                const selectedCountry = resp && (countriesBillingMap.find(country => (country.countryISOCode.toLowerCase() === resp.country.toLowerCase())))
                setCountryDetails({ value: selectedCountry.credits, country: { name: selectedCountry.countryName, code: selectedCountry.countryISOCode.toLowerCase() }, notes: selectedCountry.notes });
            });
        }
        fetchPricing();

    }, [])

    const onSelectPackage = useCallback((e) => {
        const selectedPlan = smsOndemand[e.target.id];
        const quantity = e.target.value;

        const noSelectedPlan = { ...selectedPlan, quantity: quantity, credits: { creditAmountMap: { platform: smsOndemand?.sms_v4_4?.credits?.creditAmountMap[currentSmsId] } } }
        navigateToPurchase(noSelectedPlan);
        history.push('/billing/purchase');
    }, [history, navigateToPurchase, emailOndemandQuantity]);

    const onSelectEmailPackage = useCallback((e) => {

        const selectedPlan = emailOndemand[e.target.id];

        const noSelectedPlan = { ...selectedPlan, quantity: emailOndemandQuantity }
        navigateToPurchase(noSelectedPlan);
        history.push('/billing/purchase');
    }, [history, navigateToPurchase, emailOndemandQuantity]);

    const handleClick = useCallback((e) => {
        if (node.current !== null) {
            if (node.current.contains(e.target)) {
                return;
            }
            setSelector(false);
        }
    }, [setSelector]);

    useEffect(() => {
        try {
            if (selector) {
                document.addEventListener("mousedown", handleClick);
            } else {
                document.removeEventListener("mousedown", handleClick);
            }
        } catch (e) { }
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, [selector]);

    const selectSmsOndemandPackage = useCallback((e) => {
        e.stopPropagation();
        onSelectPackage(smsOndemand[e.currentTarget.dataset.id]);
    }, [onSelectPackage, smsOndemand])

    const onClickSubscribe = useCallback((e) => {
        e.stopPropagation();
        selectedSubscriptionPackage = { id: e.currentTarget.id, type: e.currentTarget.dataset.type };
        // onSelectPackage(smsSubscription[e.currentTarget.id]);
        setSubscribeConfirm(true);
    }, [setSubscribeConfirm])

    const onHideSubscribe = useCallback(() => {
        setSubscribeConfirm(false);
        selectedSubscriptionPackage = "";
    }, [setSubscribeConfirm])


    const selectSubscriptionPackage = useCallback((e) => {
        e.stopPropagation();

        // onSelectPackage(selectedSubscriptionPackage.type === "sms" ? smsSubscription[selectedSubscriptionPackage.id] : emailSubscription[selectedSubscriptionPackage.id]);
        setSubscribeConfirm(false);
    }, [onSelectPackage, smsSubscription, emailSubscription, setSubscribeConfirm])

    const onSelectSub = useCallback((e) => {
        const selectedPlan = selectedSubscriptionPackage.type === "sms" ? smsSubscription[selectedSubscriptionPackage.id] : emailSubscription[selectedSubscriptionPackage.id]

        // update the selected plan here
        const noSelectedPlan = { ...selectedPlan, quantity: 1 }
        navigateToPurchase(noSelectedPlan);
        history.push('/billing/purchase');
    }, [history, navigateToPurchase]);

    const selectEmailOndemandPackage = useCallback((e) => {
        e.stopPropagation();
        onSelectPackage(emailOndemand[e.currentTarget.id]);
    }, [onSelectPackage, emailOndemand])



    const onDropToggle = useCallback((e) => {
        const countryName = e.target.id;
        const selectedCountry = countryBillingMap.find(country => (country.countryName === countryName));
        setCountryDetails({ value: selectedCountry.credits, country: { name: countryName, code: selectedCountry.countryISOCode.toLowerCase() }, notes: selectedCountry.notes });
        if (filterTriggered !== "") {
            setFilterTriggered("");
        }
        setSelector(!selector);

    }, [setSelector, setCountryDetails, setFilterTriggered, countryBillingMap, selector])

    const onDropDown = useCallback(() => {
        setSelector(!selector);
        setFilterCountries(countryBillingMap)
    }, [setSelector, selector, setFilterCountries, countryBillingMap])

    const onSMSCountryInput = useCallback((e) => {
        const searchCountry = e.target.value.toLowerCase();
        setFilterTriggered(searchCountry);
        const filteredCountries = filterTriggered ? countryBillingMap.filter(country => country.countryName.toLowerCase().includes(filterTriggered)) : countryBillingMap;
        setFilterCountries(filteredCountries)
    }, [setFilterCountries, setFilterTriggered, filterTriggered])

    const updateSmsOnDemandQuantity = useCallback((id, value) => {


        const packages = { ...smsOndemand };
        packages[id] = { ...packages[id], quantity: value };
        setSmsOndemand(packages)
    }, [smsOndemand, setSmsOndemand])


    const updateEmailOnDemandQuantity = useCallback((id, value) => {

        setEmailOndemandQuantity(value);
        const packages = { ...emailOndemand };
        packages[id] = { ...packages[id], quantity: value };
        setEmailOndemand(packages);
    }, [emailOndemand, setEmailOndemand, emailOndemandQuantity, setEmailOndemandQuantity])

    const openChatWidget = useCallback(() => {
        AnalyticsService.trackActivity({
            "name": "trigger-chatBox"
        });
    }, [])


    const currentSubscriptionPlans = useMemo(() => {

        return (currentSubscriptions || []).reduce((result, item) => {
            result[item.planId] = item.id;
            return result;
        }, {});

    }, [currentSubscriptions]);

    const hasSmsSubscriptionPackage = useMemo(() => {
        return Object.values(smsSubscription).find((item) => currentSubscriptionPlans[item.id]) !== undefined;
    }, [currentSubscriptionPlans, smsSubscription])


    const hasEmailSubscriptionPackage = useMemo(() => {
        return Object.values(emailSubscription).find((item) => currentSubscriptionPlans[item.id]) !== undefined;
    }, [currentSubscriptionPlans, emailSubscription])



    const unsubscribePackage = useCallback(async (e) => {
        try {
            const id = e.currentTarget.id;
            setIsUnsubscribing(true);
            await BillingService.unsubscribeSubscription(id);
            getBillingInfo();
            notify.show("Unsubscribe successfully", "success");
            // onUnsubscribePackage();
        } catch (e) {
            console.error(e);
            notify.show("Problem occurred when cancel the subscription. Please contact support", "error");
        } finally {
            setIsUnsubscribing(false);
            getBillingInfo();
        }
    }, [setIsUnsubscribing, getBillingInfo])


    useEffect(() => {
        getBillingInfo();

        //eslint-disable-next-line
    }, [isUnsubscribing, setIsUnsubscribing])



    const onSelectPurchaseAmount = (e) => {
        const amount = e.currentTarget.value;
        setPurchaseAmount(parseInt(amount))
    }

    const currentSmsSubscription = currentSubscriptions === undefined ? [] : currentSubscriptions.filter(element => element?.planId && element?.planId.includes("sms"))
    const currentSmsId = currentSmsSubscription[0] ? currentSmsSubscription[0].planId : "platform_sms_1";

    return (
        <>
            {
                selectedTransport === "sms" ? < Row >
                    <Col lg={12}>
                        <h6 className="font-weight-bold ml-1">Plans</h6>
                        <Col lg={12} sm={12} className="px-0">
                            <CardDeck className="my-3">
                                {
                                    Object.values(smsSubscription).map((item, index) => {                                      
                                        return (<Card key={item.id} className="border-0 mb-2 package-card-1" variant="primary">
                                            {/* This is used to select the active plans */}
                                            {currentSmsId === item.id ? <div className="bg-success mx-auto active-package-label">Active Package</div> : <div className="bg-transparent mx-auto active-package-label">Non Active</div>}
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-between">
                                                <p className="mb-0 text-primary" > {item.name}

                                                </p>
                                                <div className="text-center d-flex justify-content-center align-items-end">
                                                    {item.currency_amount === 0 ? <h4 className="mb-0"><strong>Free</strong></h4> :
                                                        <>
                                                            <h4 className="mb-2">
                                                                <strong> {item.currency} {item.currency_amount} </strong>
                                                            </h4>
                                                            <p className="mb-2" > /mo</p>
                                                        </>}
                                                </div>
                                                <div className="text-center">
                                                    <p class="per-sms-rate-text mb-3">Buy credits for only
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className={`d-inline-block mr-2 sms-country-selector-flag ${selectedCountry.country.code}`} > </div> <span className="" ><strong> {item.currency} {((smsOndemand?.sms_v4_4?.currency_amount) / ((index === 0 ? smsOndemand?.sms_v4_4?.credits?.creditAmountMap.platform_sms_1 : index === 1 ? smsOndemand?.sms_v4_4?.credits?.creditAmountMap.platform_sms_2 : smsOndemand?.sms_v4_4?.credits?.creditAmountMap.platform_sms_3) / selectedCountry.value)).toFixed((countryDetails.country.code === "us" || countryDetails.country.code === "ca") ? 4 : 2)} </strong> per SMS</span >
                                                        </div>
                                                    </p>

                                                    <p className={`badge-platform-offer ${index === 0 && "opacity-0"}`}>{(100 - (((
                                                        ((smsOndemand?.sms_v4_4?.currency_amount) / ((index === 0 ? smsOndemand?.sms_v4_4?.credits?.creditAmountMap.platform_sms_1 : index === 1 ? smsOndemand?.sms_v4_4?.credits?.creditAmountMap.platform_sms_2 : smsOndemand?.sms_v4_4?.credits?.creditAmountMap.platform_sms_3) / selectedCountry.value))
                                                        /
                                                        ((smsOndemand?.sms_v4_4?.currency_amount) / ((smsOndemand?.sms_v4_4?.credits?.creditAmountMap.platform_sms_1) / selectedCountry.value))
                                                    ) * 100)).toFixed(1))}

                                                        % lower than
                                                        Starter plan
                                                    </p>
                                                </div>
                                                <div className="mt-4 mb-3">
                                                    <ul className="tick-list-image pricing-features">
                                                        {item.features.map((feature, index) => {
                                                            return (<li key={index} className="mb-2">{feature}</li>)
                                                        })}
                                                    </ul>
                                                </div>

                                                {item.id !== "platform_sms_1" ? <>
                                                    {currentSubscriptionPlans[item.id] ?
                                                        <Button variant="success" size="sm" id={currentSubscriptionPlans[item.id]} onClick={unsubscribePackage} disabled={isUnsubscribing} > {isUnsubscribing ? "Unsubscribing..." : "Unsubscribe"} </Button> :
                                                        <Button variant="primary" size="sm" id={item.id} data-type="sms" onClick={onClickSubscribe} disabled={hasSmsSubscriptionPackage} > Subscribe </Button>
                                                    }
                                                </> : <div>
                                                    <br />
                                                </div>
                                                }

                                            </Card.Body>

                                        </Card>

                                        )
                                    })
                                }

                                {/* <Card className="border-0 mb-2 package-card" variant="primary">
                                    <div className="bg-transparent mx-auto active-package-label">Non Active</div>
                                    <Card.Body className="d-flex flex-column align-items-center justify-content-between">

                                        <p className="mb-0 text-primary" >Enterprise</p>
                                        <div className="text-center"><small>More than<strong> 50,000 SMS </strong> per month?</small>
                                        </div> <Button variant="primary"
                                            size="sm"
                                            onClick={openChatWidget} > Contact Us </Button>
                                    </Card.Body>

                                </Card> */}
                            </CardDeck>

                        </Col>
                        <Col lg={12} sm={12} className="px-0 mb-4">
                            <h6 className="font-weight-bold ml-1">SMS Packages</h6>
                            <Card className="border-0 mx-1 mb-3">
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} sm={12}>
                                            <div className="d-flex align-items-center">
                                                <p className="mr-2">Available SMS Amount</p>

                                                <p className="font-weight-bold">{smsCreditsOnDemand + smsCreditsPlan !== 0 ? (((smsCreditsOnDemand + smsCreditsPlan) / (selectedCountry.value)).toFixed(0)) : 0}</p>
                                                <div className={`flag-size-2x d-inline-block mx-2 mb-0 sms-country-selector-flag ${selectedCountry.country.code}`}></div>
                                            </div>
                                        </Col>
                                        <Col lg={6} sm={12}>
                                            <div className="d-flex align-items-center">
                                                <p className="mr-2">Available SMS Credits</p>
                                                <p className="font-weight-bold">{smsCreditsOnDemand + smsCreditsPlan}</p>
                                                {/* <div className={`flag-size-2x d-inline-block mx-2 mb-0 sms-country-selector-flag ${selectedCountry.country.code}`}></div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <div className="p-4 bg-white">
                                {/* <p>Purchase SMS Credits</p> */}
                                <div className="d-flex align-items-center justify-content-between py-2" >
                                    <div>
                                        <div className="d-flex">
                                            {packsRow1.map(item => {
                                                return (
                                                    <button key={item} className={`purchase-box mr-2 mb-2 py-2 px-2 ${purchaseAmount === item ? "active" : ""}`} onClick={onSelectPurchaseAmount} value={item}> {smsOndemand?.sms_v4_4?.currency} {(smsOndemand?.sms_v4_4?.currency_amount * item) || "LKR 0"} </button>
                                                )
                                            })}

                                        </div>


                                        <div className="d-flex">
                                            {packsRow2.map(item => {
                                                return (
                                                    <button key={item} className={`purchase-box mr-2 mb-2 py-2 px-2 ${purchaseAmount === item ? "active" : ""}`} onClick={onSelectPurchaseAmount} value={item}> {smsOndemand?.sms_v4_4?.currency} {(smsOndemand?.sms_v4_4?.currency_amount * item) || "LKR 0"} </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div>

                                        <h4> <strong> {(smsOndemand?.sms_v4_4?.credits?.creditAmountMap[currentSmsId] * purchaseAmount / selectedCountry.value).toFixed(0)} </strong>
                                            SMS to
                                            <div className={`flag-size-2x d-inline-block mr-2 mb-0 sms-country-selector-flag ${selectedCountry.country.code}`}></div>
                                        </h4>
                                        <p> {smsOndemand?.sms_v4_4?.credits?.creditAmountMap[currentSmsId] * purchaseAmount} credits ({smsOndemand?.sms_v4_4?.currency}{" "}{((smsOndemand?.sms_v4_4?.currency_amount) * purchaseAmount / (smsOndemand?.sms_v4_4?.credits?.creditAmountMap[currentSmsId] * purchaseAmount / selectedCountry.value)).toFixed(countryDetails.country.code === "us" ? 4 : 2)} per SMS) </p>
                                        <button className="btn btn-primary btn-sm" id={smsOndemand?.sms_v4_4?.id} value={purchaseAmount} onClick={onSelectPackage}> Purchase Credits </button>
                                    </div>
                                </div>

                            </div>


                        </Col>
                    </Col>





                </Row > :

                    <Row>
                        <Col lg={6}
                            sm={12}
                            className="px-0" >
                            <Card className="mt-3 bg-transparent border-0" >
                                <Card.Body >
                                    <h6 className="font-weight-bold mb-4" > Monthly Packages </h6>
                                    <Card className="border-0 mb-2 py-2" variant="primary" >

                                        <div className="d-flex align-items-center justify-content-between py-2" >
                                            <div className="total-email" >
                                                <small> Total </small>
                                            </div>
                                        </div>

                                    </Card>
                                    {
                                        Object.values(emailSubscription).map((item) => (<Card className="border-0 mb-2 package-card"
                                            key={item.id} >

                                            <Card.Body className="d-flex align-items-center justify-content-between" >

                                                <div>
                                                    <p className="mb-0" > {item.blockSize} Emails </p>
                                                    <hr className="my-0 dashed" />
                                                    <small className="extra-small" > Per Email Rate <strong> {item.currency} {item.currency_amount / item.credits.creditAmount} </strong></small >
                                                </div>
                                                <div className="total-email" >

                                                    <h6 className="font-weight-bold mb-0" > {item.currency} {item.currency_amount} </h6>
                                                </div> {currentSubscriptionPlans[item.id] ?
                                                    <Button variant="success" size="sm" id={currentSubscriptionPlans[item.id]} onClick={unsubscribePackage} disabled={isUnsubscribing} > {isUnsubscribing ? "Unsubscribing..." : "Unsubscribe"} </Button> :
                                                    <Button variant="primary" size="sm" id={item.id} data-type="email" onClick={onClickSubscribe} disabled={hasEmailSubscriptionPackage} > Subscribe </Button>
                                                }

                                            </Card.Body>
                                        </Card>

                                        ))
                                    }

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} sm={12} className="px-0" >
                            <Card className="mt-3 bg-transparent border-0" >
                                <Card.Body >
                                    <div className="d-flex align-items-center justify-content-start" >
                                        <h6 className="font-weight-bold mb-4" > Pay As You Go </h6> <small className="sms-country-selector-text ml-2 mb-4" > (No Expiration) </small>
                                    </div>

                                    <Card className="border-0 mb-2 py-2" variant="primary" >

                                        <div className="d-flex align-items-center justify-content-between py-2" >
                                            <div className="total-email" >
                                                < small > Total </small> </div>
                                        </div>

                                    </Card>
                                    {Object.values(emailOndemand).map((item) => (<Card className="border-0 mb-2 package-card" key={item.id} >
                                        <Card.Body className="d-flex align-items-center justify-content-between" >

                                            <div>
                                                <p className="mb-0" > {item.blockSize} Emails </p>
                                                <hr className="my-0 dashed" />
                                                <small className="extra-small" > Per Email Rate < strong > {item.currency} {item.currency_amount / item.credits.creditAmount} </strong></small >
                                            </div> <div className="total-email" >
                                                <h6 className="font-weight-bold mb-0" > {item.currency} {item.currency_amount} </h6> </div> <
                                                    div className="text-center" >
                                                <InputSpinner className="input-spinner-width" min="1" max="5" value={item.quantity} onChange={
                                                    (value) => updateEmailOnDemandQuantity(item.id, value)}
                                                /> <Button variant="primary" size="sm" id={item.id} data-type onClick={onSelectEmailPackage} > Select </Button> </div>

                                        </Card.Body> </Card>

                                    ))
                                    }

                                </Card.Body> </Card> </Col>
                        <Col lg={12} >
                            <Card className="border-0 mx-1" >
                                <Card.Body >
                                    <p className="px-4" > < FaIcon className="mr-2"
                                        icon={faInfo} />Notes</p>
                                    <div >
                                        <ul >
                                            <li > <small> You will be charged for number of <strong> emails sending out </strong> from your ShoutOUT account and you can have unlimited email subscribers/contacts </small></li >
                                            <li > <small> ShoutOUT <strong> will not guarantee </strong> on the delivery of emails to the primary folder. That will be depending on your sending email, past history of it and the email domain</small> </li>
                                        </ul> </div> </Card.Body>
                            </Card>
                        </Col>
                    </Row>
            }

            <ConfirmBox show={subscribeConfirm}
                onHide={onHideSubscribe}
                confirmCallback={onSelectSub}
                title="Warning"
                message="Please note you won’t be able to unsubscribe from monthly packages until 3 months once subscribed."
                variant="warning" />

        </>
    )
}
export default BillingPackageList;