/**
 * Created by madura on 1/26/17.
 */
import React from "react";
import {
    Button,
    Modal
} from '@shoutout-labs/shoutout-themes';

const ConfirmDeleteDialog = ({ showConfirmDeleteDialog, selectedPeopleCount, onConfirm, closeConfirmDeleteDialog, selectedPeople, selectAll, selectAllStatus, selectedFilters, showLoader, selectedSegmentId, peopleList }) => {
    return (
        <Modal show={showConfirmDeleteDialog} onHide={closeConfirmDeleteDialog}>
            <Modal.Header closeButton>
                <h6 className="mb-0 font-weight-bold">Confirm Delete</h6>
            </Modal.Header>
            <Modal.Body>
                <div>You are about to delete {selectedPeopleCount} people. Are you sure?</div>
            </Modal.Body>

            <Modal.Footer>
                <Button className="mr-2" variant="dark" size="sm" onClick={closeConfirmDeleteDialog} disabled={showLoader}>Cancel</Button>
                <Button variant="danger" size="sm" onClick={() => {
                    onConfirm(selectedPeople, selectAll, selectAllStatus, selectedFilters, selectedSegmentId, peopleList)
                }} disabled={showLoader}>{showLoader ? 'Deleting...' : 'Delete'}</Button>
            </Modal.Footer>

        </Modal>
    );
};

export default ConfirmDeleteDialog;