import React, { Component } from "react";

import {
    Row,
    Col,
    Card,
    FaIcon
} from '@shoutout-labs/shoutout-themes';
import {faExclamationCircle} from '@shoutout-labs/shoutout-themes/es/FaIconsSolid';
import { Link } from 'react-router-dom';

class InvalidRequest extends Component {

    render() {
        return (
            <div className="container">
                <Row>
                    <Col xs={12} sm={8}  md={6}  lg={6}  className="content-wrapper">
                        <Card>
                            <Card.Body>
                                <div className="text-right"><Link to="/people" activeClassName="active-link">
                                    <small>Go to dashboard</small>
                                </Link>
                                </div><br />
                                <div className="text-center">
                                    <span className="text-danger"><FaIcon icon={faExclamationCircle} size="3x" /></span><br /><h4>Invalid Request</h4>
                                </div>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </div>
        );
    }
}
export default InvalidRequest;
