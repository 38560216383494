import React, { useState, useCallback, useRef } from 'react';
import { Modal } from '@shoutout-labs/shoutout-themes';
import { notify } from 'react-notify-toast';
import Multistep from '../../lib/react-multistep/multistep';
import NumberPurchaseSelect from './NumberPurchaseSelect';
import NumberPurchaseSummary from './NumberPurchaseSummary';
import { ShoutOUTUtilityService } from "../../services/Services";


const NumberPurchaseModal = ({ show, onHide }) => {

    const [newNumbers, setNewNumbers] = useState([]);
    const [isLoadingNewNumbers, setIsLoadingNewNumbers] = useState(false);
    const [number, setNumber] = useState('');
    const [token, setToken] = useState();
    const [isPurchasing, setIsPurchasing] = useState(false);
    const cardRef = useRef();

    const showNewNumbers = useCallback(async (country, type, areaCode) => {
        try {

            let result = [];

            setIsLoadingNewNumbers(true);
            if (type === 'local') {

                result = await ShoutOUTUtilityService.getNewNumbers(country, type, areaCode);

                // Issue : Couldn't resolve the promise when retrieving data 

                // const resultWithHeaders = await ShoutOUTUtilityService.refreshNumbers(country, type, areaCode);
                // const token = resultWithHeaders.headers.get('x-last-access-token');
                // setToken(token)

            } else {

                result = await ShoutOUTUtilityService.getNewNumbers(country, type);
            }

            setNewNumbers(result);
            setIsLoadingNewNumbers(false);

        } catch (e) {
            setIsLoadingNewNumbers(false);
        }
    }, [setNewNumbers, setIsLoadingNewNumbers])


    const makePurchase = useCallback((paymentMethodId) => {
        return new Promise(async (resolve, reject) => {
            try {
                await ShoutOUTUtilityService.purchaseNumbers({ paymentMethodId: paymentMethodId, ...number });
                resolve();
            } catch (e) {
                console.error(e);
                reject();
            }
        });
    }, [number])
    const onClickFinish = useCallback(async () => {
        try {
            setIsPurchasing(true);
            await cardRef.current.submit(makePurchase);
            notify.show("Number purchased successfully", "success");
            onHide();
        } catch (e) {
            console.error(e);
            notify.show("Number purchased failed. Try again", "error");
        } finally {
            setIsPurchasing(false);
        }
    }, [cardRef, makePurchase, setIsPurchasing, onHide])


    const steps = [
        {
            name: 'Select',
            isDisableNextBtn: number === '',
            nextBtnName: 'Next',
            component: <NumberPurchaseSelect newNumbers={newNumbers} isLoadingNewNumbers={isLoadingNewNumbers} setNumber={setNumber} showNewNumbers={showNewNumbers} number={number} />
        },
        {
            name: 'Purchase',
            nextBtnName: isPurchasing ? "Purchasing..." : 'Purchase',
            showNextBtn: true,
            component: <NumberPurchaseSummary number={number} ref={cardRef} isPurchasing={isPurchasing} />,
            onClickNext: onClickFinish,
            isDisableNextBtn: isPurchasing
        }

    ];


    return (
        <Modal show={show} onHide={onHide} className="border-0" size="lg">
            <Modal.Header closeButton>
                <h6 className="mb-0 font-weight-bold">Purchase Phone Number</h6>
            </Modal.Header>
            <Modal.Body className="natural-text text-center pt-0">
                <Multistep steps={steps} fixedStepBar>
                </Multistep>
            </Modal.Body>
        </Modal>
    )
}

export default NumberPurchaseModal;