import React from 'react';

import {
  Modal, Button
} from '@shoutout-labs/shoutout-themes';



const AlertBox = ({ message, type, title, show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <h6 className="mb-0 font-weight-bold">{title}</h6>
      </Modal.Header>
      <Modal.Body>
        <p className={`text-${type}`}>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" size="sm" onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default AlertBox;